.row {
  --bs-gutter-x: 2rem;
}

.for-des {
  display: block;
}

.for-mob {
  display: none;
}

.cs-link {
  text-decoration: underline;
}

.cs-text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

/* =================================== */
/* START - defalt class for all browser */
/* =================================== */

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url(../icons/svg/outlined/calender-outlined.svg);
  background-size: 20px 20px;
}

/* Hide Eye icon of browser From Input password*/
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Over - Hide Eye icon of browser From Input password */
/* Hide Arrows From Input Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Over - Hide Arrows From Input Number */
input::-webkit-input-placeholder {
  color: var(--neutral-70) !important;
  font-size: inherit;
  font-weight: 300;
  line-height: 16px;
  text-align: left !important;
}

input::-moz-placeholder {
  color: var(--neutral-70) !important;
  font-size: inherit;
  font-weight: 300;
  line-height: 16px;
  text-align: left !important;
}

input:-ms-input-placeholder {
  color: var(--neutral-70) !important;
  font-size: inherit;
  font-weight: 300;
  line-height: 16px;
  text-align: left !important;
}

input::-ms-input-placeholder {
  color: var(--neutral-70) !important;
  font-size: inherit;
  font-weight: 300;
  line-height: 16px;
  text-align: left !important;
}

input::placeholder {
  color: var(--neutral-70) !important;
  font-size: inherit;
  font-weight: 300;
  line-height: 16px;
  text-align: left !important;
}

/* =================================== */
/* END - defalt class for all browser */
/* =================================== */

/* ========================================================== */
/* START - HTML, Body, p, a, span,etc common - related classes */
/* ========================================================== */
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--cs-font);
  font-style: normal;
  background: var(--primary-canvas-surface);
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: var(--primary-main);
}

a:hover {
  color: var(--primary-pressed);
}

a:focus,
a:active {
  color: var(--primary-main);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

a {
  text-decoration: none;
}

.cursor-pointer,
.cursor-pointer label {
  cursor: pointer;
}

.cs-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Start - Typography */
.default-semi-bold-h1 {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}

.default-regular-h1 {
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}

.default-semi-bold-h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.default-regular-h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.default-semi-bold-h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

.default-regular-h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.default-semi-bold-h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.default-regular-h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.default-semi-bold-h5 {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.default-regular-h5 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.default-semi-bold-h6 {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.default-regular-h6 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.default-medium-sub-heading-xl {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.default-regular-sub-heading-xl {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.default-medium-sub-heading-l {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

.default-regular-sub-heading-l {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.default-medium-sub-heading-m {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.default-regular-sub-heading-m {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.default-medium-sub-heading-s {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.default-regular-sub-heading-s {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.default-medium-sub-heading-xs {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.default-regular-sub-heading-xs {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.default-light-sub-heading-xs {
  font-size: 12px;
  font-weight: 200;
  line-height: 16px;
}

.default-medium-sub-heading-xxs {
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
}

.default-regular-sub-heading-xxs {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.default-regular-body-text-xl {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.default-light-body-text-xl {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

.default-regular-body-text-l {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.default-light-body-text-l {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}

.default-regular-body-text-m {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.default-light-body-text-m {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.default-regular-body-text-s {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.default-light-body-text-s {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.default-regular-body-text-xs {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.default-light-body-text-xs {
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
}

.default-regular-body-text-xxs {
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
}

.default-light-sub-heading-s {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

span.sorting-before {
  display: inline-block;
  margin-left: 4px;
  transform: rotateX(180deg);
  transition: 0.3s all;
}

span.sorting-after {
  display: inline-block;
  margin-left: 4px;
  transform: rotateX(0deg);
  transition: 0.3s all;
}

/* End - Typography */
/* ========================================================== */
/* END - HTML, Body, p, a, span,etc common - related classes */
/* ========================================================== */

/* =================== */
/* START - Font Color Class */
/* =================== */
.cs-danger,
.cs-disabled .form-error-msg {
  color: var(--danger-main) !important;
}

.cs-danger .icon svg path,
.cs-danger span svg path,
.cs-danger svg path {
  fill: var(--danger-main);
}

.cs-warning {
  color: var(--warning-main);
}

.cs-warning .icon svg path,
.cs-warning span svg path,
.cs-warning svg path {
  fill: var(--warning-main);
}

.cs-success {
  color: var(--success-main);
}

.cs-success .icon svg path,
.cs-success span svg path,
.cs-success svg path {
  fill: var(--success-main) !important;
}

.cs-info {
  color: var(--info-main);
}

.cs-info .icon svg path,
.cs-info span svg path,
.cs-info svg path {
  fill: var(--info-main);
}

.cs-primary {
  color: var(--primary-main);
}

.cs-primary .icon svg path,
.cs-primary span svg path,
.cs-primary svg path {
  fill: var(--primary-main) !important;
}

.cs-neutral-100 {
  color: var(--neutral-100);
}

.cs-neutral-100 .icon svg path,
.cs-neutral-100 span svg path,
.cs-neutral-100 svg path {
  fill: var(--neutral-100);
}

.cs-neutral-90 {
  color: var(--neutral-90);
}

.cs-neutral-90 .icon svg path,
.cs-neutral-90 span svg path,
.cs-neutral-90 svg path {
  fill: var(--neutral-90);
}

.cs-neutral-80 {
  color: var(--neutral-80);
}

.cs-neutral-80 .icon svg path,
.cs-neutral-80 span svg path,
.cs-neutral-80 svg path {
  fill: var(--neutral-80);
}

.cs-neutral-70 {
  color: var(--neutral-70);
}

.cs-neutral-70 .icon svg path,
.cs-neutral-70 span svg path,
.cs-neutral-70 svg path {
  fill: var(--neutral-70);
}

.cs-neutral-60 {
  color: var(--neutral-60);
}

.cs-neutral-60 .icon svg path,
.cs-neutral-60 span svg path,
.cs-neutral-60 svg path {
  fill: var(--neutral-60) !important;
}

.cs-neutral-50 {
  color: var(--neutral-50);
}

.cs-neutral-50 .icon svg path,
.cs-neutral-50 span svg path,
.cs-neutral-50 svg path {
  fill: var(--neutral-50);
}

.cs-neutral-40 {
  color: var(--neutral-40);
}

.cs-neutral-40 .icon svg path,
.cs-neutral-40 span svg path,
.cs-neutral-40 svg path {
  fill: var(--neutral-40);
}

.cs-neutral-30 {
  color: var(--neutral-30);
}

.cs-neutral-30 .icon svg path,
.cs-neutral-30 span svg path,
.cs-neutral-30 svg path {
  fill: var(--neutral-30);
}

.cs-neutral-20 {
  color: var(--neutral-20);
}

.cs-neutral-20 .icon svg path,
.cs-neutral-20 span svg path,
.cs-neutral-20 svg path {
  fill: var(--neutral-20);
}

.cs-neutral-10 {
  color: var(--neutral-10);
}

.cs-neutral-10 .icon svg path,
.cs-neutral-10 span svg path,
.cs-neutral-10 svg path {
  fill: var(--neutral-10);
}

.cs-asset svg path {
  fill: var(--asset-main) !important;
}

.cs-liability svg path {
  fill: var(--liability-main) !important;
}

.cs-font-color-sub-title {
  color: var(--font-color-sub-title);
}

.cs-disabled-icon,
.wm-individual-action .cs-disabled-icon span,
button:disabled,
.cs-disabled-icon:focus,
button:disabled:focus,
.react-date-picker--disabled,
.react-date-picker--disabled .react-date-picker__wrapper,
.react-date-picker--disabled input {
  cursor: not-allowed !important;
}

.cs-file-icon .cs-disabled-icon {
  border-radius: 4px;
  border: 1px solid var(--neutral-40);
  background: var(--neutral-20);
  /* cursor: not-allowed; */
}

.cs-disabled-icon svg path {
  fill: var(--neutral-60) !important;
}

.cs-disabled,
.cs-disabled span,
.cs-neutral-60.cs-disabled p,
.cs-disabled:focus-visible,
.cs-common-add-dropdown .dropdown-item.cs-disabled:focus-visible,
.cs-common-add-dropdown .dropdown-item.cs-disabled:active,
.cs-neutral-60.cs-disabled p:focus-visible {
  color: var(--neutral-60) !important;
  cursor: not-allowed !important;
  background-color: transparent !important;
}

.cs-disabled svg,
.cs-disabled svg path {
  fill: var(--neutral-60) !important;
}

.dropdown-item.disabled {
  pointer-events: all !important;
}

.cs-form-group.cs-disabled input,
.disabled,
.cs-file-icon span.cs-icon-badge-neutral-20.cs-neutral-80.cs-disabled-icon,
.cs-disabled-icon span {
  cursor: not-allowed !important;
}

.cs-table-menu-icon {
  width: 26px;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  background: var(--neutral-20);
}

.cs-table-menu-icon svg {
  width: 14px;
}

/* =================== */
/* END - Font Color Class */
/* =================== */

/* ======================= */
/* START - Pagination css */
/* ======================= */
.cs-custom-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 32px;
}

.cs-custom-pagination .cs-common-add-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-custom-pagination .cs-common-add-dropdown .dropdown .dropdown-toggle {
  color: var(--neutral-80) !important;
  font-weight: 300 !important;
  padding: 0;
  background: transparent;
  border: 0;
  width: auto;
  height: auto;
  margin-inline: 8px;
}

.cs-custom-pagination .cs-common-add-dropdown .dropdown .dropdown-toggle svg {
  margin-inline: 8px;
}

.cs-custom-pagination .cs-common-add-dropdown .dropdown .dropdown-menu.show {
  width: auto;
  max-width: 100%;
  min-width: auto;
}

.cs-custom-pagination
  .cs-common-add-dropdown
  .dropdown
  .dropdown-menu.show
  .dropdown-item {
  padding: 6px 12px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-pagination-arrow {
  text-align: right;
  margin-top: -6px;
}

.cs-pagination-arrow button {
  background: transparent !important;
  border: none !important;
  padding: 0;
  width: 40px;
  height: auto;
}

.cs-pagination-arrow button svg {
  width: 14px;
  height: 14px;
}

.cs-pagination-arrow .cs-neutral-50 {
  cursor: not-allowed;
  pointer-events: auto;
}

/* ======================= */
/* END - Pagination css */
/* ======================= */

.assets-table-container .dollar-value-gap,
.category-cs-table-container .dollar-value-gap,
.pfs-table-container .dollar-value-gap,
.finance-tbody .dollar-value-gap,
.contingent-tbody .dollar-value-gap,
.dashboard-main-section .total-networth .dollar-value-gap.net-worth-value,
.amortization-main-listing .dollar-value-gap,
.cs-transaction-table .dollar-value-gap,
.financial-activity-table [data-column-id="4"].rdt_TableCell .dollar-value-gap,
.billing-subscription-page [data-column-id="6"].rdt_TableCell .dollar-value-gap,
.billing-subscription-page
  .billing-history
  [data-column-id="4"].rdt_TableCell
  .dollar-value-gap,
.portfolio-data-table .dollar-value-gap,
.cs-accoutns-details .dollar-value-gap {
  width: 100% !important;
  max-width: 150px !important;
}

.dashboard-main-section .finance-tbody tr td:nth-child(2),
.dashboard-main-section .contingent-tbody tr td:nth-child(2) {
  padding-right: 10px;
  padding-left: 0;
}

th.default-medium-sub-heading-l:last-child {
  padding-left: 0 !important;
}

/* ======================= */
/* START - Common Buttons */
/* ======================= */
.cs-btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
}

.cs-btn-group > :first-child {
  margin-right: 16px;
}

/* Primary button */
.cs-btn-primary {
  padding: 10px;
  color: var(--neutral-10) !important;
  background: var(--primary-main);
  border-radius: 4px;
  border: 2px solid var(--primary-main);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  line-height: 16px;
}

.cs-btn-primary:hover {
  background: var(--primary-hover);
}

.cs-btn-primary:active,
.cs-btn-primary:focus-visible,
.cs-btn-primary:focus {
  background: var(--primary-pressed) !important;
  /* border-color:  var(--primary-pressed) !important; */
}

.cs-btn-primary:disabled,
.cs-btn-icon-primary:disabled,
.cs-btn-danger:disabled {
  color: var(--neutral-60) !important;
  border: 1px solid var(--neutral-40) !important;
  padding: 11px 10px !important;
  background: var(--neutral-20) !important;
  cursor: not-allowed;
  pointer-events: auto;
}

.cs-btn-primary:disabled:focus,
.cs-btn-icon-primary:disabled:focus,
.cs-btn-danger:disabled:focus,
.cs-btn-primary:disabled:focus-visible,
.cs-btn-icon-primary:disabled:focus-visible,
.cs-btn-danger:disabled:focus-visible,
.cs-btn-primary:disabled:active,
.cs-btn-icon-primary:disabled:active,
.cs-btn-danger:disabled:active {
  color: var(--neutral-60) !important;
  border: 1px solid var(--neutral-40) !important;
  padding: 11px 10px !important;
  background: var(--neutral-20) !important;
  cursor: not-allowed;
  /* pointer-events: auto; */
}

.cs-btn-icon-tertiary:disabled {
  color: var(--neutral-60) !important;
  border: none !important;
  background: transparent !important;
  cursor: not-allowed;
  pointer-events: auto;
}

.cs-btn-icon-tertiary:disabled span {
  color: var(--neutral-60) !important;
}

.cs-btn-icon-tertiary:disabled span.cs-primary svg path {
  fill: var(--neutral-60) !important;
}

.cs-rating {
  position: relative;
  margin-bottom: 24px;
}

.cs-rating label {
  display: block;
  margin-bottom: 3px;
}

.filled-icons svg {
  margin-right: 12px;
}

.empty-icons svg {
  margin-right: 12px;
}

.text-end .cs-btn-primary {
  margin-inline: auto 0;
}

/* *******Start Primary Button With Icon********* */

.cs-btn-icon-primary {
  border-radius: 4px;
  color: var(--neutral-10) !important;
  background-color: var(--primary-main);
  border: 2px solid var(--primary-main);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 9px 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 16px;
}

.cs-btn-icon-primary:hover {
  background-color: var(--primary-hover);
}

.cs-btn-icon-primary:active,
.cs-btn-icon-primary:focus-visible {
  background-color: var(--primary-pressed) !important;
}

.cs-btn-icon-primary span.cs-neutral-10,
.cs-btn-icon-secondary span.cs-primary,
.cs-btn-icon-tertiary span.cs-primary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 8px;
}

.cs-btn-icon-primary:disabled span.cs-neutral-10 svg path,
.cs-btn-icon-secondary:disabled span.cs-primary svg path,
.cs-btn-icon-tertiary:disabled span.cs-primary svg path {
  fill: var(--neutral-60);
}

.cs-btn-icon-secondary:hover span.cs-primary svg path,
.cs-btn-icon-tertiary:hover span.cs-primary svg path {
  fill: var(--primary-hover);
}

.cs-btn-icon-secondary:active span.cs-primary svg path,
.cs-btn-icon-tertiary:active span.cs-primary svg path {
  fill: var(--primary-pressed);
}

/* ********End Primary Button With Icon********** */

/* *******Start Secondary Button With Icon********* */
.cs-btn-icon-secondary {
  border-radius: 4px;
  border: 2px solid var(--primary-main) !important;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--primary-main);
  background-color: transparent;
  line-height: 16px;
}

.cs-btn-icon-secondary:hover {
  border: 2px solid var(--primary-hover);
  color: var(--primary-hover);
  background-color: var(--primary-surface);
}

.cs-btn-icon-secondary:active,
.cs-btn-icon-secondary:focus-visible {
  border: 2px solid var(--primary-pressed) !important;
  background-color: var(--primary-surface) !important;
  color: var(--primary-pressed) !important;
}

/* ********End Secondary Button With Icon********** */

/* ********Start Tertiary Button With Icon********** */
.cs-btn-icon-tertiary {
  border-radius: 4px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--primary-main);
  background-color: transparent !important;
  line-height: 16px;
}

.cs-btn-icon-tertiary:hover {
  color: var(--primary-hover);
}

.cs-btn-icon-tertiary:active,
.cs-btn-icon-tertiary:focus-visible {
  color: var(--primary-pressed) !important;
}

/* ********End Tertiary Button With Icon********** */

/* Secondary button */
.cs-btn-secondary {
  padding: 10px;
  color: var(--primary-main);
  background: var(--neutral-10);
  border-radius: 4px;
  border: 2px solid var(--primary-main);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 16px;
}

.cs-btn-secondary:hover {
  color: var(--primary-hover);
  background: var(--primary-surface);
  border: 2px solid var(--primary-hover);
}

.cs-btn-secondary:active,
.cs-btn-secondary:focus-visible,
.cs-btn-secondary:focus {
  color: var(--primary-pressed) !important;
  background: var(--primary-surface) !important;
  border: 2px solid var(--primary-pressed) !important;
}

.cs-btn-secondary:disabled,
.cs-btn-icon-secondary:disabled {
  color: var(--neutral-60) !important;
  border: 1px solid var(--neutral-40) !important;
  background: var(--neutral-20) !important;
  cursor: not-allowed;
  pointer-events: auto;
}

/* Tertiary button */
.cs-btn-tertiary {
  padding: 10px;
  color: var(--primary-main);
  border-radius: 4px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent !important;
  line-height: 16px;
}

.cs-btn-tertiary:hover {
  color: var(--primary-hover);
}

.cs-btn-tertiary:active,
.cs-btn-tertiary:focus-visible,
.cs-btn-tertiary:focus {
  color: var(--primary-pressed) !important;
}

.cs-btn-tertiary:disabled {
  color: var(--neutral-60);
}

/* Danger button */
.cs-btn-danger {
  padding: 10px;
  color: var(--neutral-10);
  background: var(--danger-main);
  border-radius: 4px;
  border: 2px solid var(--danger-main) !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 16px;
}

.cs-btn-danger:hover {
  background: var(--danger-hover);
}

.cs-btn-danger:active,
.cs-btn-danger:focus-visible,
.cs-btn-danger:focus {
  background: var(--danger-pressed) !important;
}

/* Success button */
.cs-btn-success {
  padding: 10px;
  color: var(--neutral-10);
  background: var(--success-main);
  border-radius: 4px;
  border: 2px solid var(--success-main) !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 16px;
}

.cs-btn-success:hover {
  background: var(--success-hover);
}

.cs-btn-success:active,
.cs-btn-success:focus-visible,
.cs-btn-success:focus {
  background: var(--success-pressed) !important;
}

/* button size */
.sm-btn {
  width: 100%;
  max-width: 101px;
}

.md-btn {
  width: 100%;
  max-width: 149px;
}

.lg-btn {
  width: 100%;
  max-width: 197px;
}

.xl-btn {
  width: 100%;
  max-width: 245px;
}

.responsive-btn {
  padding: 12px 96px;
}

/* ======================= */
/* END - Common Buttons */
/* ======================= */

/* ======================= */
/* START - Common Input fields */
/* ======================= */
/* start - textbox*/
.cs-form-group {
  position: relative;
  text-align: left;
  margin-bottom: 24px;
}

.cs-form-group .form-label,
.form-label {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--neutral-100);
  margin-bottom: 4px;
}

.cs-form-group .form-control,
.cs-form-group .cs-textarea,
.cs-form-group .cs-select-group {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  height: 40px;
  width: 100%;
  color: var(--neutral-100);
  border-radius: 4px;
  padding: 10px 16px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-20);
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cs-form-group .form-control:focus-visible,
.cs-input-field-active.form-control,
.cs-input-field-active.cs-textarea,
.cs-form-group .cs-input-field-active .PhoneInputInput,
.cs-form-group .cs-textarea:focus-visible {
  background: var(--neutral-10);
}

.cs-form-group.cs-disabled .form-label {
  color: var(--neutral-60);
}

.cs-form-group .form-control:disabled,
.cs-disabled .react-date-picker--disabled,
.cs-common-add-dropdown .dropdown .dropdown-toggle:disabled {
  background: var(--neutral-40);
  border-color: var(--neutral-40);
  color: var(--neutral-60);
  cursor: not-allowed;
}

.input-field-icon {
  position: absolute !important;
  top: 50%;
  right: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/* =========================== */
/* START - DatePicker Calender */
/* =========================== */

.cs-date-picker-calender {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 10px 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 40px;
}

.cs-date-picker-calender .react-date-picker__inputGroup__input {
  border: none;
  outline: none;
  cursor: pointer;
}

.cs-date-picker-calender .react-calendar {
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  border: none;
}

.cs-date-picker-calender .react-date-picker__calendar--open {
  width: 100%;
  z-index: 3;
}

.cs-add-acc-modal .cs-form-group .react-date-picker--disabled {
  border: 1px solid var(--neutral-40);
  background: var(--neutral-40);
  color: var(--neutral-60);
}

.cs-form-group
  .react-date-picker--enabled.cs-input-field-active.cs-date-picker-calender {
  background: var(--neutral-10);
  color: var(--neutral-100);
}

/* ========================= */
/* END - DatePicker Calender */
/* ========================= */
.cs-add-acc-modal {
  padding: 24px 32px;
}

/* end - textbox*/

/* start - textarea */
.cs-form-group .cs-textarea {
  height: 100px;
}

/* end - textarea*/

/* start - search bar*/
.cs-search-bar .cs-form-group {
  margin-bottom: 0;
}

.cs-search-bar .cs-form-group .form-control {
  padding-right: 45px;
}

.cs-search-bar .cs-form-group .form-control:hover {
  border: 1px solid var(--primary-hover);
}

.cs-search-bar .cs-form-group .form-control:active,
.cs-search-bar .cs-form-group .form-control:focus-visible {
  border: 1px solid var(--neutral-50);
}

/* End - search bar*/

/* start - phoneInputInput */
.form-control.PhoneInput {
  display: block;
  padding: 0;
}

.cs-form-group .PhoneInputInput {
  border: none;
  background: var(--neutral-20);
  outline: none;
  height: 100%;
  width: 100%;
  padding: 10px 16px 10px 46px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.cs-form-group .PhoneInputInput:focus-visible {
  background: var(--neutral-10);
}

.form-control.PhoneInput .PhoneInputCountry {
  position: absolute;
  top: 50%;
  left: 16px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/* end PhoneInputInput*/

/* common dropdown*/
.dropdown .dropdown-toggle {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--neutral-70);
  padding: 10px 16px;
  border-radius: 4px;
  height: 40px;
  background: var(--neutral-10);
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown .cs-active-accounts,
.dropdown .cs-active-accounts span,
.dropdown .cs-active-accounts span h6,
.dropdown .cs-active-accounts .default-light-body-text-s,
.cs-common-add-dropdown
  .dropdown
  .dropdown-menu.show
  .dropdown-item.cs-selected-dropdown {
  background: var(--primary-surface);
  color: var(--primary-main) !important;
}

.dropdown .dropdown-toggle:active {
  border-color: var(--neutral-50);
  color: var(--neutral-70);
  background: var(--neutral-10);
}

.option-btn button {
  padding: 0 !important;
  border: 0 !important;
  outline: none !important;
}

.cs-common-add-dropdown.option-btn .dropdown-menu {
  width: 200px !important;
}

.css-t3ipsp-control {
  width: 100% !important;
}

/* end common dropdown*/

/* Start Progressbar */
.cs-progress-bar {
  width: 100%;
  margin: 18px auto 24px;
}

.cs-progress-bar .progress {
  width: 100%;
  height: 30px;
  border-radius: 4px;
  background: var(--neutral-40);
}

.cs-progress-bar .progress-bar {
  border-radius: 4px;
  background: var(--primary-main);
}

/* End Progressbar */
/* form dropdown*/
.cs-form-dropdown .dropdown-toggle {
  border: 1px solid var(--neutral-50);
  position: relative;
}

.cs-form-dropdown .dropdown-item {
  padding: 10px 16px;
  color: var(--neutral-90);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.cs-form-dropdown .dropdown-item:hover {
  background: var(--primary-surface);
}

.cs-form-dropdown .dropdown-item-active {
  color: var(--primary-hover);
  background: var(--primary-surface);
}

.cs-form-dropdown .dropdown-menu.show {
  width: 100%;
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  border: 0;
}

.cs-form-dropdown {
  position: relative;
  margin-bottom: 24px;
}

.cs-form-dropdown .dropdown-toggle:active {
  background: var(--neutral-10);
  color: var(--neutral-70);
  border-color: var(--neutral-50);
}

/* end - form dropdown*/

/* START - CheckBox  */
.cs-form-check-box {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-form-check-box .form-check-input {
  width: 16px;
  height: 16px;
  border: 1px solid var(--neutral-50);
  margin: 0px 8px 0px 0px;
  cursor: pointer;
}

.cs-form-check-box .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cs-form-check-box .form-check-input:active {
  -webkit-filter: none;
  filter: none;
}

.cs-form-check-box .form-check-input:checked {
  background-color: var(--primary-main);
  border-color: var(--primary-main);
}

.cs-form-check-box .form-check-input:hover {
  border: 1px solid var(--primary-hover);
}

.cs-form-check-box .form-check-label {
  cursor: pointer;
}

.auth-mfa-item.cs-disabled {
  cursor: not-allowed !important;
  border-color: var(--neutral-40) !important;
}

.auth-mfa-item.cs-disabled .cs-neutral-100 {
  color: var(--neutral-40);
}

/* END - CheckBox  */

/* Start - radio  */
.radio-button {
  width: 18px;
  height: 18px;
  -webkit-box-shadow: 0px 0px 0px 1px var(--neutral-50);
  box-shadow: 0px 0px 0px 1px var(--neutral-50);
  border-radius: 50%;
  background: var(--neutral-10);
  border: 4px solid var(--neutral-10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.radio-button:hover {
  -webkit-box-shadow: 0px 0px 0px 1px var(--primary-hover);
  box-shadow: 0px 0px 0px 1px var(--primary-hover);
}

.radio-button:checked {
  background: var(--primary-main);
}

.radio-button:checked:hover {
  -webkit-box-shadow: 0px 0px 0px 1px var(--primary-main);
  box-shadow: 0px 0px 0px 1px var(--primary-main);
  border: 4px solid var(--neutral-10);
}

.cs-radio-btn.cs-disabled,
.cs-radio-btn.cs-disabled .radio-button,
.signature-return-box .cs-radio-btn.cs-disabled label {
  cursor: not-allowed !important;
}

.cs-radio-btn.cs-disabled .radio-button:checked:disabled:hover,
.cs-radio-btn.cs-disabled .radio-button:disabled:hover {
  -webkit-box-shadow: 0px 0px 0px 1px var(--neutral-60) !important;
  box-shadow: 0px 0px 0px 1px var(--neutral-60) !important;
}

.radio-button:checked:disabled {
  background: var(--neutral-60) !important;
}

.cs-radio-btn-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.cs-radio-btn-label span {
  margin-left: 8px;
}

/* End - radio*/

/* start - textbox - react date picker */
.cs-form-group .react-date-picker__wrapper {
  border: none;
}

/* end - textbox - react date picker */

/* start - loan month year textbox - dropdown  */
.cs-form-group .form-text-group-loan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-text-group-loan .dropdown-toggle {
  width: 160px;
}

.form-text-group-loan .cs-form-dropdown {
  margin-bottom: 0px;
}

.cs-lg-modal .amortization-schedule-container {
  margin-bottom: 24px;
}

/* end - loan month year textbox - dropdown  */

/* Date Picker start */
.cs-date-picker-calender .react-calendar__tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-90);
  border: 1px solid transparent !important;
}

.cs-date-picker-calender .react-calendar__tile--active {
  background: var(--primary-main) !important;
  color: var(--neutral-10) !important;
  border-radius: 4px;
}

.cs-date-picker-calender .react-calendar__tile--now {
  border: 1px solid var(--primary-main);
  background: var(--primary-surface);
}

.cs-date-picker-calender .react-calendar__tile:disabled {
  border: 1px solid var(--neutral-50);
  background: var(--neutral-40);
  color: var(--neutral-60);
  cursor: not-allowed;
}

.cs-date-picker-calender .react-calendar__tile:enabled:hover,
.cs-date-picker-calender .react-calendar__tile:enabled:focus {
  border-color: var(--primary-main) !important;
  background: transparent;
  border-radius: 4px;
}

/* Date Picker start */

/* ======================= */
/* END - Common Input fields */
/* ======================= */

/* ======================= */
/* START - form error */
/* ======================= */
.form-error-msg {
  position: absolute;
  display: block;
  bottom: auto;
  left: 0;
  margin-bottom: 0;
  font-size: 10px !important;
  line-height: 16px !important;
  z-index: 2;
}

.cs-form-group.cs-form-label .input-field-icon {
  margin-top: 11px;
  right: 16px !important;
}

.cs-form-group.cs-form-label .input-field-icon,
.cs-form-group.cs-form-label .input-field-icon svg {
  width: 18px;
  height: 18px;
}

/* ======================= */
/* END - form error */
/* ======================= */

/* ======================= */
/* START - CS Carousel */
/* ======================= */

.cs-common-carousel {
  position: relative;
  margin-bottom: 40px;
}

/* .cs-common-carousel .slick-list {
  width: calc(100% + 24px);
  overflow: hidden;
  padding-right: 80px;
} */
.cs-common-carousel .slick-slider {
  overflow: hidden;
}

.cs-common-carousel .slick-list {
  margin-inline: -6px;
}

.cs-common-carousel .slick-track .slick-slide:last-child {
  margin-left: -14px;
}

.cs-common-carousel .slick-track .slick-slide {
  padding-inline: 6px;
}

.cs-common-carousel .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-common-carousel .slick-dots {
  position: absolute;
  bottom: -24px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto !important;
  padding: 0 !important;
  background: var(--neutral-40);
  border-radius: 8px;
}

.cs-common-carousel .slick-dots li {
  display: block;
  padding: 0 !important;
  font-size: 0;
}

.cs-common-carousel .slick-dots li button {
  background: transparent;
  margin: 0;
  width: 20px;
  height: 8px;
  padding: 0;
  border: 0;
  border-radius: 10px;
  opacity: 1;
  -webkit-transition: background 0.6s ease-in-out;
  -o-transition: background 0.6s ease-in-out;
  transition: background 0.6s ease-in-out;
}

.cs-common-carousel .slick-dots li.slick-active button {
  background: var(--primary-main);
}

.cs-common-carousel .slick-arrow {
  position: absolute;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 50%;
  left: -14px;
  -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
  z-index: 1;
  width: 38px;
  height: 38px;
  font-size: 0;
  border: 1px solid transparent;
  border-radius: 5px;
  outline: none !important;
  background: var(--neutral-10);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
}

.cs-common-carousel .slick-arrow::before {
  content: "";
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  -webkit-mask-image: url(../icons/svg/filled/carousel-arrow.svg);
  mask-image: url(../icons/svg/filled/carousel-arrow.svg);
  background-color: var(--neutral-100);
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.cs-common-carousel .slick-arrow.slick-next {
  left: auto;
  right: -14px;
}

.cs-common-carousel .slick-arrow.slick-next::before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cs-common-carousel .slick-arrow.slick-disabled {
  background: var(--neutral-30);
  border-color: var(--neutral-50);
  cursor: not-allowed;
}

.cs-common-carousel .slick-arrow.slick-disabled:before {
  background-color: var(--neutral-60);
}

/* ======================= */
/* END - CS Carousel */
/* ======================= */
.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 16px;
  width: 18px;
  height: 18px;
  background-image: unset;
  -webkit-mask-image: url(../icons/svg/outlined/chevron-down-outlined.svg);
  mask-image: url(../icons/svg/outlined/chevron-down-outlined.svg);
  background-color: var(--neutral-90);
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
}

.financial-request-section .accordion-button::after,
.financial-request-section .accordion-button:not(.collapsed)::after {
  background-color: var(--neutral-80);
}

.companies-table .accordion-button::after,
.companies-table .accordion-button:not(.collapsed)::after {
  background-color: var(--neutral-70);
}

.rdt_Pagination .sc-jsJBEP svg {
  display: none;
}

.rdt_Pagination .sc-jsJBEP::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 14px;
  min-width: 14px;
  max-width: 14px;
  height: 14px;
  min-height: 14px;
  max-height: 14px;
  -webkit-mask-image: url(../icons/svg/outlined/chevron-down-outlined.svg);
  mask-image: url(../icons/svg/outlined/chevron-down-outlined.svg);
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: var(--neutral-70);
  margin-left: 14px;
}

.rdt_Pagination .sc-bXCLTC:active,
.rdt_Pagination .sc-bXCLTC:focus,
.rdt_Pagination .sc-bXCLTC:target,
.rdt_Pagination .sc-bXCLTC:focus-visible,
.rdt_Pagination .sc-bXCLTC:focus-within {
  outline: 0;
  border: none;
}

.rdt_Pagination .sc-bXCLTC {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: var(--neutral-80);
}

.rdt_Pagination .sc-koXPp:hover:not(:disabled),
.rdt_Pagination .sc-koXPp:focus {
  background-color: transparent;
}

.rdt_Pagination .sc-koXPp {
  color: var(--neutral-70);
  fill: var(--neutral-70);
}

.rdt_Pagination .sc-koXPp:disabled {
  color: var(--neutral-50);
  fill: var(--neutral-50);
}

.rdt_Pagination .sc-bXCLTC option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  outline: 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  white-space: wrap;
  color: var(--neutral-90);
  background-color: var(--neutral-10);
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 6px 12px;
  cursor: pointer;
}

/* ======================= */
/* START - Dropdown icon*/
/* ======================= */
.select-car-dropdown .css-1xc3v61-indicatorContainer svg,
.select-car-dropdown .css-15lsz6c-indicatorContainer svg {
  display: none;
}

.select-car-dropdown .css-15lsz6c-indicatorContainer:before,
.select-car-dropdown .css-1xc3v61-indicatorContainer:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url(../icons/svg/outlined/chevron-down-outlined.svg);
  mask-image: url(../icons/svg/outlined/chevron-down-outlined.svg);
  background-color: var(--neutral-90);
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  cursor: pointer;
}

.select-car-dropdown .css-13cymwt-control,
.select-car-dropdown .css-t3ipsp-control {
  cursor: pointer;
}

.select-car-dropdown .css-hlgwow,
.select-car-dropdown .css-1fdsijx-ValueContainer,
.select-car-dropdown .css-1fdsijx-ValueContainer {
  padding-left: 16px;
}

.select-car-dropdown .css-15lsz6c-indicatorContainer,
.select-car-dropdown .css-1xc3v61-indicatorContainer {
  padding-right: 16px;
}

.select-car-dropdown .css-1nmdiq5-menu > div {
  max-height: 100px;
  overflow: hidden;
  overflow-y: auto;
}

.css-1nmdiq5-menu * {
  cursor: pointer !important;
}

/* ======================= */
/* END - Dropdown icon */
/* ======================= */

/* ======================= */
/* START - Capsync HEADER */
/* ======================= */

.cs-header,
.expanded-header {
  background-color: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  width: 100%;
  max-width: calc(100% - 272px);
  position: fixed;
  padding: 12px 16px;
  z-index: 3;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.expanded-header {
  max-width: calc(100% - 272px);
}

.menuClosed .cs-header,
.menuClosed .expanded-header {
  max-width: calc(100% - 72px);
}

.navbar {
  padding: 0px;
}

.cs-header .nav-link {
  padding: 0px !important;
}

.expanded-header .nav-link {
  padding: 0px !important;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: block;
}

.navbar .cs-nav-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-nav-header .navbar-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* ======================= */
/* END - Capsync HEADER */
/* ======================= */

/* ============================== */
/* START - Capsync Badge & Toast */
/* ============================== */
.cs-badge {
  padding: 3px 8px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
}

.cs-badge.cs-primary {
  color: var(--primary-main);
  border: 1px solid var(--primary-border);
  background: var(--primary-surface);
}

.cs-badge.cs-danger {
  color: var(--danger-pressed);
  border: 1px solid var(--danger-border);
  background: var(--danger-surface);
}

.cs-badge.cs-info {
  color: var(--info-pressed);
  border: 1px solid var(--info-border);
  background: var(--info-surface);
}

.cs-badge.cs-warning {
  color: var(--warning-pressed);
  border: 1px solid var(--warning-border);
  background: var(--warning-surface);
}

.cs-badge.cs-success {
  color: var(--success-pressed);
  border: 1px solid var(--success-border);
  background: var(--success-surface);
}

/* Start - Tables Action icon classes */

.other-action-td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.other-action-td .table-icons-container {
  margin-right: 24px;
}

.other-action-td .cs-common-add-dropdown {
  margin: 0;
}

.other-action-td .cs-common-add-dropdown .common-tooltip-margin {
  margin: 0;
}

.cs-icon-badge-danger {
  background: var(--danger-surface);
}

.cs-icon-badge-neutral-20 {
  background: var(--neutral-20);
}

.table-icons-container.cs-file-icon span,
.amortization-schedule-box span {
  width: 26px;
  height: 26px;
  margin-right: 8px;
  margin-left: 0;
}

.table-icons-container.cs-file-icon span:last-child {
  margin-right: 0;
}

.cs-icon-badge-primary {
  border-radius: 100%;
  background: var(--primary-main);
  padding: 6px;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-danger.cs-disabled {
  background: var(--neutral-40) !important;
}

/* End - Tables Action icon classes */

/* =========================== */
/* END - Capsync Badge & Toast */
/* =========================== */

/* ======================= */
/* START - App layout*/
/* ======================= */
.cs-main-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.cs-main-layout .cs-main-area {
  margin-left: 0;
  width: 100%;
  height: 100%;
  /* padding-left: 272px; */
}

.cs-main-layout .cs-context {
  padding: 96px 16px 32px 16px;
}

.cs-main-sidebar {
  width: 272px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  border-right: 1px solid var(--neutral-30);
  background: var(--neutral-10);
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 4;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  overflow-y: auto;
}

.collapse-sidebar.cs-main-sidebar {
  width: 72px;
  padding: 12px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.collapse-sidebar.cs-main-sidebar .sidebar-nav {
  padding-inline: 4px;
}

.cs-main-sidebar .cs-logo {
  text-align: center;
  display: block;
  margin-top: 4px;
  margin-bottom: 44px;
}

.cs-main-sidebar .sidebar-header .btn-link {
  position: absolute;
  top: 30px;
  right: 15px;
}

.cs-main-sidebar .sidebar-header .close-btn {
  display: none;
}

.cs-main-layout .sidebar-nav a:focus,
.cs-main-layout .sidebar-nav a:active {
  color: var(--neutral-80);
}

.cs-main-layout .sidebar-nav a.active:focus,
.cs-main-layout .sidebar-nav a.active:active {
  color: var(--primary-main);
}

/* /Tarun start */
/* .asset-pie-chart .apexcharts-legend-series span.apexcharts-legend-text {
  overflow: visible !important;
} */

.chart-tooltip {
  position: relative;
  display: block;
}

.chart-tooltip:after {
  content: attr(data-title);
  display: block;
  position: absolute;
  top: 100%;
  left: -60px;
  background-color: var(--neutral-10);
  color: var(--neutral-100);
  padding: 4px;
  border-radius: 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  border: 1px solid var(--neutral-50);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  opacity: 0;
  z-index: 1;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  white-space: pre-line;
  /* white-space: revert; */
}

.apexcharts-legend-series
  span.apexcharts-legend-text:hover
  .chart-tooltip:after {
  opacity: 1;
}

.mortgages-on-real-estate-form .cs-form-group.last-form-field,
.cs-complete-profile .cs-form-group.last-form-field {
  margin-bottom: 24px;
}

/* Tarun end */
/* ======================= */
/* END - App layout */
/* ======================= */

/* ============================= */
/* START - Common scrollbar */
/* ============================= */
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--neutral-60);
}

/* Small webkit-scrollbar start*/
.cs-scroller {
  position: relative;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-10);
  border-radius: 5px;

  /* box-shadow: none; */
}

::-webkit-scrollbar-thumb {
  background: var(--neutral-50);
  border-radius: 5px;
}

/* Small webkit-scrollbar end*/

/* ============================= */
/* END - Common scrollbar */
/* ============================= */

/* ============================= */
/* START - Text Selections */
/* ============================= */
::-moz-selection {
  color: var(--neutral-10);
  fill: var(--neutral-10);
  background-color: var(--primary-main);
}

::selection {
  color: var(--neutral-10);
  fill: var(--neutral-10);
  background-color: var(--primary-main);
}

::-moz-selection {
  color: var(--neutral-10);
  fill: var(--neutral-10);
  background-color: var(--primary-main);
}

/* ============================= */
/* END - Text Selections */
/* ============================= */

/* ============================= */
/* START - Sidebar menu item component */
/* ============================= */

.cs-main-nav-item {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
  background: var(--neutral-10);
  color: var(--neutral-80);
  padding: 8px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.cs-menu-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 16px;
}

.cs-menu-icon svg path {
  fill: var(--neutral-80);
}

.cs-main-nav-item.active .default-regular-h5 {
  color: var(--primary-main);
}

.cs-main-nav-item.active .cs-menu-icon svg path,
.cs-primary-main svg path {
  fill: var(--primary-main);
}

.cs-main-nav-item.active {
  background-color: var(--primary-surface);
  color: var(--primary-main);
}

.cs-main-nav-item:hover {
  background-color: var(--primary-surface);
  color: var(--neutral-100);
}

.cs-main-nav-item.active:hover {
  color: var(--primary-main);
}

.cs-main-nav-item:hover svg path {
  fill: var(--neutral-100);
}

/* ============================= */
/* END - Sidebar menu item component */
/* ============================= */

/* ============================= */
/* START - Common Tab  */
/* ============================= */

/* ====(Stroke All side) ======= */

.cs-tabs-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 24px;
}

.cs-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.cs-tabs .cs-stroke-default-tab,
.cs-tabs .cs-stroke-bottom-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 222px;
  text-wrap-mode: nowrap;
  padding: 11px 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  color: var(--neutral-90);
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 16px;
}

.cs-tabs .cs-stroke-default-tab.cs-stroke-active {
  border: 1px solid var(--primary-main);
  background: var(--primary-surface);
  color: var(--primary-main);
}

.cs-tabs .cs-stroke-default-tab:hover,
.cs-tabs .cs-stroke-default-tab:focus-visible {
  border: 1px solid var(--primary-hover);
  background: var(--primary-surface);
  color: var(--neutral-90);
}

.cs-tabs .cs-stroke-default-tab:active,
.cs-tabs .cs-stroke-default-tab:focus-visible,
.cs-tabs .cs-stroke-default-tab.cs-stroke-active:active {
  border: 1px solid var(--primary-main);
  background: var(--primary-surface);
  color: var(--primary-main);
}

.cs-tabs .cs-stroke-default-tab:disabled,
.cs-tabs .cs-stroke-bottom-tab:disabled {
  border: 1px solid var(--neutral-50);
  background: var(--neutral-40);
  color: var(--neutral-60);
}

.cs-tabs .cs-stroke-default-tab:first-child {
  border-radius: 4px 0px 0px 4px;
}

.cs-tabs .cs-stroke-default-tab:last-child {
  border-radius: 0px 4px 4px 0px;
}

.cs-tabs-flex .cs-search-bar {
  width: 350px;
}

/* ====(Stroke Bottom side) ======= */
.cs-tabs .cs-stroke-bottom-tab {
  border: 0;
  border-bottom: 2px solid transparent;
}

.cs-tabs .cs-stroke-bottom-tab.cs-stroke-bottom-active,
.cs-tabs .cs-stroke-bottom-tab:hover,
.cs-tabs .cs-stroke-bottom-tab:active,
.cs-tabs .cs-stroke-bottom-tab:focus-visible,
.cs-tabs .cs-stroke-bottom-active:hover,
.cs-tabs .cs-stroke-bottom-active:active,
.cs-tabs .cs-stroke-bottom-active:focus-visible {
  border-color: var(--primary-main);
  color: var(--primary-hover);
  background: var(--neutral-10);
}

.cs-main-layout .setting-mobile-dropdown {
  display: none;
}

/* ============================= */
/* END - Common Tab  */
/* ============================= */

/* ============================= */
/* START - Common Breadcrumb  */
/* ============================= */
.cs-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  line-height: 0;
}

.cs-breadcrumb a {
  color: var(--neutral-80);
}

.cs-breadcrumb span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-breadcrumb span.cs-neutral-70 {
  padding: 0 4px;
}

/* ============================= */
/* END - Common Breadcrumb  */
/* ============================= */

/* ==================================================================================================*/
/* START - Common default tooltip (content aligned left,content with bullets,content with icon and text)*/
/* ==================================================================================================*/
.accredited-investor-tooltip.tooltip .tooltip-inner {
  text-align: left;
  width: auto;
  max-width: 100%;
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.cs-tooltip .tooltip-inner {
  text-align: left;
  padding: 8px;
  min-width: 196px;
  border-radius: 4px;
  background: var(--neutral-100);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  color: var(--neutral-10);
}

.cs-tooltip .tooltip-inner ul {
  margin-bottom: 0px;
  padding-left: 16px;
}

.cs-tooltip .content-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

.cs-tooltip .content-box .cs-neutral-10 {
  margin-right: 8px;
}

.cs-tooltip .tooltip-title {
  color: var(--neutral-50);
}

.cs-form-tooltip {
  position: absolute;
  top: 50%;
  right: 14px;
  -webkit-transform: translate(0, -16%);
  -ms-transform: translate(0, -16%);
  transform: translate(0, -16%);
}

.cs-form-tooltip span,
.cs-form-tooltip svg {
  width: 22px;
  height: 22px;
}

/* ==================================================================================================*/
/* END - Common default tooltip (content aligned left,content with bullets,content with icon and text)*/
/* ==================================================================================================*/

/* ============================= */
/* START - Common Switch  */
/* ============================= */
.cs-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.cs-switch-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  width: 28px;
  height: 16px;
  /* background: var(--primary-main); */
  background: var(--neutral-40);
  border-radius: 20px;
  position: relative;
  -webkit-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
  padding: 2px;
}

.cs-switch-label:disabled {
  background: var(--neutral-30);
}

.cs-switch-label .switch-button {
  content: "";
  position: absolute;
  top: 50%;
  left: 4px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 100%;
  -webkit-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  -o-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  background: var(--neutral-10);
}

.cs-switch-checkbox:checked + .cs-switch-label .switch-button {
  left: calc(100% - 4px);
  -webkit-transform: translateX(-100%) translateY(-50%);
  -ms-transform: translateX(-100%) translateY(-50%);
  transform: translateX(-100%) translateY(-50%);
  -webkit-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  -o-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

.cs-switch-label:active .switch-button {
  width: 12px;
}

input[type="checkbox"]:checked ~ .cs-switch-label {
  background: var(--primary-main);
}

/* ============================= */
/* END - Common Switch  */
/* ============================= */

/* ============================= */
/* START - Common Table  */
/* ============================= */

.cs-table {
  margin-bottom: 0px;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0;
  border-bottom: 0px;
}

.cs-table th:first-child {
  border-radius: 4px 0 0 0;
}

.cs-table th:last-child {
  border-radius: 0 4px 0 0;
}

.cs-table tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}

.cs-table tr:last-child td:last-child {
  border-radius: 0 0 4px 0;
}

.cs-table > :not(caption) > * > * {
  padding: 11px 16px;
  color: var(--neutral-90);
}

.cs-table .cs-thead {
  vertical-align: middle;
}

.cs-thead th {
  white-space: nowrap;
  border-bottom: 1px solid var(--neutral-50);
  background: var(--neutral-40);
  color: var(--neutral-100);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.cs-tbody td {
  color: var(--neutral-90);
  vertical-align: middle;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.cs-tbody tr {
  border-bottom: 1px solid var(--neutral-50);
}

.cs-tfoot-assets {
  color: var(--neutral-100);
}

.cs-tfoot-liability {
  color: var(--neutral-100);
}

.cs-tfoot-liability td {
  background: var(--liability-surface);
  color: var(--neutral-100);
  font-weight: 400;
}

.cs-tfoot-assets td {
  background: var(--asset-surface);
  color: var(--neutral-100);
  font-weight: 400;
}

.go-next-page-div {
  text-align: right;
}

.go-next-page-div svg {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.common-tooltip-margin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.common-tooltip-margin span {
  margin-left: 6px;
}

.common-tooltip-margin svg path {
  fill: var(--neutral-70);
}

.listing-page-amount-width {
  width: 15%;
}

.header-gap-width {
  width: 12%;
}

.per-month-badge {
  border-radius: 4px;
  border: 1px solid var(--primary-border);
  background: var(--primary-surface);
  color: var(--primary-main);
  padding: 4px 8px;
  margin-left: 16px;
}

.per-month-gap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.amortization-schedule-box .cs-form-check-box,
.amortization-schedule-box .cs-form-check-box span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.go-next-page-div svg path {
  fill: var(--neutral-70);
}

.table-actions-container {
  padding: 4px;
  border-radius: 4px;
  background: var(--neutral-20);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.table-actions-container svg path {
  fill: var(--neutral-80);
}

.delete-icon-container svg path {
  fill: var(--danger-main);
}

.ownership-th,
.ownership-td {
  text-align: right;
  /* padding-right: 3% !important; */
  padding-right: 45px !important;
}

.ownership-th span {
  vertical-align: middle;
  margin-left: 8px;
}

.market-value-th span {
  vertical-align: bottom;
  margin-left: 8px;
}

.market-value-th span svg path {
  fill: var(--neutral-70);
}

.ownership-th span svg path {
  fill: var(--neutral-70);
}

.ownership-td .common-tooltip-margin span {
  margin-right: -24px;
}

.ownership-td .common-tooltip-margin {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.first-content-th {
  width: 20% !important;
}

.last-th-content {
  width: 8% !important;
}

.last-td-content {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  cursor: pointer;
}

.common-tooltip-margin p {
  white-space: nowrap;
}

.common-tooltip-margin.cs-disabled svg path {
  fill: var(--neutral-60) !important;
}

.table-icons-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.table-icons-container .table-action {
  margin-right: 8px;
}

.common-tooltip-margin.cs-gap {
  gap: 4px;
}

.common-tooltip-margin.cs-gap img {
  width: 27px;
  height: auto;
}

.common-tooltip-margin.cs-gap span {
  width: auto !important;
  height: auto !important;
}
.common-tooltip-margin.cs-gap span:first-child {
  margin: 0;
}
/* ============================= */
/* END - Common Table  */
/* ============================= */
/* ============================= */
/* START - Common Data Table  */
/* ============================= */
.cs-data-table .rdt_Table {
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-data-table .jzrolw,
.cs-data-table .eguvnT {
  padding-bottom: 4px;
}

.cs-data-table .rdt_Table .rdt_TableHeadRow {
  background: var(--neutral-40);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: auto;
}

.cs-data-table .rdt_Table .rdt_TableHeadRow:first-child,
.cs-data-table .rdt_TableRow:last-child {
  border-radius: 0 0 4px 4px;
}

.cs-data-table .rdt_Table .rdt_TableHeadRow:last-child {
  border-radius: 4px 4px 0 0;
}

.cs-data-table .rdt_Table .rdt_TableHeadRow .rdt_TableCol_Sortable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.cs-data-table .rdt_Table .rdt_TableHeadRow .rdt_TableCol_Sortable div {
  width: auto;
  margin-right: 8px;
  white-space: nowrap;
  overflow: visible;
}

.cs-data-table .rdt_TableBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.billing-subscription-page .cs-data-table .rdt_TableBody .rdt_TableRow {
  background: var(--neutral-10);
  padding: 0;
}

.cs-data-table .rdt_Table .rdt_TableHeadRow .rdt_TableCol {
  padding: 0;
  color: var(--neutral-100);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 12px 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-data-table .rdt_TableCell {
  padding: 12px 16px;
  color: var(--neutral-90);
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.cs-data-table .rdt_TableCell div {
  white-space: normal !important;
  overflow: visible !important;
}

.cs-data-table .rdt_Table div.cTsUPQ,
.cs-data-table .rdt_Table div.kpyoXL {
  border-radius: 4px;
}

.cs-data-table .rdt_Table div.cTsUPQ div,
.cs-data-table .rdt_Table div.kpyoXL div {
  color: var(--neutral-100);
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  padding: 22px 0 !important;
}

.cs-data-table .rdt_TableBody div.hbgCLW:last-child {
  border-radius: 0 0 4px 4px;
}

.cs-data-table .rdt_Pagination {
  border: none;
}

.cs-data-table .rdt_Pagination svg {
  width: 22px;
  height: 22px;
}

.cs-data-table [data-tag="allowRowEvents"] {
  width: 100%;
}

.cs-common-no-record {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 22px 0;
  background-color: var(--neutral-10);
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

.cs-data-table .sc-iHGNWf {
  color: var(--neutral-80);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin: 0 14px;
}

/* ============================= */
/* END - Common Data Table  */
/* ============================= */

/* START - db validation message  */
/* ============================= */
.cs-msg {
  margin-bottom: 16px;
}

.cs-msg .icon {
  padding-right: 4px;
  vertical-align: text-bottom;
}

.cs-msg .icon svg {
  width: 16px;
  height: 16px;
}

.cs-msg.cs-flex .icon {
  padding-right: 3px;
}

/* ============================= */
/* END - db validation message  */
/* ============================= */

/* ============================= */
/* START - Common Card */
/* ============================= */
.cs-card {
  width: 484px;
  border-radius: 4px;
  border: 1px solid var(--neutral-40);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
}

.cs-card:hover {
  border: 1px solid var(--primary-main);
}

.cs-card .card-img {
  width: 100%;
  height: 208px;
  background: var(--primary-surface);
  border: none;
}

.cs-card .card-body {
  padding: 24px;
}

.cs-card .default-semi-bold-h3 {
  color: var(--neutral-100);
}

.cs-card .card-text {
  margin-top: 0px;
  margin-bottom: 0px;
}

.cs-card .default-regular-h5 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.cs-card .default-light-body-text-l {
  margin-top: 24px;
  margin-bottom: 24px;
}

.cs-card .cs-btn-primary {
  width: 100%;
}

/* ============================= */
/* END - Common Card  */
/* ============================= */

/* ============================= */
/* START - Common Add Dropdown  */
/* ============================= */

.cs-common-add-dropdown .dropdown .dropdown-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 16px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-40);
  background: var(--neutral-10);
  color: var(--neutral-100);
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.cs-common-add-dropdown .dropdown .dropdown-menu.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  border: none;
  max-height: 310px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cs-common-add-dropdown .dropdown .dropdown-menu.show .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  white-space: wrap;
  color: var(--neutral-90);
  background-color: var(--neutral-10);
}

.cs-common-add-dropdown .dropdown .dropdown-menu.show .dropdown-item:hover {
  background: var(--primary-surface);
}

.cs-common-add-dropdown .dropdown-item.active,
.cs-common-add-dropdown .dropdown-item:active,
.cs-common-add-dropdown .dropdown-item:focus-visible {
  color: var(--primary-main) !important;
  background: var(--primary-surface) !important;
}

.action-box .dropdown .dropdown-toggle,
.cs-list-options .dropdown .dropdown-toggle {
  display: block;
  width: 26px;
  height: 26px;
  padding: 4px;
  border: 0;
  outline: none;
  border-radius: 4px;
  background: var(--neutral-20);
}

.action-box .dropdown .dropdown-toggle svg,
.cs-list-options .dropdown .dropdown-toggle svg {
  width: 14px;
  height: 14px;
}

.action-box .cs-common-add-dropdown .dropdown .dropdown-menu.show {
  width: 210px;
}

.action-box .cs-common-add-dropdown .dropdown .common-disabled .dropdown-menu {
  -webkit-transform: translate(-210px, -30px) !important;
  -ms-transform: translate(-210px, -30px) !important;
  transform: translate(-210px, -30px) !important;
}

.action-box
  .cs-common-add-dropdown
  .dropdown
  .common-disabled.align-portfolio
  .dropdown-menu {
  -webkit-transform: translate(-16px, -30px) !important;
  -ms-transform: translate(-16px, -30px) !important;
  transform: translate(-16px, -30px) !important;
}

.action-box
  .cs-common-add-dropdown
  .dropdown
  .dropdown-menu.show
  .dropdown-item {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.action-box
  .cs-common-add-dropdown
  .dropdown
  .dropdown-menu.show
  .dropdown-item
  svg {
  display: block;
  width: 18px;
  height: 18px;
  margin-left: 4px;
}

.dropdown-item {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.cs-list-options .cs-common-add-dropdown .dropdown .dropdown-toggle {
  width: 24px;
  height: 24px;
  border-radius: 4px !important;
  border: 0 !important;
}

.cs-list-options .cs-common-add-dropdown .dropdown .dropdown-toggle .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-list-options .cs-common-add-dropdown .dropdown .dropdown-toggle svg {
  width: 14px;
  height: auto;
}

.react-date-picker__button svg path {
  fill: var(--neutral-70) !important;
}

.trim-dropdown-styling .trim-content {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding-right: 18px;
}

.trim-dropdown-styling .dropdown-item.cs-tabs-height {
  height: auto;
  padding: 6px 16px;
  line-height: 18px;
}

/* ============================= */
/* END - Common Add Dropdown  */
/* ============================= */

/* ============================= */
/* START - Common Progress Bar  */
/* ============================= */

/* Thick progress bar */

.cs-progress-bar {
  width: 350px;
  height: 8px;
  border-radius: 4px;
}

.cs-progress-bar .sc-bdfBwQ {
  border-radius: 10px;
  background: var(--neutral-40);
  height: 100%;
}

.cs-progress-bar .sc-gsTCUz {
  border-radius: 10px;
  background: var(--primary-main);
  height: 100%;
}

/* Thin progress bar */
.cs-thin-progress-bar {
  width: 350px;
  height: 8px;
  border-radius: 4px;
}

.cs-thin-progress-bar .sc-bdfBwQ {
  border-radius: 10px;
  background: var(--neutral-40);
  height: 100%;
}

.cs-thin-progress-bar .sc-gsTCUz {
  border-radius: 10px;
  background: var(--primary-main);
  height: 100%;
}

/* Thick progress bar with label*/

.cs-progress-bar-container {
  width: 350px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cs-progress-bar-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 4px;
}

.cs-progress-bar-label .default-regular-body-text-s {
  color: var(--neutral-100);
}

.cs-progress-bar-label .default-light-body-text-m {
  color: var(--neutral-90);
}

/* ============================= */
/* END - Common Progress Bar */
/* ============================= */

/* ============================= */
/* START - Common Modal  */
/* ============================= */

/* -------- Start Medium Size-------- */
.cs-md-modal {
  max-width: 522px;
}

.cs-md-modal .modal-content,
.cs-lg-modal .modal-content {
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  border: none;
}

.cs-md-modal .modal-header,
.cs-lg-modal .modal-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 32px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--neutral-20);
}

.modal-header span {
  cursor: pointer;
}

.modal-header .cs-close-btn {
  position: absolute;
  top: 50%;
  right: 32px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.modal-header .cs-previous-btn,
.cs-s-modal .modal-header .cs-previous-btn {
  position: absolute;
  top: 50%;
  left: 32px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.cs-s-modal .modal-header .cs-previous-btn {
  left: 26px;
}

.cs-md-modal .modal-title,
.cs-lg-modal .modal-title {
  color: var(--neutral-100);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
}

.modal-title {
  line-height: 24px;
}

.cs-md-modal .modal-body,
.cs-lg-modal .modal-body {
  padding: 24px 32px;
}

.cs-md-modal .modal-footer,
.cs-lg-modal .modal-footer {
  padding: 0px 32px 24px 32px;
  border: none;
}

/* -------- End Medium Size-------- */
/* -------- Start Large Size-------- */
.cs-lg-modal {
  max-width: 778px;
}

/* -------- End Large Size-------- */

.cs-modal-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cs-modal-btn.cs-center-btn {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-modal-btn button:last-child {
  margin-left: 16px;
}

.modal-footer > * {
  margin: 0;
}

.payment-success .success-head {
  text-align: center;
  padding: 0 20px;
}

.payment-success .success-head img {
  margin-bottom: 24px;
}

.payment-success .modal-footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* ============================= */
/* END - Common Modal  */
/* ============================= */

/* ============================= */
/* START - Common Canva */
/* ============================= */

.cs-offcanvas {
  width: 462px;
  padding: 40px 24px 24px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 4px;
  background: var(--neutral-10);
}

.offcanvas.offcanvas-end {
  width: 378px;
  top: 10px;
  right: 10px;
  bottom: 10px;
}

.cs-offcanvas .cs-badge {
  display: inline-block;
}

.cs-offcanvas p {
  margin: 0px;
}

.cs-offcanvas .offcanvas-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 24px;
}

.cs-offcanvas .img-container {
  padding: 0px 0px 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.img-container img {
  border-radius: 100%;
}

.cs-offcanvas .img-container span {
  cursor: pointer;
}

.cs-offcanvas .img-container button {
  width: 28px;
  height: 28px;
}

.cs-offcanvas .header-information {
  padding: 0px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.cs-offcanvas .name-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cs-offcanvas .name-information .vertical-line {
  width: 1px;
  height: 20px;
  background: var(--neutral-100);
  margin-right: 16px;
}

.cs-offcanvas .default-semi-bold-h3 {
  color: var(--neutral-100);
  /* text-transform: capitalize; */
  margin-right: 16px;
  word-break: break-all;
}

.header-information .name-information span:nth-child(2) {
  margin-right: 16px;
}

.cs-offcanvas .offcanvas-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0px;
  margin-right: -4px;
  padding-right: 4px;
  margin-bottom: 24px;
}

.cs-offcanvas .top-information-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 4px;
  background: var(--primary-surface);
  margin-bottom: 16px;
}

.cs-offcanvas .top-information-container:last-child {
  margin-bottom: 0;
}

.offcanvas-body .body-top-information {
  display: block;
}

.offcanvas-body .body-top-information .default-medium-sub-heading-s {
  color: var(--neutral-60);
  margin-bottom: 4px;
}

.offcanvas-body .body-top-information .default-regular-body-text-l {
  color: var(--neutral-100);
  margin-bottom: 16px;
  font-weight: 300;
}

.cs-offcanvas .offcanvas-header .name-information .default-semi-bold-h3 {
  font-size: 14px;
}

.offcanvas-body .body-top-information .cs-badge {
  margin-bottom: 16px;
}

.offcanvas-body .body-top-information .d-flex.align-items-center {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cs-offcanvas .bottom-information-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 4px;
  background: var(--primary-surface);
}

.cs-offcanvas .radio-btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-offcanvas .cs-radio-btn,
.cs-radio-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 16px;
}

.cs-offcanvas .cs-radio-btn .radio-button,
.cs-radio-btn .radio-button {
  margin-right: 8px;
  cursor: pointer;
}

.cs-offcanvas .cs-radio-btn .default-regular-body-text-m {
  color: var(--neutral-90);
}

.cs-offcanvas .cs-btn-group {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.designation-information span {
  text-transform: capitalize;
}

.offcanvas-body {
  height: auto;
}

.mob-scroller {
  margin-right: -4px;
  padding-right: 4px;
  overflow: hidden;
  overflow-y: auto;
}

.offcanvas-body .top-information-container .cs-ellipsis {
  max-width: 100%;
}

.view-pfs-modal .cs-ellipsis {
  max-width: 100%;
}

.view-pfs-modal .cs-ellipsis span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.collaboration-data-table .cs-ellipsis,
.collaboration-data-table .cs-ellipsis span,
.offcanvas-body .top-information-container .cs-ellipsis span {
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ============================= */
/* END - Common Canva  */
/* ============================= */

/* ================================= */
/* START - Common Progress Indicator */
/* ================================= */
.cs-progress-indicator,
.cs-progress-indicator span,
.cs-progress-indicator .cs-indicator,
.cs-progress-indicator .cs-indicator span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
}

.cs-progress-indicator .cs-indicator .cs-indicator-line {
  width: 40px;
  height: 2px;
  border-radius: 10px;
  background: var(--neutral-50);
  margin: 0 2px;
}

.cs-progress-indicator .cs-indicator.cs-primary .cs-indicator-line {
  background: var(--primary-main);
}

.cs-progress-indicator .cs-indicator.cs-success .cs-indicator-line {
  background: var(--success-main);
}

.cs-progress-indicator .cs-indicator.cs-danger .cs-indicator-line {
  background: var(--danger-main);
}

.cs-progress-indicator .cs-indicator.cs-warning .cs-indicator-line {
  background: var(--warning-main);
}

.cs-progress-indicator .cs-indicator span.cs-indicator-line.cs-danger {
  background: var(--danger-main) !important;
}

/* =================================*/
/* END - Common Progress Indicator  */
/* ================================ */

/* =================================*/
/* START - Common notification badge */
/* ================================ */
.blue-notification-badge,
.red-notification-badge {
  width: 8px;
  height: 8px;
  /* border-radius: 30px; */
  border-radius: 100%;
  background: var(--primary-main);
}

.red-notification-badge {
  background: var(--danger-main);
}

/* =================================*/
/* END - Common notification badge  */
/* ================================ */

/* =================================*/
/* START - Common Notification Component  */
/* ================================ */
.cs-notification {
  width: 459px;
  height: auto;
  max-height: 358px;
  margin-right: 4px;
}

.cs-notification p {
  margin: 0px;
}

.cs-notification .title {
  padding: 16px 26px 16px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--neutral-20);
}

.cs-notification .notification,
.cs-notification .notification .notification-img,
.cs-notification .notification span.cs-neutral-100 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-notification .notification .notification-img {
  margin-right: 8px;
}

.cs-notification .default-light-body-text-m {
  color: var(--primary-main);
}

.cs-notification .notification-item {
  /* padding: 8px 26px 8px 16px; */
  padding: 8px 16px;
  height: auto;
  min-height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px solid var(--neutral-20);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* .cs-notification .notification-item .notification-message {
  width: 325px;
} */

.cs-notification .notification-item .notification-date-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-left: auto;
}

.cs-notification .notification-item .blue-notification-badge {
  /* margin-right: 16px; */
  top: 4px;
  left: 0px;
}

.notification-message span:last-child {
  padding-inline: 16px 8px;
}

.cs-notification .default-light-body-text-s {
  color: var(--neutral-60);
}

.cs-notification .bullet-item-container {
  padding: 8px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px solid var(--neutral-20);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.cs-notification .bullet-item-container .red-notification-badge,
.cs-notification .bullet-item-container .blue-notification-badge {
  margin-right: 16px;
}

.cs-notification .notification-item:hover,
.cs-notification .bullet-item-container:hover {
  background: var(--primary-surface);
}

.cs-notification .bullet-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.cs-notification .bullet-item .notification-message {
  width: 291px;
}

.cs-notification .notification-message {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.cs-notification .notification-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-notification .bullet-item .notification-date-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.cs-notification .hidden {
  display: none;
}

/* =================================*/
/* END - Common Notification Component  */
/* ================================ */

/* =================================*/
/* START - Common Profile dropdown Component  */
/* ================================ */
.cs-settings-dropdown .dropdown-menu {
  width: 183px;
  border-radius: 5px;
  background: var(--neutral-10, #fff);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  padding-top: 8px;
  padding-bottom: 8px;
  left: -140px;
  border: none;
  margin-top: 0px;
}

.cs-settings-dropdown .profile-item {
  padding: 8px 24px;
  color: var(--neutral-90);
}

.cs-settings-dropdown .selected-profile-item,
.select-pfs-form .selected-nav-item {
  background: var(--primary-surface);
}

.cs-settings-dropdown .profile-item:hover {
  background: var(--primary-surface);
}

.cs-settings-dropdown .profile-item:hover .default-regular-h5 {
  color: var(--neutral-100);
}

.cs-settings-dropdown .selected-profile-item:hover .default-regular-h5 {
  color: var(--primary-main);
}

.cs-settings-dropdown .profile-item:active .default-regular-h5,
.cs-settings-dropdown .profile-item:focus .default-regular-h5 {
  color: var(--primary-main);
}

.cs-settings-dropdown .profile-item-link,
.account-details-page .profile span.cs-icon-badge-primary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-settings-dropdown .profile-item-link svg {
  margin-right: 16px;
}

.cs-settings-dropdown .profile-item-link svg path {
  fill: var(--neutral-90);
}

.cs-settings-dropdown .selected-nav-icon svg path {
  fill: var(--primary-main);
}

.cs-settings-dropdown .profile-item:hover svg path {
  fill: var(--neutral-100);
}

.cs-settings-dropdown .profile-item:hover .selected-nav-icon svg path {
  fill: var(--primary-main);
}

.cs-settings-dropdown .default-regular-h5 {
  color: var(--neutral-90);
}

.cs-settings-dropdown .selected-nav-item,
.select-pfs-form .selected-nav-item {
  color: var(--primary-main) !important;
}

.last-email-field .cs-manual-address.dropdown-item {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 10px;
}

.last-email-field .cs-manual-address.dropdown-item .cs-primary {
  margin-left: 4px;
}

/* =================================*/
/* END - Common Profile dropdown Component  */
/* ================================ */
/* ======================================================= */
/* START - Common Upload files / Documents (File Selector) */
/* ======================================================= */

.cs-upload-box {
  position: relative;
}

.cs-upload-files {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed var(--primary-border);
  background: var(--primary-surface);
  width: 100%;
  cursor: pointer;
}

.cs-upload-files span.cs-primary {
  padding-bottom: 8px;
}

.cs-upload-files p span {
  color: var(--primary-main);
}

.cs-upload-files p:nth-child(3) {
  margin-bottom: 0;
  line-height: 24px;
  color: var(--neutral-70);
}

.cs-upload-files p:nth-child(4) {
  margin-bottom: 0;
  line-height: 20px;
  color: var(--neutral-70);
}

.cs-upload-files .cs-upload-list {
  list-style-type: none;
}

.cs-upload-files:hover {
  border: 1px dashed var(--primary-hover);
}

.category-attachments-files ul {
  margin: 0;
  padding: 0;
}

.category-attachments-files ul li {
  list-style: none;
}

.category-attachments-files .attachment-files.cs-files .cs-selected-files {
  border: 0;
}

input[type="file"]::-webkit-file-upload-button {
  display: none;
}

input[type="file"]::file-selector-button {
  display: none;
}

/* ======================================================= */
/* END - Common Upload files / Documents (File Selector)   */
/* ======================================================= */

/* ======================================================= */
/* START - Multiple User Flow  */
/* ======================================================= */

.upgrade-navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.upgrade-navbar span {
  color: var(--primary-main);
}

.upgrade-navbar .cs-btn-primary {
  padding: 8px 15px;
  margin-inline: 8px 24px;
}

.multiple-user-flow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 24px;
  grid-gap: 24px;
}

.multiple-user-flow .user-text {
  text-transform: uppercase;
}

.multiple-user-flow .active-user-profile {
  position: relative;
}

.multiple-user-flow .active-user-profile .active-dot {
  display: block;
  position: absolute;
  bottom: 2px;
  /* right: 4px; */
  left: 24px;
  height: 8px;
  width: 8px;
  border-radius: 20px;
}

.dropdown-menu .multiple-user-list .active-user-profile .active-dot {
  bottom: 0px;
  left: 27px;
}

.active-user-profile .active-dot.cs-success {
  background: var(--success-main);
}

.active-user-profile .active-dot.cs-danger {
  background: var(--danger-main);
}

.active-user-profile .dropdown .dropdown-toggle {
  padding: 0;
  border: 0;
}

.active-user-profile .dropdown .dropdown-menu.show {
  width: 200px;
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
  padding-inline: 16px;
}

.active-user-profile .multiple-user-item .profile-info {
  margin-left: 8px;
}

.active-user-profile .dropdown .dropdown-menu.show .multiple-user-item {
  padding: 8px 0;
}

.active-user-profile .dropdown .dropdown-menu.show button {
  padding: 4px 16px;
  margin-block: 8px;
}

.active-user-profile .dropdown .dropdown-menu.show .cs-btn-primary {
  -webkit-margin-after: 8px;
  margin-block-end: 8px;
}

.multiple-user-flow .profile-image {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  border: 2px solid var(--neutral-50);
  border-radius: 100%;
  background: var(--neutral-50);
  overflow: hidden;
  margin-left: 0;
}

.multiple-user-flow .user-image {
  display: block;
  border-radius: 100%;
  overflow: hidden;
}

.multiple-user-flow .user-image img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.multiple-user-dropdown {
  position: relative;
  margin-left: 24px;
}

.cs-common-add-dropdown.multiple-user-dropdown .dropdown .dropdown-toggle {
  padding: 0;
  border-radius: 50px;
  border: 2px solid var(--neutral-20);
  background: var(--neutral-20);
  width: 64px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-common-add-dropdown.multiple-user-dropdown .profile-image {
  margin-right: 6px;
}

.multiple-user-dropdown .cs-icon {
  position: relative;
  /* margin-right: 5px; */
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.multiple-user-dropdown .dropdown-toggle.show .cs-icon {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.multiple-user-dropdown .cs-scroller.dropdown-menu.show {
  width: 250px;
}

.multiple-user-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.cs-common-add-dropdown.multiple-user-dropdown
  .dropdown
  .dropdown-menu.show
  .multiple-user-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 24px;
  width: 100%;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  background: transparent;
  cursor: pointer;
}

.cs-common-add-dropdown.multiple-user-dropdown
  .dropdown
  .dropdown-menu.show
  .multiple-user-list:hover {
  background: var(--primary-surface);
}

.profile-info span {
  display: block;
}

.multiple-user-dropdown .multiple-user-item .profile-image {
  border: 0;
  margin-right: 0;
}

.multiple-user-dropdown .multiple-user-item .profile-info {
  margin-inline: 10px;
}

.cs-list-options .dropdown-menu.show {
  width: 160px;
  top: 0;
  -webkit-transform: translate(-160px, -18px);
  -ms-transform: translate(-160px, -18px);
  transform: translate(-160px, -18px);
}

.cs-list-options .dropdown .dropdown-menu.show .dropdown-item {
  padding: 8px 12px;
}

.multiuser-popup {
  text-align: center;
}

.multiuser-popup .profile-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  margin: 10px auto 24px;
  background: var(--neutral-50);
}

/* ======================================================= */
/* END - Multiple User Flow  */
/* ======================================================= */

/* ======================================================= */
/* START PFS Dropdown Component   */
/* ======================================================= */
.cs-navbar-dropdown-btn {
  margin-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 24px 0px 16px;
}

.cs-navbar-dropdown-btn .dropdown-toggle {
  background-color: var(--neutral-10);
  border: none;
  color: var(--neutral-100);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.cs-navbar-dropdown-btn svg {
  margin-left: 16px;
}

.cs-navbar-dropdown-btn .dropdown-toggle:active,
.cs-navbar-dropdown-btn .dropdown-toggle:focus {
  background-color: var(--neutral-10);
  color: var(--neutral-100);
}

.pfs-dropdown-chev-icon {
  width: 12px;
}

/* ======================================================= */
/* END - PFS Dropdown Component  */
/* ======================================================= */

/* ======================================================= */
/* START - PFS Menu Component  */
/* ======================================================= */
.cs-pfs-menu {
  position: absolute;
  width: 313px;
  height: auto;
  max-height: 252px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  margin: 0px;
  border: none;
  overflow-y: auto;
  margin-top: 0px !important;
  z-index: 1;
}

.cs-pfs-menu .pfs-item,
.cs-pfs-menu .pfs-item-name {
  padding: 10px 24px;
}

.cs-pfs-menu .pfs-item:hover {
  background: var(--primary-surface);
}

.cs-pfs-menu .pfs-item:hover .default-regular-h5 {
  color: var(--neutral-100);
}

.cs-pfs-menu .default-regular-sub-heading-xs {
  display: block;
  padding: 8px 24px;
  text-transform: uppercase;
}

.cs-pfs-menu .cs-search-bar {
  margin-left: 16px;
  margin-right: 16px;
}

.cs-pfs-menu .cs-search-bar .cs-form-group {
  margin-bottom: 8px;
}

.cs-pfs-menu .pfs-item-name:hover {
  background: var(--primary-surface);
  color: var(--neutral-90);
}

.cs-pfs-menu .pfs-item-name:hover .default-regular-h5 {
  color: var(--neutral-100);
}

.cs-pfs-menu .pfs-item-name .default-regular-sub-heading-xs {
  padding: 0px;
}

.cs-pfs-menu .cs-search-bar .cs-form-group .form-control {
  padding-right: 40px;
}

.pfs-container .cs-credit-report-outer {
  height: 100%;
}

.business-attachment-container .attachment-row {
  height: auto;
}

.business-attachment-container .cs-preview-body {
  /* max-height: calc(100vh - 336px); */
  overflow-x: auto;
  margin-right: -4px;
  padding-right: 4px;
}

.other-attachment-container.business-attachment-container .col-md-6:last-child {
  height: calc(100vh - 40.9vh) !important;
}

.other-attachment-container.business-attachment-container
  .col-md-6:last-child
  .cs-preview-body {
  height: 100%;
}

.other-attachment-container.business-attachment-container
  .col-md-6:last-child
  .cs-preview-body
  iframe {
  height: calc(100vh - 342px) !important;
  height: 98% !important;
}

.business-attachment-container .cs-preview-body .cs-table {
  border: 1px solid var(--neutral-50) !important;
}

.business-attachment-container
  .attachment-files.cs-files
  .cs-selected-files
  .cs-file-icon
  span {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.business-attachment-container ul#sheet-tab {
  margin-top: 4px;
}

.business-attachment-container ul#sheet-tab::-webkit-scrollbar {
  height: 0;
}

.business-attachment-container .csv-table-preview-container,
.business-attachment-container .table-preview-container {
  height: 100%;
}

.business-attachment-container .table-preview-row {
  display: block;
  height: calc(100vh - 42vh);
  overflow: auto;
  /* margin-bottom: 30px; */
}

.business-attachment-container tr td {
  border-right: 1px solid var(--neutral-50);
  border-bottom: 1px solid var(--neutral-50);
}

.business-attachment-container .cs-modal-btn.cs-center-btn span {
  margin: 10px 20px;
}

/* ======================================================= */
/* END - PFS Menu Component  */
/* ======================================================= */
/* ================================= */
/* START - Common Selected Files     */
/* ================================= */
.cs-files {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.manage-profile-upload .cs-files:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 24px;
  background: -o-linear-gradient(
    top,
    rgb(255 255 255),
    rgb(255 255 255 / 80%),
    transparent
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(255 255 255)),
    color-stop(rgb(255 255 255 / 80%)),
    to(transparent)
  );
  background: linear-gradient(
    180deg,
    rgb(255 255 255),
    rgb(255 255 255 / 80%),
    transparent
  );
  z-index: 1;
  pointer-events: none;
}

.cs-files .cs-selected-files {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-40);
  background: var(--neutral-10);
  margin-top: 16px;
}

.cs-files .cs-preview-selected-file {
  border: 1px solid var(--primary-main);
  background: var(--primary-surface) !important;
}

.cs-files .cs-selected-files .tool-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
}

.cs-files .cs-selected-files .tool-content span.cs-neutral-60 {
  margin-right: 8px;
}

.cs-files .cs-selected-files .tool-content p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0;
}

.cs-files .cs-selected-files .tool-content p span:nth-child(1) {
  color: var(--neutral-100);
}

.cs-files .cs-selected-files .tool-content p span:nth-child(2) {
  color: var(--neutral-80);
}

.cs-file-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-file-icon span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  margin-left: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.cs-file-icon span:first-child {
  margin-left: 0;
}

.cs-choose-file .form-control {
  padding-block: 11px 10px;
  padding-inline: 16px 40px;
  color: transparent;
}

.cs-choose-file-box:before {
  content: attr(title);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  pointer-events: none;
  padding-right: 55px;
  padding-left: 18px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

/* =================================*/
/* END - Common  Selected Files     */
/* ================================ */

/* =================================*/
/* START - Common  Avatar Component     */
/* ================================ */

.cs-avatar-lg,
.profile.cs-avatar-lg {
  border: 1px solid;
  width: 160px;
  height: 160px;
  border-radius: 160px;
}

.cs-avatar-lg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-avatar-md {
  width: 96px;
  height: 96px;
  border-radius: 96px;
}

.cs-avatar-md img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-avatar-sm {
  width: 72px;
  height: 72px;
  border-radius: 72px;
}

.cs-avatar-sm img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-avatar-xs {
  width: 48px;
  height: 48px;
  border-radius: 48px;
}

.cs-avatar-xs img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-avatar-xxs {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.cs-avatar-xxs img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-avatar-md.avtar-container > div {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

/* =================================*/
/* END - Common  Avatar Component     */
/* ================================ */
/* ================================ */
/* START - Common Capsync  Logo     */
/* ================================ */

/* ===========Capsync Loader======= */
.cs-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
}

.cs-loader img {
  width: 100%;
  height: auto;
}

.cs-loader.lg {
  width: 192px;
  height: 192px;
}

.cs-loader.md {
  width: 128px;
  height: 128px;
}

.cs-loader.sm {
  width: 64px;
  height: 64px;
}

/* ===========Capsync Logo======= */
.cs-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.cs-logo.lg {
  width: 192px;
  height: 192px;
}

.cs-logo.md {
  width: 128px;
  height: 128px;
}

.cs-logo.sm {
  width: 64px;
  height: 64px;
}

/* =========Capsync Icon Logo===== */
.cs-icon-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.cs-icon-logo.lg {
  width: 196px;
  height: 56px;
}

.cs-icon-logo.md {
  width: 184px;
  height: 52px;
}

.cs-icon-logo.sm {
  width: 148px;
  height: 42px;
}

/* =================================*/
/* END - Common Capsync  Logo       */
/* ================================ */

/* =================================*/
/* START - Common Toast Message       */
/* ================================ */

.cs-toast .Toastify__toast-container {
  margin: 65px 12px 0 0;
  width: 400px;
  padding: 0px;
}

.cs-toast .Toastify__toast {
  padding-block: 12px;
  min-height: auto;
  border-radius: 4px;
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
}

.cs-toast .Toastify__toast-theme--light {
  color: var(--neutral-100);
}

.cs-toast .Toastify__toast-body {
  padding: 0px;
  margin: 0px;
  color: var(--neutral-100);
  font-family: Readex Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
}

.cs-toast .Toastify__toast-icon {
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
  width: 16px;
}

.cs-toast .Toastify__close-button > svg {
  height: 100%;
  width: 100%;
  margin-top: 4px;
}

.cs-toast .Toastify__close-button {
  /* -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 16px;
  width: 16px;
}

.cs-toast .Toastify__toast--success {
  border: 1px solid var(--success-border);
  background: var(--success-surface);
}

.cs-toast .Toastify__toast--success svg path {
  fill: var(--success-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--success {
  background-color: var(--success-main);
}

.cs-toast .Toastify__toast--error {
  border: 1px solid var(--danger-border);
  background: var(--danger-surface);
}

.cs-toast .Toastify__toast--error svg path {
  fill: var(--danger-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--error {
  background-color: var(--danger-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--success {
  background-color: var(--success-main);
}

.cs-toast .Toastify__toast--warning {
  border: 1px solid var(--warning-border);
  background: var(--warning-surface);
}

.cs-toast .Toastify__toast--warning svg path {
  fill: var(--warning-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--warning {
  background-color: var(--warning-main);
}

.cs-toast .Toastify__toast--info {
  border: 1px solid var(--info-border);
  background: var(--info-surface);
}

.cs-toast .Toastify__toast--info svg path {
  fill: var(--info-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--info {
  background-color: var(--info-main);
}

/* =================================*/
/* END - Common Toast Message       */
/* ================================ */

/* =================================*/
/* START - Common filter dropdown       */
/* ================================ */
.cs-filter-dropdown .dropdown-toggle {
  width: 157px;
  padding: 10px 24px;
  border-radius: 4px;
  border: 1px solid var(--neutral-40);
  background: var(--neutral-10);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-filter-dropdown .default-regular-h5 {
  color: var(--neutral-100);
}

.cs-filter-dropdown .dropdown-toggle:hover,
.cs-filter-dropdown .dropdown-toggle:active,
.cs-filter-dropdown .dropdown-toggle:focus {
  background: var(--neutral-10);
  color: var(--neutral-100);
  border: 1px solid var(--neutral-40);
}

.cs-filter-dropdown .dropdown-menu {
  width: 147px;
  min-width: 157px;
  background: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  border-radius: 4px;
}

.cs-view-pfs-filter .menu-item {
  padding: 10px 24px;
  color: var(--neutral-90);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.cs-view-pfs-filter .menu-item:hover {
  background: var(--primary-surface);
}

.cs-view-pfs-filter .selected,
.pfs-preview-dropdown .dropdown-item.selected {
  color: var(--primary-main) !important;
  background: var(--primary-surface) !important;
}

.cs-view-pfs-filter .menu-item.selected:hover {
  color: var(--primary-main);
}

.transaction-period-select .dropdown-item-transaction {
  width: 157px;
}

.transaction-period-select .dropdown-item-transaction .dropdown-menu.show {
  min-width: 100%;
}

/* =================================*/
/* END - Common filter dropdown       */
/* ================================ */

/* =================================*/
/* START - dollar value gap     */
/* ================================ */
.dollar-value-gap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* =================================*/
/* END - dollar value gap       */
/* ================================ */

/* =================================*/
/* Start - [cs-section-topbar]      */
/* ================================ */
.cs-section-topbar {
  position: relative;
  margin-bottom: 32px;
}

.cs-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-section-topbar-row {
  position: relative;
  display: flex;
  align-items: center;
}

.chart-collapse-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 4px;
  background-color: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  margin-left: 16px;
}

.cs-content-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-right: 30px;
}

.cs-content-title .cs-title .default-semi-bold-h1 {
  color: var(--neutral-100);
}

.cs-view-pfs-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-view-pfs-filter .default-regular-body-text-m {
  margin-right: 16px;
}

.cs-view-pfs-filter .default-regular-body-text-m {
  color: var(--neutral-100);
}

/* =================================*/
/* end - [cs-section-topbar]   */
/* ================================ */
/* ----------------------------------------------Cards------------------------------------------------ */

.category-card .card {
  padding: 24px 32px;
  border-radius: 4px;
  background: var(--neutral-10);
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 32px;
}

.category-cards .col-lg-4:nth-child(1) .card {
  border-bottom: 4px solid var(--asset-main);
}

.category-cards .col-lg-4:nth-child(2) .card {
  border-bottom: 4px solid var(--liability-main);
}

.category-cards .col-lg-4:nth-child(3) .card {
  border-bottom: 4px solid var(--primary-main);
  cursor: auto;
}

.category-cards .col-lg-4:nth-child(1) .card-icon {
  background-color: var(--asset-opacity);
}

.category-cards .col-lg-4:nth-child(2) .card-icon {
  background-color: var(--liability-opacity);
}

.category-cards .col-lg-4:nth-child(3) .card-icon {
  background-color: var(--primary-surface);
}

.category-card .card .card-icon {
  padding: 16px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.category-card .card .card-icon img {
  width: 40px;
  height: 40px;
}

.category-card .card .card-item {
  width: 100%;
  margin-left: 16px;
}

/* -------------------------------------charts----------------------------------------------- */

.chart-container {
  position: relative;
  margin-bottom: 32px;
}

.cs-context .pie-chart-container,
.cs-context .line-chart-container {
  padding: 16px 24px;
  border-radius: 4px;
  background: var(--neutral-10);
}

.cs-context .pie-chart-container {
  padding-bottom: 30px;
  height: 100%;
}

.cs-context .chart-heading {
  border-bottom: 1px solid var(--neutral-20);
  padding-bottom: 16px;
}

.cs-context .graph-pie-chart-main-container,
.cs-context .graph-line-chart-main-container {
  height: 413px;
  position: relative;
}

.cs-context .graph-pie-chart-main-container {
  /* padding-top: 32px; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin-top: -20px;
}

.pie-chart-total-networth {
  position: absolute;
  bottom: 0;
  left: 26%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  text-align: center;
}

.dashboard-main-section
  .graph-pie-chart-main-container.asset-pie-chart
  .pie-chart-total-networth,
.pie-chart-container.portfolio-allocation-chart .pie-chart-total-networth {
  left: 32%;
}

.graph-pie-chart-main-container.asset-pie-chart .pie-chart-total-networth {
  left: 32%;
}

.pie-chart-total-networth .default-semi-bold-h2 {
  display: block;
}

.pie-chart-container .recharts-pie-labels {
  color: var(--neutral-100);
  font-size: 14px;
  font-weight: 400;
}

.graph-pie-chart-main-container .recharts-legend-wrapper {
  right: -8% !important;
}

.graph-pie-chart-main-container .recharts-responsive-container {
  width: 80% !important;
}

.recharts-legend-wrapper .recharts-legend-item-text,
.legend-row span {
  color: var(--neutral-90) !important;
  font-size: 12px;
  font-weight: 300;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 16px;
}

.legend-check {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.recharts-legend-wrapper .recharts-surface,
.legend-row span {
  width: 100%;
}

.graph-pie-chart-main-container .default-regular-body-text-m {
  color: var(--neutral-100);
}

.line-chart-container .chart-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 14px;
}

.line-chart-container
  .recharts-cartesian-axis-tick
  .recharts-cartesian-axis-tick-value {
  color: var(--neutral-80);
  font-size: 12px;
  font-weight: 400;
}

.line-chart-container .recharts-responsive-container {
  height: 372px;
}

.line-chart-container .recharts-wrapper {
  top: 10px;
}

.line-chart-container .recharts-legend-wrapper {
  top: -12px !important;
  right: 0px !important;
}

.recharts-default-legend li:last-child {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.recharts-default-legend .recharts-surface {
  width: 8px;
}

.recharts-layer:focus,
.recharts-pie-sector:focus,
.recharts-sector:focus {
  outline: 0;
}

.chart-custom-tooltip {
  background-color: var(--neutral-10);
  color: var(--neutral-100);
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  border: 1px solid var(--neutral-50);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
}

.chart-custom-tooltip .cs-asset-main {
  color: var(--asset-main);
}

.chart-custom-tooltip .cs-liability-main {
  color: var(--liability-main);
  margin-block: 4px;
}

.chart-custom-tooltip .cs-primary-main {
  color: var(--primary-main);
}

.assets-main-section .chart-custom-tooltip p,
.liablities-main-section .chart-custom-tooltip p,
.cs-category-main-section .chart-custom-tooltip p {
  color: var(--neutral-70);
}

.graph-pie-chart-main-container .chart-custom-tooltip {
  background: var(--neutral-100);
  border: 0;
}

.graph-pie-chart-main-container .chart-custom-tooltip p {
  color: var(--neutral-10) !important;
}

.graph-pie-chart-main-container .chart-custom-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 10%;
  margin-left: -10px;
  border-width: 7px;
  border-style: solid;
  border-color: var(--neutral-100) transparent transparent transparent;
}

.cs-main-layout .apexcharts-tooltip {
  z-index: 0;
}

/* .tooltip {
  z-index: 1;
} */
.modal.show ~ .tooltip,
.offcanvas.show ~ .tooltip {
  z-index: 1056;
}

.asset-pie-chart .recharts-responsive-container {
  width: 91% !important;
}

.asset-pie-chart .recharts-legend-wrapper {
  right: -30px !important;
}

.pie-chart-container li.recharts-legend-item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
  margin-right: 0 !important;
}

/* line chart checkbox start*/
.legend-check span:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #c2c2c2;
  background: transparent;
  vertical-align: middle;
  background: none;
  border-radius: 50%;
  -webkit-margin-end: 0.5em;
  margin-inline-end: 0.5em;
}

.legend-check span:after {
  content: "";
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 6px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: transparent;
  border-radius: 10px;
  -webkit-transition: 250ms ease;
  -o-transition: 250ms ease;
  transition: 250ms ease;
}

.legend-check input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-right: 0;
  z-index: 2;
  cursor: pointer;
}

.legend-check input[type="checkbox"] + span {
  position: relative;
  cursor: pointer;
  opacity: 0.6;
}

.legend-check input[type="checkbox"]:checked + span,
.legend-check.legend-check-active span {
  opacity: 1;
}

.legend-check input[type="checkbox"]:checked + span:after,
.legend-check.legend-check-active span:after {
  width: 10px;
  height: 10px;
  left: 5px;
}

.legend-check:first-child span:after {
  background: var(--asset-main);
}

.legend-check:nth-child(2) span:after {
  background: var(--liability-main);
}

.legend-check:last-child span:after {
  background: var(--primary-main);
}

.assets-main-section .graph-line-chart-main-container .legend-row,
.liablities-main-section .graph-line-chart-main-container .legend-row {
  margin-bottom: 0;
}

/* line chart checkbox end */
/* -----------------Common category section------------------- */
.capsync-category-main-section .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.breadcrumb-content-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cs-context .cs-category-main-section .category-line-chart-container {
  width: 100%;
  border-radius: 4px;
  background: var(--neutral-10);
}

.cs-category-main-section .chart-heading {
  padding: 16px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-category-main-section .graph-line-chart-main-container {
  padding: 16px 24px;
}

.cs-category-main-section .default-semi-bold-h2 {
  color: var(--neutral-100);
}

.category-line-chart-container .default-light-body-text-s {
  color: var(--neutral-70);
}

.category-line-chart-container
  .recharts-cartesian-axis-tick
  .recharts-cartesian-axis-tick-value {
  color: var(--neutral-80);
  font-size: 12px;
  font-weight: 400;
}

.category-line-chart-container .recharts-legend-wrapper {
  top: 0px !important;
  right: 50px !important;
}

.cs-tabs-height {
  height: 40px;
}

/* Assets category list Start */
.category-line-chart-container {
  margin-bottom: 32px;
}

.linked-pin svg path {
  fill: var(--primary-main);
}

.action-box .link-margin-right {
  margin-right: 8px !important;
}

/* ========== Manage files Start ========== */

.manage-files .modal-body {
  padding-bottom: 0;
}

.manage-files .modal-footer {
  padding-top: 40px !important;
}

.manage-files .modal-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.cs-files .cs-scroller {
  display: block;
  width: 100%;
  height: auto;
  max-height: 250px;
  margin-right: -4px;
  padding-right: 4px;
}

.manage-profile-upload .cs-files .cs-selected-files:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  margin-bottom: 0;
}

.cs-files-names {
  display: block;
  width: 100%;
  padding: 0 8px;
}

.cs-files-names span {
  display: block;
}

.cs-files-names span:last-child {
  margin-top: 4px;
}

/* ========== Manage files End ========== */

/* ========== Transaction page start ========== */
.cs-transaction-table #cell-1-undefined,
.cs-transaction-table .rdt_TableHead .rdt_TableHeadRow div:first-child {
  max-width: 200px;
}

.cs-transaction-table #cell-3-undefined,
.cs-transaction-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
.cs-transaction-table #cell-4-undefined,
.cs-transaction-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
.cs-transaction-table #cell-5-undefined,
.cs-transaction-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
  max-width: 250px;
}

.cs-transaction-table .rdt_Pagination {
  background: transparent;
}

.transaction-search-bar,
.amortization-header {
  position: relative;
  padding: 16px 24px;
  border-radius: 4px;
  background: var(--neutral-10);
  margin-bottom: 32px;
}

.transaction-period-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.transaction-period-select .react-date-picker {
  width: 248px;
  padding: 6px 24px;
}

.transaction-period-select .react-date-picker .react-date-picker__wrapper {
  border: none;
  cursor: pointer;
}

.transaction-search-bar .showdate {
  border-radius: 4px;
  margin-left: 8px;
  background: var(--primary-surface);
}

.transaction-search-bar .showdate input {
  outline: none;
  background: transparent;
  cursor: pointer;
}

.transaction-search-bar .showdate span.default-regular-h6.cs-neutral-70 {
  margin: 6px 8px;
  text-transform: lowercase;
}

.datepicker-icon svg path {
  fill: var(--neutral-70) !important;
}

.transaction-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.transaction-search .cs-search-bar {
  width: 100%;
}

.transaction-search button.cs-btn-primary {
  height: 40px;
  margin-left: 16px;
}

/* ========== Transaction page end ========== */

/* ========== Amortization page start ========== */

.amortization-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.amortization-header .amortization-icon {
  margin-left: 16px;
}

.cs-amortization-table .cs-tbody tr td:nth-child(2) {
  width: 130px;
  text-align: right;
}

.cs-amortization-table .cs-tbody tr td,
.cs-amortization-table .cs-table tr th {
  padding: 11px 24px;
}

.cs-amortization-table .dollar-value-gap span {
  margin-left: 10px;
}

.amortization_headingTable {
  margin-bottom: 32px;
}

.amortization-price-box .amortization-icon svg {
  width: 100%;
  height: 100%;
}

.amortization-content {
  margin-left: 8px;
}

.cs-thead .amortization-principal,
.cs-tbody .amortization-principal {
  background: #dbdeef;
}

.cs-thead .amortization-interest,
.cs-tbody .amortization-interest {
  background: #efded2;
}

.amortization-main-listing .amortization-principal-icon,
.amortization-main-listing .amortization-interest-icon {
  padding: 0 8px !important;
}

.amortization-main-listing .accordion-button {
  border: 0;
  padding: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.accordion-button {
  z-index: 0 !important;
}

.amortization-main-listing .accordion-header .table,
.amortization-main-listing .accordion-header .table tr,
.amortization-main-listing .accordion-header .table td,
.amortization-main-listing .accordion-item,
.amortization-main-listing .accordion-item table {
  margin-bottom: 0;
  border: 0;
}

.amortization-main-listing td.accordion-table-collapse.accordion-after,
.amortization-main-listing .accordion-body {
  padding: 0;
}

.amortization-main-listing .accordion-header .table td,
.amortization-main-listing .accordion-body .table td {
  height: 48px;
}

.amortization-main-listing .accordion-button::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 16px;
}

.amortization-main-listing .accordion-button:not(.collapsed)::after {
  -webkit-transform: translate(0, -50%) rotate(-180deg);
  -ms-transform: translate(0, -50%) rotate(-180deg);
  transform: translate(0, -50%) rotate(-180deg);
}

.amortization-main-listing .accordion-body .table td {
  padding: 11px 16px;
}

.amortization-main-listing .accordion-body .table tr,
.amortization-main-listing .accordion-body .table tr td {
  border-bottom: 0;
}

.amortization-main-listing .accordion-collapse {
  border-top: 1px solid var(--neutral-50);
}

.amortization-main-listing .cs-tbody tr td.accordion-table-collapse tr td {
  border-radius: 0;
}

.amortization-main-listing .cs-tbody tr:last-child td.accordion-table-collapse,
.amortization-main-listing
  .cs-tbody
  tr:last-child
  td.accordion-table-collapse
  tr
  td:first-child {
  border-radius: 0 0 4px 4px !important;
}

.amortization-main-listing
  .cs-tbody:last-child
  td.accordion-table-collapse
  tr
  td:last-child {
  border-radius: 0 0 4px 0 !important;
}

.amortization-dropdown-menu-wrapper .disable-amortization {
  background-color: transparent !important;
  color: var(--neutral-60) !important;
  cursor: not-allowed;
}

.amortization-dropdown-menu-wrapper span svg path {
  fill: var(--neutral-60) !important;
}

/* ========== Amortization page end ========== */

/* ========== Portfolio page start ========== */
.cs-data-table .rdt_Pagination {
  padding: 20px 0 0 0;
  background: transparent;
}

.portfolio-cs-table-container .data-table-extensions {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  display: none;
}

.portfolio-data-table div[data-column-id="1"],
.portfolio-data-table.cs-data-table .rdt_TableBody #cell-1-undefined {
  width: 100% !important;
  max-width: 270px;
  min-width: auto;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.portfolio-data-table div[data-column-id="portfolio-quantity"] {
  text-align: right !important;
}

.portfolio-data-table.cs-data-table .dollar-value-gap {
  max-width: 100% !important;
  display: block !important;
  text-align: right;
}

.portfolio-data-table.cs-data-table .rdt_TableBody #cell-1-undefined,
.portfolio-data-table.cs-data-table .rdt_TableBody #cell-3-undefined {
  text-transform: uppercase;
}

.portfolio-data-table.cs-data-table .rdt_TableBody #cell-2-undefined {
  text-transform: capitalize;
}

.portfolio-data-table div[data-column-id="portfolio-price"],
.portfolio-data-table div[data-column-id="portfolio-value"],
.portfolio-data-table div[data-column-id="portfolio-quantity"],
.portfolio-data-table div[data-column-id="portfolio-market"] {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.portfolio-data-table .rdt_TableCol:last-child,
.portfolio-data-table .rdt_TableCell:last-child {
  max-width: 130px;
}

.portfolio-cs-table-container .cs-tbody td:last-child {
  padding-right: 32px !important;
}

/* ===== cs-portfolio-transaction Table start ===== */
.cs-transaction-table.cs-portfolio-transaction div[data-column-id="2"] {
  width: 100%;
  max-width: 200px !important;
}

.cs-transaction-table.cs-portfolio-transaction div[data-column-id="3"] {
  width: 100%;
  max-width: 478px !important;
}

.cs-transaction-table.cs-portfolio-transaction div[data-column-id="4"],
.cs-transaction-table.cs-portfolio-transaction div[data-column-id="5"],
.cs-transaction-table.cs-portfolio-transaction div[data-column-id="6"],
.cs-transaction-table.cs-portfolio-transaction div[data-column-id="7"],
.cs-transaction-table.cs-portfolio-transaction div[data-column-id="8"] {
  width: 100%;
  max-width: 152px !important;
}

.cs-transaction-table.cs-portfolio-transaction .dollar-value-gap {
  padding-right: 0;
}

/* ===== cs-portfolio-transaction Table end ===== */

/* ========== Portfolio page end ========== */

.income-input,
input.dollar-symbol {
  padding-right: calc(1.5em + 0.75rem);
  text-align: right;
  background-image: url(../icons/svg/filled/dollar-filled.svg) !important;
  background-repeat: no-repeat !important;
  background-position: left calc(14px + 0.1875rem) center !important;
  background-size: calc(2px + 0.375rem) calc(6px + 0.375rem) !important;
}

input.percentage-symbol {
  padding-right: calc(1.5em + 0.75rem);
  text-align: left;
  background-image: url(../icons/svg/filled/percentage-filled.svg) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(14px + 0.1875rem) center !important;
  background-size: calc(8px + 0.375rem) calc(6px + 0.375rem) !important;
}

.loan-term-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.loan-term-box .loan-term-input {
  width: 100%;
  max-width: 100%;
}

.loan-term-box .loan-term-dropdown {
  width: 100%;
  max-width: 110px;
  margin-left: 0;
}

.loan-term-box
  .loan-term-dropdown.cs-common-add-dropdown
  .dropdown
  .dropdown-menu.show {
  min-width: 100%;
}

.loan-term-box .loan-term-input input {
  border-radius: 4px 0px 0px 4px;
  border-right: 0;
}

.loan-term-box .loan-term-dropdown .dropdown-toggle {
  border-radius: 0 4px 4px 0;
  border: 1px solid var(--neutral-50);
}

.loan-term-dropdown button {
  font-size: 14px;
  line-height: 20px;
  border-color: var(--neutral-50);
}

.message_popup_div,
.wm-company-delete-content {
  text-align: center;
}

.message_popup_div img {
  margin-bottom: 24px;
}

.amortization-schedule-box {
  margin-bottom: 22px;
}

.amortization-schedule-box .indi-assets-ammort-schedule {
  padding-left: 0;
}

.amortization-schedule-box .indi-disabled-schedule {
  color: var(--danger-main) !important;
  padding-left: 0;
}

.amortization-schedule-box
  .indi-disabled-schedule
  .cs-icon-badge-danger.cs-danger {
  margin-left: 9px;
  width: 24px;
  height: 24px;
}

.cs-common-add-dropdown .spinner-border.spinner-border-sm {
  position: absolute;
  right: 16px;
}

.img-container .offcanvas-avatar svg {
  border-radius: 100%;
}

@-webkit-keyframes spinner-spin {
  0% {
    -webkit-transform: rotate(0turn);
    transform: rotate(0turn);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes spinner-spin {
  0% {
    -webkit-transform: rotate(0turn);
    transform: rotate(0turn);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.cs-common-spinner {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-animation: spinner-spin 1s ease 0.1s infinite;
  animation: spinner-spin 1s ease 0.1s infinite;
}

.form-error-msg {
  cursor: auto;
}

/* 404 page start */
.error-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}

.error-image {
  max-width: 100%;
  margin: 50px 0 30px;
}

.error-image img {
  width: 100%;
  height: auto;
}

.error-content h1 {
  margin-bottom: 10px;
}

.error-content button {
  margin: 36px auto 0;
}

/* 404 page end */
.dropdown-toggle,
.dropdown-item,
.cs-date-picker-calender,
.cs-date-picker-calender .react-calendar__tile {
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 16px !important;
}

/* Custom dropdown draggable */
.custom-dropdown-menu {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 244px;
  right: 0;
  padding: 8px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  max-height: 160px;
  overflow: scroll;
  background: var(--neutral-10);
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  border: none;
  z-index: 2;
}

.custom-dropdown {
  position: relative;
}

.custom-dropdown-menu li {
  list-style: none;
  padding-inline: 8px 16px;
}

.custom-dropdown-menu .manage-columns-items .cs-form-check-box {
  padding: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.custom-dropdown-menu .manage-columns-items li:hover {
  background: var(--primary-surface);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.custom-dropdown-menu .cs-form-check-box .form-check-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding-left: 16px;
  margin: 0;
}

.cs-form-check-box span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.custom-dropdown-menu
  .manage-columns-items
  .cs-disabled
  .form-check-input:checked {
  background-color: var(--neutral-50);
  border-color: var(--neutral-50);
}

/* Custom dropdown draggable */

/* QBO Design start */
.cs-qbo-modal .plaid-head {
  padding: 0;
}

.cs-qbo-modal .modal-body {
  padding: 0 0 24px;
}

.cs-qbo-modal .modal-body p {
  padding-inline: 4.5vw;
}

.cs-qbo-modal .modal-header {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-color: var(--neutral-20);
}

.cs-qbo-modal .cs-bank-images img:nth-child(2) {
  width: 64px;
  margin: 0 10px;
}

.cs-qbo-card .card {
  border-bottom: 4px solid transparent;
}

.cs-qbo-card .card-icon {
  width: 30%;
  max-width: 72px;
  min-width: 72px;
  height: 72px;
  background: var(--neutral-30);
}

.cs-qbo-card .card-icon svg path {
  fill: var(--neutral-80);
}

.cs-qbo-card.cs-qbo-active .card-icon {
  background: var(--primary-surface);
}

.cs-qbo-card.cs-qbo-active .card {
  border-color: var(--primary-main);
}

.cs-qbo-card.cs-qbo-active .card-icon svg path {
  fill: var(--primary-main);
}

.report-main-section .showdate {
  background: transparent;
}

.report-main-section .cs-date-picker-calender {
  width: 180px;
  padding-inline: 12px;
}

.report-main-section .react-date-picker__button {
  padding-right: 0;
}

.report-main-section
  .cs-date-picker-calender
  .react-date-picker__calendar--open {
  width: auto;
}

.report-main-section .transaction-period-select {
  display: block;
}

.cs-report-period {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.report-main-section h4 {
  margin-bottom: 4px;
  white-space: nowrap;
}

.report-main-section .dropdown {
  width: 180px;
}

.qbo-select-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}

.cs-radio-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 14px;
}

.qbo-select-row > div:not(.qbo-select-row > div:last-child) {
  margin-right: 24px;
}

.cs-qbo-accounting {
  margin-right: 0;
}

.cs-qbo-compare .show.dropdown-menu {
  display: block !important;
  padding-inline: 10px !important;
}

.cs-qbo-compare .cs-form-check-box.form-check {
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
}

.report-canvas {
  position: relative;
  padding: 16px 32px;
  max-width: 960px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid var(--neutral-40);
  background-color: var(--neutral-10);
}

.report-top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.report-top-bar .cs-btn-icon-primary {
  height: 40px;
  width: 40px;
  padding: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--neutral-20);
  border: 1px solid var(--neutral-20) !important;
  margin-left: 16px;
}

.report-top-bar .cs-btn-icon-primary:hover,
.report-top-bar .cs-btn-icon-primary:active,
.report-top-bar .cs-btn-icon-primary:focus-visible {
  background-color: transparent !important;
  border-color: var(--neutral-20) !important;
}

.report-canvas h2 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 300;
  color: var(--neutral-100);
  margin-bottom: 24px;
}

.report-canvas .cs-common-spinner {
  margin: 30px auto 0;
}

.cs-qbo-container {
  position: relative;
  width: 100%;
  max-width: 894px;
  padding-bottom: 4px;
  overflow: hidden;
  overflow-x: auto;
  margin-top: 32px;
}

.cs-qbo-container .cs-table {
  border: 0;
  border-collapse: collapse !important;
}

.cs-qbo-total th {
  white-space: nowrap;
  width: 100%;
  padding: 0 20px;
  text-transform: uppercase;
  text-align: right;
}

.cs-main-title th {
  border-inline: 1px dashed var(--neutral-50);
}

.cs-main-title th:last-child {
  border: 0;
}

.cs-qbo-total {
  position: relative;
  height: 44px;
  border-block: 1px solid var(--neutral-50);
}
.cs-qbo-md {
  max-width: 100%;
}
.cs-qbo-total th:first-child,
.cs-qbo-total th:last-child {
  position: relative;
  top: 0;
  z-index: 1;
  border: 0;
  background: var(--neutral-10);
}

.cs-qbo-full-width-container .cs-qbo-total th:first-child,
.cs-qbo-full-width-container .cs-qbo-total th:last-child {
  background: var(--neutral-10);
  border: 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.cs-qbo-body tr td {
  text-align: right;
  padding: 10px 20px;
}

.cs-qbo-container tr > th:first-child,
.cs-qbo-container tr > td:first-child {
  position: relative;
  left: 0;
  text-align: left;
  padding-inline: 0 16px;
  background-color: var(--neutral-10);
}

.cs-qbo-full-width-container tr > th:first-child,
.cs-qbo-full-width-container tr > td:first-child {
  position: sticky;
  left: 0;
  text-align: left;
  padding-inline: 0 16px;
  background-color: var(--neutral-10);
}

.cs-accordion-tr {
  padding-left: 20px;
  cursor: pointer;
}

.cs-qbo-td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-pl {
  padding-left: 20px;
}

.cs-qbo-container tr > td .cs-qbo-title {
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.cs-qbo-accordion.accordion .accordion-item {
  border: 0;
}

.cs-accordion-total {
  border-block: 1px solid var(--neutral-50);
}

/* .cs-accordion-total td{
  border-block: 1px solid var(--neutral-50);
} */

.cs-qbo-container .cs-accordion-icon {
  margin-right: 10px;
}

.cs-qbo-full-width-container {
  max-width: 100%;
}

.report-container .qbo-no-record {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 40px 0;
}

.report-main-section .qbo-select-row #dropdown-basic span:first-child {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

/* QBO Design end */

/* Password requirements start */

.last-password-feild {
  margin-bottom: 0;
}

.password-requirements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 24px;
}

.password-requirements span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.password-requirements span {
  margin-top: 4px;
}

.password-requirements span > span:nth-child(1) {
  margin-right: 8px;
  margin-top: 0;
}

.password-requirements span.default-regular-sub-heading-xxs span:nth-child(2) {
  margin: 0;
  margin-left: 6px;
  width: 16px;
  height: 16px;
}

/* Password requirements end */

/* --------- Array page Start --------- */
.cs-grid.cs-array-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 32px;

  margin: 0;
}

.cs-grid.cs-array-row .category-card {
  width: 100%;
}

.cs-grid.cs-array-row .cs-array-card.card {
  margin-bottom: 0;
  padding: 20px 14px 20px 20px;
}

.cs-grid.cs-array-row .cs-array-card.card .card-icon {
  max-width: 64px;
  min-width: 64px;
  height: 64px;
}

.cs-grid.cs-array-row .cs-array-card.card.active-card {
  border-color: var(--primary-main);
}

.cs-grid.cs-array-row .cs-array-card.card.active-card .cs-neutral-90 {
  color: var(--primary-main);
}

.cs-grid.cs-array-row .cs-array-card.card.active-card .card-icon {
  background: var(--primary-surface);
}

.cs-grid.cs-array-row .cs-array-card.card.active-card .card-icon svg path {
  fill: var(--primary-main);
}

/* --------- Array page End --------- */

/* --------- File Management page start --------- */
/* .file-management-section {
  margin-bottom: 16px;
} */
.file-management-section h1,
.cs-breadcrumb span:last-child {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.file-management-section h1 {
  max-width: 300px;
}

.cs-breadcrumb span:last-child {
  max-width: 160px;
}

.file-management-section .row {
  height: calc(100vh - 130px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.cs-list-view,
.cs-grid-view {
  height: calc(100vh - 270px);
  overflow-y: auto;
  margin-right: -6px;
  padding-right: 6px;
}

.fm-list table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.cs-list-view .table {
  margin-bottom: 0;
}

.file-management-section .row .col-lg-12:last-child {
  margin-top: auto !important;
  padding-block: 20px 10px;
}

.layout-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.layout-row .layout-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.layout-box .cs-action-btn span {
  margin-right: 8px;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.layout-box .cs-action-btn span:last-child {
  margin-right: 16px;
}

.layout-box .cs-action-btn span:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.layout-btn {
  position: relative;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  border-radius: 4px;
}

.layout-btn span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-row .cs-btn-icon-primary {
  width: 129px;
  background-color: var(--primary-main);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-grid-view .file-management-dropdown {
  margin-left: 0;
}

.file-management-dropdown {
  margin-left: 16px;
}

.file-management-dropdown a.dropdown-toggle {
  padding: 0;
  background-color: transparent !important;
}

.file-management-dropdown .dropdown-menu {
  width: 225px;
  padding-block: 8px;
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  border: 0;
  z-index: 2;
}

.file-management-dropdown .dropdown-item {
  padding: 9px 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: normal;
}

.file-management-dropdown .dropdown-item span {
  margin-right: 16px;
  color: var(--neutral-90);
}

.file-management-dropdown .dropdown-item:active,
.file-management-dropdown .dropdown-item:focus,
.file-management-dropdown .dropdown-item:focus-visible {
  background-color: var(--primary-surface);
  color: var(--neutral-90);
}

.file-management-dropdown .dropdown-item svg path {
  fill: var(--neutral-90);
}

.file-management-dropdown .dropdown-item.active {
  background-color: var(--primary-surface);
  color: var(--primary-main);
}

.file-management-dropdown .dropdown-item.active svg path {
  fill: var(--primary-main);
}

.file-management-dropdown .cs-divider {
  margin: 2px auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.file-management-dropdown .cs-divider:before {
  height: 0.4px;
}

.file-management-section .cs-section-topbar .row {
  height: auto;
  -webkit-box-orient: inherit;
  -webkit-box-direction: inherit;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
  -ms-flex-wrap: unset;
  flex-wrap: unset;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-action-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-action-btn span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 6px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  cursor: pointer;
}

.cs-action-btn span.cs-primary {
  background-color: #eef1ff;
}

.cs-action-btn span.cs-danger {
  background-color: var(--danger-surface);
}

.cs-action-btn span svg {
  width: 100%;
  height: 100%;
}

/* List Start */
.fm-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--neutral-100);
}

.fm-name span:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-right: 16px;
}

.fm-name span:last-child {
  display: block;
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.fm-list td {
  color: var(--neutral-60);
  height: 52px;
  border-bottom: 1px solid var(--neutral-20);
  vertical-align: middle;
  cursor: pointer;
}

.fm-list tbody tr:last-child td {
  border: 0;
}

.fm-list th,
.fm-list td {
  padding: 12px 16px;
  border-color: var(--neutral-20);
  white-space: nowrap;
}

.fm-list th:first-child {
  border-radius: 4px 0 0 0;
}

.fm-list td:first-child {
  border-radius: 0 0 0 4px;
}

.fm-list th:last-child {
  border-radius: 0 4px 0 0;
}

.fm-list td:last-child {
  border-radius: 0 0 4px 0;
}

/* FM table icon */
.fm-table-icon .dropdown .dropdown-toggle {
  width: initial;
  height: initial;
  padding: 0;
}

.fm-table-icon .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.fm-table-icon .dropdown-item .default-regular-h5 {
  width: initial;
  background: transparent;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  padding: 0;
  margin-left: 12px;
  font-weight: 300;
}

.cs-neutral-80-mobile .dropdown-item .default-light-body-text-m {
  margin-left: 6px;
}

.fm-table-icon .dropdown-item:hover svg path,
.cs-neutral-80-mobile .dropdown-item:hover svg path {
  fill: var(--primary-main);
}

.fm-table-icon .dropdown-item:hover span,
.cs-neutral-80-mobile .dropdown-item:hover {
  color: var(--primary-main);
}

.fm-table-icon .dropdown-item:hover,
.fm-table-icon .dropdown-item:focus,
.fm-table-icon .dropdown-item:active,
.fm-table-icon .dropdown-item:focus-visible,
.cs-neutral-80-mobile .dropdown-item:hover,
.cs-neutral-80-mobile .dropdown-item:focus,
.cs-neutral-80-mobile .dropdown-item:active,
.cs-neutral-80-mobile .dropdown-item:focus-visible {
  background-color: var(--primary-surface);
}

.cs-neutral-80-mobile .dropdown-toggle {
  padding: 0;
  background: transparent !important;
  height: 18px !important;
  width: 18px !important;
}

.cs-table-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fm-table-icon span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  padding: 5px;
  border-radius: 4px;
  background-color: var(--neutral-10);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  cursor: pointer;
}

.fm-table-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-neutral-80-mobile {
  padding: 5px;
  margin-right: 2px;
  margin-left: 2px;
  color: var(--bs-white);
  border-radius: 5px;
  background-color: var(--primary-surface);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-neutral-80-mobile svg path {
  fill: var(--neutral-80);
}

.cs-neutral-80-mobile.cs-danger svg path {
  fill: var(--danger-main);
}

.cs-folder-drop-selected {
  border: 2px solid var(--primary-main) !important;
  background: rgb(238 241 255 / 50%) !important;
}

.cs-folder-drop-selected td {
  background: rgb(238 241 255 / 50%) !important;
}

.cs-folder-drop-selected .cs-table-icon.fm-table-icon {
  display: none;
}

.cs-list-view tr:hover td,
.cs-list-view .selected td,
.cs-grid-view .cs-box:hover,
.cs-grid-view .cs-box.selected {
  background-color: var(--primary-surface);
}

.fm-table-icon span:not(span:last-child) {
  margin-right: 8px;
}

.fm-table-icon span.cs-danger {
  background: var(--danger-surface);
}

tr:hover .fm-table-icon span {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.file-management-section .cs-table-icon .cs-danger {
  padding: 5px;
  margin-right: 8px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--danger-surface);
}

.file-management-section .cs-table-icon .cs-danger:last-child {
  margin-right: 0;
}

.cs-settings-dropdown .dropdown-item svg {
  width: 18px;
  height: 18px;
}

/* List End */
/* Grid view */
.cs-grid-dropzone {
  height: calc(100vh - 324px);
}

.cs-dropzone.cs-grid-no-record {
  height: calc(100vh - 265px);
}

.cs-grid-view {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-animation: fade-in 0.5s forwards;
  animation: fade-in 0.5s forwards;
  display: -ms-grid;
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  -ms-grid-rows: -webkit-max-content;
  -ms-grid-rows: max-content;
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
  margin: 0;
  padding-bottom: 26px;
  height: 100%;
  /* max-height: calc(100vh - 324px); */
  /* max-height: 100%; */
}

.cs-grid-view .cs-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 220px;
  flex: 1 1 220px;
  max-width: 100%;
  min-width: 0;
  max-height: 248px;
  /* overflow: hidden; */
  position: relative;
  vertical-align: top;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  cursor: pointer;
}

.file-preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 176px;
  border: 1px solid var(--neutral-20);
  border-radius: 2px;
  background-color: var(--neutral-40);
  margin-bottom: 16px;
  overflow: hidden;
}

.file-preview img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
}

.file-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: auto;
}

.file-info .dropdown-toggle {
  padding: 0;
  height: auto;
  border: 0;
  background: transparent;
}

.file-info.file-management-dropdown .dropdown-menu.show {
  width: 210px;
}

.file-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: calc(100% - 30px);
}

.file-title h4 {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.file-title span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 8px;
  width: 22px;
  height: 22px;
}

/* Grid view end*/
.cs-storage {
  position: relative;
  max-width: 350px;
}

.storage-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.storage-info .storage-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-storage .cs-progress-bar {
  width: 100%;
  height: 6px;
  margin-block: 4px 0;
}

.cs-storage .cs-progress-bar .progress {
  height: 100%;
}

.cs-section-topbar .global-search-box .cs-form-dropdown {
  margin-bottom: 0;
}

.cs-section-topbar .global-search-box .cs-form-dropdown .dropdown-toggle {
  padding: 0;
  background: var(--neutral-20);
}

.cs-section-topbar
  .global-search-box
  .cs-form-dropdown
  .dropdown-toggle
  .form-control {
  border: 0;
  border-radius: 4px;
  height: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  padding-right: 44px;
  color: var(--neutral-100);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent;
}

.cs-section-topbar
  .global-search-box
  .cs-form-dropdown
  .dropdown-toggle
  .form-control:focus,
.cs-section-topbar
  .global-search-box
  .cs-form-dropdown
  .dropdown-toggle
  .cs-input-field-active {
  background-color: var(--neutral-10);
}

.cs-section-topbar .global-search-box .google-map.dropdown-item {
  padding: 10px 16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-section-topbar
  .global-search-box
  .google-map.dropdown-item
  span.cs-neutral-80 {
  margin-right: 8px;
}

.file-management-section .cs-breadcrumb span.default-light-body-text-s,
.file-management-modal .cs-breadcrumb span.default-light-body-text-s {
  display: block;
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.file-management-modal .modal-title {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.file-management-modal .modal-title .default-semi-bold-h3 {
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 260px;
}

.file-management-modal
  .cs-folder-title
  span.default-regular-sub-heading-m.cs-neutral-100 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 340px;
}

.cs-list-view tr td::-moz-selection,
.cs-list-view ::-moz-selection,
.cs-grid-view tr td::-moz-selection,
.cs-grid-view ::-moz-selection {
  background: transparent !important;
  color: var(--neutral-100) !important;
}

.cs-list-view tr td::-moz-selection,
.cs-list-view ::-moz-selection,
.cs-grid-view tr td::-moz-selection,
.cs-grid-view ::-moz-selection {
  background: transparent !important;
  color: var(--neutral-100) !important;
}

.cs-list-view tr td::-moz-selection,
.cs-list-view ::-moz-selection,
.cs-grid-view tr td::-moz-selection,
.cs-grid-view ::-moz-selection {
  background: transparent !important;
  color: var(--neutral-100) !important;
}

.cs-list-view tr td::-moz-selection,
.cs-list-view ::-moz-selection,
.cs-grid-view tr td::-moz-selection,
.cs-grid-view ::-moz-selection {
  background: transparent !important;
  color: var(--neutral-100) !important;
}

.cs-list-view tr td::-moz-selection,
.cs-list-view ::-moz-selection,
.cs-grid-view tr td::-moz-selection,
.cs-grid-view ::-moz-selection {
  background: transparent !important;
  color: var(--neutral-100) !important;
}

.cs-list-view tr td::-moz-selection,
.cs-list-view ::-moz-selection,
.cs-grid-view tr td::-moz-selection,
.cs-grid-view ::-moz-selection {
  background: transparent !important;
  color: var(--neutral-100) !important;
}

.cs-list-view tr td::-moz-selection,
.cs-list-view ::-moz-selection,
.cs-grid-view tr td::-moz-selection,
.cs-grid-view ::-moz-selection {
  background: transparent !important;
  color: var(--neutral-100) !important;
}

.cs-list-view tr td::-moz-selection,
.cs-list-view ::-moz-selection,
.cs-grid-view tr td::-moz-selection,
.cs-grid-view ::-moz-selection {
  background: transparent !important;
  color: var(--neutral-100) !important;
}

.cs-list-view tr td::selection,
.cs-list-view ::selection,
.cs-grid-view tr td::selection,
.cs-grid-view ::selection {
  background: transparent !important;
  color: var(--neutral-100) !important;
}

/* *-*-*-* File & Folder upload start *-*-*-* */
.file-management-section .manage-file-container {
  /* height: 72vh; */
  height: calc(100vh - 27vh);
}

.file-management-section .manage-file-container.no-records {
  height: 100%;
}

.file-management-section.archive-page .manage-file-container {
  height: 64vh;
}

.file-management-section.trash-page .manage-file-container {
  height: 56vh;
}

.file-management-section .manage-file-container .cs-upload-files {
  height: 100%;
  /* height: calc(100% - 3%); */
  background-color: var(--neutral-10);
  border: none;
  cursor: auto;
}

.archive-page .manage-file-container .cs-upload-files,
.trash-page .manage-file-container .cs-upload-files {
  cursor: auto;
}

.file-management-section .manage-file-container input#folderInput {
  display: none;
}

/* *-*-*-* File & Folder upload end *-*-*-* */
.file-management-dropdown .dropdown-toggle {
  padding: 0;
  border: 0;
  background: transparent !important;
}

.file-management-dropdown .dropdown-item:hover {
  color: var(--primary-main);
  background-color: var(--primary-surface);
}

.file-management-dropdown .dropdown-item .filled-icon,
.file-management-dropdown .dropdown-item:hover .outlined-icon {
  display: none;
}

.file-management-dropdown .dropdown-item .outlined-icon,
.file-management-dropdown .dropdown-item:hover .filled-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.file-management-dropdown .dropdown-item:hover svg path {
  fill: var(--primary-main);
}

/* *-*-*-* Trash page design start *-*-*-* */
.archive-page .cs-grid-dropzone {
  height: calc(100vh - 348px);
}

.archive-page .cs-list-view {
  height: calc(100vh - 352px);
}

.trash-page .cs-list-view {
  height: calc(100vh - 432px);
}

.archive-page .cs-grid-view,
.trash-page .cs-grid-view {
  height: auto;
}

.archive-page .cs-grid-view {
  max-height: calc(100vh - 346px);
}

.trash-page .cs-grid-view {
  max-height: calc(100vh - 426px);
}

.trash-page .cs-dropzone.cs-grid-dropzone {
  height: calc(100vh - 428px);
}

.cs-trash {
  width: 100%;
  padding: 12px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 32px;
}

.cs-trash h4 .cs-primary {
  margin-right: 6px;
}

.cs-trash h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--neutral-90);
}

.cs-trash span.cs-primary.default-regular-h5 {
  margin-right: 8px;
  cursor: pointer;
}

.cs-trash-dropdown .dropdown {
  position: relative;
  max-width: 157px;
  width: 100%;
}

.cs-trash-dropdown {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 18px;
}

.file-management-section .cs-label {
  display: inline-block;
  margin-bottom: 16px;
}

.sorting-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
}

.sorting-dropdown .sorting-icons {
  width: 40px;
  height: 40px;
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  background-color: var(--neutral-10);
  border-radius: 4px;
  padding: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.grid-dropdown-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.fm-list thead th span {
  cursor: pointer;
  margin-left: 4px;
}

/* *-*-*-* Trash page design end *-*-*-* */

.cs-lp-screen.file-management-dropdown {
  margin-left: 0;
  margin-right: 16px;
  display: none;
}

.file-preview-modal img {
  /* width: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain; */
  width: auto !important;
  height: auto;
  max-height: calc(100vh - 20vh);
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.file-preview-modal .fixed-capsync-loader.cs-preview-loader,
.file-preview-modal .fixed-capsync-loader.cs-preview-loader .cs-loader,
.file-preview-modal .fixed-capsync-loader.cs-preview-loader img {
  width: 120px;
  height: 120px;
  position: relative;
}

.file-preview-modal .modal-body .cs-preview-body pre,
.file-preview-modal .modal-body .cs-height {
  display: block;
  height: 83vh;
  margin: 0;
  overflow: auto;
}

.file-preview-modal .modal-dialog {
  /* max-height: 100%; */
  max-width: calc(100vw - 5vw);
}

.file-preview-modal .modal-content {
  height: 100%;
  border: 0;
}

.file-preview-modal .modal-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.file-preview-modal .modal-body div,
.file-preview-modal .modal-body iframe {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.file-preview-modal .modal-body iframe {
  height: 83vh;
}

.global-search-box .google-map.dropdown-item span:last-child {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.cs-move-container {
  height: 420px;
  overflow-y: auto;
  margin-right: -6px;
  padding-right: 6px;
}

.cs-move-container .cs-loader {
  width: 80px;
  height: 80px;
}

.cs-move-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid var(--neutral-50);
  border-radius: 4px;
  padding: 14px 24px;
  margin-bottom: 16px;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.cs-move-row .cs-folder-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-move-row .cs-folder-title span.cs-neutral-80 {
  margin-right: 16px;
}

.cs-move-row .cs-folder-title span.file-icon {
  margin-right: 16px;
}

.cs-icon-disabled svg,
.cs-icon-disabled svg path {
  opacity: 0.9;
}

.cs-icon-disabled {
  cursor: not-allowed;
  border-color: var(--neutral-40) !important;
  background-color: transparent !important;
}

.cs-icon-disabled span {
  color: var(--neutral-60) !important;
  cursor: not-allowed;
  background-color: transparent !important;
}

.cs-move-row:hover {
  border-color: var(--primary-main);
  background-color: var(--primary-surface);
}

.cs-move-container span.default-regular-h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.cs-choose-file-box:before {
  content: attr(title);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  line-height: 16px;
  pointer-events: none;
  padding-right: 55px;
  padding-left: 18px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.cs-move-row.cs-disabled {
  cursor: not-allowed;
  border-color: var(--neutral-60) !important;
}

.file-upload-status {
  position: fixed;
  bottom: 0;
  right: 25px;
  border-radius: 8px 8px 0 0;
  border: 1px solid var(--primary-border);
  border-bottom: 0;
  width: 240px;
  padding: 15px;
  background-color: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
}

.file-upload-status .file-upload-row {
  max-height: 180px;
  overflow-y: auto;
  margin-top: 10px;
  margin-right: -4px;
  padding-right: 4px;
}

.file-upload-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-upload-list span.cs-danger {
  margin-left: 10px;
  padding: 7px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  background-color: var(--danger-surface);
  border-radius: 4px;
}

.file-upload-status h4 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

.file-upload-status .cs-storage {
  width: 100%;
  max-width: 100%;
  margin-bottom: 6px;
}

.file-upload-status span.cs-neutral-100.default-regular-sub-heading-xxs {
  /* max-width: 160px; */
  max-width: 120px;
  margin-right: 18px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.file-upload-status .hide-status {
  padding-bottom: 5px;
}

.file-upload-status .hide-status .file-upload-row {
  display: none;
}

.file-upload-status .cs-arrow {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.file-upload-status .hide-status .cs-arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.file-upload-status hr {
  margin: 0.5rem 0;
}

/* File Upload Text Visibility Start */
.cs-choose-file .form-control {
  padding-block: 11px 10px;
  padding-inline: 16px 40px;
  color: transparent;
}

.cs-choose-file-box:before {
  content: attr(title);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  pointer-events: none;
  padding-right: 55px;
  padding-left: 18px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.file-preview-modal .modal-title {
  max-width: 400px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.cs-videoplayer {
  width: auto;
  height: 100%;
}

.cs-videoplayer video {
  max-width: 100%;
  max-height: 80vh;
}

.cs-grid-view .cs-box:hover {
  background-color: var(--neutral-10);
}

.cs-grid-view .cs-box.selected:hover {
  background-color: var(--primary-surface);
}

.layout-box span.cs-disabled {
  cursor: not-allowed !important;
  background: var(--neutral-20);
}

.layout-box span.cs-disabled:hover {
  -webkit-box-shadow: var(--box-shadow-one) !important;
  box-shadow: var(--box-shadow-one) !important;
}

/* .csv, .xlsx, .xls preview modal start */
.table-preview-container .cs-preview-dropdown {
  margin-bottom: 24px;
}

.table-preview-container span.default-regular-h4 {
  text-align: center;
  display: block;
}

.file-preview-modal .modal-body .table-preview-container {
  display: block;
}

.file-preview-modal .modal-body .table-preview-row {
  display: block;
  height: calc(100vh - 26vh);
  overflow: auto;
  margin-bottom: 30px;
}

.file-preview-modal .modal-body .table-preview-row tr th,
.file-preview-modal .modal-body .table-preview-row tr td {
  white-space: nowrap;
  border-right: 1px solid var(--neutral-50);
  border-bottom: 1px solid var(--neutral-50);
}

/* .file-preview-modal .modal-body .table-preview-row th:last-child,
.file-preview-modal .modal-body .table-preview-row tr td:last-child{
  border-right: 0;
} */
.cs-preview-xls tr td,
.file-preview-modal .modal-body .table-preview-row tr td {
  white-space: unset !important;
}

ul#sheet-tab {
  position: relative;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  border: 0;
}

ul#sheet-tab li,
ul#sheet-tab li button {
  width: auto;
}

.file-preview-modal .modal-body .cs-preview-tab {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  -webkit-box-shadow: none !important;

  box-shadow: none !important;
  outline: none !important;
}

ul#sheet-tab li .nav-link {
  border: none;
  color: var(--neutral-100);
  background-color: var(--neutral-20);
  margin-right: 6px;
  border-radius: 4px;
}

ul#sheet-tab li .nav-link.active {
  color: var(--primary-main);
  background-color: transparent;
  background: var(--primary-surface);
}

.tab-content {
  display: none;
}

.file-preview-modal .modal-body ul#sheet-tab::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.file-preview-modal .modal-body .table-preview-row::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.file-preview-modal .modal-body .table-preview-row::-webkit-scrollbar-thumb {
  background: var(--primary-main);
  border-radius: 5px;
}

.file-preview-modal .modal-body .table-preview-row::-webkit-scrollbar-track {
  background: var(--primary-surface);
  border-radius: 5px;
}

.file-management-section .cs-settings-dropdown .drop-icon {
  width: auto;
  height: auto;
  background-color: transparent;
}

/* .csv, .xlsx, .xls preview modal end */

.cs-dropzone {
  outline: none;
  position: relative;
  border: 2px solid transparent;
  margin-left: -2px;
  margin-right: -2px;
  border-radius: 4px;
}

.cs-dropzone:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: background-color 0.1s linear;
  pointer-events: none;
}

.cs-dropzone-hover::before {
  background: rgb(238 241 255 / 50%);
  border-color: var(--primary-main);
}

.cs-dropzone-hover-not-allowed::before {
  background: rgb(255 244 244 / 50%);
  border-color: var(--danger-main);
}

/* --------- File Management page end --------- */

/* -------------- New Pie Chart Start ---------------- */
/* .new-pie-chart .chart-heading {
  padding: 16px 24px;

} */

.common-pie-charts,
.common-pie-charts > div,
.new-pie-chart foreignObject {
  width: 100% !important;
  height: 100% !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  overflow: visible !important;
  /* padding-top: 4px; */
}

/* .common-pie-charts>div{
  width: auto !important;
} */

.charts-container {
  margin-bottom: 32px;
}

.new-pie-chart,
.line-chart-container {
  background-color: var(--neutral-10);
  height: 500px;
  width: 100%;
  border-radius: 4px;
  padding: 16px 24px;
  position: relative;
}

.new-pie-chart .cs-common-no-record {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0;
  border: none;
  width: 100%;
}

.apexcharts-canvas {
  width: 100% !important;
  height: 430px !important;
  overflow: visible !important;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; */
  padding-top: 16px;
  display: block;
}

.apexcharts-canvas svg {
  /* width: 100% !important;
  height: 430px !important; */
  overflow: visible !important;
  display: block !important;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; */
}

.apexcharts-legend {
  padding: 0 !important;
  margin: 0 !important;
  padding-inline: 16px !important;
  max-height: 100% !important;
  overflow: visible !important;
  /* bottom: 1vh !important; */
}

.apexcharts-legend-series {
  padding: 0 !important;
  margin: 0 !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  margin: 0 8px 8px 0 !important;
}

.apexcharts-legend-series span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.apexcharts-legend-marker {
  margin-right: 8px !important;
  border-style: none !important;
  width: 8px !important;
  height: 8px !important;
  transform: unset !important;
}

.apexcharts-legend-marker svg {
  position: relative;
  top: 4px !important;
  left: 8px !important;
  display: flex !important;
  align-items: center;
}

.apexcharts-legend-text {
  font-family: "Readex Pro" !important;
  font-size: 10px !important;
  font-weight: 300 !important;
  line-height: 16px !important;
  color: var(--neutral-90) !important;
}

.apexcharts-datalabel-label {
  font-family: "Readex Pro" !important;
  font-size: 10px !important;
  font-weight: 300 !important;
  line-height: 16px !important;
  fill: var(--neutral-90);
  background-color: transparent !important;
}

.apexcharts-datalabel-value {
  font-family: "Readex Pro" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
  fill: var(--neutral-100);
  background-color: transparent !important;
}

.apexcharts-datalabels .apexcharts-text.apexcharts-pie-label,
.legend-mouseover-inactive .apexcharts-text.apexcharts-pie-label {
  font-family: "Readex Pro" !important;
  font-size: 8px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  background-color: transparent !important;
  text-shadow: none !important;
  opacity: 1 !important;
}

.apexcharts-tooltip-y-group {
  padding: 8px !important;
  margin: 0px !important;
  width: 100% !important;
  background-color: transparent !important;
  border-radius: 4px !important;
}

.apexcharts-tooltip-y-group .apexcharts-tooltip-text-y-label {
  font-family: "Readex Pro" !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.apexcharts-tooltip-y-group .apexcharts-tooltip-text-y-value {
  font-family: "Readex Pro" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  margin: 0 0 0 8px !important;
}

.apexcharts-text.apexcharts-pie-label {
  -webkit-filter: none !important;
  filter: none !important;
}

.apexcharts-series.apexcharts-pie-series path::-moz-selection {
  outline: none !important;
}

.apexcharts-series.apexcharts-pie-series path:active,
.apexcharts-series.apexcharts-pie-series path:focus,
.apexcharts-series.apexcharts-pie-series path:focus-visible,
.apexcharts-series.apexcharts-pie-series path:target,
.apexcharts-series.apexcharts-pie-series path:hover,
.apexcharts-series.apexcharts-pie-series path:visited,
.apexcharts-series.apexcharts-pie-series path::selection {
  outline: none !important;
}

.apexcharts-series.apexcharts-pie-series path {
  stroke-width: 1px !important;
  -webkit-filter: none !important;
  filter: none !important;
}

/* --------- New Pie Chart End --------- */
.automobile-dropdown-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.automobile-dropdown-box > div,
.automobile-dropdown-box .css-13cymwt-control,
.automobile-dropdown-box .css-13cymwt-control {
  width: 100% !important;
}

.automobile-dropdown-box .css-b62m3t-container .css-t3ipsp-control:hover,
.automobile-dropdown-box
  .css-b62m3t-container
  .css-t3ipsp-control:focus-visible,
.automobile-dropdown-box .css-b62m3t-container .css-t3ipsp-control:focus,
.automobile-dropdown-box .css-b62m3t-container .css-t3ipsp-control:active,
.automobile-dropdown-box .css-b62m3t-container .css-t3ipsp-control:visited,
.automobile-dropdown-box
  .css-b62m3t-container
  .css-t3ipsp-control:focus-within {
  width: 100% !important;
}

.automobile-dropdown-box .cs-common-spinner {
  width: 16px;
  height: 16px;
}

.cs-line-clamp {
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden; */
  word-break: break-all;
}

.favicon-small .advanced-cropper-boundary.advanced-cropper__boundary,
.favicon-Avatar .advanced-cropper-boundary.advanced-cropper__boundary {
  height: 458px;
}

.advanced-cropper-boundary.advanced-cropper__boundary {
  height: 260px;
}

.advanced-cropper__background-wrapper {
  background-image: url(../images/back-image.png);
  /* background-color: var(--neutral-10); */
}

/* ========== Profile navigation Start ========== */
.profile-navigation {
  display: flex;
  position: relative;
  align-items: center;
  padding: 16px 0 6px;
  z-index: 2;
  justify-content: space-between;
  background-color: var(--neutral-10);
}

.profile-navigation .cs-icon {
  position: relative;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  border: 0;
  outline: none !important;
  background: var(--primary-main);
  border-radius: 3px;
}

.profile-navigation .cs-icon.cs-disabled {
  background: var(--neutral-30) !important;
}

.profile-navigation .cs-icon svg {
  width: 14px;
  height: 14px;
}

input.form-range,
.form-range:focus {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-range {
  -webkit-appearance: none;
  width: 84%;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--neutral-40);
  border-radius: 30px;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--primary-main);
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  transform: translateY(calc(-50% + 7px));
}

.form-range:focus::-webkit-slider-thumb {
  background: var(--primary-pressed);
}

/* ========== Profile navigation End ========== */

.cs-session-modal {
  background-color: var(--primary-canvas-surface);
}

.cs-dropzone .manage-file-container .cs-upload-files label {
  padding: 8px 0 4px 0;
}
