.settings-main-section .settings-body .mfa-setup-page {
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.settings-main-section .mfa-setup-page .settings-mfa-card {
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.settings-main-section .mfa-setup-page .settings-mfa-card .mfa-icon-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: var(--neutral-10);
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.settings-main-section .mfa-setup-page .settings-mfa-card .mfa-content-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  margin: 16px 0;
}

.settings-main-section .mfa-setup-page .mfa-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}

.settings-main-section
  .mfa-setup-page
  .mfa-cards
  .settings-mfa-card:nth-child(2) {
  margin-left: 32px;
}

.authenticator-app-verification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.authenticator-buttons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.authenticator-buttons .cs-btn-primary {
  margin-left: 16px;
}

.authenticator-app-verification h5.default-regular-h5.cs-neutral-100 {
  margin-bottom: 16px;
  text-align: center;
}

.authenticator-app-verification .qr-image-background {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2.5px;
  border: 0.5px solid var(--neutral-50);
  background: var(--neutral-10);
}

.authenticator-app-verification .qr-image-background img {
  height: auto;
  width: 100%;
}

#mfa-setup-modal .email-verification-buttons,
#mfa-setup-modal .authenticator-buttons {
  margin-top: 24px;
}

#mfa-setup-modal .email-otp-verfication {
  display: block;
  text-align: center;
}

#mfa-setup-modal .email-otp-verfication .default-semi-bold-h3,
#mfa-setup-modal .default-semi-bold-h3 {
  padding-bottom: 16px;
  margin-bottom: 16px;
  text-align: center;
  border-bottom: 1px solid var(--neutral-20);
}

.mobile-verification-title {
  display: block;
  margin-bottom: 20px;
}

.cs-form-group.mobile-verification-box {
  margin-bottom: 30px;
}

.cs-qr-code {
  position: relative;
  display: block;
  width: 192px;
  height: 192px;
  border-radius: 2.5px;
  border: 0.5px solid var(--neutral-50);
  background: var(--neutral-10);
  margin-bottom: 24px;
}

.cs-qr-code img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.email-otp-verfication .text-boxes-content span,
.email-otp-verfication .cs-msg,
.email-otp-verfication .otp-content span {
  margin: 8px 0;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .settings-main-section .settings-body .mfa-setup-page {
    padding: 24px;
  }

  .settings-main-section
    .mfa-setup-page
    .mfa-cards
    .settings-mfa-card:nth-child(2) {
    margin-left: 24px;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .settings-main-section .settings-body .mfa-setup-page {
    padding: 24px 16px;
  }

  .settings-main-section .mfa-setup-page .mfa-cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .settings-main-section
    .mfa-setup-page
    .mfa-cards
    .settings-mfa-card:nth-child(2) {
    margin: 24px 0 0 0;
  }

  .mfa-content-text h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .mfa-content-text h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .settings-mfa-card button {
    line-height: 20px;
    font-weight: 500;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
