.qualified-purchaser-methods .cs-form-check-box {
    padding-left: 0;

}

.qualified-purchaser-methods ul {
    margin-left: 8px;

}

.method-thirty-three .cs-common-add-dropdown {
    margin-bottom: 24px;
}

.accreditation-main-section .verify-invester-col .cs-file-icon .cs-disabled {
    cursor: not-allowed !important;
}