.cs-main-layout .main-content .financial-advisor-cards .card {
  border-bottom: 4px solid var(--primary-main);
}

.financial-advisor-cards.category-cards .col-lg-4:nth-child(1) .card,
.financial-advisor-cards.category-cards .col-lg-4:nth-child(2) .card {
  border-bottom: 4px solid var(--primary-main);
}

.cs-main-layout .main-content .financial-advisor-cards .card-icon {
  background-color: var(--primary-surface);
}

.financial-advisor-cards.category-cards .col-lg-4:nth-child(1) .card-icon,
.financial-advisor-cards.category-cards .col-lg-4:nth-child(2) .card-icon {
  background-color: var(--primary-surface);
}

.wm-tab-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 32px;
}

.wm-view-all {
  color: var(--primary-main);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.wm-ind-dashboard-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  cursor: pointer;
}

.financial-advisor-subscribe-modal .cs-md-modal {
  max-width: 545px;
}

.financial-advisor-subscribe-modal .cs-xl-modal {
  max-width: 800px;
}

.financial-advisor-subscribe-modal .modal-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 40px 56px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.financial-advisor-subscribe-modal
  .cs-subscription-section
  .subscript-Planarea
  .card-plan.card-body {
  width: 384px;
}

.financial-advisor-subscribe-modal.cs-md-modal
  .cs-subscription-section
  .subscript-Planarea
  .card-simple-plan.card {
  margin: 0;
}

.financial-advisor-subscribe-modal
  .cs-subscription-section
  .subscript-Planarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.financial-advisor-subscribe-modal
  .cs-subscription-section
  .month-years-plan
  label {
  white-space: nowrap;
}

.skeleton-cards .card {
  border: none !important;
  background-color: var(--neutral-30);
}

.wm-skeleton-margin {
  margin-bottom: 32px;
}

.wm-skeleton-tabs-container {
  margin-top: 0px;
}

.financial-advisor-subscribe-modal.cs-xl-modal
  .cs-subscription-section
  .subscript-Planarea {
  height: 100%;
  -webkit-box-align: normal;
  -ms-flex-align: normal;
  align-items: normal;
}

.financial-advisor-subscribe-modal.cs-md-modal label.save-plan-offer,
.financial-advisor-subscribe-modal.cs-md-modal label.disable-save-plan-offer {
  margin-right: 0;
}

.financial-initial-modal .cs-form-dropdown {
  margin-top: 4px;
}

.financial-initial-modal .cs-radio-btn {
  cursor: pointer;
}

.financial-initial-modal .cs-radio-btn:last-child {
  margin-right: 0px;
}

.financial-initial-modal .partner-details {
  margin-bottom: 16px;
}

.financial-initial-modal .cs-btn-icon-tertiary,
.financial-initial-modal .cs-btn-icon-tertiary span.cs-primary {
  padding-left: 0 !important;
  margin: 0;
}

.financial-initial-modal .cs-btn-icon-tertiary span svg {
  margin-right: 8px;
}

.financial-initial-modal .initial-add-more .form-error-msg {
  left: auto;
}

.financial-initial-modal .initial-add-more {
  margin: 10px 0 24px 0;
}

.financial-initial-modal .financial-initial-bulk-upload {
  margin: 0 0 24px 0;
}

.financial-initial-modal .financial-initial-bulk-upload span {
  display: inline-block;
}

.financial-initial-modal .cs-form-dropdown .dropdown-toggle {
  color: var(--neutral-100);
}

.fa-cp-popup .cs-form-dropdown .dropdown-menu.show {
  max-height: 135px;
  overflow: hidden;
  overflow-y: auto;
}

.WM-choose-plan-modal .card-simple-plan.card:first-child {
  width: 100%;
  margin-right: 0;
}

.WM-choose-plan-modal .cs-subscription-section .month-years-plan label {
  margin-right: 14px;
}

.WM-choose-plan-modal
  .cs-subscription-section
  .month-years-plan
  label:last-child {
  margin: 0;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
  .skeleton-table,
  .skeleton-manage-container.wm-skeleton-tabs-container {
    position: relative;
    width: 100%;
    overflow-x: auto;
  }

  .WM-choose-plan-modal
    .cs-subscription-section
    .subscription-plan-cards
    .card {
    width: 100% !important;
  }
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .wm-tab-content h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 8px;
  }

  .WM-choose-plan-modal .cs-subscription-section .month-years-plan label {
    font-size: 14px;
  }

  .skeleton-manage-container.wm-skeleton-tabs-container {
    overflow: hidden;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .wm-tab-content {
    width: 100%;
    margin-bottom: 24px;
    margin-top: 8px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wm-tab-content .cs-tabs {
    width: 100%;
  }

  .wm-tab-content .cs-tabs button {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
  }

  .wm-ind-dashboard-action span svg {
    width: 16px;
    height: 16px;
  }

  .financial-initial-modal .financial-initial-bulk-upload {
    margin: 0 0 16px 0;
  }

  .financial-initial-modal .default-regular-sub-heading-xs.cs-neutral-100,
  .financial-initial-modal
    .financial-initial-bulk-upload
    .default-regular-sub-heading-xs.cs-neutral-80 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .financial-initial-modal .initial-add-more {
    margin: 8px 0 16px 0;
  }

  .financial-tab-skeleton .skeleton-recently {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
  .WM-choose-plan-modal
    .cs-subscription-section
    .plan-content-area
    .plan-toggle {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
  }

  .WM-choose-plan-modal .cs-subscription-section .month-years-plan label {
    margin-right: 8px;
  }

  .WM-choose-plan-modal
    .cs-subscription-section
    .month-years-plan
    label:last-child {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
