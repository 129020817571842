.cs-md-modal .log-out-popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.cs-md-modal .log-out-popup p {
  margin-top: 24px;
}

/* .log-out-buttons {
   width: 100%;
   margin: 0;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
   -ms-flex-direction: row;
   flex-direction: row;
 }

 .log-out-buttons button.cs-btn-primary {
   margin-left: 16px;
 } */
