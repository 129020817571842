.report-main-section .qbo-download-dropdown .dropdown .dropdown-toggle {
  padding: 0;
  border: none;
}

.report-main-section .qbo-download-dropdown .qbo-drop-down {
  width: 100%;
}

.qbo-disconnect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.qbo-disconnect img {
  margin-left: 16px;
}

.qbo-disconnect .cs-btn-icon-tertiary {
  padding-right: 0 !important;
}

.report-main-section .end-date-error {
  background: var(--danger-surface) !important;
  border: 1px solid var(--danger-main) !important;
}

.report-main-section .end-date-error .react-date-picker__inputGroup {
  color: var(--danger-main) !important;
}

.report-main-section .end-date-error .react-date-picker__calendar-button .cs-neutral-70 svg path {
  fill: var(--danger-main) !important;
}

.cs-qbo-modal .qbo-btn {
  padding-right: 30px;
  padding-left: 30px;
  gap: 16px;
}

.cs-qbo-modal .qbo-btn span {
  display: inline-block;
  cursor: pointer;
  max-width: 223px;
}

.cs-qbo-modal .qbo-btn .cs-btn-secondary {
  max-width: 223px;
  padding: 8px 10px;
}

.quickbooks-share-modal .signature-return-box {
  margin-top: 10px;
  margin-bottom: 20px;
}

.quickbooks-share-modal .last-email-field .form-error-msg {
  position: relative;
  margin-top: -5px;
}

/* ************Skeleton************** */
.report-main-section .skeleton-qbo {
  background-color: var(--neutral-30);
}

.report-main-section .skeleton-qbo .for-des {
  display: block;
}

.report-main-section .skeleton-qbo .for-mob {
  display: none;
}

/*=========================================================================================*/
/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {}

@media (max-width: 1199px) {
  .report-main-section .skeleton-qbo .for-des {
    display: none;
  }

  .report-main-section .skeleton-qbo .for-mob {
    display: block;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {}

@media (max-width: 767px) {}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .qbo-disconnect img {
    width: 70px;
    height: auto;
    margin-left: 10px;
  }
}

@media (max-width: 425px) {}

@media (max-width: 360px) {}

@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */