.settings-main-section .settings-body .file-encryption-page {
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.settings-main-section .settings-body .file-encryption-page .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 24px;
}

.settings-main-section .settings-body .file-encryption-page .text h3 {
  margin-bottom: 8px;
}

.settings-main-section .settings-body .file-encryption-page .form-input-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.settings-main-section .settings-body .file-encryption-page .text p {
  margin: 0;
}

.file-encryption-page .change-password-button {
  margin-top: 24px;
}

.change-password-buttons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.change-password-buttons .cs-btn-primary {
  margin-left: 16px;
}

.cs-md-modal .input-field-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-form-group.last-form-field {
  margin-bottom: 0;
}

.cs-form-group > div {
  position: relative;
}

.pfs-password-buttons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.pfs-password-buttons .cs-btn-primary {
  margin-left: 16px;
}

.choose-password-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.choose-password-section .email-verification,
.choose-password-section .mobile-verification,
.choose-password-section .authentication-app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  width: 100%;
  margin-top: 24px;
}

.choose-password-section .email-verification:hover,
.choose-password-section .mobile-verification:hover,
.choose-password-section .authentication-app:hover,
.auth-mfa-item.active-method,
.auth-mfa-item:hover,
.choose-password-section .active-method,
.choose-password-section .cursor-pointer:hover {
  background-color: var(--primary-surface);
  border: 1px solid var(--primary-border);
}

.choose-password-section .email-verification:active,
.choose-password-section .mobile-verification:active,
.choose-password-section .authentication-app:active,
.auth-mfa-item.active-method,
.auth-mfa-item:hover,
.choose-password-section .active-method,
.choose-password-section .cursor-pointer:hover {
  border: 1px solid var(--primary-main);
}

.choose-password-section .email-verification .choose-pass-text,
.choose-password-section .mobile-verification .choose-mob-text,
.choose-password-section .authentication-app .choose-auth-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 16px;
}

.choose-password-section .email-verification .radio-text,
.choose-password-section .mobile-verification .radio-text,
.choose-password-section .authentication-app .radio-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.choose-password-section .email-verification .radio-text .cs-radio-btn,
.choose-password-section .mobile-verification .radio-text .cs-radio-btn,
.choose-password-section .authentication-app .radio-text .cs-radio-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0;
}

.email-otp-verfication .otp-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.email-otp-verfication p.default-regular-sub-heading-s.cs-neutral-90 {
  margin-top: 32px;
}

.email-otp-verfication .otp-content h5.default-regular-h5.cs-neutral-80 {
  margin-bottom: 24px;
}

.email-otp-verfication {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.email-otp-verfication .otp-layout .otp-txt:first-child {
  margin-left: 0px;
}

.email-otp-verfication .otp-layout .otp-txt {
  margin-left: 8px;
}

.email-otp-verfication h5.default-regular-sub-heading-s.cs-neutral-70 {
  margin-top: 24px;
}

.email-otp-verfication .text-boxes-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.email-otp-verfication .otp-form-boxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.otp-content p.default-regular-h5.cs-neutral-90,
.email-otp-verfication .default-regular-h5.cs-neutral-90 {
  margin-top: 30px;
}

.email-otp-verfication .otp-form-boxes div {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.email-verification-buttons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0;
  margin-top: 24px;
}

.email-verification-buttons .cs-btn-primary {
  margin-left: 16px;
}

.otp-content h5.default-regular-sub-heading-xs.cs-neutral-70 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
  padding-inline: 60px;
}

.mobile-file-encryption {
  display: none;
}

.skeleton-file-encryption-heading {
  width: 20%;
}

.skeleton-file-encryption-para {
  width: 63%;
}

.skeleton-file-encryption-input {
  width: 100%;
}

.file-encryption-row {
  position: relative;
  padding-right: calc(100% - 59vw);
}
.file-encryption-row .cs-form-group {
  margin-bottom: 22px;
}
.file-encryption-row .cs-form-check-box.form-check {
  margin: 0;
}

.file-encryption-row .change-password-button {
  margin-top: 44px;
}
.file-encryption-row .col-md-6:last-child .change-password-button {
  margin-top: 68px;
}

.file-encryption-row .cs-form-group span {
  margin-top: 11px;
}

.file-encryption-row .cs-form-group .form-control {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding-right: 38px;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
  .file-encryption-row {
    padding-right: 0;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .settings-main-section .settings-body .file-encryption-page {
    padding: 24px;
  }
}

@media (max-width: 767px) {
  .file-encryption-row .change-password-button {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .file-encryption-row .col-md-6:last-child .change-password-button {
    margin-top: 6px;
    margin-bottom: 0;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .settings-main-section .settings-body .file-encryption-page {
    padding: 24px 16px 24px 16px;
  }

  /* .change-password-button {
    display: none;
  } */

  /* .mobile-file-encryption {
    display: block;
    margin: 24px 0 0 0;
    width: 100%;
  } */

  .mobile-file-encryption button {
    max-width: 100%;
  }

  .settings-main-section .settings-body .file-encryption-page .text h3 {
    font-size: 18px;
    line-height: 28px;
  }

  .settings-main-section .settings-body .file-encryption-page .text p {
    font-size: 14px;
    line-height: 20px;
  }

  .change-password-buttons button {
    font-weight: 400;
    line-height: 20px;
  }

  .choose-password-section .email-verification,
  .choose-password-section .mobile-verification,
  .choose-password-section .authentication-app {
    padding: 16px 14px;
    margin-top: 16px;
  }

  .choose-password-section span svg {
    width: 20px;
    height: 20px;
  }

  .pfs-password-buttons button,
  .email-verification-buttons button,
  .authenticator-buttons button {
    line-height: 20px;
  }

  .choose-password-section .email-verification .choose-pass-text,
  .choose-password-section .mobile-verification .choose-mob-text,
  .choose-password-section .authentication-app .choose-auth-text {
    margin-left: 8px;
  }

  .email-otp-verfication .otp-content h5.default-regular-h5.cs-neutral-80,
  .email-otp-verfication .otp-content span,
  .authenticator-app-verification h5.default-regular-h5.cs-neutral-100,
  .authenticator-app-verification span {
    font-weight: 400;
    line-height: 20px;
  }
}

@media (max-width: 425px) {
  .change-password-buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .change-password-buttons button {
    max-width: 100%;
  }

  .change-password-buttons button:last-child {
    margin: 16px 0 0 0;
  }
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
