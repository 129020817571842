.req-input-bg {
  background-color: var(--neutral-40);
}

.action-td .cursor-pointer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.action-td .cursor-pointer.cs-neutral-50 {
  cursor: auto;
}

.action-td .cursor-pointer span:first-child {
  margin-right: 10px;
}

.accreditation-inner-section .cs-section-topbar,
.accredited-tabbing-row {
  margin-bottom: 24px;
}

.dollar-value-width {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 20%;
  color: var(--primary-main);
}

.verification-method-box {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  margin-bottom: 24px;
  overflow: hidden;
}

.accredited-method {
  background-color: var(--neutral-40);
  padding: 12px 16px;
  border-radius: 4px 4px 0px 0px;
}

.vi-innerArea {
  padding: 12px 16px;
  background: var(--neutral-10);
}

.vi-innerArea .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.vi-innerText {
  width: 100%;
  color: var(--neutral-90);
}

.vi-innerArea button {
  width: 100px;
  height: 40px;
}

.verification-method-box .cs-divider,
.method-section .cs-divider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 16px;
  margin: 0;
  background: var(--neutral-10);
}

.method-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background: var(--neutral-10);
}

.method-section .cs-divider {
  margin: 24px 0;
  height: auto;
}

.row-spacing,
.row-spacing p {
  margin-bottom: 24px;
}

.row-spacing .cs-form-group {
  margin-top: 24px;
}

.row-spacing .cs-divider,
.row-spacing p:last-child {
  margin: 0;
}

.accredited-title {
  margin-bottom: 14px;
}

.accredited-title h3,
.accredited-tabbing-row h3 {
  margin-bottom: 4px;
}

.accredited-tabbing-row .cs-tabs {
  margin: 24px 0;
}

.row-spacing .cs-upload-files {
  margin: 16px 0;
}

.accreditation-footer {
  margin-top: 24px;
}

.accreditation-btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.accreditation-btn-group button:last-child {
  margin-left: 16px;
}

.accredited-content-box p,
.accredited-content-box li {
  margin-bottom: 16px;
}

.accredited-content-box p:last-child,
.accredited-content-box li:last-child {
  margin-bottom: 0;
}

.trust-btn {
  margin-bottom: 10px;
}

.noRecord-body {
  width: 100%;
  padding: 32px 0;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--neutral-10);
}

.select-files-box .cs-divider {
  width: 100%;
  margin-top: 0;
}

.manage-profile-upload.select-files-box .cs-divider {
  margin-top: 16px;
}

.select-pfs-file-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.select-pfs-file-row .select-pfs-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  margin-right: 16px;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-20);
}

.select-pfs-file-row .select-pfs-box span {
  margin-right: 8px;
}

.request-verification-modal .initial-modal-loader {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-column-align: center;
  justify-self: center;
  width: 150px;
  height: 150px;
  margin: 0 auto 30px;
}

.request-verification-modal .initial-modal-loader img {
  width: 100%;
  height: auto;
}

.cs-divider.cs-select-divider {
  width: 100%;
  margin: 18px auto;
}

/* start */
.payment-method-modal .cs-scroller {
  max-height: 422px;
  margin-bottom: 16px;
}

.accredited-content-box .cs-upload-files-box {
  margin-top: 0;
}

.cs-upload-files-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 32px;
}

.cs-upload-files-box .cs-form-group {
  margin-bottom: 0;
  margin-right: 16px;
}

.accredited-content-box .attachment-files.cs-files {
  margin-top: 16px;
}

.accredited-content-box .row:last-child .cs-form-group,
.accredited-upload-modal .row-spacing {
  margin-bottom: 16px;
}

.accredited-upload-modal .accredited-content-box .row:last-child .cs-form-group,
.accredited-upload-modal .accredited-content-box .cs-attached-doc {
  margin: 0;
}

.accredited-upload-modal .accredited-content-box .cs-divider.cs-select-divider {
  margin: 16px auto;
}

/* end */
.accreditation-inner-section .investor-type-dropdown .dropdown-menu.show {
  max-height: 300px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.accredited-content-box ul:last-child,
.accredited-content-box ol:last-child {
  margin-bottom: 0;
}

.net-worth-asset-libility .net-worth-info-icon {
  margin-left: 8px;
}

.method-section .form-label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--neutral-100);
  margin-bottom: 4px;
}

.method-spacing {
  margin-bottom: 16px;
}

.method-section .cs-form-check-box.form-check {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-footer p.default-light-body-text-m.cs-danger {
  margin-bottom: 24px;
}

/* start accreditation dropdownMenu */
.accreditation-action .dropdown .dropdown-toggle {
  padding: 0;
  border: none;
  height: 0;
}

.accreditation-action .dropdown .accreditation-menu .dropdown-menu.show {
  width: 220px;
}

.accreditation-action .dropdown .accreditation-menu .dropdown-menu.show .dropdown-item {
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
  padding-inline: 16px;
}

.accreditation-action .dropdown .accreditation-menu svg {
  margin-right: 16px;
}

.stripe_card_content_body_area {
  position: relative;
}

.cs-form-group div {
  position: relative;
}

/* end */

/* Select attached document  CSS start */
.cs-attached-doc {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 16px;
  border: 1px solid var(--neutral-40);
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 6px;
}

.cs-attached-doc span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.cs-attached-doc span svg {
  width: 100%;
  height: 100%;
}

.cs-attached-doc .cs-attached-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.cs-attached-doc .cs-attached-title span {
  position: absolute;
  top: 50%;
  right: 12px;
  margin: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.cs-attached-doc .cs-attached-title span svg path {
  fill: var(--neutral-60);
}

.selected-category-list {
  position: relative;
  margin-top: 24px;
}

.selected-category-list.cs-scroller {
  height: 410px;
  padding-right: 4px;
  margin-right: -4px;
}

.category-list {
  margin-top: 16px;
}

.category-list h5 {
  padding: 12px 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--neutral-40);
}

.selected-category-list .cs-accoutns-list .finance-data-container .finance-heading {
  margin-bottom: 0;
}

.selected-doc-list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 6px 16px 6px 8px;
  cursor: pointer;
}

.category-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.category-title span.cs-neutral-70 {
  margin-right: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.selected-doc-list .form-check-input {
  margin: 0;
}

.request-verification-modal .cs-msg {
  margin: 4px 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.request-verification-modal .cs-msg span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* *******************************Start Skeleton********************************** */
.skeleton-accreditation-btn span:last-child {
  margin-left: 16px;
}

/* *******************************End Skeleton************************************ */

/* === Identity Protect Start === */
.user-details-modal .cs-form-group {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .user-details-modal .cs-form-group {
    margin-bottom: 16px;
  }

  .user-details-modal .cs-form-group.last-form-field {
    margin-bottom: 0;
  }
}

/* ==== Identity Protect End ==== */

/* Select attached document  CSS end */

/* Error msg CSS start */
.investor-error p.cs-danger {
  display: block;
  margin-top: 6px;
}

.investor-error .dropdown-toggle {
  border-color: var(--danger-main);
}

.investor-error .form-check-input,
.cs-upload-files.investor-error,
.investor-error .cs-upload-files,
.cs-form-group .investor-error.form-control {
  border-color: var(--danger-main) !important;
}

.investor-error .vi-error-msg {
  margin-top: -24px;
  margin-bottom: 24px;
}

.confirmation-modal .modal-footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.confirmation-modal .modal-footer button {
  margin-bottom: 16px;
}

.confirmation-modal .modal-footer button:last-child {
  margin-bottom: 0;
}

.request-verification-modal .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

/* .manage-files .cs-files .cs-selected-files {
  margin: 0;
} */

.manage-files .select-pfs-file-row .select-pfs-box {
  cursor: not-allowed;
}

.manage-files .cs-divider.cs-select-divider {
  margin: 16px auto;
}

.attached-doc-popup .selected-documents-box h4 {
  padding-inline: 64px;
}

.selected-category-list .selected-doc-list.cs-disabled {
  cursor: not-allowed;
}

/* Error msg CSS end */

.verification-dropdown {
  overflow: hidden;
}

.verification-dropdown .cs-ellipsis {
  max-width: 100%;
}

.request-verification-modal .cs-form-dropdown .dropdown-toggle {
  padding-right: 46px;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {}

@media (max-width: 1199px) {
  .accreditation-main-section .listing-request.commonTable {
    height: 100vh;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .accreditation-btn-group {
    margin-top: 20px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .attached-doc-popup .cs-modal-btn.modal-footer {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .attached-doc-popup .selected-documents-box h4 {
    padding-inline: 0;
  }
}

@media (max-width: 767px) {
  .accredited-investor-tooltip {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .accredited-method {
    padding-block: 6px;
  }

  .method-section {
    padding: 16px;
  }

  .accreditation-btn-group {
    display: block;
    margin-top: 24px;
  }

  .accreditation-btn-group button,
  .manage-files .cs-modal-btn.modal-footer button {
    width: 100%;
    max-width: 100%;
    margin-left: 0 !important;
  }

  .accreditation-btn-group button:last-child,
  .manage-files .cs-modal-btn.modal-footer button:last-child {
    margin-top: 16px;
  }

  .select-pfs-file-row .select-pfs-box {
    height: 36px;
  }

  .select-pfs-file-row button {
    height: 36px;
  }

  .table-icons-container.cs-file-icon span,
  .amortization-schedule-box span,
  .cs-file-icon span,
  .action-box .dropdown .dropdown-toggle,
  .cs-list-options .dropdown .dropdown-toggle {
    width: 24px;
    height: 24px;
  }

  .action-box .dropdown .dropdown-toggle span,
  .cs-list-options .dropdown .dropdown-toggle span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .cs-file-icon span svg,
  .action-box .dropdown .dropdown-toggle svg,
  .cs-list-options .dropdown .dropdown-toggle svg {
    width: 14px;
    height: 14px;
  }

  .manage-profile-upload .cs-files .cs-selected-files .cs-neutral-60.cs-fileicon,
  .manage-profile-upload .cs-files .cs-selected-files .cs-neutral-60.cs-fileicon span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cs-files .cs-selected-files {
    padding-block: 6px;
  }

  .cs-files-names span:last-child {
    margin: 0;
  }

  .vi-innerArea button {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-top: 14px;
  }

  .selected-category-list .cs-accoutns-list .finance-data-container .finance-heading {
    margin: 0;
  }

  .selected-category-list .finance-data-container {
    margin-top: 0;
  }

  .selected-category-list.cs-scroller {
    height: 350px;
  }

  .accredited-content-box .cs-choose-file .form-control {
    /* height: auto; */
    padding-block: 5px;
  }

  .accredited-content-box .cs-choose-file span.cs-icon.cs-neutral-10 {
    width: 24px;
    height: 24px;
  }

  .accredited-content-box .cs-choose-file span.cs-icon.cs-neutral-10 svg {
    width: 14px;
    height: 14px;
  }

  .accredited-upload-modal .cs-modal-btn.modal-footer {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .method-section .cs-divider {
    margin: 16px 0;
  }

  .row-spacing,
  .row-spacing p {
    margin-bottom: 16px;
  }

  .accreditation-action .dropdown .accreditation-menu svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .accreditation-action .dropdown .accreditation-menu .dropdown-menu.show {
    width: 100%;
  }

  .accreditation-action .dropdown .accreditation-menu .dropdown-menu.show .dropdown-item {
    padding-inline: 8px;
  }

  .cs-attached-doc span.cs-neutral-60 {
    width: 20px;
    height: 20px;
  }

  .cs-attached-doc,
  .select-pfs-file-row .select-pfs-box {
    padding: 8px;
  }

  .cs-divider.cs-select-divider {
    margin: 16px auto;
  }

  .accreditation-inner-section .vi-innerArea.for-mob .cs-divider {
    margin: 16px 0;
  }
}

@media (max-width: 425px) {
  .investor-accreditation-description h2 {
    font-size: 18px;
  }
}

@media (max-width: 360px) {}

@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */