.billing-subscription-page {
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.billing-subscription-page .plan-toggle,
.cs-subscription-section .plan-toggle,
.billing-subscription-page .plan-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.billing-subscription-page .plan-toggle .cs-switch-checkbox {
  display: none;
}

.billing-subscription-page .plan-toggle .cs-switch-label {
  width: 60px;
  height: 34px;
  margin-right: 24px;
}

.billing-subscription-page .plan-toggle .cs-switch-label .switch-button {
  width: 26px;
  height: 26px;
}

.billing-subscription-page .plan-toggle .cs-switch-label:active .switch-button {
  width: 26px;
}

.billing-subscription-page .plan-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.billing-subscription-page .plan-heading .plan-toggle .save-plan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.billing-subscription-page .subscription-plan-cards .default-plan:nth-child(2) {
  margin-left: 32px;
}

/* .billing-subscription-page label.default-regular-sub-heading-l.cs-neutral-80 {
  padding-right: 24px;
} */

.billing-subscription-page
  .plan-heading
  p.default-regular-sub-heading-l.cs-neutral-100 {
  padding-right: 40px;
}

.billing-subscription-page .plan-text-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.billing-subscription-page .plan-text-cards .subscription-plan-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .card-popular-plan {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .card-popular-plan
  .card-body {
  background-color: var(--primary-main);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .card-popular-plan
  .card-body
  span.cs-neutral-60 {
  color: var(--neutral-20);
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .default-plan
  .card-body
  span.cs-neutral-60 {
  height: 0;
  width: 100%;
  border: 1px solid var(--neutral-60);
  opacity: 0.2;
  margin: 24px 0;
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .card-popular-plan
  .card-body
  span.cs-neutral-60 {
  height: 0;
  width: 100%;
  border: 1px solid var(--neutral-20);
  opacity: 0.2;
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .default-plan {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  border: none;
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .card-body {
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .card-body
  img {
  position: absolute;
  left: 94%;
  top: -2%;
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .plan-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.billing-subscription-page
  .plan-text-cards
  .subscription-plan-cards
  .plan-amounts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-bottom: 24px;
}

.billing-subscription-page .subscription-plan-cards .plan-icons .card-spacing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.billing-subscription-page .popular-plan-hide {
  display: none;
}

.billing-subscription-page .popular-plan-show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 16px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 21px;
  background: var(--neutral-10);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  right: 35px;
  top: -16px;
}

.billing-subscription-page .subscription-plan-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 100%;
  padding-top: 32px;
}

.billing-subscription-page .card-text {
  width: 100%;
}

.billing-subscription-page .plan-lists ul {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
}

.billing-subscription-page .plan-lists ul li {
  position: relative;
  margin-bottom: 16px;
}

.billing-subscription-page .plan-lists ul li:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  width: 20px;
  height: 12px;
  margin-right: 16px;
  background-image: url(../../../../../../src/assets/images/other/check-icon-color.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.billing-subscription-page .plan-lists ul li:last-child {
  margin-bottom: 0;
}

.subscription-plan-cards .card-popular-plan .plan-lists ul li:before {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.subscription-plan-cards
  .card-popular-plan
  .default-semi-bold-h1.cs-neutral-100,
.subscription-plan-cards
  .card-popular-plan
  .default-regular-sub-heading-l.cs-neutral-80 {
  color: var(--neutral-10);
}

.subscription-plan-cards .card-popular-plan .default-regular-h4.cs-neutral-60 {
  color: var(--neutral-30);
}

/* .subscription-plan-cards .card:nth-child(2), */
.subscription-plan-cards .card-popular-plan .plan-lists ul li {
  color: var(--neutral-20) !important;
}

.subscription-plan-cards .card {
  width: 26%;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: var(--neutral-10);
}

.billing-subscription-page .subscription-plan-buttons button.cs-btn-primary {
  background: var(--neutral-10) !important;
  color: var(--primary-main) !important;
  width: 100%;
}

.subscription-plan-cards .default-plan.card button.cs-btn-primary {
  border: 1px solid;
  padding: 9px 10px;
}

.subscription-plan-cards .default-plan.card button.cs-btn-primary:hover {
  color: var(--primary-hover) !important;
  background: var(--primary-surface) !important;
}

.subscription-plan-cards .default-plan.card button.cs-btn-primary:active,
.subscription-plan-cards
  .default-plan.card
  button.cs-btn-primary:focus-visible {
  color: var(--primary-pressed) !important;
  background: var(--primary-surface) !important;
}

.payment-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 32px;
}

.payment-cards .text-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.payment-cards .text-button button {
  padding: 0 !important;
}

.payment-cards .text-button button span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment-method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-item-align: normal;
  -ms-grid-row-align: normal;
  align-self: normal;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.payment-cards .credit-payment-card {
  margin-top: 16px;
  width: 100%;
}

.payment-cards .credit-payment-card .credit-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 24px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.payment-cards .credit-payment-card:hover .credit-box {
  background: var(--primary-surface);
}

.payment-cards .credit-payment-card .credit-box .credit-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.payment-cards
  .credit-payment-card
  .credit-box
  .credit-text
  p.default-regular-body-text-xl.cs-neutral-80 {
  margin-bottom: 8px;
}

.card-images img {
  width: 52px;
  height: 36px;
}

.credit-text span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.payment-cards .credit-box span.delete-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background: var(--danger-surface);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.payment-cards .credit-box span.delete-action:hover {
  background: var(--danger-hover);
}

.payment-cards .credit-box span.delete-action:hover svg path {
  fill: var(--neutral-10);
}

.billing-subscription-page .billing-history {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 32px;
}

.billing-subscription-page
  .billing-history
  p.default-regular-sub-heading-l.cs-neutral-100 {
  margin-bottom: 8px;
}

.settings-body .billing-subscription-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.payment-cards .credit-payment-card .credit-box-active {
  border: 1px solid var(--primary-main);
  background-color: var(--primary-surface);
}

.billing-subscription-page .cs-data-table .rdt_Pagination {
  padding: 20px 0 0 0;
}

.wm-mobile-dropdown,
.wm-data-table {
  display: none;
}

.wm-billing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 32px;
}

.wm-billing .cs-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 16px 0 24px 0;
}

.wm-billing .cs-tabs button:first-child {
  border-radius: 4px 0px 0px 4px;
}

.wm-billing .cs-tabs button:last-child {
  border-radius: 0px 4px 4px 0px;
}

.wm-advisor-option .cs-form-dropdown .dropdown-toggle,
.wm-advisor-filter .cs-form-dropdown .dropdown-toggle,
.wm-client-option .cs-form-dropdown .dropdown-toggle,
.wm-clientbill-filter .cs-form-dropdown .dropdown-toggle {
  background-color: transparent;
  border: none;
  height: 0;
  padding: 0;
}

.wm-advisor-option .cs-form-dropdown.dropdown,
.wm-advisor-filter .cs-form-dropdown.dropdown,
.wm-client-option .cs-form-dropdown.dropdown,
.wm-clientbill-filter .cs-form-dropdown.dropdown {
  margin: 0;
  border: none;
}

.billing-subscription-page .wm-billing .cs-tabs .cs-stroke-default-tab {
  width: 262px;
}

.billing-subscription-page
  .payment-cards
  p.default-light-body-text-s.cs-neutral-70 {
  margin: 8px 0 16px 0;
}

.mobile-billing-history {
  display: none;
}

.wm-clientbill-filter span.cs-neutral-100,
.wm-advisor-filter span.cs-neutral-100 {
  margin-left: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.billing-subscription-page .sc-jlZhew.fwhpon.rdt_TableCol_Sortable {
  overflow: visible;
}

.wm-clientbill-filter .dropdown .dropdown-toggle,
.wm-advisor-filter .dropdown .dropdown-toggle {
  padding: 0;
  background-color: transparent;
  border: none;
  height: 0;
}

.wm-clientbill-filter .dropdown .dropdown-menu.show,
.wm-advisor-filter .dropdown .dropdown-menu.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 8px 8px 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  border: none;
  margin-right: 0 !important;
  width: 145px;
  min-width: 100%;
  top: -20px !important;
  left: 30px !important;
}

.wm-clientbill-filter .client-status-default,
.wm-advisor-filter .client-status-default {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  color: var(--neutral-90);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
  width: auto;
}

.wm-clientbill-filter .dropdown .dropdown-item span,
.wm-advisor-filter .dropdown .dropdown-item span {
  margin-right: 8px;
}

.wm-clientbill-filter .client-status-active,
.wm-advisor-filter .client-status-active {
  border-radius: 4px;
  border: 1px solid var(--primary-main);
  background: var(--neutral-10);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--primary-main);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.wm-client-option .cs-form-dropdown .dropdown-item,
.wm-advisor-filter .cs-form-dropdown .dropdown-item {
  color: var(--neutral-90);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
}

.billing-subscription-page .billing-history .cs-btn-tertiary,
.billing-subscription-page
  .wm-setting-billing-exportbtn.wm-set-bill-sub.cs-btn-tertiary {
  padding: 0;
  display: block;
  text-align: left;
}

.desktop-billing-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile-billing-dropdown {
  display: none;
}

.wm-advisor-option .cs-form-dropdown .dropdown-menu.show,
.wm-client-option .cs-form-dropdown .dropdown-menu.show {
  width: auto;
}

.wm-advisor-option .show-normal-row .default-regular-sub-heading-s,
.wm-client-option .cs-form-dropdown .dropdown-item,
.wm-advisor-filter .cs-form-dropdown .dropdown-item {
  line-height: 14px;
}

.cs-cancelling-popup img,
.cs-plans-change img {
  margin-bottom: 24px;
}

.cs-cancelling-popup h4,
.cs-plans-change h4 {
  margin-bottom: 8px;
}

.cs-cancelling-popup label.form-label {
  margin-bottom: 7px;
}

.cs-cancelling-popup .modal-footer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-cancelling-popup .modal-footer .cs-modal-btn {
  width: 90%;
}

.cs-cancelling-popup .cs-btn-icon-tertiary {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 0;
  cursor: pointer;
}

.cs-cancelling-popup .row .col .cs-form-group:last-child {
  margin-bottom: 0;
}

.cs-cancelling-popup .dropdown-menu.show {
  max-height: 400px !important;
}

.skeleton-billing-toggle {
  margin: 0 24px;
}

.skeleton-card-title-container {
  width: 100%;
  margin-bottom: 48px;
}

.skeleton-card-btn {
  width: 100%;
}

.skeleton-subscription-plan-cards .card {
  border: none;
  margin-right: 32px;
}

.skeleton-subscription-plan-cards .card-body {
  background-color: var(--neutral-30) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.skeleton-payment-box {
  background-color: var(--neutral-30) !important;
  width: 100%;
  border-radius: 4px;
  margin-top: 16px;
  padding: 16px 24px;
}

.skeleton-payment-box .skeleton-payment-name {
  margin-bottom: 8px;
}

.skeleton-plan-lists {
  margin-bottom: 16px;
}

.settings-main-section .amount-dollar-width {
  width: 150px;
}

/* FA Billing history CSS start */
.wm-clientbill-filter .cs-form-check-box.form-check {
  margin: 0 !important;
  cursor: pointer;
}

.wm-clientbill-filter .cs-form-check-box.form-check label,
.wm-clientbill-filter .cs-form-check-box.form-check label span {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  margin: 0;
}

.wm-clientbill-filter .cs-form-check-box.form-check input.form-check-input {
  display: none;
}

.wm-clientbill-filter .client-status-active label span {
  color: var(--primary-main);
}

.wm-clientbill-filter .client-status-active span svg path {
  fill: var(--primary-main);
}

.wm-clientbill-filter .client-status-default {
  padding: 3px 16px;
}

/* FA Billing history CSS end */
.cs-cancelling-popup .cs-form-dropdown .dropdown-item-active {
  color: var(--primary-hover) !important;
  background: var(--primary-surface) !important;
}

.wm-billing [data-column-id="1"].rdt_TableCol,
.wm-billing [data-column-id="1"].rdt_TableCell {
  min-width: 220px;
}

.wm-billing [data-column-id="2"].rdt_TableCol,
.wm-billing [data-column-id="2"].rdt_TableCell {
  min-width: 130px;
}

.wm-billing [data-column-id="3"].rdt_TableCol,
.wm-billing [data-column-id="3"].rdt_TableCell {
  min-width: 400px;
}

.wm-billing [data-column-id="4"].rdt_TableCol,
.wm-billing [data-column-id="4"].rdt_TableCell,
.wm-billing [data-column-id="5"].rdt_TableCol,
.wm-billing [data-column-id="5"].rdt_TableCell {
  min-width: 180px;
}

.wm-billing [data-column-id="6"].rdt_TableCol,
.wm-billing [data-column-id="6"].rdt_TableCell {
  min-width: 200px;
}

.billing-subscription-page .wm-billing [data-column-id="5"].rdt_TableCol div {
  overflow: visible;
  height: auto;
}

.wm-advisor-option .show-first-row .dropdown-menu.show {
  -webkit-transform: translate(-200px, -70px) !important;
  -ms-transform: translate(-200px, -70px) !important;
  transform: translate(-200px, -70px) !important;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
  .subscription-plan-cards .card {
    width: 50% !important;
  }
  .cloud-storage-modal .subscription-plan-cards .card {
    width: 100% !important;
    margin-block: 0 !important;
  }
}

@media (max-width: 1199px) {
  .billing-history [data-column-id="1"].rdt_TableCol,
  .billing-history [data-column-id="1"].rdt_TableCell {
    min-width: 120px;
  }

  .billing-history [data-column-id="2"].rdt_TableCol,
  .billing-history [data-column-id="2"].rdt_TableCell {
    min-width: 350px;
  }

  .billing-history [data-column-id="3"].rdt_TableCol,
  .billing-history [data-column-id="3"].rdt_TableCell {
    min-width: 150px;
  }

  .billing-history [data-column-id="4"].rdt_TableCol,
  .billing-history [data-column-id="4"].rdt_TableCell {
    min-width: 170px;
  }

  .billing-history [data-column-id="5"].rdt_TableCol,
  .billing-history [data-column-id="5"].rdt_TableCell {
    min-width: 200px;
  }

  .financial-client-bill-table [data-column-id="4"].rdt_TableCol,
  .financial-client-bill-table [data-column-id="4"].rdt_TableCell,
  .financial-advisor-bill-table [data-column-id="4"].rdt_TableCol,
  .financial-advisor-bill-table [data-column-id="4"].rdt_TableCell {
    min-width: 160px;
  }

  .financial-billing-details [data-column-id="3"].rdt_TableCol,
  .financial-billing-details [data-column-id="3"].rdt_TableCell {
    min-width: 160px;
  }

  .billing-subscription-page .wm-billing .cs-tabs .cs-stroke-default-tab {
    width: auto;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .desktop-billing-dropdown {
    display: none;
  }

  .mobile-billing-dropdown {
    display: block;
    width: 35%;
  }

  .settings-main-section .settings-body .billing-subscription-page {
    padding: 24px;
  }

  .payment-cards .credit-payment-card {
    margin-top: 8px;
  }

  .settings-main-section
    .wm-setting-billing-exportbtn.wm-set-bill-sub.cs-btn-tertiary {
    padding: 0 !important;
    display: block;
    text-align: left;
  }
  .cloud-storage-modal .cs-subscription-section .card-specification li {
    font-size: 10px;
    font-weight: 300;
    line-height: 16px;
  }
}

@media (max-width: 767px) {
  .mobile-billing-dropdown {
    display: block;
    width: 50%;
  }

  .financial-billing-details [data-column-id="1"].rdt_TableCol,
  .financial-billing-details [data-column-id="1"].rdt_TableCell {
    min-width: 115px;
  }

  .financial-billing-details [data-column-id="5"].rdt_TableCol,
  .financial-billing-details [data-column-id="5"].rdt_TableCell {
    min-width: 145px;
  }

  .subscription-plan-cards .card {
    width: 100% !important;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .desktop-billing-dropdown {
    display: none;
  }

  .mobile-billing-dropdown {
    display: block;
    width: 100%;
  }

  .billing-subscription-page
    .plan-text-cards
    .subscription-plan-cards
    .card-body,
  .settings-main-section .settings-body .billing-subscription-page {
    padding: 24px 16px;
  }

  .billing-subscription-page .plan-text-cards .subscription-plan-cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .billing-subscription-page .plan-heading {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .billing-subscription-page
    .plan-heading
    p.default-regular-sub-heading-l.cs-neutral-100 {
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0;
    margin: 0 0 8px 0;
  }

  .billing-subscription-page .plan-toggle {
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .billing-subscription-page
    .subscription-plan-cards
    .default-plan:nth-child(2) {
    margin: 32px 0 0 0;
  }

  .billing-subscription-page .plan-toggle .cs-switch-label {
    width: 40px;
    height: 22px;
  }

  .billing-subscription-page .plan-toggle .cs-switch-label .switch-button {
    width: 15px;
    height: 15px;
  }

  .billing-subscription-page
    .plan-toggle
    .cs-switch-label:active
    .switch-button {
    width: 15px;
  }

  /* .billing-subscription-page label.default-regular-sub-heading-l.cs-neutral-80 {
    padding-right: 16px;
  } */

  .billing-subscription-page .plan-toggle .cs-switch-label {
    margin-right: 16px;
  }

  .billing-subscription-page
    .plan-text-cards
    .subscription-plan-cards
    .plan-amounts {
    margin-bottom: 16px;
  }

  .billing-subscription-page .subscription-plan-buttons {
    padding-top: 16px;
  }

  .billing-subscription-page
    .plan-text-cards
    .subscription-plan-cards
    .default-plan
    .card-body
    span.cs-neutral-60 {
    margin: 16px 0;
  }

  .payment-cards .credit-payment-card .credit-box {
    padding: 12px 16px;
  }

  .billing-subscription-page
    .plan-text-cards
    .subscription-plan-cards
    .plan-icons,
  .billing-subscription-page
    .subscription-plan-cards
    .plan-icons
    .card-spacing {
    width: 100%;
  }

  .billing-subscription-page .plan-heading .plan-toggle .save-plan img {
    width: 64px;
  }

  .card-spacing h2 {
    font-size: 18px;
    line-height: 28px;
  }

  .billing-subscription-page .plan-lists ul li {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .subscription-plan-buttons button {
    font-weight: 400;
    line-height: 20px;
  }

  .billing-subscription-page .popular-plan-show {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    right: auto;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  .settings-main-section .billing-subscription-page .billing-history {
    display: none;
  }

  .mobile-billing-history {
    display: block;
    margin-top: 24px;
  }

  .settings-main-section .mobile-billing-history p {
    margin-bottom: 8px;
  }

  .mobile-billing-history .cs-data-table .rdt_Pagination {
    background-color: transparent;
  }

  .payment-cards .credit-box svg {
    width: 20px;
    height: 20px;
  }

  .payment-cards .credit-box span.delete-action {
    padding: 6px;
  }

  .payment-cards
    .credit-payment-card
    .credit-box
    .credit-text
    p.default-regular-body-text-xl {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .payment-method button {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .card-images img,
  .change-card-image img {
    width: 35px;
    height: 24px;
  }

  .choose-payment-modal .form-check-input {
    position: relative;
    top: 6px;
  }

  .credit-change-method,
  .bank-details {
    padding: 12px 14px;
    width: 100%;
  }

  .cs-form-group.last-input-field {
    margin-bottom: 0;
  }

  .settings-main-section .mobile-billing-history div[data-column-id="2"],
  .settings-main-section
    .mobile-billing-history
    .cs-data-table
    .rdt_TableBody
    div#cell-2-undefined
    div {
    min-width: 350px;
  }

  .cs-cancelling-popup .modal-footer .cs-modal-btn {
    width: 70%;
  }

  .payment-cards {
    margin-top: 24px;
  }

  .wm-billing {
    margin-top: 24px;
  }

  .settings-main-section .amount-dollar-width {
    width: 120px;
  }

  .skeleton-billing-toggle {
    margin: 0 8px;
  }

  .skeleton-subscription-plan-cards .card {
    margin: 0 0 24px 0;
  }

  .skeleton-subscription-plan-cards .card:last-child {
    margin: 0;
  }
}

@media (max-width: 425px) {
  .billing-subscription-page .popular-plan-show {
    font-size: 10px;
    line-height: 16px;
    top: -10px;
  }
}

@media (max-width: 320px) {
  .settings-main-section .settings-body .billing-subscription-page {
    padding: 16px;
  }

  .billing-subscription-page .plan-heading {
    margin-bottom: 16px;
  }
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
