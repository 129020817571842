.audit-logs-page {
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.audit-logs-page .cs-tabs-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 32px;
}

.audit-logs-page .cs-tabs-flex .cs-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.audit-logs-page .cs-tabs-flex .cs-tabs button:first-child {
  border-radius: 4px 0px 0px 4px;
}

.audit-logs-page .cs-tabs-flex .cs-tabs button:last-child {
  border-radius: 0px 4px 4px 0px;
}

.audit-logs-page .cs-data-table .rdt_Pagination {
  padding: 20px 0 0 0;
}

.cap-success.text-center.cap-messageArea {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cap-success.text-center.cap-messageArea svg {
  margin-right: 8px;
}

.text-boxes-content .cs-msg.default-regular-body-text-s.cs-danger {
  margin-block: 16px 0;
}

.skeleton-audit-log-search-tab {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.skeleton-audit-container .for-mob {
  width: 100%;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
  .audit-logs-page [data-column-id="1"].rdt_TableCol,
  .audit-logs-page [data-column-id="1"].rdt_TableCell {
    min-width: 130px;
  }

  .audit-logs-page [data-column-id="2"].rdt_TableCol,
  .audit-logs-page [data-column-id="2"].rdt_TableCell {
    min-width: 100px;
  }

  .audit-logs-page [data-column-id="3"].rdt_TableCol,
  .audit-logs-page [data-column-id="3"].rdt_TableCell,
  .audit-logs-page [data-column-id="4"].rdt_TableCol,
  .audit-logs-page [data-column-id="4"].rdt_TableCell {
    min-width: 220px;
  }

  .audit-logs-page [data-column-id="5"].rdt_TableCol,
  .audit-logs-page [data-column-id="5"].rdt_TableCell,
  .audit-logs-page [data-column-id="6"].rdt_TableCol,
  .audit-logs-page [data-column-id="6"].rdt_TableCell {
    min-width: 330px;
  }
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .settings-main-section .settings-body .audit-logs-page {
    padding: 24px;
  }

  .audit-logs-page .cs-tabs-flex {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .audit-logs-page .cs-tabs-flex .cs-tabs {
    margin-bottom: 16px;
  }

  .audit-logs-page .cs-tabs-flex .cs-search-bar {
    width: 100%;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .settings-main-section .settings-body .audit-logs-page {
    padding: 0;
  }

  .audit-logs-page .cs-tabs-flex .cs-tabs {
    width: 100%;
  }

  .audit-logs-page .cs-tabs-flex .cs-tabs .cs-stroke-default-tab {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cs-main-layout .audit-log-card,
  .audit-logs-page .cs-data-table .rdt_Pagination {
    background-color: transparent;
  }

  .audit-logs-page .cs-tabs-flex {
    margin-top: 0;
  }

  .financial-audit-log .setting-mobile-dropdown {
    margin-bottom: 0;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
