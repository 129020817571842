/*==============================Downgrade Plan Modal=======================================*/
.downgrade-confirm-plan-modal .modal-body {
    max-height: 410px;
    overflow: hidden;
    overflow-y: auto;
}

.downgrade-plan-modal span:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.downgrade-plan-modal .downgrade-content {
    margin: 24px 0 0 0;
    text-align: center;
}

.downgrade-plan-modal .name-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--neutral-50);
    background: var(--neutral-10);
    width: 100%;
    margin-bottom: 8px;
    height: 52px;
    cursor: pointer;
}

.downgrade-plan-modal .name-box:last-child {
    margin-bottom: 0;
}

.downgrade-plan-modal .name-box span {
    position: relative;
    width: 37px;
    height: 32px;
    min-width: 32px;
    margin-right: 16px;
    overflow: hidden;
}

.downgrade-plan-modal .name-box span img {
    border-radius: 100%;
    width: 32px;
    height: 32px;
}

.downgrade-plan-modal .name-box span svg {
    width: 32px;
    height: 32px;
    overflow: visible;
}

.downgrade-plan-modal .name-box .name-permission {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}


.cs-modal-btn .cs-btn-primary .cs-common-spinner {
    position: absolute;
    right: 12px;
    top: 7px;
}

.cs-modal-btn .cs-btn-primary {
    position: relative;
}

.cs-stripe-spinner {
    position: absolute !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9;
}

.cs-form-check-box.form-check {
    margin-top: 10px;
}

.stripe-form .cs-form-group {
    margin-bottom: 0;
}

.stripe-form .form-error-msg {
    position: relative;
}

.stripe-form .form-check-label {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: pointer;
}

.downgrade-plan-modal .cs-profile-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.downgrade-plan-modal .cs-profile-group .profile-overlap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 100%;
    border: 4px solid var(--neutral-10);
    margin-right: -30px;
    overflow: hidden;
}

.downgrade-plan-modal .cs-profile-group .profile-overlap:first-child {
    margin-right: 0px;
}

.downgrade-plan-modal .cs-profile-group .profile-overlap svg,
.downgrade-plan-modal .cs-profile-group .profile-overlap img {
    position: relative;
    width: 100%;
    height: 100%;
}

.credit-change-method.cs-disabled {
    background: var(--neutral-40) !important;
    border-color: var(--neutral-40) !important;
    cursor: not-allowed !important;
}

.credit-change-method.cs-disabled .cs-card-logo {
    opacity: 0.5;
}