.delete-modal-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-delete-modal .modal-footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.delete-modal-content img {
  margin-bottom: 24px;
  width: 100px;
  height: 100px;
}

.delete-modal-content p {
  text-align: center;
  margin-bottom: 8px !important;
  padding-inline: 16px;
  line-height: 16px;
}

.delete-modal-content p:last-child {
  margin-bottom: 0 !important;
}