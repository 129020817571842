.categories-manage-files .default-regular-sub-heading-xs {
  margin-bottom: 4px;
}

.categories-manage-files .cs-upload-files {
  margin-bottom: 24px;
}

.save-btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.realestate-form .dropdown-toggle {
  padding-inline: 0;
  border: 0;
}

.bottom-text-box .d-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bottom-text-box .cs-form-check-box {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  top: 2px;
}

.bottom-text-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.bottom-text-box .d-flex img {
  margin-left: 10px;
}

.attom-data-lpd {
  position: relative;
  margin-bottom: 20px;
}

.attom-data-lpd a {
  text-decoration: underline;
}

.realestate-form .bottom-text-box {
  margin-bottom: 8px;
}

.market-value-input .text-md-end.form-control {
  position: relative;
  padding-inline: 24px;
  height: 60px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  border: none !important;
  color: var(--neutral-100);
  background: var(--neutral-20);
}

.market-value-input .cs-form-group:before {
  content: attr(data);
  display: block;
  position: absolute;
  top: 17px !important;
  left: 24px;
  font-size: 18px;
  font-weight: 600;
  color: var(--neutral-60);
  z-index: 1;
  pointer-events: none;
}

.market-value-input .cs-disabled.cs-form-group:before,
.market-value-input .cs-disabled.cs-form-group .text-md-end.form-control {
  color: var(--neutral-100);
}

.market-value-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  margin-top: 8px;
  cursor: auto;
}

.total-value-input .cs-common-spinner {
  position: relative;
  right: 14px;
}

.total-value-input {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--neutral-20);
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
}

.value-input {
  background: var(--neutral-20);
  border-radius: 4px;
}

/* 11/01/2024 */
.other-action-td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.other-action-td .cs-common-add-dropdown {
  width: auto;
  margin: 0;
  margin-left: 42px;
}

/* .other-action-td .cs-common-add-dropdown .common-tooltip-margin {
  margin-left: 24px;
} */

.attom-data-lpd.automobile-check {
  margin-top: 14px;
}

.automobile-check img {
  width: 80px;
  height: auto;
}

.attom-data-lpd.automobile-check .checkboxREalestate {
  margin-bottom: 8px;
}

.automobile-popup .select-car-details-box {
  margin-top: 0;
}

.trim-modal .cs-form-group {
  margin-bottom: 24px !important;
}

.trim-modal .col-md-12:last-child .cs-form-group,
.trim-modal .last-condition-field {
  margin-bottom: 0 !important;
}

.automobile-popup .market-value-input .cs-form-group input.dollar-symbol {
  background-image: none !important;
}

.automobile-popup .market-value-box img {
  width: 80px;
  height: auto;
  margin-left: 8px;
}

.automobile-popup .market-value-box {
  margin-bottom: 24px;
  cursor: auto;
}

.market-value-box .attom-data-image {
  margin-inline: 8px;
}

form.cs-add-acc-modal.realestate-form {
  padding: 0;
}

.cs-disabled.cs-form-group .total-value-input input.dollar-symbol {
  background-image: none !important;
}

.attom-footer-text {
  padding-top: 32px;
}

.automobile-popup .attom-footer-text {
  padding-top: 0;
}

/* Automobile */
.select-car-details-box {
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-20);
  margin-block: 24px;
  padding: 18px 24px 24px;
}

.select-car-details-box .search-radio,
.category-line-chart-container .chart-heading .loader-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.select-car-details-box .cs-radio-btn label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.select-car-details-box span.default-regular-body-text-m.cs-neutral-100 {
  margin-right: 16px;
  white-space: nowrap;
}

.cs-form-group.last-car-field {
  margin-block: 16px 0;
}

.select-car-dropdown {
  margin-top: 16px;
}

.select-car-dropdown .cs-form-group {
  margin-bottom: 0;
}

.select-car-dropdown .css-1nmdiq5-menu,
.cs-address-dropdown .css-1nmdiq5-menu {
  position: absolute !important;
  top: 30px;
  z-index: 99;
}

.select-car-dropdown .css-b62m3t-container .css-t3ipsp-control:hover,
.select-car-dropdown .css-b62m3t-container .css-t3ipsp-control:focus-visible,
.select-car-dropdown .css-b62m3t-container .css-t3ipsp-control:focus,
.select-car-dropdown .css-b62m3t-container .css-t3ipsp-control:active,
.select-car-dropdown .css-b62m3t-container .css-t3ipsp-control:visited,
.select-car-dropdown .css-b62m3t-container .css-t3ipsp-control:focus-within {
  border-color: var(--primary-main) !important;
  -webkit-box-shadow: 0 0 0 0 var(--primary-main) !important;
  box-shadow: 0 0 0 0 var(--primary-main) !important;
  height: 40px !important;
}

.cs-form-textarea {
  margin-bottom: 24px !important;
}

.cs-address-dropdown .css-1nmdiq5-menu {
  top: 35px;
}

.cs-address-dropdown.select-car-dropdown {
  margin-top: 0 !important;
}

.cs-thead .ownership-th span {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  margin-top: -3px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-thead .ownership-th svg {
  width: 100%;
  height: 100%;
}

.select-car-dropdown .css-1dimb5e-singleValue {
  color: var(--neutral-100) !important;
  /* font-size: 14px;
  font-weight: 400;
  line-height: 20px; */
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.select-car-dropdown .css-1xc3v61-indicatorContainer svg {
  fill: var(--neutral-90) !important;
  width: 20px;
  height: 20px;
}

.select-car-dropdown .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.select-car-dropdown .css-13cymwt-control {
  height: 40px !important;
}

.cs-lg-modal .modal-body .realestate-form .cs-form-group:last-child {
  margin-bottom: 24px !important;
}

/* .select-car-details-box .select-car-dropdown,
.select-car-details-box .last-car-field {
  margin-bottom: 16px;
} */

.automobile-responsive-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.action-box .dropdown-toggle .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-realestate-form {
  position: relative;
}

.cs-realestate-form .form-error-msg.cs-manual-address {
  bottom: 0px;
}

.cs-realestate-form .form-error-msg.cs-google-address {
  bottom: 0px;
}

.select-car-details-box .select-car-dropdown .form-error-msg {
  white-space: nowrap;
}

.mortgages-on-real-estate-form .cs-form-group.last-form-field,
.cs-complete-profile .cs-form-group.last-form-field {
  margin-bottom: 24px;
}

.cashonhand-table .verified-value-icon,
.cashonhand-table .dollar-value-gap,
.savingaccount-table .verified-value-icon,
.savingaccount-table .dollar-value-gap,
.retirementaccount-table .verified-value-icon,
.retirementaccount-table .dollar-value-gap,
.accountnotesreceviable-table .verified-value-icon,
.accountnotesreceviable-table .dollar-value-gap,
.lifeinsurance-table .verified-value-icon,
.lifeinsurance-table .dollar-value-gap,
.nonretirementbrokerage-table .verified-value-icon,
.nonretirementbrokerage-table .dollar-value-gap,
.realestate-table .verified-value-icon,
.realestate-table .dollar-value-gap,
.automobile-table .verified-value-icon,
.automobile-table .dollar-value-gap,
.business-table .verified-value-icon,
.business-table .dollar-value-gap,
.personalproperty-table .verified-value-icon,
.personalproperty-table .dollar-value-gap,
.otherassets-table .verified-value-icon,
.otherassets-table .dollar-value-gap,
.accountpayable-table .verified-value-icon,
.accountpayable-table .dollar-value-gap,
.notespayabletobanksandothers-table .verified-value-icon,
.notespayabletobanksandothers-table .dollar-value-gap,
.installmentsauto-table .verified-value-icon,
.installmentsauto-table .dollar-value-gap,
.installmentsaccount-table .verified-value-icon,
.installmentsaccount-table .dollar-value-gap,
.mortgagesonrealestate-table .verified-value-icon,
.mortgagesonrealestate-table .dollar-value-gap,
.unpaidtaxes-table .verified-value-icon,
.unpaidtaxes-table .dollar-value-gap,
.otherliabilities-table .verified-value-icon,
.otherliabilities-table .dollar-value-gap,
.share-pfs-modal .pfs-preview-modal .pfs-container .verified-value-icon,
.billing-history .dollar-value-gap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.wm-billing
  .cs-data-table
  .rdt_Table
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(6)
  .rdt_TableCol_Sortable {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 15px;
}
.wm-billing .dollar-value-gap {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}
.wm-billing .billing_details .rdt_TableCol:nth-child(4) .rdt_TableCol_Sortable {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 90px;
}

.cs-main-layout .cs-main-area .share-value-th {
  padding-right: 56px;
}

.cs-main-layout .cs-main-area .share-value-td {
  padding-right: 40px;
}

.cs-main-layout .cs-main-area .automobile-market {
  padding-right: 42px;
}

.cs-main-layout .cs-main-area .market-value-verified-th {
  padding-right: 44px;
}

.cs-main-layout .cs-main-area .market-verified-td {
  padding-right: 28px;
}

.category-line-chart-container .loader-text span:first-child {
  margin-right: 8px;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .select-car-details-box .select-car-dropdown,
  .select-car-details-box .last-car-field,
  .cs-form-textarea,
  .attom-data-lpd {
    margin-bottom: 16px !important;
  }

  .select-car-dropdown .col-lg-4.col-md-4,
  .automobile-popup .market-value-box {
    margin-bottom: 16px;
  }

  .select-car-dropdown .col-lg-4.col-md-4:last-child {
    margin-bottom: 0px;
  }

  .total-value-input input::-webkit-input-placeholder {
    text-align: right;
  }

  .total-value-input input::-moz-placeholder {
    text-align: right;
  }

  .total-value-input input:-ms-input-placeholder {
    text-align: right;
  }

  .total-value-input input::-ms-input-placeholder {
    text-align: right;
  }

  .total-value-input input::placeholder {
    text-align: right;
  }

  .realestate-form .bottom-text-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-text-box .d-flex img {
    margin-left: 8px;
  }

  .attom-data-lpd .bottom-text-box .checkboxREalestate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .select-car-details-box span.default-regular-body-text-m.cs-neutral-100,
  .select-car-details-box .search-radio .cs-radio-btn {
    margin-right: 8px;
  }

  .select-car-details-box .search-radio .cs-radio-btn:last-child {
    margin-right: 0px;
  }

  .select-car-details-box .search-radio {
    display: block;
    width: 100%;
  }

  .automobile-responsive-radio {
    margin-top: 8px;
  }

  .select-car-details-box {
    padding: 16px;
    margin-block: 16px;
  }

  .market-value-input .text-md-end.form-control {
    padding-inline: 16px;
  }

  .select-car-dropdown .cs-form-group {
    margin-bottom: 16px;
  }

  .cs-category-main-section .cs-table th:first-child,
  .cs-category-main-section .cs-table td:first-child {
    width: 100%;
    min-width: 65vw;
  }

  .mortgages-on-real-estate-form .cs-form-group.last-form-field,
  .cs-complete-profile .cs-form-group.last-form-field {
    margin-bottom: 16px;
  }
  .attom-data-lpd .form-error-msg {
    position: relative;
    margin-top: 6px;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
