.skeleton-cards .card {
  border: none !important;
  background-color: var(--neutral-30);
}

.skeleton-pie-graph .graph-pie-chart-main-container {
  height: 413px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
}

.cs-main-layout .cs-context .skeleton-pie-chart-container {
  /* margin-top: 50px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100% !important;
  min-height: auto !important;
}

.cs-main-layout .cs-main-area .skeleton-pie-legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: auto !important;
}

.cs-context .line-chart-skeleton .skeleton-line-graph,
.dashboard-main-section .skeleton-line-graph,
.assets-main-section .skeleton-line-graph,
.liablities-main-section .skeleton-line-graph {
  margin: 0;
}

.skeleton-pie-legend .skeleton-pie-legends {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 30px;
}

.skeleton-pie-chart-container .skeleton-pie-legends {
  margin-left: 100px;
}

.cs-context .graph-pie-chart-main-container .skeleton-networth-container {
  /* width: 300px; */
  /* margin-left: 100px; */
  /* margin-top: 10px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: auto !important;
  min-height: auto !important;
}

.skeleton-pie-graph {
  background-color: var(--neutral-30) !important;
}

.skeleton-line-graph {
  background-color: var(--neutral-30) !important;
  margin-bottom: 32px;
}

.skeleton-line-chart {
  margin-right: 30px;
  margin-top: 20px;
  width: 90%;
}

.skeleton-line-legends {
  margin-right: 30px;
}

.skeleton-line-chart-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.skeleton-graph-line-chart-main-container {
  /* height: 450px; */
}

.skeleton-finance-heading span {
  margin-left: 0px;
}

.skeleton-table-first-th {
  width: 57%;
}

.skeleton-finance-data {
  background-color: var(--neutral-30) !important;
}

.skeleton-table > :not(caption) > * > * {
  background-color: var(--neutral-30) !important;
}

.skeleton-finance-data .heading {
  background-color: var(--neutral-30) !important;
}

.skeleton-total-networth {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 97px 16px 47px;
  border-radius: 4px;
  background-color: var(--neutral-30) !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-line-chart.for-des,
.skeleton-table.for-des,
.category-cards.for-des {
  display: block;
}

.skeleton-line-chart.for-mob,
.skeleton-table.for-mob,
.category-cards.for-mob {
  display: none;
}

.cs-main-layout .cs-main-area .skeleton-portfolio .skeleton-line-graph {
  margin-bottom: 0;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
  .cs-main-layout .cs-main-area .line-chart-skeleton .skeleton-line-graph {
    margin: 0;
    height: auto;
  }

  .skeleton-line-chart.for-des {
    display: none;
  }

  .skeleton-line-chart.for-mob {
    display: block;
  }

  .skeleton-total-networth {
    padding: 12px 24px;
  }
}

@media (max-width: 1199px) {
  .skeleton-pie-legend .skeleton-pie-legends,
  .skeleton-line-graph .chart-heading > span {
    display: none;
  }

  .skeleton-line-legends {
    margin: 0;
  }

  .category-cards.for-des {
    display: none;
  }

  .category-cards.for-mob {
    display: block;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .skeleton-mob-drag {
    margin-right: 16px;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .cs-context .graph-pie-chart-main-container .skeleton-networth-container,
  .cs-main-layout .cs-main-area .skeleton-pie-legend {
    margin-top: 30px;
  }

  .skeleton-table.for-des {
    display: none;
  }

  .skeleton-table.for-mob {
    display: block;
  }

  .skeleton-finance-data {
    width: 100% !important;
  }

  .for-mob .skeleton-contingent {
    padding: 12px 24px;
  }

  .for-mob.line-chart-skeleton .chart-container .row {
    width: 100%;
    margin: 0;
  }

  .for-mob.line-chart-skeleton .chart-container .row .col-lg-6 {
    padding: 0;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
