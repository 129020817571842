/* ----------------------------------Dashboard tables----------------------------------------------- */
span.cs-modal-close {
  float: right;
  cursor: pointer;
}

.dashboard-main-section .finance-data {
  padding: 24px;
  border-radius: 4px;
  background: var(--neutral-10);
  margin-bottom: 32px;
}

.dashboard-main-section .finance-data .table,
.contingent-finance-data .table {
  margin-bottom: 0;
}

.dashboard-main-section .contingent-finance-data {
  padding: 24px;
  border-radius: 4px;
  background: var(--neutral-10);
}

.finance-data-container .heading-asset {
  background: var(--asset-surface);
}

.finance-data-container .heading-liability {
  background: var(--liability-surface);
}

.asset-thead th {
  border-bottom: none;
  color: var(--neutral-100);
  padding: 16px 24px;
}

.finance-data-container .liability-table-icon svg path {
  fill: var(--liability-main);
}

.finance-data-container .asset-table-icon svg path {
  fill: var(--asset-main);
}

.finance-data .finance-heading {
  padding: 8px 24px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.finance-heading span {
  margin-left: 8px;
}

.finance-heading span svg {
  width: 20px;
}

.finance-thead th {
  border-bottom: none;
  color: var(--neutral-100);
  padding: 16px 24px 6px;
}

.finance-data .finance-tbody {
  color: var(--neutral-90);
}

.finance-tbody td {
  padding: 12px 24px;
  border-bottom: 1px solid var(--neutral-20);
  color: var(--neutral-90);
}

.finance-tbody .default-regular-sub-heading-m {
  color: var(--neutral-90);
}

.finance-data .go-next-page-div {
  text-align: right;
}

.finance-data .liability-tfoot {
  background: var(--neutral-40, #e0e0e0);
}

.finance-data .liability-tfoot td {
  background: var(--neutral-40);
}

.finance-data .liability-tfoot td {
  color: var(--neutral-100);
}

.contingent-finance-data .default-light-body-text-s {
  color: var(--neutral-70, #757575);
}

.contingent-finance-data .heading {
  padding: 8px 24px;
  border-radius: 4px;
  background: var(--liability-surface);
  color: var(--neutral-100);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.contingent-finance-thead th {
  border-bottom: none;
  color: var(--neutral-100);
  padding: 16px 24px 6px;
}

.contingent-finance-data .contingent-tbody {
  color: var(--neutral-90);
}

.contingent-tbody td {
  padding: 12px 24px;
  border-bottom: 1px solid var(--neutral-20);
  color: var(--neutral-90);
}

.contingent-tbody .default-regular-sub-heading-m {
  color: var(--neutral-90);
}

.dashboard-main-section .go-next-page-div {
  padding-left: 0px !important;
}

.dashboard-main-section .total-networth {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 110px 16px 47px;
  border-radius: 4px;
  background: var(--primary-surface);
  border-bottom: 5px solid var(--primary-main);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dashboard-main-section .legal-contingent-row td {
  border-bottom: 0px;
}

.contingent-finance-thead th:nth-child(2) {
  width: 25%;
}

.dashboard-main-section .total-networth .net-worth-value p {
  width: 162px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.chart-container {
  margin-bottom: 32px;
}

.finance-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.dashboard-main-section .dashboard-tfoot td {
  background-color: var(--neutral-40);
  border-bottom: 0;
  color: var(--neutral-100);
}

.dashboard-main-section .dashboard-tfoot td:first-child {
  border-radius: 4px 0 0 4px;
}

.dashboard-main-section .dashboard-tfoot td:last-child {
  border-radius: 0 4px 4px 0;
}

.contingent-tbody .dollar-value-gap div,
.contingent-tbody .dollar-value-gap div span,
.contingent-tbody .dollar-value-gap div .dash-input-contingency {
  width: 100%;
  display: block;
}

.plan-content-area .sub-head .close-subscription {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  cursor: pointer;
}

.finance-tbody .default-regular-sub-heading-m,
.finance-tbody.default-regular-sub-heading-m,
.contingent-tbody.default-regular-sub-heading-m

/* tbody.cs-tbody.default-regular-sub-heading-m*/
  {
  font-weight: 300;
}

.graph-line-chart-main-container .legend-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 8px;
}

.cs-category-main-section .legend-row {
  margin-bottom: 10px;
}

.graph-line-chart-main-container .legend-check-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.legend-row .legend-check {
  margin-right: 16px;
}

.legend-row .legend-check:last-child {
  margin: 0;
}

.legend-row .legend-check input {
  margin-right: 4px;
}

.graph-line-chart-main-container .dollar-legend {
  margin-left: 45px;
  color: var(--neutral-80);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}


/* ========== Only for Dashboard net worth $ Alignment Start ========== */

@media (min-width: 2000px) and (max-width: 8000px) {
  .dashboard-main-section .total-networth {
    justify-content: unset;
  }

  h3.net-worth-heading.default-semi-bold-h3.cs-neutral-100 {
    width: 68%;
  }

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-left: 6px !important;
  }

  .pfs-modal-scroll.dashboard-main-section.cs-scroller .total-networth h3.net-worth-heading.default-semi-bold-h3.cs-neutral-100 {
    width: 74.5%;
  }

  .pfs-modal-scroll.dashboard-main-section.cs-scroller .contingent-finance-thead th:nth-child(2) {
    width: 27%;
  }
}

@media (min-width: 1920px) and (max-width: 2000px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 62px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 95px;
  }


}

@media (min-width: 1800px) and (max-width: 1919px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 48px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 75px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 52px;
  }
}

@media (min-width: 1680px) and (max-width: 1799px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 36px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 60px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 42px;
  }
}

@media (min-width: 1600px) and (max-width: 1679px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 28px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 48px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 32px;
  }
}

@media (min-width: 1536px) and (max-width: 1599px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 22px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 42px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 26px;
  }
}

@media (min-width: 1440px) and (max-width: 1535px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 8px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 32px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 22px;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 7px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 17px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 34px;
  }
}

@media (min-width: 1300px) and (max-width: 1365px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 8px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 15px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: -4px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 6px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 5px;
    width: 130px !important;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 20px;
  }

}

@media (min-width: 992px) and (max-width: 1150px) {
  .dashboard-main-section .total-networth {
    padding: 16px 27px;
  }
}

@media (min-width: 834px) and (max-width: 991px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 136px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 90px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth .dollar-value-gap {
    margin-right: 0 !important;
    /* width: 100px !important; */
  }
}

@media (max-width: 915px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 112px;
  }
}

@media (min-width: 820px) and (max-width: 900px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 86px;
  }
}

@media (min-width: 991px) and (max-width: 833px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 136px;
  }

  .dashboard-main-section .total-networth {
    padding-inline: 26px;
  }
}

@media (min-width: 768px) and (max-width: 809px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 70px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 20px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth .dollar-value-gap {
    width: 110px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 70px;
  }

  .contingent-finance-thead th:nth-child(2) {
    width: 29%;
  }
}

@media (min-width: 420px) and (max-width: 575px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 21px;

  }

  .contingent-finance-thead th:nth-child(2) {
    width: 33%;
  }

  .favicon-small .advanced-cropper-boundary.advanced-cropper__boundary,
  .favicon-Avatar .advanced-cropper-boundary.advanced-cropper__boundary {
    height: 89vw;
  }
}

@media (min-width: 300px) and (max-width: 420px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 21px;
  }
}


/* .dashboard-main-section .total-networth {
  padding: 16px 47px;
  -webkit-box-pack: initial;
  -ms-flex-pack: initial;
  justify-content: initial;
}

*/
/* ========== Only for Dashboard net worth $ Alignment End ========== */


@media (max-width: 1199px) {
  .dashboard-main-section .total-networth {
    padding-inline: 20px;
  }

  .dashboard-main-section .finance-data,
  .dashboard-main-section .contingent-finance-data {
    padding: 14px;
  }

  .dashboard-main-section .finance-tbody td {
    padding: 12px;
  }

  .dashboard-main-section .finance-data .finance-heading,
  .dashboard-main-section .finance-thead th,
  .dashboard-main-section .contingent-finance-data .heading,
  .dashboard-main-section .contingent-finance-thead th,
  .dashboard-main-section .contingent-tbody td {
    padding-inline: 12px;
  }

  .dashboard-main-section .contingent-finance-data .default-light-body-text-s {
    padding-left: 12px;
    text-align: right;
    width: 40%;
  }

  .per-month-gap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .per-month-gap .per-month-badge {
    margin-left: 0;
  }
}

/* Dashboard net worth Dollar Alignment */
.dashboard-main-section .total-networth {
  padding: 16px 47px;
}

.dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
  max-width: 150px !important;
  margin-left: auto;
  /* margin-right: 10px; */
}


/* Dashboard net worth Dollar Alignment */
@media (max-width: 991px) {
  .dashboard-main-section .total-networth {
    margin-top: 24px;
    /* padding-inline: 26px 83px; */
  }

  .dashboard-main-section .finance-data,
  .dashboard-main-section .contingent-finance-data {
    margin-bottom: 24px;
  }
}

@media (max-width: 660px) {
  .dashboard-main-section .total-networth {
    padding-inline: 23px 27px;
  }

  .dashboard-main-section .total-networth .dollar-value-gap.net-worth-value {
    width: 120px;
    font-size: 14px;
    line-height: 22px;
  }

  .per-month-gap .per-month-badge {
    margin-left: 6px;
    padding: 2px 4px;
  }

  .dashboard-main-section .finance-tbody td,
  .dashboard-main-section .finance-thead th,
  .dashboard-main-section .contingent-tbody td {
    padding: 12px 8px;
  }

  .dashboard-main-section .go-next-page-div {
    width: 18px;
  }

  .contingent-tbody .dollar-value-gap {
    width: 106px;
    padding-right: 7px;
  }

  .finance-heading span {
    margin-left: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  .finance-data .finance-heading {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (max-width: 575px) {

  .contingent-tbody .dollar-value-gap,
  .dashboard-main-section .contingent-tbody td:nth-child(2) {
    width: 124px !important;
  }

  /* .pfs-preview-modal .contingent-tbody tr td:nth-child(2) {
    padding-right: 10px !important;
  } */

  /* .dashboard-main-section .total-networth {
    padding-right: 45px;
  } */

  .dashboard-main-section .total-networth .dollar-value-gap.net-worth-value {
    width: 95px !important;
  }

  .dashboard-main-section .line-chart-container svg.recharts-surface {
    margin-left: -4vw;
  }

  .dashboard-main-section .pie-chart-container,
  .dashboard-main-section .chart-container {
    margin-bottom: 0;
  }

  .cs-main-layout .cs-main-area .dashboard-main-section .line-chart-container .recharts-responsive-container {
    height: 340px !important;
  }

  .dashboard-main-section .graph-line-chart-main-container {
    height: auto !important;
  }

  .dashboard-main-section .graph-line-chart-main-container .dollar-legend {
    margin-left: 40px;
  }

  .dashboard-main-section .cs-section-topbar .cs-title {
    margin-top: 0;
  }

  .cs-main-layout .cs-main-area .dashboard-main-section .cs-section-topbar {
    margin-bottom: 12px;
  }

  .cs-main-layout .cs-main-area .dashboard-main-section .cs-section-topbar .cs-title {
    margin-bottom: 0;
  }
}

@media (max-width: 375px) {

  .dashboard-main-section .finance-data,
  .dashboard-main-section .contingent-finance-data {
    margin-bottom: 4px;
  }

  .dashboard-main-section .contingent-finance-data {
    margin-top: 20px;
  }
}