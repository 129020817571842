/* ================== */
/* Start - initial-setup */
/* ================== */
.add-account-head {
  text-align: center;
}

.add-account-head h3 {
  margin: 56px 0px 16px 0px;
}

.add-account-main-button {
  margin: 24px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 18px;
}

.account-popup-setup2 .or-section,
.add-account-main-button .or-section {
  margin: 16px 0px;
  color: var(--neutral-50);
}

.add-account-main-button button {
  width: 100%;
  max-width: 100%;
}
iframe#plaid-link-iframe-2 {
  background-color: rgb(0 0 0 / 34%);
}
.plaid-add-popup-note {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-add-account-modal .modal-header {
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-add-account-modal .ind-plaid-footer.modal-footer {
  display: none;
}

.plaid-add-popup-note a {
  text-decoration: underline;
}

.cs-s-modal {
  width: 435px;
}

.cs-s-modal .modal-content {
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  border: none;
}

.cs-s-modal .modal-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 32px 16px 26px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--neutral-20);
}

.cs-s-modal .modal-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  color: var(--neutral-100);
}

.cs-s-modal .modal-body {
  padding: 24px 32px;
}

.cs-s-modal .modal-footer {
  padding: 0px 32px 24px 32px;
  border: none;
}

.xl-btn {
  width: 100%;
  max-width: 335px;
}

.account-popup-setup2 h4 {
  margin: 32px 0px 16px 0px;
  text-align: center;
}

.account-popup-setup2 .choose-account-category {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

span.initial-hr-sec {
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 16px 0px;
}

.account-popup-setup2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.choose-category {
  width: 100%;
}

.category-dropdown {
  margin: 48px 0px;
}

.choose-category .dropdown {
  margin-top: 4px;
}

.account-popup-setup2 .choose-category span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.account-popup-setup2 .choose-category img {
  margin-bottom: 102px;
}

.selected-category {
  margin: 120px 0px 200px 0px;
}

.account-popup-setup2 .dropdown-menu.show,
.select-pfs-form .dropdown-menu.show {
  display: block;
  width: 100%;
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  border: 0;
}

.account-popup-setup2 .dropdown-item,
.select-pfs-form .dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: var(--neutral-90);
  padding: 10px 24px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.account-popup-setup2 .dropdown-item:focus,
.account-popup-setup2 .dropdown-item:hover,
.select-pfs-form .dropdown-item:focus,
.select-pfs-form .dropdown-item:hover {
  color: var(--neutral-90);
  background-color: var(--primary-surface);
}

.add-account-head img.plaid-banks-img {
  margin-bottom: 56px;
  width: 100%;
  max-width: 343px;
}

.cap-dash-main-modal h4 {
  margin-bottom: 16px;
}

.cs-complete-profile .modal-header span {
  cursor: pointer;
}

.cs-complete-profile .add-account-head span {
  display: block;
  margin-bottom: 16px;
}

.plaid-add-popup-note a:hover {
  color: var(--primary-hover);
}

.plaid-add-popup-note a:active {
  color: var(--primary-pressed);
}

.plaid-add-popup-note span {
  margin-bottom: 4px;
}

.cs-divider {
  position: relative;
  width: calc(100% - 102px);
  text-align: center;
  margin: 16px auto;
}

.cs-divider.default-regular-body-text-s {
  position: relative;
  width: 100%;
  text-align: center;
}

.cs-divider:before {
  content: " ";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: var(--neutral-20);
}

.cs-divider span {
  position: relative;
  color: var(--neutral-50);
  background: var(--neutral-10);
  padding: 0 16px;
}

.account-popup-setup2 .choose-category .category-dropdown .dropdown-toggle,
.choose-category .category-dropdown .dropdown-toggle,
.select-pfs-form .dropdown-toggle {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 10px 40px 10px 16px;
  display: block;
  text-align: left;
  border-color: var(--neutral-50);
}

.account-popup-setup2 .choose-category span.cs-dropdown-icon,
.select-pfs-form span.cs-dropdown-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.category-dropdown .dropdown-menu.cs-scroller {
  height: 194px;
  overflow-y: auto;
  border-radius: 4px;
}

.cs-loader {
  width: 150px;
  height: 150px;
}

.cs-loader img {
  width: 100%;
  height: auto;
}

.account-success-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.succes-smodal-body .account-success-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 24px;
}

.succes-smodal-body {
  max-width: 10px 0px;
}

/* Account Update start */
.cs-bank-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-bank-images .cs-common-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 96px;
  height: 96px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--neutral-10);
  border-radius: 100px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 36, 212, 0.1);
  box-shadow: 0px 0px 10px 1px rgba(0, 36, 212, 0.1);
}

.cs-bank-images img:nth-child(2) {
  width: 48px;
  margin: 0 24px;
}

.add-account-head .cs-bank-text {
  margin: 32px 0 16px;
}

.cs-accoutns-list .table-responsive {
  margin-bottom: 16px;
}

.cs-accoutns-list.liability-accoutns-list .table-responsive:last-child {
  margin-bottom: 0;
}

.cs-accoutns-list .finance-data-container .finance-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.cs-accoutns-list .cs-thead th {
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 240px !important;
}

.plaid-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.plaid-account-data .cs-accoutns-details {
  height: 314px;
  padding-right: 4px;
  margin-right: -4px;
}

.cs-accoutns-list.liability-accoutns-list {
  margin-top: 32px;
}

.plaid-icon.cs-success {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 18px;
  height: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 30px;
  margin-left: 8px;
  background: var(--success-surface);
}

.cs-accoutns-list span.plaid-icon.cs-warning {
  margin-left: 8px;
}
.plain-footer .cs-modal-btn {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.plaid-icon.cs-success svg {
  width: 10px;
  height: 10px;
}

.cs-accoutns-details .dollar-value-gap,
.cs-accoutns-details .plaid-text {
  font-size: 12px;
  line-height: 22px;
}

.cs-accoutns-details .cs-tbody td:last-child {
  width: 126px;
}

.plain-footer button:last-child {
  margin-left: 0;
}

.plain-footer .plaid-icon.cs-success {
  margin-left: 0;
  margin-right: 8px;
}

.plaid-error-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 10px;
}
.plaid-error-content h3 {
  margin-bottom: 8px;
}
.plaid-error-content img {
  margin-bottom: 24px;
}

.cs-accoutns-list .cs-common-no-record {
  padding: 6px 16px;
  margin-top: 12px;
}

.cs-accoutns-list .cs-common-no-record span {
  font-weight: 300;
  line-height: 24px;
}

/* Account Update end */

/* plaid CSS start */
.plaid-head {
  padding: 0 24px 32px;
}

.new-plaid-progress-bar {
  padding: 0 24px;
}

.plaid-head .cs-modal-btn.cs-center-btn {
  margin-top: 24px;
}

.plaid-head .log-out-popup {
  padding-top: 24px;
}

.plaid-head .log-out-popup p.default-light-body-text-m.cs-neutral-90 {
  margin-top: 24px;
}

.plaid-modal .add-account-head span {
  margin-bottom: 16px;
  display: block;
}

.cs-accoutns-list.unsupported-account-list span.default-regular-sub-heading-xs {
  margin-bottom: 16px;
  display: block;
}

tr.cs-radius td:first-child,
tr.cs-radius td:last-child {
  border-radius: 4px 4px 4px 4px !important;
}

tr.cs-radius td:last-child {
  border-radius: 4px 4px 4px 0 !important;
}
/*plaid CSS end */

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .cs-complete-profile .add-account-head span:last-child {
    margin-bottom: 0;
  }

  .plaid-add-popup-note span:first-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .account-popup-setup2 h4 {
    margin: 32px 0px 24px 0px;
  }

  .category-dropdown p:first-child {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .account-popup-setup2 .choose-category .category-dropdown .dropdown-toggle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    height: auto;
  }

  .account-popup-setup2 .dropdown-item,
  .select-pfs-form .dropdown-item {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cs-divider span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .plaid-icon.cs-success {
    width: 16px;
    height: 16px;
  }

  .plaid-icon.cs-success svg,
  .cs-accoutns-list span.plaid-icon.cs-warning {
    width: 10px;
    height: 10px;
  }

  .cs-s-modal .modal-body {
    padding: 24px 40px;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .cs-s-modal {
    max-width: 100%;
    margin: 0 auto;
    padding: 24px;
  }

  .cs-complete-profile p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .cs-complete-profile .cs-avatar-md img {
    width: 72px;
    height: 72px;
  }

  .personal-profile {
    margin: 16px 0;
  }

  .profile-footer {
    margin-top: 24px;
  }

  .profile-footer button.cs-btn-primary {
    max-width: 100%;
    line-height: 20px;
  }

  .add-account-head img.plaid-banks-img {
    height: auto;
    max-width: 100%;
    margin-bottom: 32px;
  }

  .account-popup-setup1 span.default-medium-sub-heading-m {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .add-account-main-button button {
    line-height: 20px;
  }

  .plaid-add-popup-note span:first-child {
    font-size: 12px;
    line-height: 16px;
  }

  .plaid-add-popup-note a.default-regular-h5 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .add-account-main-button {
    padding: 0;
  }

  .cs-complete-profile .add-account-head span {
    margin-bottom: 8px;
  }

  .account-popup-setup2 p:first-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .selected-category {
    margin: 96px 0px 174px 0px;
  }

  .account-popup-setup2 h4 {
    margin: 16px 0px 24px 0px;
  }

  .choose-account-category.selected-category button,
  .account-popup-setup2 .dropdown-item {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .choose-account-category h4:first-child {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .choose-account-category button {
    line-height: 20px;
  }

  .category-dropdown {
    margin: 24px 0px;
  }

  .category-dropdown p:first-child,
  .account-popup-setup2 .choose-category .category-dropdown .dropdown-toggle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .account-popup-setup2 .choose-category img {
    width: 72px;
    height: 72px;
    margin-bottom: 100px;
  }

  .cs-s-modal .modal-body,
  .cs-common-modal-overlay .cs-sign-modal {
    padding: 24px 16px;
  }

  .cs-complete-profile.cs-add-account .modal-header {
    padding-right: 20px;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
