.fm-skeleton,
.fm-trash-skeleton {
  height: 100%;
  padding-inline: 14px;
}

.fm-skeleton .fm-skelton-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.fm-skeleton .fm-skelton-button:last-child span {
  margin-left: 4px;
}

.fm-skeleton .skeleton-manage-container,
.fm-trash-skeleton .skeleton-manage-container {
  margin-top: 0;
  width: 100%;
}

.fm-skeleton .skeleton-manage-container .row {
  width: 100%;
}

.fm-skeleton .skeleton-upgrade {
  margin-top: auto;
}

.fm-skeleton .cs-section-topbar {
  height: calc(100vh - 150px);
  margin: 0;
}

.fm-skeleton .skeleton-manage-container .row:last-child .col:last-child {
  padding-right: 0;
}

.fm-trash-skeleton .skeleton-no-record .no-record-box span {
  margin-bottom: 32px;
}

.fm-trash-skeleton .skeleton-manage-container .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.fm-trash-skeleton .skeleton-manage-container .row:last-child .col:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0;
}

.fm-skeleton .cs-section-topbar > .row:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}

.fm-skeleton .file-preview {
  width: 100%;
}

.fm-skeleton .file-title span {
  width: auto;
}

.fm-skeleton .cs-grid-view .file-management-dropdown {
  margin-top: 16px;
}

.fm-skeleton .cs-grid-view .cs-box {
  background: var(--neutral-30);
}

.cloud-storage-modal .error-msg {
  justify-content: flex-start;
  margin-block: 14px 4px;
}

.sorting-dropdown .dropdown-menu.show .dropdown-item {
  justify-content: flex-start !important;
  gap: 16px;
}

.sorting-dropdown span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-management-section .sorting-dropdown .dropdown {
  max-width: 173px;
}

.file-management-section .sorting-dropdown .dropdown .dropdown-menu.show {
  width: auto;
  min-width: 128px;
  max-width: 100%;
}
.file-management-section :focus-visible {
  outline: none;
}
/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .fm-trash-skeleton .skeleton-no-record .no-record-box span {
    margin-bottom: 24px;
  }

  .fm-skeleton .cs-section-topbar .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .fm-skeleton .cs-section-topbar .col:first-child {
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    margin-bottom: 10px;
  }

  .fm-skeleton .skeleton-upgrade .row > div {
    max-width: 350px;
  }

  .fm-skeleton .cs-section-topbar > .row:first-child {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .cloud-storage-modal .error-msg {
    align-items: baseline;
    text-align: left;
    margin-bottom: 0;
  }

  /* .cloud-storage-modal .error-msg span.cs-neutral-100.default-regular-h5 {
        display: block;
        margin-top: 4px;
    } */
}

@media (max-width: 700px) {
  .fm-skeleton .skeleton-new-btn {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .fm-trash-skeleton .skeleton-no-record .no-record-box span {
    margin-bottom: 16px;
  }

  .fm-skeleton .cs-section-topbar > .row:first-child {
    display: none;
  }

  .fm-skeleton .skeleton-manage-container {
    margin-bottom: 16px;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 660px) {
  .fm-skeleton .cs-section-topbar {
    height: 100%;
  }
}

@media (max-width: 575px) {
  .fm-skeleton .skeleton-upgrade .row > div {
    max-width: 100%;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
