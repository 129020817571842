.financial-request-table .accordion-body .cs-thead tr th {
  background: var(--neutral-20);
  border: none;
}

.financial-request-table .accordion-body .cs-table th:first-child {
  border-radius: 4px 0 0 4px;
}

.financial-request-table .accordion-body .cs-table th:last-child {
  border-radius: 0 4px 4px 0;
}

.financial-request-table [aria-expanded="true"] .cs-tbody tr td {
  border: none;
}

.financial-request-table .collapse.show .accordion-table .cs-tbody tr td {
  border-bottom: 1px solid var(--neutral-50);
  background: var(--neutral-10);
}

.financial-request-table .collapse.show .accordion-table .cs-tbody tr:last-child td {
  border-bottom: none;
}

.cs-main-layout .financial-request-table .accordion-collapse {
  border-top: 0;
}

.cs-main-layout .finance-action-acc-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.cs-main-layout .finance-action-acc-column span:last-child,
.cs-main-layout .finance-action-column span:last-child {
  margin: 0;
}

.cs-main-layout .finance-action-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-main-layout .finance-action-column .cs-detail-btn .icon {
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0 0 0 8px;
}

.cs-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
}

.request-main-section .amortization-main-listing .accordion-header .table td:last-child,
.request-main-section .amortization-main-listing .table th:last-child,
.request-main-section .amortization-main-listing .table tr td:last-child,
.request-main-section .amortization-main-listing .accordion-body .table td:last-child {
  /* width: 220px !important; */
  min-width: 220px !important;
}

.cs-main-layout .request-main-section .amortization-main-listing .accordion-body .table tr td {
  border-bottom: 1px solid var(--neutral-50) !important;
  border-radius: 0 !important;
}

.cs-main-layout .request-main-section .amortization-main-listing .accordion-body .table tr td:last-child,
.cs-main-layout .request-main-section .amortization-main-listing .accordion-body .table tr td:first-child {
  border-radius: 0 !important;
}

.cs-main-layout .request-main-section .amortization-main-listing .accordion-body .table tr:last-child td {
  border-bottom: none !important;
}

.request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:first-child,
.request-main-section .amortization-main-listing.signatur-from .table th:first-child,
.request-main-section .amortization-main-listing.signatur-from .table tr td:first-child,
.request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:first-child {
  width: 280px !important;
  min-width: 280px !important;
}

.request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:nth-child(2),
.request-main-section .amortization-main-listing.signatur-from .table th:nth-child(2),
.request-main-section .amortization-main-listing.signatur-from .table tr td:nth-child(2),
.request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:nth-child(2) {
  width: 200px !important;
  min-width: 200px !important;
}

.request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:nth-child(3),
.request-main-section .amortization-main-listing.signatur-from .table th:nth-child(3),
.request-main-section .amortization-main-listing.signatur-from .table tr td:nth-child(3),
.request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:nth-child(3) {
  width: 180px !important;
  min-width: 180px !important;
}

.request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:nth-child(4),
.request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:nth-child(4),
.request-main-section .amortization-main-listing.signatur-from .table th:nth-child(4),
.request-main-section .amortization-main-listing.signatur-from .table tr td:nth-child(4),
.request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:nth-child(5),
.request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:nth-child(5),
.request-main-section .amortization-main-listing.signatur-from .table th:nth-child(5),
.request-main-section .amortization-main-listing.signatur-from .table tr td:nth-child(5) {
  min-width: 180px !important;
  /* width: 180px !important; */
}

.professional-requests .request-main-section.financial-request-section .cs-table td:nth-child(5),
.professional-requests .request-main-section.financial-request-section .cs-table th:nth-child(5),
.professional-requests .request-main-section .amortization-main-listing.signatur-from .table th:nth-child(5),
.professional-requests .request-main-section .amortization-main-listing.signatur-from .table td:nth-child(5) {
  min-width: 250px !important;

}

.request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:nth-child(6),
.request-main-section .amortization-main-listing.signatur-from .table th:nth-child(6),
.request-main-section .amortization-main-listing.signatur-from .table tr td:nth-child(6),
.request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:nth-child(6) {
  width: 280px !important;
  min-width: 280px !important;
}

.request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:last-child,
.request-main-section .amortization-main-listing.signatur-from .table th:last-child,
.request-main-section .amortization-main-listing.signatur-from .table tr td:last-child,
.request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:last-child {
  width: 220px !important;
  min-width: 220px !important;
}


/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1799px) {
  .request-main-section .amortization-main-listing {
    width: 100%;
    max-width: 1799px;
    padding-bottom: 4px;
    overflow: hidden;
    overflow-x: auto;
  }

  .request-main-section .cs-table th:nth-child(1),
  .request-main-section .cs-table td:nth-child(1) {
    min-width: 280px;
  }

  .request-main-section .cs-table th:nth-child(2),
  .request-main-section .cs-table td:nth-child(2),
  .request-main-section .cs-table th:nth-child(3),
  .request-main-section .cs-table td:nth-child(3),
  .request-main-section .cs-table th:nth-child(4),
  .request-main-section .cs-table td:nth-child(4) {
    min-width: 220px;
  }

  .request-main-section .cs-table th:nth-child(5),
  .request-main-section .cs-table td:nth-child(5) {
    min-width: 250px;
  }

  /* .request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:nth-child(6),
  .request-main-section .amortization-main-listing.signatur-from .table th:nth-child(6),
  .request-main-section .amortization-main-listing.signatur-from .table tr td:nth-child(6),
  .request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:nth-child(6) {
    width: 250px !important;
    min-width: 250px !important;
  } */
}

@media (max-width: 1499px) {}

@media (max-width: 1199px) {}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .financial-request-table .request-dashboard td:nth-child(5) {
    white-space: nowrap;
  }

  .financial-request-section .cs-tabs-flex .cs-search-bar {
    width: auto;
  }
}

@media (max-width: 767px) {}

@media (max-width: 700px) {
  .financial-request-section .cs-tabs-flex .cs-search-bar {
    margin-bottom: 16px;
  }

  .skeleton-manage-container.for-des {
    display: none;
  }

  .skeleton-manage-container.for-mob {
    display: block;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {

  .financial-request-section .cs-tabs-flex,
  .financial-request-section .cs-tabs-flex .cs-search-bar {
    width: 100%;
  }

  .financial-request-section .cs-tabs-flex .cs-search-bar {
    margin-bottom: 16px;
  }

  .request-main-section .cs-table th:nth-child(1),
  .request-main-section .cs-table td:nth-child(1),
  .request-main-section .cs-table th:nth-child(3),
  .request-main-section .cs-table td:nth-child(3),
  .request-main-section .cs-table th:nth-child(4),
  .request-main-section .cs-table td:nth-child(4) {
    min-width: 180px;
  }

  .request-main-section .cs-table th:nth-child(2),
  .request-main-section .cs-table td:nth-child(2),
  .request-main-section .cs-table th:nth-child(5),
  .request-main-section .cs-table td:nth-child(5),
  .request-main-section .cs-table th:nth-child(6),
  .request-main-section .cs-table td:nth-child(6) {
    min-width: 190px;
  }

  .request-main-section .cs-table .cs-progress-indicator svg {
    width: 20px;
    height: 20px;
  }

  .request-main-section .cs-table .accordion-button::after {
    width: 16px;
    height: 16px;
    background-size: cover;
  }

  .request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:first-child,
  .request-main-section .amortization-main-listing.signatur-from .table th:first-child,
  .request-main-section .amortization-main-listing.signatur-from .table tr td:first-child,
  .request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:first-child {
    width: 200px !important;
    min-width: 200px !important;
  }

  .request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:nth-child(2),
  .request-main-section .amortization-main-listing.signatur-from .table th:nth-child(2),
  .request-main-section .amortization-main-listing.signatur-from .table tr td:nth-child(2),
  .request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:nth-child(2),
  .request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:nth-child(3),
  .request-main-section .amortization-main-listing.signatur-from .table th:nth-child(3),
  .request-main-section .amortization-main-listing.signatur-from .table tr td:nth-child(3),
  .request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:nth-child(3) {
    width: 160px !important;
    min-width: 160px !important;
  }

  .request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:nth-child(6),
  .request-main-section .amortization-main-listing.signatur-from .table th:nth-child(6),
  .request-main-section .amortization-main-listing.signatur-from .table tr td:nth-child(6),
  .request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:nth-child(6) {
    width: 200px !important;
    min-width: 200px !important;
  }

  .request-main-section .amortization-main-listing.signatur-from .accordion-header .table td:last-child,
  .request-main-section .amortization-main-listing.signatur-from .table th:last-child,
  .request-main-section .amortization-main-listing.signatur-from .table tr td:last-child,
  .request-main-section .amortization-main-listing.signatur-from .accordion-body .table td:last-child {
    width: 160px !important;
    min-width: 160px !important;
  }

  .cs-ellipsis {
    max-width: 130px;
  }
}

@media (max-width: 425px) {}

@media (max-width: 360px) {}

@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */