.auto-link-search {
  margin: 16px 0;
}

.auto-link-popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.link-table-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 4px;
}

.auto-link-popup .cs-common-no-record {
  margin-bottom: 14px;
}

.auto-link-popup .auto-selection-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 14px;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  margin-bottom: 16px;
  cursor: pointer;
}

.auto-link-popup .auto-selection-box p {
  word-break: break-word;
}

.auto-link-popup .selected-box {
  border: 1px solid var(--primary-main);
  background: var(--primary-surface);
}

.auto-selection-box span.dollar-value-gap {
  width: 100px;
  margin-left: 24px;
}

.add-another-asset {
  padding: 0;
}

.auto-selection-box .verified-value-icon .value-align {
  margin-right: 24px;
}

.auto-link-popup .auto-selection-box p,
.auto-link-popup .auto-selection-box .verified-value-icon span {
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
}

.link-table-heading span {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
