.wm-individual-action,
.wm-individual-action span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.wm-individual-action span {
  cursor: pointer;
}

.wm-individual-action.company-dashboard {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.wm-individual-action span.cs-danger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 4px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background: var(--danger-surface);
}

.last-email-field {
  margin-bottom: 0;
}

.financial-advisor-topbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.financial-advisor-individuals .cs-data-table .sc-eeDRCY.kqWIVd.rdt_Pagination {
  background-color: transparent;
}

.advisor-individual-table .sc-eeDRCY.kqWIVd.rdt_Pagination {
  background-color: transparent;
}

.financial-advisor-topbar .cs-form-group .form-control {
  width: 450px;
}

.mobile-add-acc-icon {
  display: none;

}

/*=========================================================================================*/


/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {

  .advisor-individual-table .cs-data-table .jzrolw,
  .financial-recent-dashboard {
    width: 100%;
    max-width: 1499px;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 4px;
  }

  .advisor-individual-table [data-column-id="1"].rdt_TableCol,
  .advisor-individual-table [data-column-id="1"].rdt_TableCell,
  .advisor-individual-table [data-column-id="2"].rdt_TableCol,
  .advisor-individual-table [data-column-id="2"].rdt_TableCell,
  .advisor-individual-table [data-column-id="6"].rdt_TableCol,
  .advisor-individual-table [data-column-id="6"].rdt_TableCell {
    min-width: 200px;
  }

  .advisor-individual-table [data-column-id="3"].rdt_TableCol,
  .advisor-individual-table [data-column-id="3"].rdt_TableCell {
    min-width: 280px;
  }

  .advisor-individual-table [data-column-id="4"].rdt_TableCol,
  .advisor-individual-table [data-column-id="4"].rdt_TableCell,
  .advisor-individual-table [data-column-id="5"].rdt_TableCol,
  .advisor-individual-table [data-column-id="5"].rdt_TableCell {
    min-width: 155px;
  }

  .financial-recent-dashboard .cs-thead th:nth-child(4) {
    min-width: 190px;
  }
}


@media (max-width: 1199px) {
  .financial-advisor-topbar .cs-form-group .form-control {
    width: 300px;
  }


}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */


/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .financial-advisor-topbar .cs-form-group .form-control {
    width: 248px;
  }
}


@media (max-width: 767px) {
  .desktop-add-button {
    display: none;
  }

  .mobile-add-acc-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 36px;
    height: 36px;
    padding: 6px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--primary-main);
    -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }

  .financial-advisor-topbar {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .financial-advisor-topbar .mobile-add-head-button {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }


  .financial-advisor-topbar .cs-form-group .form-control {
    width: 100%;
    margin: 16px 0 8px 0;
  }

  .financial-advisor-topbar button {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .wm-individual-action span.cs-danger svg,
  .wm-individual-action span svg {
    width: 16px;
    height: 16px;
  }

  .wm-individual-action span.cs-danger {
    width: 24px;
    height: 24px;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */


/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .financial-recent-dashboard .cs-thead th:nth-child(4) {
    min-width: 190px;
  }

  .skeleton-financial-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}


@media (max-width: 425px) {}


@media (max-width: 360px) {}


@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */