.offcanvas-avatar {
  position: relative;
}

.offcanvas-avatar svg {
  border-radius: 120px;
}

/* **************************************Start of the custom css to be removed *************/
.cs-cs-section-topbar {
  margin-bottom: 40px;
}

.cs-manage-users-button {
  width: 187px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}

.cs-tabs-height {
  height: 40px;
}

.sidebar-add-account .cs-btn-icon-primary {
  width: 187px;
  max-width: 100%;
}

/* ==================Start - Manage User Modal Code==================== */

.manage-user-modal-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  /* gap: 16px; */
  margin: 0;
  width: 100%;
}

.manage-user-modal-buttons button {
  margin-right: 16px;
}

.manage-user-modal-buttons button:last-child {
  margin-right: 0;
}

.cs-manage-user-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.cs-manage-user-radio .cs-radio-btn-label {
  margin-right: 24px;
}

.cs-manage-user-radio .cs-radio-btn-label:last-child {
  margin-right: 0;
}

.cs-manage-user-radio .cs-radio-btn-label .radio-button {
  cursor: pointer;
}

/* ==================End - Manage User Modal Code==================== */
.button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.button-container button {
  width: 157px;
  margin-right: 16px;
}

.button-container button:last-child {
  margin-right: 0;
}

.manage-user-input-modal form .row:last-child .cs-form-group {
  margin-bottom: 0;
}

.manage-user-input-modal .form-error-msg {
  bottom: auto;
}

.manage-user-input-modal .form-error-msg {
  text-wrap: nowrap;
}

/* **************************************End of the custom css to be removed *************/

.collaboration-data-table .rdt_TableBody .rdt_TableRow {
  cursor: pointer;
}

.collaboration-data-table div[data-column-id="1"],
.collaboration-data-table .cs-data-table .rdt_TableBody #cell-1-undefined,
.collaboration-data-table div[data-column-id="2"],
.collaboration-data-table .cs-data-table .rdt_TableBody #cell-2-undefined,
.collaboration-data-table div[data-column-id="3"],
.collaboration-data-table .cs-data-table .rdt_TableBody #cell-3-undefined,
.collaboration-data-table div[data-column-id="4"],
.collaboration-data-table .cs-data-table .rdt_TableBody #cell-4-undefined {
  width: 340px;
}

.collaboration-data-table div[data-column-id="5"],
.collaboration-data-table .cs-data-table .rdt_TableBody #cell-5-undefined {
  width: 110px;
}

.skeleton-tabs {
  height: 40px;
}

.skeleton-tabs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-manage-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 24px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.skeleton-audit-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 24px;
}

.skeleton-manage-audit-1 {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.skeleton-manage-audit-2 {
  margin-left: auto;
}

.skeleton-table > :not(caption) > * > * {
  background-color: var(--neutral-30) !important;
}

.skeleton-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.skeleton-breadcrumb .skeleton-breadcrumb-1 {
  margin-right: 4px;
}

.skeleton-breadcrumb .skeleton-breadcrumb-2 {
  margin-right: 4px;
}

.manage-user-input-modal .cs-msg {
  margin-block: 8px 0;
}

.cs-btn-group > :first-child {
  margin-right: 0 !important;
}

.manage-user-input-modal a.cs-btn-tertiary {
  padding: 0;
}

/* .skeleton-manage-container.for-des {
  display: block;
} */

.skeleton-manage-container.for-mob {
  display: none;
}

.request-main-section
  .collaboration-data-table
  .cs-data-table
  .rdt_Table
  .rdt_TableHeadRow
  .rdt_TableCol_Sortable {
  width: auto !important;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */

@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  /* .request-main-section .cs-header,
  .sidebar-add-account {
    width: 187px;
  } */
}

@media (max-width: 767px) {
  .request-main-section .sidebar-add-account {
    margin-top: 0;
  }

  .request-main-section .cs-tabs-flex .buttons {
    margin-bottom: 24px;
  }

  .collaboration-data-table div[data-column-id="3"] div {
    white-space: nowrap !important;
    overflow: unset;
  }

  .collaboration-data-table div[data-column-id="5"],
  .collaboration-data-table .cs-data-table .rdt_TableBody #cell-5-undefined {
    width: auto;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 700px) {
  .request-main-section .cs-tabs-flex .buttons {
    margin-block: 16px 0;
    width: 100%;
  }

  .request-main-section .cs-tabs-flex .sidebar-add-account,
  .request-main-section .cs-tabs-flex .cs-tabs {
    width: 100%;
  }

  .request-main-section .cs-tabs-flex .sidebar-add-account button {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
  }

  .request-main-section .cs-tabs-flex .cs-tabs button {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 50%;
    padding: 8px;
  }

  .manage-user-input-modal .cs-manage-user-radio .cs-radio-btn-label span,
  .manage-user-input-modal .cs-form-dropdown .dropdown-toggle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .manage-user-input-modal .cs-form-dropdown .dropdown-item {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .collaboration-data-table div[data-column-id="1"],
  .collaboration-data-table .cs-data-table .rdt_TableBody #cell-1-undefined,
  .collaboration-data-table div[data-column-id="2"],
  .collaboration-data-table .cs-data-table .rdt_TableBody #cell-2-undefined,
  .collaboration-data-table div[data-column-id="3"],
  .collaboration-data-table .cs-data-table .rdt_TableBody #cell-3-undefined,
  .collaboration-data-table div[data-column-id="4"],
  .collaboration-data-table .cs-data-table .rdt_TableBody #cell-4-undefined {
    width: 230px;
  }

  .cs-offcanvas {
    padding: 24px 16px;
  }

  .cs-offcanvas .name-information span:nth-child(1) {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-right: 8px;
  }

  .cs-offcanvas .name-information span:nth-child(3) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .cs-offcanvas .header-information .name-information span:nth-child(2) {
    margin-right: 8px;
  }

  .cs-offcanvas .designation-information span {
    color: var(--neutral-60);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .cs-offcanvas .name-information {
    margin-bottom: 0;
  }

  .cs-offcanvas .top-information-container {
    padding: 16px;
  }

  .cs-offcanvas .body-top-information p.default-medium-sub-heading-s {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .cs-offcanvas .body-top-information p.default-regular-body-text-l {
    font-size: 12px;
    line-height: 20px;
  }

  .cs-offcanvas .body-top-information span {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .cs-offcanvas .body-top-information .cs-manage-user-radio span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cs-offcanvas .button-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }

  .cs-offcanvas .button-container button {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
    width: 100%;
  }

  .cs-offcanvas .button-container button:last-child {
    margin-bottom: 0;
  }

  .cs-offcanvas .header-information,
  .cs-offcanvas .img-container {
    padding: 0;
  }

  .cs-offcanvas .img-container {
    margin-bottom: 16px;
  }

  .cs-offcanvas .button-container button:first-child {
    margin-right: 0;
  }
}

/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .skeleton-manage-container.for-des {
    display: none;
  }

  .skeleton-manage-container.for-mob {
    display: block;
  }

  .skeleton-manage-container.for-mob > div {
    margin-bottom: 8px;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
