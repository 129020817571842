.profile-image div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.edit-profile-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.crop-profile-buttons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0;
}

.crop-profile-buttons .cs-btn-primary {
  margin-left: 16px;
}

.cs-md-modal p {
  margin: 0;
}

.cs-md-modal p.default-regular-body-text-m.cs-neutral-100 {
  margin-bottom: 4px;
}

.image-crop-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.image-crop-area canvas {
  border-radius: 100%;
}

.edit-profile-upload p.default-regular-body-text-s.cs-neutral-60 {
  padding-top: 8px;
}

.avtar-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.avatar-label {
  margin-top: 24px;
  margin-bottom: 8px;
}

.avtar-container .cs-avatar-sm {
  width: 60px;
  height: 60px;
}

.avtar-container .avatar-circle svg {
  width: 100%;
  height: 100%;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 100%;
}

.avtar-container .avatar-initials {
  background-color: var(--neutral-50);
}

.avatar-initials {
  position: relative;
}

.avatar-initials img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.initials-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
}

.avtar-container .avatar-circle .avatar-selected {
  border-color: var(--primary-main);
}

.avtar-container .cs-avatar-sm {
  cursor: pointer;
}

.avatar-circle {
  position: relative;
}

.avatar-success-badge {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  right: -3px;
  bottom: 2px;
}

.setting-image-loader {
  text-align: center !important;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {}

@media (max-width: 1199px) {}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {}

@media (max-width: 767px) {}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  label.default-regular-sub-heading-xs.cs-neutral-100.form-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  p.default-regular-sub-heading-s {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cs-upload-files p,
  .cs-upload-files p:nth-child(3) {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
  }

  .cs-upload-files {
    padding: 16px;
  }

  .cs-upload-files .cs-primary svg {
    width: 24px;
    height: 24px;
  }

  .cs-upload-files .cs-upload-list {
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 10px;
  }

  .edit-profile-upload p.default-regular-body-text-s.cs-neutral-60 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .image-crop-area canvas {
    width: 50% !important;
    height: 50% !important;
  }

  .avtar-container .cs-avatar-sm {
    width: 10vw;
    height: 10vw;
  }

  .avatar-success-badge {
    width: 14px !important;
    height: 14px !important;
    right: 0;
  }

  .avtar-container .avatar-circle svg {
    border-width: 1px;
  }

  .cs-settings-dropdown .initials-overlay {
    font-size: 10px;
    line-height: 16px;
  }
}

@media (max-width: 425px) {

  .avatar-success-badge {
    width: 12px !important;
    height: 12px !important;
  }

  .edit-profile-upload p.default-regular-body-text-s.cs-neutral-60 {
    font-size: 8px;
    line-height: 12px;
    padding-top: 4px;
  }
}

@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */