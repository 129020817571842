.notification-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-vertical-line {
  border-right: 1px solid var(--neutral-50);
  height: 38px;
}

.navbar-wrapper .nav-icons {
  margin-right: 24px;
}

.cs-header .profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.expanded-header .profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.trial-expiry-info {
  color: var(--primary-main);
  cursor: pointer;
}

.upgrade-plan-btn-container .cs-btn-primary {
  padding: 8px 16px;
}

.nav-notification {
  position: relative;
}

.nav-notification-btn {
  border-radius: 50%;
  position: absolute;
  right: -1px;
  top: 1px;
}

.nav-notification svg path {
  fill: var(--neutral-80);
}

.nav-icon-styling svg path {
  fill: var(--neutral-80);
}

.navbar-nav .cs-nav-dropdown .dropdown-menu {
  left: -435px;
  padding: 0px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  border: 0px;
}

.upgrade-header-mobile {
  display: none;
}

/* --------------------------------------------sidebar css---------------------------------------------------- */
.cs-main-sidebar .cs-btn-primary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 10px 48px;
}

.collapse-sidebar .cs-btn-primary {
  padding: 12px;
}

.request-pending-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 30px;
  margin-left: auto;
  margin-top: 1px;
  background: var(--primary-main);
  color: var(--neutral-10);
}

.sidebar-add-account {
  margin-top: auto;
}

.sidebar-add-account .add-account-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.add-account-plus-icon {
  width: 16px;
  height: 16px;
}

.sidebar-add-account .add-account-plus-icon {
  -webkit-filter: brightness(100);
  filter: brightness(100);
}

.sidebar-notification-icon {
  position: absolute;
  left: 17px;
  top: -1px;
}

/* -------------------------------------Banking Error Message--------------------------------------- */

.sidebar-add-account .banking-validations {
  padding: 12px 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background: var(--danger-surface);
}

.sidebar-add-account .banking-validations .banking-error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

.sidebar-add-account .banking-validations .banking-error div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-nav-toggled .banking-icon {
  pointer-events: none;
}

.sidebar-add-account .banking-validations .banking-error span.cs-danger,
.sidebar-add-account .banking-validations .banking-error span.cs-primary {
  margin-right: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sidebar-add-account .banking-sync-data {
  background: var(--primary-surface);
}

.sidebar-add-account .banking-sync-data .open-banking-messages {
  border-color: var(--primary-border);
  /* margin-top: 12px; */
  width: 100%;
}

.sidebar-add-account .banking-validations .banking-error p {
  margin-top: 8px;
}

.sidebar-add-account .banking-validations .dollar-value-gap {
  width: 100%;
  max-width: 72px;
}

/* -------------------------------------Collapse sidebar css--------------------------------------- */

.add-account-plus-icon-sm-btn {
  width: 20px;
  height: 20px;
}

.sidebar-add-account .add-account-plus-icon-sm-btn {
  -webkit-filter: brightness(100);
  filter: brightness(100);
}

.collapse-sidebar .cs-logo img {
  width: 40px;
  height: 42px;
}

.collapse-sidebar .cs-main-nav-item {
  padding: 8px;
  width: 40px;
  height: 40px;
}

.collapse-sidebar .sidebar-nav .nav {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.collapse-sidebar .cs-btn-primary {
  width: 48px;
  height: 48px;
}

.collapse-sidebar .sidebar-add-account .banking-validations {
  width: 48px;
  height: 48px;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* .collapse-sidebar .sidebar-add-account .banking-validations svg {
  margin-left: 2px;
} */

.open-banking-messages .warning-message .accordion-button {
  padding: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: transparent;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.open-banking-messages .warning-message .accordion {
  width: 100%;
  margin-bottom: 4px;
}

.open-banking-messages .warning-message .accordion:last-child {
  margin-bottom: 0;
}

.open-banking-messages .accordion-button::after {
  display: none;
}

.cs-sync-btn .cs-success,
.cs-sync-btn .cs-primary,
.cs-sync-btn .cs-success:focus,
.cs-sync-btn .cs-primary:focus {
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
}

.open-banking-messages .accordion-item {
  background: transparent;
  border: 0;
  margin-bottom: 4px;
}

.open-banking-messages .accordion-item:last-child {
  margin-bottom: 0;
}

.open-banking-messages .accordion-body {
  padding: 0;
}

.open-banking-messages .accordion-button.collapsed span {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.open-banking-messages .accordion-button span {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.open-banking-messages .warning-message span,
.open-banking-messages .user-data-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.open-banking-messages .warning-message .content-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.open-banking-messages .warning-message .content-icon span,
.banking-validations .expand-collapse .sync-all-icon span {
  margin-right: 4px;
}

.open-banking-messages .user-data-value {
  margin-top: 6px;
}

.banking-validations span.cs-neutral-90,
.banking-validations span.cs-neutral-80,
.banking-validations .expand-collapse p,
.banking-validations .expand-collapse div {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.banking-validations .expand-collapse,
.banking-validations .expand-collapse .sync-all-icon,
.banking-validations .expand-collapse .sync-all-icon span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.open-banking-messages {
  max-height: 215px;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid var(--danger-border);
  margin-right: -4px;
  padding-right: 4px;
  padding-top: 8px;
  margin-top: 12px;
}

.banking-validations p {
  margin-top: 4px;
}

.cs-notification .notification span.cs-neutral-100 {
  margin-right: 8px;
}

.mobile-add-icon {
  display: none;
}

.linked-account {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.linked-account svg {
  margin-left: 8px;
}

.linked-account .linked-account-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-nav-skeleton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 40px;
}

.skeleton-navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-navbar .item-1 {
  margin-left: 16px;
}

.skeleton-navbar .item-2 {
  margin-left: 50px;
}

.skeletion-menu {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeletion-menu .menu-1 {
  width: 144px;
}

.skeletion-menu .menu-2 {
  width: 67px;
  margin-left: 57px;
}

.skeletion-menu .menu-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 85px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 144px;
}

.skeletion-menu .menu-3 .menu-item {
  width: 24px;
}

.skeletion-menu .menu-4 {
  margin-left: 50px;
}

.skeletion-menu .menu-4.for-mob {
  margin-left: 20px;
  margin-right: 10px;
}

.skeletion-main-nav-item {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-menu-icon {
  position: relative;
  margin-right: 16px;
}

.collaboration-data-table div[data-column-id="3"],
.collaboration-data-table div[data-column-id="3"] div {
  width: 340px !important;
  min-width: auto;
}

.collaboration-data-table .cs-data-table .rdt_TableCell div {
  white-space: nowrap !important;
}

.fixed-capsync-loader {
  z-index: 9999;
  position: absolute;
}

.fixed-capsync-loader div {
  position: fixed;
  z-index: 9;
}

.cs-notification .cs-scroller {
  max-height: 170px;
  overflow: hidden;
  overflow-y: auto;
}

/*=========================================================================================*/

/* ================== Sidebar submenu design Start ================== */
.sidebar-nav .cs-nav-dropdown {
  width: 100%;
}

.sidebar-nav .cs-nav-dropdown .cs-dropdown-menu {
  position: relative !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  border: none;
  padding-left: 56px;
}

.sidebar-nav .cs-nav-dropdown .cs-dropdown-menu::before,
.sidebar-nav .cs-nav-drop-item::before {
  content: "";
  position: absolute;
  background: var(--neutral-30);
}

.sidebar-nav .cs-nav-dropdown .cs-dropdown-menu::before {
  height: calc(100% - 24px);
  width: 1px;
  top: 5px;
  left: 30px;
}

.sidebar-nav .cs-nav-drop-item::before {
  width: 16px;
  height: 1px;
  top: 50%;
  left: -26px;
}

.sidebar-nav li.cs-nav-item.nav-item {
  list-style: none;
  position: relative;
}

.sidebar-nav .cs-nav-dropdown.nav-item .default-regular-sub-heading-m {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.sidebar-nav .cs-nav-dropdown.nav-item .default-regular-sub-heading-m .cs-menu-icon.chevron-down {
  margin: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
}

.sidebar-nav .cs-nav-dropdown.nav-item.navDropOpen .default-regular-sub-heading-m .cs-menu-icon.chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
}

.fixed-download-loader {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  background-color: transparent;
}

/* ================== Sidebar submenu design End ================== */

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */

@media (max-width: 1499px) {}

@media (max-width: 1199px) {
  .navbar-nav .cs-nav-dropdown .dropdown-menu {
    left: -250px;
    width: 430px;
  }

  .financial-notification .navbar-nav .cs-nav-dropdown .dropdown-menu {
    left: -34vw;
  }

  .cs-notification {
    width: 100%;
  }

  .cs-notification .notification-item .notification-message,
  .cs-notification .bullet-item .notification-message {
    width: 100%;
  }

  .cs-navbar-dropdown-btn {
    margin-left: 0;
    padding-inline: 14px;
  }

  .upgrade-navbar .cs-btn-primary {
    margin-inline: 14px;
  }

  /* .multiple-user-dropdown {
    margin-inline: 10px 14px;
  } */
  .multiple-user-flow {
    margin-right: 16px;
  }

  .navbar-wrapper .nav-icons {
    margin-right: 14px;
  }

  .navbar-vertical-line {
    height: 34px;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {

  .cs-header,
  .sidebar-add-account {
    width: 100%;
    max-width: 100%;
  }

  .collaboration-data-table div[data-column-id="2"],
  .collaboration-data-table div[data-column-id="2"] div {
    width: 100px !important;
  }

  .cs-tabs-flex .sidebar-add-account {
    width: auto;
  }

  .request-main-section .cs-title,
  .request-main-section .cs-title h1 {
    margin-bottom: 0;
  }

  .cs-main-sidebar {
    padding: 8px 24px 24px 24px;
    width: 304px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-height: 100%;
    overflow-y: auto;
  }

  .cs-main-sidebar .cs-logo {
    margin-block: 32px;
  }

  .cs-main-sidebar .cs-logo img.capsync-logo {
    width: 164px;
    height: 47px;
  }

  .cs-main-sidebar .sidebar-header .close-btn {
    display: block;
    top: 15px;
    right: 20px;
    padding: 0;
  }

  .collapse-sidebar {
    display: none;
  }

  .expanded-header,
  .cs-header {
    width: 100%;
    max-width: 100% !important;
    padding: 12px 20px;
    background-color: var(--neutral-10);
    -webkit-box-shadow: var(--box-shadow-one);
    box-shadow: var(--box-shadow-one);
  }

  .cs-main-layout .ms-auto.navbar-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .cs-main-layout .cs-main-area {
    padding: 0 !important;
  }

  .cs-navbar-dropdown-btn {
    margin-left: 16px;
  }

  .upgrade-navbar .cs-btn-primary {
    margin-inline: 8px 16px;
    padding-inline: 23px;
  }

  /* .multiple-user-dropdown {
    margin-inline: 16px;
  } */

  .navbar-wrapper .nav-icons {
    margin-right: 16px;
  }

  .navbar-vertical-line {
    height: 32px;
  }

  .cs-main-area .navbar-expand-lg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    width: auto;
  }

  .cs-header .nav-icon-styling svg,
  .cs-header .nav-notification svg,
  .expanded-header .nav-icon-styling svg,
  .expanded-header .nav-notification svg {
    width: 20px;
    height: 20px;
  }

  .cs-header .profile-img,
  .expanded-header .profile-img {
    width: 40px;
    height: 40px;
  }

  /* .nav-notification-btn {
    left: 12px;
    top: -2px;
  } */

  .nav-notification-btn.blue-notification-badge {
    width: 8px;
  }

  .sidebar-add-account .banking-validations {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .sidebar-add-account button.cs-btn-primary {
    display: none;
  }

  .cs-header .mobile-add-icon {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 100%;
    background: var(--primary-main);
    -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  }

  .navbar .cs-nav-header span.cursor-pointer svg {
    width: 20px;
    height: 20px;
  }

  .cs-main-layout.cs-nav-toggled:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--neutral-100);
    z-index: 4;
    opacity: 0.3;
  }

  .navbar-nav .cs-nav-dropdown .dropdown-menu {
    left: -250px;
  }

  .financial-notification .navbar-nav .cs-nav-dropdown .dropdown-menu {
    left: -42vw;
  }
}

@media (max-width: 767px) {

  .cs-notification span.default-medium-sub-heading-s.cs-neutral-90,
  .cs-notification .bullet-item {
    margin-inline: 8px;
  }

  .cs-notification .notification-item .notification-date-time svg {
    width: 10px;
    height: 10px;
  }

  .cs-notification .notification-item {
    padding: 8px 16px;
  }

  .cs-notification .notification-item .blue-notification-badge {
    margin: 0;
    width: 11px !important;
  }

  .cs-notification {
    width: 100%;
  }

  .cs-notification .title {
    padding: 14px 16px;
  }

  .cs-notification .notification-item {
    min-height: auto;
  }

  .financial-notification .navbar-nav .cs-nav-dropdown .dropdown-menu {
    left: -48vw;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {

  .expanded-header,
  .cs-header {
    padding: 10px 0;
    height: auto;
  }

  .navbar .cs-nav-header {
    height: 28px;
  }

  .cs-main-sidebar {
    padding: 8px 16px 16px 16px;
    width: 281px;
  }

  .cs-main-sidebar .cs-logo img.capsync-logo {
    width: 148px;
    height: 42px;
  }

  .navbar-wrapper .nav-icons {
    margin-right: 12px;
  }

  /* .multiple-user-dropdown {
    margin-inline: 4px 10px;
  } */

  .cs-navbar-dropdown-btn {
    padding: 0px 8px 0px 8px;
    margin-left: 6px;
  }

  .cs-navbar-dropdown-btn svg {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }

  .navbar-vertical-line {
    height: 24px;
  }

  .cs-header .nav-icon-styling svg,
  .cs-header .nav-notification svg,
  .expanded-header .nav-icon-styling svg,
  .expanded-header .nav-notification svg,
  .upgrade-header-mobile img,
  .expanded-header .upgrade-header-mobile img,
  .navbar .navbar-wrapper .cs-nav-header span.cursor-pointer svg {
    width: 16px;
    height: 16px;
  }

  .nav-icon-styling.nav-icons svg {
    width: 16px !important;
    height: 16px !important;
  }

  .cs-header .profile-img,
  .expanded-header .profile-img {
    width: 28px;
    height: 28px;
  }

  .expanded-header span.cursor-pointer svg {
    width: 20px;
    height: 20px;
  }

  .blue-notification-badge,
  .red-notification-badge {
    width: 6px;
    height: 6px;
    margin: 0 !important;
    max-width: 6px !important;
  }

  .cs-notification .bullet-item-container,
  .cs-notification .title {
    padding-inline: 10px;
  }

  .nav-notification-btn {
    right: 0;
  }

  .sidebar-add-account button.cs-btn-primary {
    display: none;
  }

  .cs-header .mobile-add-icon {
    bottom: 30px;
  }

  .banking-validations h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .banking-validations .default-light-body-text-s {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }

  .notification span:first-child,
  .notification span svg,
  .notification-img svg {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cs-settings-dropdown a.dropdown-toggle.nav-link {
    width: 28px;
    height: 28px;
  }

  .cs-settings-dropdown a.dropdown-toggle.nav-link svg {
    width: 100%;
    height: 100%;
  }

  .cs-navbar-dropdown-btn .dropdown-toggle {
    height: auto;
  }

  /* Multiple User flow start */
  .multiple-user-flow .profile-image {
    width: 26px;
    height: 26px;
    border: 0;
    min-height: 26px;
    min-width: 26px;
  }

  .active-user-profile .active-dot.cs-success {
    left: 19px;
    bottom: 8px;
    width: 6px;
    height: 6px;
  }

  .dropdown-menu .active-user-profile .active-dot.cs-success {
    left: 20px;
    bottom: 4px;
  }

  .multiple-user-dropdown .cs-scroller.dropdown-menu.show {
    width: 230px;
    left: -45px;
  }

  .cs-common-add-dropdown.multiple-user-dropdown .dropdown .dropdown-toggle,
  .profile-image .multiple-user-avatar svg {
    width: 50px;
    height: 28px;
  }

  .multiple-user-dropdown .cs-icon {
    width: 12px;
    /* margin-right: 6px; */
  }

  .multiple-user-dropdown .cs-icon svg {
    width: 100%;
  }

  .multiple-user-dropdown .cs-scroller.dropdown-menu.show .dropdown .dropdown-toggle {
    height: 24px;
  }

  .cs-common-add-dropdown.multiple-user-dropdown .dropdown .dropdown-menu.show .multiple-user-list {
    padding: 8px 14px;
  }

  .upgrade-navbar .cs-btn-primary {
    font-size: 8px;
    line-height: 12px;
    padding: 4px 8px !important;
    margin-inline: 8px 0 !important;
  }

  .cs-settings-dropdown .profile-item {
    padding: 8px 14px;
  }

  .cs-settings-dropdown .profile-item-link svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  /* Multiple User flow start */
  .navbar-nav .cs-nav-dropdown .dropdown-menu {
    left: -150px;
    width: 280px;
  }

  .financial-notification .navbar-nav .cs-nav-dropdown .dropdown-menu {
    left: -50vw;
  }

  .multiple-user-flow {
    margin-right: 12px;
  }

  .profile-image .multiple-user-avatar img {
    width: 28px;
    height: 28px;
  }

  .cs-nav-skeleton {
    height: 28px;
  }
}

@media (max-width: 425px) {
  .cs-main-area .navbar-expand-lg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .financial-notification .navbar-nav .cs-nav-dropdown .dropdown-menu {
    left: -56vw;
  }
}

@media (max-width: 360px) {
  .cs-navbar-dropdown-btn {
    padding-left: 6px;
    margin-left: 0;
  }

  .financial-notification .navbar-nav .cs-nav-dropdown .dropdown-menu {
    left: -68vw;
  }
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */