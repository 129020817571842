/* ========== Only for Dashboard net worth $ Alignment Start ========== */
@media (min-width:1921px) {

  .cs-grid-view {
    height: auto !important;
    max-height: 100% !important;
  }
}

@media (min-width: 1920px) and (max-width: 2000px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 62px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 95px;
  }
}

@media (min-width: 1800px) and (max-width: 1919px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 48px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 75px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 52px;
  }
}

@media (min-width: 1680px) and (max-width: 1799px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 36px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 60px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 42px;
  }
}

@media (min-width: 1600px) and (max-width: 1679px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 28px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 48px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 32px;
  }
}

@media (min-width: 1536px) and (max-width: 1599px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 22px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 42px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 26px;
  }
}

@media (min-width: 1440px) and (max-width: 1535px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 8px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 32px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 22px;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 7px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 17px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 34px;
  }
}

@media (min-width: 1300px) and (max-width: 1365px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 8px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 15px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: -4px;
  }

  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 6px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 5px;
    width: 130px !important;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1150px) {
  .dashboard-main-section .total-networth {
    padding: 16px 27px;
  }
}

@media (min-width: 834px) and (max-width: 991px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 136px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 90px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth .dollar-value-gap {
    margin-right: 0 !important;
    /* width: 100px !important; */
  }
}

@media (max-width: 915px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 112px;
  }
}

@media (min-width: 820px) and (max-width: 900px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 86px;
  }
}

@media (min-width: 991px) and (max-width: 833px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 136px;
  }

  .dashboard-main-section .total-networth {
    padding-inline: 26px;
  }
}

@media (min-width: 768px) and (max-width: 809px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 70px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth {
    padding-right: 20px;
  }

  .dashboard-main-section .pfs-preview-modal .total-networth .dollar-value-gap {
    width: 110px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 70px;
  }

  .contingent-finance-thead th:nth-child(2) {
    width: 29%;
  }
}

@media (min-width: 420px) and (max-width: 575px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 21px;
  }

  .contingent-finance-thead th:nth-child(2) {
    width: 33%;
  }

  .favicon-small .advanced-cropper-boundary.advanced-cropper__boundary,
  .favicon-Avatar .advanced-cropper-boundary.advanced-cropper__boundary {
    height: 89vw;
  }
}

@media (min-width: 300px) and (max-width: 420px) {

  .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap,
  .sidebarClose~.cs-contentarea .dashboard-main-section .asset-liability-container .total-networth .dollar-value-gap {
    margin-right: 21px;
  }
}

.dashboard-main-section .total-networth {
  padding: 16px 47px;
  -webkit-box-pack: initial;
  -ms-flex-pack: initial;
  justify-content: initial;
}

/* ========== Only for Dashboard net worth $ Alignment End ========== */

/* Height Media start */
@media (max-height: 540px) {

  /* .cs-main-sidebar {
    height: 100%;
    max-height: 550px;
    min-height: unset;
    overflow-y: auto;
  } */
  .cs-list-view,
  .cs-grid-view {
    height: 100%;
  }

  .sidebar-nav {
    margin-bottom: 24px;
  }
}

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */

@media (min-width: 2100px) {
  .portfolio-cs-table-container .portfolio-data-table .sc-hzhJZQ {
    width: 100%;
  }
}

@media (min-width: 1680px) {
  .os-mac.browser-safari .new-pie-chart .apexcharts-legend {
    bottom: -2vh !important;
  }
}

@media (min-width:1921px) {
  .auth-image-box {
    width: 40vw !important;
    margin: 0 auto;
  }

  .auth-left-area img {
    width: 100%;
  }
}

@media (max-width: 1800px) {
  .vi-innerArea .row {
    padding-right: 30px;
  }
}

@media (max-width: 1730px) {
  .report-main-section .dropdown {
    width: 140px;
  }

  .transaction-main-section.report-main-section .showdate {
    margin-top: 0;
  }

  .report-main-section .transaction-period-select .react-date-picker {
    width: 130px;
  }

  .report-main-section .text-end .cs-btn-primary {
    max-width: 110px;
  }

  /* .cs-grid.cs-array-row{
    flex-wrap: wrap;
    justify-content: flex-start;
  } */

  .cs-grid.cs-array-row {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: unset;
    flex-wrap: unset;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow: hidden;
    overflow-x: scroll;
    padding-bottom: 6px;
    gap: 0;
  }

  .cs-grid.cs-array-row::-webkit-scrollbar {
    visibility: hidden !important;
    height: 0 !important;
    width: 0 !important;
  }

  .cs-grid.cs-array-row .category-card {
    width: 100%;
    margin-right: 26px;
  }

  .cs-grid.cs-array-row .category-card:last-child {
    margin: 0;
  }

  .cs-grid.cs-array-row .category-card .card {
    margin-bottom: 0 !important;
    width: 270px;
  }
}

@media (max-width: 1699px) {

  .request-main-section .collaboration-data-table div[data-column-id="2"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-2-undefined {
    width: 160px !important;
  }

  .request-main-section .collaboration-data-table div[data-column-id="3"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-2-undefined {
    width: 230px !important;
  }

  .request-main-section .collaboration-data-table div[data-column-id="4"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-2-undefined {
    width: 180px;
    min-width: unset;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .request-main-section .collaboration-data-table div[data-column-id="5"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-3-undefined {
    width: 130px;
    min-width: unset;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .request-main-section .collaboration-data-table div[data-column-id="6"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-4-undefined {
    width: 80px;
    min-width: unset;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .fm-name span:last-child {
    max-width: 30vw;
  }

  .os-mac.browser-safari .apexcharts-legend {
    bottom: -2.5vh !important;
  }
}

@media (max-width: 1499px) {
  .pfs-preview-modal .cs-tabs .cs-stroke-default-tab {
    width: 140px;
    padding-inline: 14px;
  }

  .cs-pfs-right {
    width: 60%;
  }

  .category-cs-table-container .common-tooltip-margin {
    white-space: nowrap;
  }

  .vi-innerArea .row {
    padding-right: 70px;
  }

  .vi-innerArea button {
    margin-left: auto;
  }

  .retirementaccount-table td:nth-child(1),
  .retirementaccount-table td:nth-child(2) {
    white-space: nowrap;
  }

  .transaction-period-select .react-date-picker {
    width: 188px;
  }

  .accreditation-footer .col-lg-4 {
    width: 100%;
    margin-top: 16px;
  }

  .skeleton-common-table {
    width: 100%;
    max-width: 1499px;
    padding-bottom: 4px;
    overflow: hidden;
    overflow-x: auto;
  }

  .report-main-section .cs-qbo-card .card {
    padding: 14px;
    height: 80px;
  }

  .report-main-section .cs-qbo-card .card-icon {
    width: 44px;
    max-width: 44px;
    min-width: 44px;
    height: 44px;
  }

  .report-main-section .cs-qbo-card .default-semi-bold-h2 {
    font-size: 14px !important;
    line-height: 20px;
  }

  .report-main-section .cs-qbo-card .card-icon svg {
    width: 100%;
    height: 100%;
  }

  .report-main-section .cs-qbo-card .card .card-item {
    margin-left: 12px;
  }

  /* File management dropdown design start */
  .cs-lp-screen.file-management-dropdown {
    display: block;
  }

  .cs-lp-screen.file-management-dropdown a.dropdown-toggle {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--neutral-10) !important;
    -webkit-box-shadow: var(--box-shadow-one);
    box-shadow: var(--box-shadow-one);
    border-radius: 4px;
  }

  .cs-lp-screen.file-management-dropdown a.dropdown-toggle span {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .layout-row .layout-box .for-des {
    display: none;
  }

  .file-preview-modal .modal-title {
    max-width: 50vw;
  }

  .file-preview-modal .modal-body .table-preview-row {
    height: calc(100vh - 34vh);
  }

  /* File management dropdown design end */
  .os-mac.browser-safari .apexcharts-legend {
    bottom: 0 !important;
  }

  .error-image {
    margin-top: 30px;
    max-width: 70%;
  }
}

@media (max-width: 1420px) {

  .report-main-section .transaction-period-select .react-date-picker,
  .cs-report-period,
  .cs-report-period .dropdown {
    width: 100%;
  }

  .report-main-section .row .col-lg-5:first-child,
  .report-main-section .row .col-xl-auto:first-child {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    margin-bottom: 26px;
  }

  .report-main-section .align-items-center.row {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .report-main-section .row .col-lg-5 {
    width: auto;
  }

  .report-main-section .row .col-lg-2 {
    width: auto;
  }

  .cs-report-period {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cs-report-period .cs-common-add-dropdown,
  .report-main-section .showdate {
    width: 48%;
    margin: 0 !important;
  }

  .report-main-section .md-btn {
    width: 150px;
  }

  .qbo-select-row {
    margin-bottom: 16px;
  }
}

@media (max-width: 1366px) {

  .chart-container .pie-chart-container,
  .chart-container .line-chart-container {
    margin: 0;
  }

  .cs-tbody td:first-child,
  .cs-tbody td:nth-child(3) {
    white-space: nowrap;
  }

  .accreditation-main-section .cs-tabs .cs-stroke-default-tab {
    width: 130px;
  }

  .investor-type-dropdown {
    max-width: 458px;
  }

  .investor-type-row .col-lg-3 {
    width: 100%;
  }

  .accredited-tabbing-row .cs-tabs {
    width: 100%;
  }

  .accredited-tabbing-row .cs-tabs .cs-stroke-default-tab {
    width: 100%;
    max-width: 222px;
  }

  .transaction-main-section .cs-transaction-table .sc-hzhJZQ {
    width: 1400px;
    padding-bottom: 4px;
  }

  .transaction-search-bar .col-lg-8,
  .transaction-search-bar .col-lg-4 {
    width: 50%;
  }

  .transaction-search-bar .col-lg-8 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .transaction-search-bar .showdate {
    margin-left: 0;
    margin-top: 8px;
  }

  .transaction-period-select .react-date-picker {
    width: 180px;
    padding: 6px 20px;
  }

  .recharts-legend-wrapper .recharts-legend-item-text {
    width: 140px;
  }

  li.recharts-legend-item {
    margin-bottom: 8px;
  }

  .file-management-section .cs-breadcrumb span.default-light-body-text-s {
    max-width: 80px;
  }

  .apexcharts-datalabel-label {
    font-size: 8px !important;
    line-height: 14px !important;
  }

  .line-chart-container .chart-heading {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .category-card .card {
    padding: 16px;
  }

  .category-card .card .card-icon {
    padding: 14px;
  }

  .category-card .card .card-icon img {
    width: 35px;
    height: 35px;
  }

  .category-cards {
    padding-inline: 10px;
  }

  .category-cards .col-lg-4.col-md-4 {
    padding-inline: 6px;
  }

  .category-card .card .card-item {
    margin-left: 10px;
  }

  .card-item .default-semi-bold-h2.cs-neutral-100 {
    font-size: 16px;
  }

  .card-item .default-regular-h4.cs-neutral-90 {
    font-size: 14px;
    line-height: 20px;
  }

  .listing-request.commonTable {
    width: 100%;
    max-width: 1920px;
    /* height: 70vh; */
    padding-bottom: 4px;
    overflow: hidden;
    overflow-x: auto;
  }

  .vi-innerArea .col-xl-11.col-md-9 {
    width: 80%;
  }

  .vi-innerArea .col-xl-1.col-md-3 {
    width: 20%;
  }

  .assets-table-container {
    max-width: 992px;
    overflow-x: auto;
    padding-bottom: 4px;
  }

  .vi-innerArea .row {
    padding-right: 0;
  }

  .legend-check span:before {
    width: 16px;
    height: 16px;
  }

  .legend-check span:after {
    width: 6px;
    height: 6px;
    left: 5px;
  }

  .legend-check input[type="checkbox"]:checked+span:after,
  .legend-check.legend-check-active span:after {
    width: 8px;
    height: 8px;
    left: 4px;
  }

  .report-main-section .col-lg-3.col-md-3 {
    width: 50%;
  }

  .os-mac.browser-safari .apexcharts-legend {
    bottom: -6vh !important;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .for-mob {
    display: block;
  }

  .for-des {
    display: none;
  }

  .row {
    --bs-gutter-x: 1.5rem;
  }

  .cs-section-topbar {
    margin-bottom: 24px;
  }

  .cs-main-layout .cs-context {
    padding: 88px 12px 24px 12px;
  }

  .cs-data-table .rdt_Pagination {
    padding-top: 20px;
  }

  /* Choose plan, complete profile and add account start */
  .modal-body,
  .cs-xl-modal .modal-body {
    padding: 30px 26px;
  }

  .choose-plan-box .modal-body {
    padding: 30px 26px;
  }

  .cs-s-modal .modal-footer {
    padding: 30px 26px;
  }

  .modal-dialog {
    padding: 0 24px;
    margin: 0 auto;
  }

  .share-pfs-modal .modal-dialog {
    margin: 1.75rem auto;
  }

  .modal-dialog.cs-s-modal {
    padding: 0;
  }

  .cs-complete-profile .cs-avatar-md span,
  .cs-complete-profile .cs-avatar-lg span,
  .profile.cs-avatar-lg .cs-avatar-md span {
    height: 34px;
    width: 34px;
    padding: 8px;
  }

  .react-date-picker__button,
  .form-control.PhoneInput {
    padding: 0 !important;
  }

  .cs-accoutns-list .finance-data-container .finance-heading {
    padding-block: 8px;
    font-size: 14px;
    line-height: 20px;
    /* margin-top: 16px; */
  }

  .plain-footer.modal-footer {
    padding: 0 25px 30px !important;
  }

  .plain-footer.modal-footer {
    padding: 0 25px 30px !important;
  }

  .plain-footer.modal-footer .cs-modal-btn {
    display: block;
  }

  .plain-footer.modal-footer .cs-modal-btn button {
    max-width: 100%;
    margin-top: 16px;
  }

  .plain-footer.modal-footer .cs-modal-btn .default-regular-sub-heading-s {
    font-size: 12px;
    line-height: 16px;
  }

  /* Choose plan, complete profile and add account end */
  /* Toast CSS start */
  .cs-toast .Toastify__toast-container {
    width: 300px;
  }

  /* Toast CSS end */
  .cs-progress-bar {
    width: 100%;
    height: auto;
    margin-block: 14px 0;
  }

  .cs-context .pie-chart-container,
  .cs-context .line-chart-container {
    margin-inline: 14px;
  }

  /* Portfolio page CSS start */
  .pie-chart-total-networth {
    left: 50% !important;
  }

  .portfolio-cs-table-container .portfolio-data-table .sc-feUZmu {
    max-width: 992px;
  }

  .portfolio-cs-table-container .portfolio-data-table .sc-hzhJZQ {
    width: 1900px;
  }

  .portfolio-data-table div[data-column-id="1"],
  .portfolio-data-table.cs-data-table .rdt_TableBody #cell-1-undefined {
    max-width: 350px;
  }

  /* Portfolio page CSS end */

  /* transaction page CSS start */
  .transaction-search-bar .col-lg-8,
  .transaction-search-bar .col-lg-4 {
    width: 100%;
  }

  .transaction-search button.cs-btn-primary {
    width: 36px;
    height: 36px;
    margin-left: 8px;
  }

  .transaction-search button.cs-btn-primary span.for-mob {
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .transaction-search button.cs-btn-primary[disabled] {
    opacity: 1;
  }

  .transaction-search button.cs-btn-primary[disabled] svg path {
    fill: var(--neutral-60) !important;
  }

  .cs-search-bar .cs-form-group .form-control {
    font-size: 12px;
    height: 36px;
    padding-inline: 12px 30px;
  }

  .input-field-icon {
    width: 16px;
    height: 16px;
  }

  .transaction-period-select {
    display: block;
    width: 100%;
    margin-top: 16px;
  }

  .transaction-period-select .dropdown-item-transaction {
    width: 100%;
  }

  .transaction-search-bar .showdate {
    margin-top: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .dropdown-item-transaction .dropdown .dropdown-toggle {
    height: 36px;
  }

  .transaction-period-select .react-date-picker {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    padding: 9px 14px;
  }

  span.datepicker-icon {
    display: block;
    width: 16px;
    height: 16px;
  }

  span.datepicker-icon svg {
    width: 100%;
    height: 100%;
  }

  .react-date-picker__inputGroup {
    min-width: auto;
    padding-inline: 0 2px;
  }

  /* .amortization-main-listing .accordion-header .table td .mob-width,
  .amortization-main-listing .accordion-body .table td .mob-width {
    display: block;
    width: 100px;
  } */

  .amortizationSection .amortization-main-listing .accordion-body tr td:first-child span {
    text-align: left !important;
  }

  /* transaction page CSS end */
  .cs-main-layout .skeleton-for-mob {
    padding: 88px 24px 24px !important;
  }

  .cs-report-period {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .cs-report-period .cs-common-add-dropdown {
    margin-bottom: 12px !important;
  }

  .cs-report-period .cs-common-add-dropdown,
  .report-main-section .showdate {
    width: 100%;
  }

  /* File management start */
  .file-management-section .cs-section-topbar .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .file-management-section .cs-section-topbar .col:first-child {
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    margin-bottom: 10px;
  }

  .file-management-section .cs-section-topbar .col:last-child {
    width: 40%;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }

  .archive-page .col,
  .trash-page .col {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }

  .archive-page .col:last-child,
  .trash-page .col:last-child {
    display: none;
  }

  .archive-page .cs-trash-dropdown,
  .trash-page .cs-trash-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .archive-page .cs-trash-dropdown .dropdown,
  .trash-page .cs-trash-dropdown .dropdown {
    width: 100%;
    margin-right: 16px;
  }

  .archive-page .cs-trash-dropdown .for-mob,
  .trash-page .cs-trash-dropdown .for-mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .archive-page .cs-trash-dropdown .cs-lp-screen.file-management-dropdown,
  .trash-page .cs-trash-dropdown .cs-lp-screen.file-management-dropdown {
    margin: 0;
  }

  .archive-page .cs-trash-dropdown .cs-lp-screen.file-management-dropdown a.dropdown-toggle,
  .trash-page .cs-trash-dropdown .cs-lp-screen.file-management-dropdown a.dropdown-toggle {
    padding: 8px;
    border: 0;
  }

  .cs-common-add-dropdown .dropdown .dropdown-menu.show .dropdown-item {
    padding: 8px 14px;
  }

  .cs-lp-screen.file-management-dropdown a.dropdown-item {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .cs-trash {
    padding: 8px;
    margin-bottom: 16px;
  }

  .cs-trash h4 {
    text-align: left;
  }

  .file-management-modal.cs-md-modal .modal-header span svg {
    width: 12px;
    height: 12px;
  }

  .file-management-modal .cs-breadcrumb {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .file-management-modal .cs-breadcrumb span:last-child {
    cursor: auto;
  }

  .file-management-modal .cs-move-row .cs-folder-title {
    overflow: hidden;
  }

  .cs-storage {
    margin-top: 18px;
    margin-bottom: 12px;
  }

  .file-preview-modal .modal-dialog {
    max-width: 100%;
  }

  .file-preview-modal .modal-dialog div#pspdfkit-container {
    height: 70vh !important;
  }

  .file-preview-modal .modal-body iframe {
    height: 70vh !important;
  }

  /* File management  end */
  /* .apexcharts-canvas svg {
    width: auto !important;
  } */
}

@media (max-width: 767px) {
  .skeleton-common-table {
    width: 100%;
    max-width: 767px;
    padding-bottom: 4px;
    overflow: hidden;
    overflow-x: auto;
  }

  .qbo-select-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }

  .report-main-section .dropdown {
    width: 100%;
  }

  .report-main-section .row .col-lg-5 {
    width: 100%;
  }

  .qbo-select-row>div:not(.qbo-select-row > div:last-child) {
    margin: 0;
    margin-bottom: 19px;
  }

  .request-main-section .collaboration-data-table div[data-column-id="1"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-1-undefined,
  .request-main-section .collaboration-data-table div[data-column-id="3"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-3-undefined {
    min-width: 300px !important;
  }

  .request-main-section .collaboration-data-table div[data-column-id="2"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-2-undefined {
    min-width: 260px !important;
  }

  .request-main-section .collaboration-data-table div[data-column-id="4"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-4-undefined {
    min-width: 200px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }

  .request-main-section .collaboration-data-table div[data-column-id="5"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-5-undefined {
    min-width: 230px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }

  .request-main-section .collaboration-data-table div[data-column-id="6"],
  .request-main-section .collaboration-data-table .cs-data-table .rdt_TableBody #cell-6-undefined {
    min-width: 180px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }

  .cs-grid-view {
    gap: 14px;
    grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
  }

  .cs-grid-view .cs-box {
    max-height: 200px;
  }

  .file-management-dropdown {
    margin-left: 6px;
  }

  .file-info .dropdown-toggle {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .share-pfs-modal .modal-dialog {
    margin: 8px auto;
  }

  .share-pfs-modal .modal-dialog {
    height: calc(100% - 16px);
  }

  .other-attachment-container.business-attachment-container .col-md-6:last-child {
    height: calc(100vh - 336px) !important;
  }

  .business-attachment-container .cs-preview-body {
    max-height: calc(100vh - 336px) !important;
  }

  .cs-tbody.default-regular-sub-heading-xs td:first-child {
    white-space: normal;
  }

  .cs-accoutns-details .cs-tbody td:last-child {
    width: 130px;
  }
}

@media (max-width: 700px) {
  .cs-section-topbar .cs-tabs-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .accreditation-main-section .cs-tabs .cs-stroke-default-tab {
    width: 100%;
    margin-top: 24px;
  }

  .file-management-section .cs-section-topbar .col:last-child {
    width: 100%;
    margin-top: 20px;
  }

  .layout-row {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */

@media (max-width: 660px) {
  .upgrade-navbar .default-regular-sub-heading-xs {
    display: none;
  }

  /*===== Pie chart and line chart CSS start =====*/
  .cs-context .graph-pie-chart-main-container,
  .cs-context .graph-line-chart-main-container {
    height: 100% !important;
  }

  .pie-chart-total-networth,
  .liablities-main-section .pie-chart-total-networth {
    position: relative;
    left: 0 !important;
    -webkit-transform: unset !important;
    -ms-transform: unset !important;
    transform: unset !important;
  }

  .recharts-default-legend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .recharts-wrapper g.recharts-layer.recharts-pie {
    position: relative;
    -webkit-transform: translate(50%, 0) !important;
    -ms-transform: translate(50%, 0) !important;
    transform: translate(50%, 0) !important;
    width: 100% !important;
    height: auto !important;
  }

  .recharts-wrapper .recharts-surface {
    position: relative;
    width: 100% !important;
    text-align: center;
  }

  .recharts-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100% !important;
  }

  .asset-pie-chart .recharts-responsive-container {
    position: relative;
    left: -14px;
    width: 110% !important;
    height: 320px !important;
    min-width: 100% !important;
  }

  .asset-pie-chart .recharts-legend-wrapper {
    position: relative !important;
    top: -2px !important;
    right: 0 !important;
  }

  .recharts-legend-wrapper .recharts-surface {
    width: 8px !important;
    height: auto !important;
  }

  .chart-heading {
    text-align: center;
  }

  .recharts-default-legend li:last-child {
    margin-right: 0 !important;
  }

  .line-chart-container .chart-heading {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 18px;
  }

  .line-chart-container .recharts-legend-wrapper {
    width: 100% !important;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
  }

  .line-chart-container .recharts-responsive-container {
    height: 355px !important;
  }

  .line-chart-container .recharts-wrapper {
    top: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .assets-main-section .recharts-legend-wrapper .recharts-default-legend,
  .liablities-main-section .recharts-legend-wrapper .recharts-default-legend {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .assets-main-section .recharts-legend-wrapper .recharts-default-legend li,
  .liablities-main-section .recharts-legend-wrapper .recharts-default-legend li {
    width: 42%;
    margin-right: 20px !important;
    margin-bottom: 3px;
    padding-inline: 16px !important;
  }

  .go-next-page-div svg {
    width: 16px;
  }

  .category-line-chart-container .graph-line-chart-main-container,
  .skeleton-category-graph-box {
    height: 400px !important;
  }

  .portfolio-allocation-chart .recharts-legend-wrapper .recharts-default-legend li {
    display: block !important;
    width: auto;
  }

  .portfolio-allocation-chart .recharts-legend-wrapper .recharts-default-legend {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  /*===== Pie chart and line chart CSS end =====*/
  .accredited-tabbing-row .cs-tabs .cs-stroke-default-tab {
    max-width: 100%;
    padding-inline: 14px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0;
  }

  .accreditation-inner-section .cs-section-topbar {
    margin-bottom: 16px;
  }

  .dashboard-main-section .recharts-legend-wrapper .recharts-legend-item-text,
  .dashboard-main-section .pie-chart-container li.recharts-legend-item,
  .dashboard-main-section .recharts-legend-item-text {
    width: auto !important;
  }

  .dashboard-main-section .pie-chart-container li.recharts-legend-item {
    margin-right: 12px !important;
  }

  .file-management-section .row {
    height: 100%;
  }

  .cs-list-view,
  .cs-grid-view,
  .trash-page .cs-list-view,
  .trash-page .cs-grid-view,
  .archive-page .cs-list-view,
  .archive-page .cs-grid-view {
    height: 100%;
    margin-right: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .cs-grid-dropzone,
  .cs-dropzone.cs-grid-no-record {
    height: 100% !important;
  }

  .file-management-section .manage-file-container.no-records {
    height: 60vh;
  }

  .cs-grid-view {
    margin-right: -6px;
  }

  .file-management-modal .cs-modal-btn.modal-footer {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .file-management-modal .cs-modal-btn.modal-footer .btn.btn-primary {
    margin: 0 0 0 16px !important;
  }

  .cs-grid.cs-array-row .category-card {
    margin-right: 16px;
  }

  .cs-section-topbar-row {
    justify-content: space-between;
  }

  .chart-collapse-btn {
    margin: 0;
    width: 30px;
    height: 30px;
  }

}

/* @media (max-width: 660px),
(max-height: 640px) {

  .cs-grid-view,
  .trash-page .cs-grid-view,
  .archive-page .cs-grid-view,
  .cs-list-view,
  .trash-page .cs-list-view,
  .archive-page .cs-list-view {
    min-height: 500px;
    height: calc(100vh - 350px);
  }
} */

@media (max-width: 575px) {
  .row {
    --bs-gutter-x: 1rem;
  }

  .auth-mfa-options {
    margin-right: 8px;
  }

  .cs-menu-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .cs-attached-doc {
    padding-inline: 12px;
  }

  .cs-attached-doc span,
  .select-pfs-file-row .select-pfs-box span {
    width: 20px;
    height: 20px;
  }

  .select-pfs-file-row .select-pfs-box {
    padding-inline: 12px;
  }

  .select-pfs-file-row .select-pfs-box span svg {
    width: 100%;
    height: 100%;
  }

  .category-cards .col-lg-4.col-md-4 {
    padding-inline: inherit;
  }

  .default-semi-bold-h1 {
    font-size: 20px;
    line-height: 32px;
  }

  .default-regular-h1 {
    font-size: 20px;
    line-height: 32px;
  }

  .default-semi-bold-h2 {
    font-size: 16px;
    line-height: 26px;
  }

  .default-regular-h2 {
    font-size: 16px;
    line-height: 26px;
  }

  .default-semi-bold-h3,
  .default-light-body-text-xl {
    font-size: 14px;
    line-height: 22px;
  }

  .default-regular-h3 {
    font-size: 14px;
    line-height: 22px;
  }

  /* .default-semi-bold-h4 {
    font-size: 12px;
    line-height: 18px;
  } */
  .add-account-head .cs-bank-text {
    margin-bottom: 12px;
  }

  .default-regular-h4 {
    font-size: 12px;
    line-height: 18px;
  }

  .default-semi-bold-h5 {
    font-size: 12px;
    line-height: 16px;
  }

  .cap-dash-main-modal h4 {
    margin-bottom: 8px;
  }

  .default-semi-bold-h6 {
    font-size: 10px;
    line-height: 16px;
  }

  .default-regular-h6 {
    font-size: 10px;
    line-height: 16px;
  }

  .default-medium-sub-heading-xl {
    font-size: 16px;
    line-height: 24px;
  }

  .default-regular-sub-heading-xl {
    font-size: 16px;
    line-height: 24px;
  }

  .cs-thead th.default-medium-sub-heading-xs,
  tbody.cs-tbody.default-regular-sub-heading-xs td {
    font-size: 12px;
    line-height: 16px;
  }

  .cs-bank-images .cs-common-logo {
    width: 86px;
    height: 86px;
  }

  .cs-main-layout .cs-main-area {
    padding: 0px !important;
  }

  .cs-main-sidebar.is-open {
    min-width: 250px;
    margin-left: 0;
    -webkit-transition: all 0.5s, height 0s;
    -o-transition: all 0.5s, height 0s;
    transition: all 0.5s, height 0s;
    z-index: 5;
  }

  .cs-progress-bar .progress {
    height: 20px;
  }

  .cs-toast .Toastify__toast-container {
    width: 280px;
    left: auto;
    margin-right: 0;
    margin-top: 54px;
  }

  /* ============================= */
  /* START - Common Modal  */
  /* ============================= */

  .cs-s-modal,
  .cs-md-modal,
  .cs-lg-modal,
  .cs-xl-modal,
  .modal-dialog.cs-s-modal {
    max-width: 100%;
    margin: 0 auto;
    padding: 24px;
  }

  .cs-md-modal .modal-header,
  .cs-lg-modal .modal-header {
    padding: 16px;
    text-align: center;
  }

  .cs-md-modal .modal-body,
  .cs-lg-modal .modal-body,
  .cs-add-acc-modal {
    padding: 24px 16px;
  }

  .cs-md-modal .modal-footer,
  .cs-lg-modal .modal-footer {
    padding: 0px 16px 24px 16px;
    border: none;
  }

  .cs-divider {
    width: 100%;
  }

  .cs-s-modal .modal-header {
    padding: 16px;
    text-align: center;
  }

  /* ============================= */
  /* END - Common Modal  */
  /* ============================= */

  /* ============================= */
  /* START - Common Buttons  */
  /* ============================= */
  .cs-btn-primary,
  .cs-btn-secondary,
  .cs-btn-tertiary,
  .cs-btn-danger,
  .cs-btn-icon-primary,
  .cs-btn-icon-secondary,
  .cs-btn-icon-tertiary,
  .cs-btn-primary:disabled,
  .cs-btn-icon-primary:disabled,
  .cs-btn-danger:disabled {
    padding: 6px 8px !important;
    border-width: 1px !important;
  }

  .cs-btn-primary:disabled:focus,
  .cs-btn-icon-primary:disabled:focus,
  .cs-btn-danger:disabled:focus,
  .cs-btn-primary:disabled:focus-visible,
  .cs-btn-icon-primary:disabled:focus-visible,
  .cs-btn-danger:disabled:focus-visible,
  .cs-btn-primary:disabled:active,
  .cs-btn-icon-primary:disabled:active,
  .cs-btn-danger:disabled:active {
    padding: 6px 8px !important;
    border-width: 1px !important;
  }

  /* ============================= */
  /* END - Common Buttons  */
  /* ============================= */
  .cs-form-group {
    margin-bottom: 16px;
  }

  .cs-main-layout .cs-context {
    padding: 60px 4px 24px 4px !important;
  }

  .cs-data-table .rdt_Table .rdt_TableHeadRow .rdt_TableCol,
  .cs-data-table .rdt_TableCell {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
  }

  .cs-main-layout .cs-main-area .cs-section-topbar,
  .cs-main-layout .cs-main-area .accreditation-inner-section .cs-section-topbar {
    margin-bottom: 16px;
  }

  .accreditation-inner-section .cs-section-topbar .cs-title {
    margin-bottom: 0;
  }

  .cs-modal-btn button {
    line-height: 20px;
  }

  .cs-s-modal .modal-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body,
  .cs-xl-modal .modal-body {
    padding: 24px 4px;
  }

  .pfs-preview-modal.modal-body {
    padding: 0 !important;
  }

  .share-pfs-modal .modal-footer {
    padding-inline: 16px !important;
  }

  .pfs-second-modal.modal-footer {
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .pfs-second-modal.modal-footer .pfs-second-modal-check-box {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .pfs-second-modal-check-box .cs-form-check-box {
    margin-top: 0 !important;
  }

  .choose-plan-box .modal-body {
    padding: 24px 4px !important;
  }

  .cs-avatar-lg,
  .profile.cs-avatar-lg {
    width: 72px;
    height: 72px;
  }

  .cs-complete-profile .profile span.cs-icon-badge-primary,
  .profile.cs-avatar-lg .cs-avatar-md span {
    width: 24px !important;
    height: 24px !important;
    bottom: 0;
    right: 0px;
    padding: 4px;
  }

  .cs-complete-profile.cs-add-account .modal-header {
    padding-inline: 14px;
  }

  .profile.cs-avatar-lg .cs-avatar-md span svg {
    width: 14px;
    height: 14px;
  }

  .profile {
    margin-bottom: 0;
  }

  /* ======================= */
  /* START - Common Input fields */
  /* ======================= */
  .cs-form-group .form-control {
    height: 38px;
    padding: 8px 16px;
  }

  .input-field-icon {
    right: 10px;
  }

  .input-field-icon svg {
    width: 16px;
    height: 16px;
  }

  .cs-data-table .rdt_Table div.cTsUPQ div {
    font-size: 14px;
    line-height: 20px;
    padding: 12px !important;
  }

  .cs-breadcrumb a,
  .cs-breadcrumb span:last-child {
    display: inline-block;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  .individual-assets-savingaccount .cs-breadcrumb a {
    max-width: 100%;
    -o-text-overflow: unset;
    text-overflow: unset;
    overflow: visible;
  }

  .individual-assets-savingaccount .cs-breadcrumb span:last-child {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  .file-encryption-page .cs-form-group .form-control {
    padding-inline: 16px 30px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  /* ======================= */
  /* END - Common Input fields */
  /* ======================= */

  /* Amortization Page CSS start */
  .cs-table> :not(caption)>*>*,
  .amortization-main-listing .accordion-body .table td {
    padding: 8px 16px;
  }

  .amortization-main-listing .accordion-header .table td,
  .amortization-main-listing .accordion-body .table td {
    height: 36px;
  }

  /* Amortization Page CSS end */

  /* Toast CSS start */
  .cs-toast .Toastify__toast-body {
    margin-right: 10px;
    font-size: 10px;
    line-height: 14px;
  }

  .cs-toast .Toastify__close-button>svg {
    margin-top: 0;
  }

  .cs-toast .Toastify__toast-icon {
    width: 14px;
  }

  .cs-toast .Toastify__toast {
    padding-block: 10px;
  }

  /* Toast CSS end */
  .cs-pfs-menu {
    width: 260px;
  }

  .radio-button {
    width: 16px;
    height: 16px;
  }

  .cs-common-no-record {
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
  }

  .cs-accoutns-list .cs-common-no-record span {
    font-size: 12px;
    line-height: 16px;
  }

  .cs-main-layout .cs-main-area .category-card .card {
    margin-bottom: 16px;
  }

  .cs-section-topbar .cs-title {
    display: block;
    margin-block: 6px 16px;
  }

  .report-main-section .cs-section-topbar .cs-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cs-section-topbar .cs-title .cs-view-pfs-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cs-section-topbar .cs-title .cs-view-pfs-filter .dropdown-toggle {
    height: 32px;
    margin-left: 12px;
  }

  .cs-title h1 {
    margin-bottom: 12px;
  }

  .cs-table-menu-icon {
    width: 20px;
    height: 20px;
  }

  .cs-table-menu-icon svg {
    width: 14px;
  }

  .vi-innerArea .col-xl-11.col-md-9 {
    width: 100%;
  }

  .vi-innerArea .col-xl-1.col-md-3 {
    width: 100%;
  }

  .accreditation-inner-section .default-semi-bold-h3,
  .accreditation-inner-section .default-medium-sub-heading-m,
  .accreditation-inner-section .default-regular-sub-heading-m {
    font-size: 14px;
    line-height: 20px;
  }

  .accreditation-inner-section .default-regular-body-text-l,
  .accreditation-inner-section .default-regular-h4,
  .accreditation-inner-section .default-regular-sub-heading-xs,
  .cs-files-names .default-regular-sub-heading-xs {
    font-size: 12px;
    line-height: 16px;
  }

  .row-spacing ul {
    padding-left: 16px;
  }

  .transaction-main-section .cs-transaction-table .sc-hzhJZQ {
    width: 991px;
  }

  .cs-transaction-table #cell-1-undefined,
  .cs-transaction-table .rdt_TableHead .rdt_TableHeadRow div:first-child {
    max-width: 140px;
  }

  .cs-transaction-table #cell-3-undefined,
  .cs-transaction-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .cs-transaction-table #cell-4-undefined,
  .cs-transaction-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .cs-transaction-table #cell-5-undefined,
  .cs-transaction-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    max-width: 180px;
  }

  .cs-transaction-table .dollar-value-gap {
    padding-right: 30px;
  }

  .cs-data-table .hbgCLW {
    min-height: auto;
  }

  .for-mob .cs-title,
  .for-mob .cs-title h1,
  .accreditation-main-section .cs-title {
    margin-bottom: 0;
  }

  .cs-section-topbar .cs-tabs-flex {
    margin-top: 16px;
  }

  .cs-category-main-section .chart-heading {
    padding-inline: 16px;
  }

  .cs-category-main-section .default-semi-bold-h2 {
    font-size: 16px;
    line-height: 20px;
  }

  .chart-heading p.default-regular-body-text-s.cs-neutral-70 {
    text-align: right;
  }

  .settings-main-section .cs-title {
    margin-bottom: 0;
  }

  .settings-main-section .cs-section-topbar {
    margin-bottom: 0;
  }

  .offcanvas.offcanvas-end {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }

  .finance-tbody .dollar-value-gap {
    width: auto;
  }

  .accreditation-main-section .cs-tabs .cs-stroke-default-tab {
    padding-inline: 14px;
    margin-block: 0 14px;
  }

  .cs-tabs-flex .default-regular-h5.cs-btn-icon-primary.btn.btn-primary {
    margin-bottom: 12px;
  }

  /* Start custom-pagination */
  .cs-custom-pagination {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cs-custom-pagination span.default-light-body-text-m.cs-neutral-80 {
    display: none;
  }

  .cs-pagination-arrow {
    margin-top: -24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: center;
    width: 100%;
  }

  .default-light-body-text-m.cs-neutral-80 {
    display: none;
  }

  /* End custom-pagination */
  #connect-your-institutions.cs-lg-modal .modal-body .realestate-form .cs-form-group:last-child {
    margin-bottom: 16px !important;
  }

  .cs-common-add-dropdown .dropdown .dropdown-menu.show {
    max-height: 230px;
  }

  .legend-row .legend-check {
    margin-right: 6px;
  }

  .legend-check span:before {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  .legend-check span:after {
    width: 6px;
    height: 6px;
    left: 4px;
  }

  .legend-check input[type="checkbox"]:checked+span:after,
  .legend-check.legend-check-active span:after {
    width: 8px;
    height: 8px;
    left: 3px;
  }

  .legend-check input[type="checkbox"]+span {
    font-size: 10px;
    line-height: 14px;
  }

  .graph-line-chart-main-container .legend-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .graph-line-chart-main-container .legend-check-container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 6px;
  }

  .cs-main-layout .skeleton-for-mob {
    padding: 60px 16px 24px 16px !important;
  }

  .recharts-wrapper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .cs-main-layout .cs-main-area .assets-main-section .line-chart-container,
  .cs-main-layout .cs-main-area .liablities-main-section .line-chart-container {
    padding-left: 6px;
  }

  .cs-main-layout .category-line-chart-container .graph-line-chart-main-container {
    padding-left: 0;
  }

  .cs-qbo-total th {
    padding: 0 12px;
  }

  .cs-qbo-body tr td {
    padding: 8px 12px;
  }

  .cs-qbo-container tr>td .cs-qbo-title {
    width: 150px;
  }

  .cs-qbo-body.default-light-body-text-l,
  .cs-qbo-body .default-regular-body-text-l,
  .cs-qbo-body .default-light-body-text-l {
    font-size: 12px;
    line-height: 18px;
  }

  .report-canvas h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .report-canvas {
    padding-inline: 24px;
  }

  .report-main-section .cs-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .report-main-section .cs-title h1 {
    margin-bottom: 0;
  }

  .report-main-section .col-lg-3.col-md-3 {
    width: 100%;
  }

  /* File management start */

  .cs-lp-screen.file-management-dropdown {
    margin-right: 10px;
  }

  .file-management-section .cs-lp-screen.file-management-dropdown a.dropdown-toggle,
  .file-management-section .layout-btn {
    width: 36px;
    height: 36px;
    padding: 8px;
    -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  }

  .layout-btn span {
    width: 100%;
  }

  .file-management-section .file-management-btn .dropdown .dropdown-toggle {
    width: 100%;
    height: auto;
  }

  .file-management-dropdown .dropdown-item {
    padding: 8px 14px;
  }

  .file-management-dropdown .dropdown-menu,
  .file-info.file-management-dropdown .dropdown-menu.show {
    width: 180px;
  }

  .file-management-dropdown .dropdown-item span {
    margin-right: 8px;
  }

  .fm-name span:last-child {
    max-width: 46vw;
  }

  .archive-page .cs-trash-dropdown .cs-lp-screen.file-management-dropdown a.dropdown-toggle,
  .trash-page .cs-trash-dropdown .cs-lp-screen.file-management-dropdown a.dropdown-toggle {
    padding: 7px;
  }

  .sorting-dropdown .dropdown-toggle,
  .archive-page .cs-trash-dropdown .dropdown-toggle,
  .trash-page .cs-trash-dropdown .dropdown-toggle {
    height: 36px;
    padding: 6px 10px;
  }

  .layout-row .cs-btn-icon-primary {
    width: 36px;
    height: 36px;
    font-size: 0;
  }

  .layout-row .cs-btn-icon-primary span.cs-neutral-10 {
    margin: 0;
  }

  .cs-storage {
    max-width: 100%;
  }

  .cs-grid-view {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  .file-preview {
    height: 100px;
  }

  .file-preview span {
    width: 60px;
    height: 60px;
  }

  .file-preview span svg {
    width: 100%;
    height: 100%;
  }

  .cs-trash span.cs-primary.default-regular-h5 {
    margin-right: 0;
  }

  .cs-trash h4 .cs-primary svg {
    width: 100%;
    height: 100%;
  }

  .cs-trash h4 .cs-primary {
    width: 20px;
    height: 20px;
  }

  .modal-header .cs-close-btn {
    right: 16px;
  }

  .file-management-modal .cs-move-row {
    padding: 14px 14px;
  }

  .file-management-modal .cs-move-container span.cs-arrow {
    width: 22px;
    height: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    margin-left: 6px;
  }

  .file-management-modal .cs-move-container span.cs-arrow svg {
    width: 100%;
    height: 100%;
  }

  .file-privew-modal .modal-title {
    max-width: 200px;
  }

  .manage-files .modal-footer {
    padding-top: 24px !important;
  }

  .sorting-dropdown .sorting-icons {
    width: 36px;
    height: 36px;
  }

  .sorting-dropdown .sorting-icons svg {
    width: 18px;
    min-width: 18px;
    max-width: 18px;
    height: 18px;
    min-height: 18px;
    max-height: 18px;
  }

  .sorting-dropdown .dropdown {
    width: 100%;
    max-width: 100%;
  }

  .archive-page .sorting-dropdown .dropdown,
  .trash-page .sorting-dropdown .dropdown {
    margin-right: 0;
  }

  .archive-page .cs-trash-dropdown,
  .trash-page .cs-trash-dropdown {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
  }

  .archive-page .cs-trash-dropdown .dropdown,
  .trash-page .cs-trash-dropdown .dropdown {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    max-width: 100%;
    margin-right: 0;
  }

  .archive-page .cs-trash-dropdown .for-mob,
  .trash-page .cs-trash-dropdown .for-mob {
    display: block;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .sorting-dropdown {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .manage-files span.download-filename.cs-neutral-100 {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 140px;
  }

  /* File management end */
  .cs-common-no-record,
  .cs-data-table .sc-iHGNWf,
  .cs-data-table .sc-hmdnzv {
    font-size: 12px;
    line-height: 16px;
  }

  .file-preview-modal .modal-body .table-preview-row {
    height: calc(100vh - 40vh);
  }

  .cs-qbo-modal.cap-dash-main-modal .qbo-btn {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-right: 16px;
    padding-left: 16px;
  }

  .cs-qbo-modal.cap-dash-main-modal .qbo-btn .cs-btn-secondary {
    width: 223px;
    max-width: 100%;
  }

  .cs-qbo-modal.cap-dash-main-modal .cs-md-modal .modal-header {
    padding-top: 0;
    padding-inline: 0;
  }

  /* -----------------New Pie Chart Start--------------- */
  .new-pie-chart,
  .line-chart-container {
    height: 480px;
  }

  .apexcharts-canvas {
    height: 410px !important;
  }

  /* .apexcharts-canvas svg {
    width: 100% !important;
  } */

  /* -----------------New Pie Chart End--------------- */
  .cs-modal-btn .cs-btn-primary .cs-common-spinner {
    top: 4px !important;
  }

  .favicon-small .advanced-cropper-boundary.advanced-cropper__boundary,
  .favicon-Avatar .advanced-cropper-boundary.advanced-cropper__boundary {
    height: 89vw;
  }
}

@media (max-width: 425px) {
  .capsync-logo {
    width: 166px;
  }

  .card-popular-plan .popular-plan span:first-child {
    font-size: 12px;
    line-height: 18px;
    padding-inline: 12px;
  }

  .cs-subscription-section .month-years-plan {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .cs-subscription-section .month-years-plan label:last-child {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 10px;
  }

  .plaid-account-data .cs-accoutns-details .finance-data-container {
    margin-top: 0 !important;
  }
}

@media (max-width: 360px) {
  /* ============================= */
  /* START - Common Modal  */
  /* ============================= */

  .cs-md-modal,
  .cs-lg-modal {
    max-width: 100%;
  }

  .cs-md-modal .modal-header,
  .cs-lg-modal .modal-header {
    padding: 16px;
  }

  .cs-md-modal .modal-body,
  .cs-lg-modal .modal-body {
    padding: 24px 16px;
  }

  .cs-md-modal .modal-footer,
  .cs-lg-modal .modal-footer {
    padding: 0px 16px 24px 16px;
    border: none;
  }

  .cs-s-modal,
  .cs-md-modal,
  .cs-lg-modal,
  .cs-xl-modal,
  .modal-dialog.cs-s-modal {
    padding: 24px 12px;
  }

  .cs-modal-btn.wm-req-shareicon-viewpdf button {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .cs-modal-btn.wm-req-shareicon-viewpdf button:last-child {
    margin-top: 12px;
  }

  .dashboard-main-section .pie-chart-container li.recharts-legend-item {
    margin-right: 12px !important;
  }

  /* ============================= */
  /* END - Common Modal  */
  /* ============================= */
}

/* *******External******** */
@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */

@media (max-width: 1899px) and (max-height: 1920px) {
  .category-card .card {
    margin-bottom: 22px;
    padding-block: 14px;
  }

  .cs-section-topbar {
    margin-bottom: 20px;
  }

  .category-card .card .card-icon {
    padding: 10px;
  }

  .category-card .card .card-icon img {
    width: 30px;
    height: 30px;
  }

  .card-item .default-semi-bold-h2 {
    font-size: 16px;
    line-height: 24px;
  }

  .chart-container {
    margin-bottom: 20px !important;
  }

  .chart-container {
    margin-bottom: 20px !important;
  }

  .cs-main-layout .cs-context {
    padding-top: 90px;
  }

  .cs-context .dashboard-main-section .graph-pie-chart-main-container,
  .cs-context .dashboard-main-section .graph-line-chart-main-container {
    height: 360px !important;
  }

  .cs-context .dashboard-main-section .graph-pie-chart-main-container,
  .cs-context .dashboard-main-section .graph-line-chart-main-container {
    height: 360px !important;
  }
}

@media (max-width: 1199px) and (max-height: 767px) {
  .offcanvas-body {
    height: auto;
  }

  .mob-scroller {
    margin-right: -4px;
    padding-right: 4px;
    overflow: hidden;
    overflow-y: auto;
  }
}