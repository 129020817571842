.settings-tab-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: var(--neutral-10);
  border-bottom: 1px solid var(--neutral-30);
  border-radius: 4px 4px 0 0;
}

.billing-history
  .cs-data-table
  .rdt_TableHeadRow
  [data-column-id="2"].rdt_TableCol,
.billing-history
  .cs-data-table
  .rdt_TableBody
  [data-column-id="2"].rdt_TableCell {
  min-width: 500px;
}
.settings-main-section
  .mobile-billing-history
  .cs-data-table
  .rdt_TableHeadRow
  [data-column-id="5"].rdt_TableCol,
.settings-main-section
  .mobile-billing-history
  .cs-data-table
  .rdt_TableBody
  [data-column-id="5"].rdt_TableCell,
.settings-main-section
  .billing-history
  .cs-data-table
  .rdt_TableHeadRow
  [data-column-id="5"].rdt_TableCol,
.settings-main-section
  .billing-history
  .cs-data-table
  .rdt_TableBody
  [data-column-id="5"].rdt_TableCell {
  min-width: 150px;
}
.settings-main-section
  .cs-data-table
  .rdt_TableBody
  [data-column-id="3"].rdt_TableCell {
  text-transform: capitalize;
}
.billing-history
  .cs-data-table
  .rdt_TableHeadRow
  [data-column-id="4"].rdt_TableCol,
.billing-history
  .cs-data-table
  .rdt_TableBody
  [data-column-id="4"].rdt_TableCell,
.mobile-billing-history
  .cs-data-table
  .rdt_TableHeadRow
  [data-column-id="4"].rdt_TableCol,
.mobile-billing-history
  .cs-data-table
  .rdt_TableBody
  [data-column-id="4"].rdt_TableCell {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
}
.cs-data-table
  .rdt_Table
  .rdt_TableHeadRow
  [data-column-id="4"].rdt_TableCol
  .rdt_TableCol_Sortable
  div {
  margin: 0;
}
.billing-history
  .cs-data-table
  .rdt_TableHeadRow
  [data-column-id="4"].rdt_TableCol
  .rdt_TableCol_Sortable,
.billing-history .cs-data-table .amount-dollar-width,
.mobile-billing-history
  .cs-data-table
  .rdt_TableHeadRow
  [data-column-id="4"].rdt_TableCol
  .rdt_TableCol_Sortable,
.mobile-billing-history .cs-data-table .amount-dollar-width {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  padding-right: 20px;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .cs-tabs .cs-stroke-bottom-tab {
    padding: 16px;
  }

  .cs-main-layout .settings-main-section .settings-body .settings-tab-btn {
    padding: 0 24px;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  /* **********settings Mobile Tab / Button dropdown********************** */
  .cs-main-layout .settings-tab-btn {
    display: none;
  }

  .cs-main-layout .setting-mobile-dropdown {
    display: block;
    margin-bottom: 24px;
  }

  .settings-main-section .setting-mobile-dropdown .dropdown .dropdown-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 16px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--neutral-40);
    background: var(--neutral-10);
    color: var(--neutral-100);
    width: 100%;
  }

  .settings-main-section
    .setting-mobile-dropdown
    .dropdown
    .dropdown-menu.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 8px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 4px;
    background: var(--neutral-10);
    -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
    border: none;
  }

  .settings-main-section
    .setting-mobile-dropdown
    .dropdown
    .dropdown-menu.show
    .dropdown-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--neutral-10);
  }

  .settings-main-section
    .setting-mobile-dropdown
    .dropdown
    .dropdown-menu.show
    .dropdown-item:hover {
    color: var(--neutral-90);
    background: var(--primary-surface);
  }

  .settings-main-section
    .setting-mobile-dropdown
    .dropdown
    .dropdown-menu.show
    .dropdown-item.active,
  .settings-main-section
    .setting-mobile-dropdown
    .dropdown
    .dropdown-menu.show
    .dropdown-item.active
    .dropdown-item:active,
  .settings-main-section
    .setting-mobile-dropdown
    .dropdown
    .dropdown-menu.show
    .dropdown-item.active
    .dropdown-item:focus-visible {
    background: var(--primary-surface);
    color: var(--primary-main);
  }

  .cs-main-layout .settings-main-section .card {
    border: none;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
