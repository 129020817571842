/* ================== */
/* Start -subscription CSS */
/* ================== */
.cs-xl-modal {
  max-width: 912px;
}

.choose-plan-box .modal-content {
  border-radius: 4px;
  border: none;
}

.cs-xl-modal .modal-body {
  padding: 40px 56px;
}

.cs-common-modal-overlay:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--neutral-100);
  opacity: 0.5;
  pointer-events: none;
}

.cs-xl-modall .modal-content {
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-popup);
  box-shadow: var(--box-shadow-popup);
  border: none;
}

.cs-xl-modal .modal-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 32px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--neutral-20);
}

.cs-xl-modal .modal-title {
  color: var(--neutral-100);
  line-height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-xl-modal .modal-footer {
  padding: 0px 32px 24px 32px;
  border: none;
}

/* ---------------------------------------------------------- */
.cs-subscription-section .head {
  text-align: center;
  width: 100%;
}

.cs-subscription-section .month-years-plan {
  margin: 24px 0px 40px 0px;
}

.cs-subscription-section .month-years-plan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-subscription-section .month-years-plan label {
  margin-right: 24px;
}

.cs-subscription-section .month-years-plan label:last-child {
  margin-right: 0px;
  margin-top: 0;
}

.cs-subscription-section .cs-switch-checkbox {
  display: none;
}

.cs-subscription-section .cs-switch-label:active .switch-button {
  width: 26px;
}

.cs-subscription-section .switch-button {
  width: 26px;
  height: 26px;
}

.cs-subscription-section .cs-switch-label {
  width: 60px;
  height: 34px;
}

.cs-subscription-section .subscript-Planarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-subscription-section .subscript-Planarea .card-simple-plan.card {
  margin-right: 32px;
}

.cs-subscription-section .subscript-Planarea .card-popular-plan.card,
.cs-subscription-section .subscript-Planarea .card-simple-plan.card {
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 4px;
}

.choose-plan-box .subscription-plan-cards .card {
  width: 100%;
}

.cs-scroller .credit-change-method:last-child {
  margin-bottom: 0;
}

.choose-plan-box .container-fluid {
  padding-inline: 10px;
}

.choose-plan-box .modal-body {
  padding: 40px 46px;
}

.cs-subscription-section .card-plan-amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  margin-bottom: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-subscription-section .card-text hr {
  margin: 24px 0px;
}

.cs-subscription-section .card-specification li {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-subscription-section .subscript-Planarea .card-plan.card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
}

.card-plan.popular-plan.card-body {
  background-color: var(--primary-main);
}

span.default-regular-h4.cs-neutral-60 {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}

.cs-subscription-section .cs-card .default-semi-bold-h3 {
  color: var(--neutral-100);
}

.cs-subscription-section label.disable-save-plan-offer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--neutral-40);
}

.cs-subscription-section label.save-plan-offer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--primary-main);
}

.cs-subscription-section .sub-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.cs-subscription-section .card-body {
  padding: 24px;
}

.cs-subscription-section .subscript-Planarea button {
  margin-top: 32px;
}

/* ----------------------popular-plan------------------------------ */
.popular-plan button.cs-btn-primary.btn.btn-primary {
  background: var(--neutral-10) !important;
  border-color: var(--neutral-10) !important;
  color: var(--primary-main) !important;
}

.popular-plan .default-regular-h4 .cs-neutral-60,
.popular-plan h2.default-semi-bold-h1.cs-neutral-100,
.popular-plan .card-specification,
.popular-plan span.default-regular-h4.cs-neutral-60,
.popular-plan span.popularPlanTag.default-semi-bold-h1,
.popular-plan p.default-regular-sub-heading-m {
  color: var(--neutral-10);
}

.cs-subscription-section span.popular-plan-tag {
  position: absolute;
  right: 35px;
  top: -16px;
  background: var(--neutral-10);
  padding: 4px 16px;
  -webkit-box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 21px;
  color: var(--primary-main);
}

.cs-subscription-section span.popular-plantag-hide {
  display: none;
}

.cs-subscription-section .popular-plan .card-text hr {
  color: var(--neutral-20);
}

/* ----------------------- secound modal----------------------------------- */
.payment-types {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.payment-method-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 8px;
}

.cs-md-modal .payment-method-card p {
  margin-bottom: 8px;
}

.payment-types .card-bank-details {
  margin-bottom: 16px;
}

.bank-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 24px;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  cursor: pointer;
}

.bank-details:hover {
  border-color: var(--primary-main);
}

.bank-details:active,
.payment-active.bank-details {
  border-color: var(--primary-main);
  background-color: var(--primary-surface);
}

.payment-method-card span.default-regular-h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.payment-method-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
}

.payment-method-buttons button.cs-btn-primary {
  margin-left: 16px;
}

.payment-types .bank-details span:first-child {
  margin-right: 16px;
}

.card-modal-head {
  margin: 0 auto;
}

.bank-card-detials {
  margin-bottom: 0px;
}

.cs-subscription-section .sub-head p {
  margin-top: 8px;
}

/* ------------------complate profile popup------------------------ */
.cs-complete-profile .cs-form-dropdown .dropdown-toggle {
  border: none;
  padding: 0px;
}

.cs-complete-profile .cs-avatar-md,
.cs-complete-profile .cs-avatar-lg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  position: relative;
  border: 0;
}

.cs-complete-profile .cs-avatar-md span,
.cs-complete-profile .cs-avatar-lg span {
  position: absolute;
  right: 20px;
}

.cs-complete-profile .cs-avatar-md img,
.cs-complete-profile .cs-avatar-lg img {
  border-radius: 100%;
}

.personal-profile {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 24px 0px;
}

.cs-complete-profile p {
  text-align: center;
}

.cs-complete-profile .profile-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/* ================== */
/* END - subscription CSS */
/* ================== */

.cs-subscription-section ul {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
}

.cs-subscription-section ul li {
  position: relative;
  margin-bottom: 16px;
}

.cs-subscription-section ul li:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  width: 20px;
  height: 12px;
  margin-right: 16px;
  background-image: url(../../../assets/images/other/check-icon-color.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.cs-subscription-section .card-popular-plan ul li {
  color: var(--neutral-20);
}

.cs-subscription-section ul li:last-child {
  margin-bottom: 0;
}

.cs-subscription-section .card-popular-plan ul li:before {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.cs-scroller.cs-setup-dropdown {
  max-height: 194px;
  overflow-y: auto;
}

.google-map.dropdown-item {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  padding-bottom: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.google-map.dropdown-item span {
  word-break: break-all;
  display: block;
  white-space: normal;
}

.google-map.dropdown-item img {
  width: 22px;
  height: 22px;
  margin-left: 14px;
}

.cs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-overlay:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--neutral-100);
  opacity: 0.6;
  pointer-events: none;
}

.cs-auth-form.auth-sign-up-scrollbar .signup-checkbox {
  display: block;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {}

@media (max-width: 1199px) {}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {

  .choose-plan-box .sub-head p.cs-neutral-90.default-regular-sub-heading-m,
  .choose-plan-box .month-years-plan label.default-regular-body-text-l.cs-neutral-80 {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .cs-subscription-section .month-years-plan label:last-child {
    margin-right: 0;
  }

  .card-plan-amount h2:first-child {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
  }

  .card-plan-summary h2:first-child {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }

  .cs-subscription-section .card-specification li {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .card-popular-plan .popular-plan span:first-child {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .payment-method {
    margin-bottom: 8px;
  }
}

@media (max-width: 767px) {
  .cs-subscription-section .subscript-Planarea {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cs-subscription-section .subscript-Planarea .card-simple-plan.card {
    margin: 0 0 32px 0;
  }

  .cs-subscription-section .subscript-Planarea .card-simple-plan.card:last-child {
    margin: 0;
  }

  .WM-choose-plan-modal .cs-subscription-section .subscript-Planarea .card-simple-plan.card {
    margin: 0;
  }

  .bank-card-detials {
    margin-bottom: 16px;
  }

}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .choose-plan-box {
    max-width: 100%;
    margin: 0 auto;
    padding: 24px;
  }

  .cs-md-modal .modal-header span svg {
    width: 20px;
    height: 20px;
  }

  .cs-subscription-section .card-body {
    padding: 24px 16px;
  }

  .sub-head h1.default-semi-bold-h1.cs-neutral-100 {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }

  .cs-subscription-section .month-years-plan {
    margin: 16px 0 !important;
  }

  .choose-plan-box .sub-head p.cs-neutral-90.default-regular-sub-heading-m {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .choose-plan-box .month-years-plan label.default-regular-body-text-l.cs-neutral-80 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cs-subscription-section .month-years-plan label {
    margin-right: 12px;
  }

  .cs-subscription-section .cs-switch-label {
    width: 40px;
    height: 22px;
  }

  .cs-subscription-section .switch-button {
    width: 15px;
    height: 15px;
  }

  .cs-subscription-section .cs-switch-label:active .switch-button {
    width: 15px;
  }

  .month-years-plan img {
    width: 64px;
  }

  .cs-subscription-section .card-plan-amount {
    margin-bottom: 16px;
  }

  .cs-subscription-section .card-text hr {
    margin: 16px 0px;
  }

  .cs-subscription-section .subscript-Planarea button {
    margin-top: 16px;
  }

  .cs-subscription-section .card-specification li {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cs-subscription-section span.popular-plan-tag {
    left: 50%;
    top: -12px;
    right: auto;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  .bank-card-detials.acc-number-field {
    margin-bottom: 0;
  }

  .cs-subscription-section .month-years-plan label {
    font-size: 14px;
  }

  .choose-plan-box .modal-body {
    position: relative;
  }

  .choose-plan-box .cs-modal-close,
  .WM-choose-plan-modal .cs-modal-close {
    position: absolute;
    right: 14px;
    top: 14px;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .choose-plan-box .cs-modal-close svg,
  .WM-choose-plan-modal .cs-modal-close svg {
    width: 100%;
    height: 100%;
  }

  .choose-plan-box .plan-content-area,
  .WM-choose-plan-modal .plan-content-area {
    padding-top: 20px;
  }
}

@media (max-width: 425px) {}

@media (max-width: 400px) {

  .settings-main-section .billing-subscription-page .container-fluid {
    padding: 0;
  }

  .cs-subscription-section .plan-content-area .plan-toggle {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

  }

  .cs-subscription-section .card-popular-plan .popular-plan span:first-child {
    font-size: 10px;
    line-height: 16px;
    padding-inline: 12px;
  }

  .cs-subscription-section ul li:before {
    width: 14px;
    height: 10px;
  }

  .save-plan-offer .default-semi-bold-h4,
  .disable-save-plan-offer .default-semi-bold-h4 {
    font-size: 12px;
  }

  .payment-method {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .payment-cards .text-button {
    margin-top: 0px;
  }
}

@media (max-width: 320px) {}

@media (max-width: 322px) {
  .payment-cards .text-button {
    margin-top: 8px;
  }
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */