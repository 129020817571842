.cap-dash-main-modal .modal-body .mortgages-on-real-estate-form .row:nth-child(3) .cs-form-group {
  margin-bottom: 24px !important;
}

/*=========================================================================================*/


/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {}


@media (max-width: 1199px) {}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */


/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {}


@media (max-width: 767px) {}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */


/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .cap-dash-main-modal .modal-body .mortgages-on-real-estate-form .row:nth-child(3) .cs-form-group {
    margin-bottom: 16px !important;
  }
}


@media (max-width: 425px) {}


@media (max-width: 360px) {}


@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */