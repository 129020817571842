.last-notes-field {
  margin-bottom: 0;
}

.pfs-container .category-cs-table-container {
  padding-bottom: 0;
}

.share-pfs-modal .pfs-preview-modal .pfs-container .category-cs-table-container {
  width: auto;
}

.share-pfs-modal .pfs-preview-modal .pfs-container .common-tooltip-margin {
  display: block;
}

.share-pfs-modal .pfs-preview-modal .pfs-container .other-action-td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.share-pfs-modal .pfs-preview-modal .pfs-container .cs-thead th,
.share-pfs-modal .pfs-preview-modal .pfs-container .cs-tbody td {
  border-right: 1px solid var(--neutral-50);
}

.share-pfs-modal .pfs-preview-modal .pfs-container .cs-thead th:last-child,
.share-pfs-modal .pfs-preview-modal .pfs-container .cs-tbody td:last-child {
  border-right: 0;
}

.share-pfs-modal .pfs-preview-modal .pfs-container .other-action-td .table-icons-container {
  margin: 0;
}

.pfs-share-worksheet-listing .cs-tbody td {
  white-space: nowrap;
}

/* ---------------------SharePfsSecondMainModal------------------------------- */
.share-pfs-modal .modal-dialog {
  width: calc(100% - 250px);
  height: calc(100% - 70px);
  max-width: 100%;
  overflow: hidden;
}

.share-pfs-modal .pfs-modal-scroll {
  padding: 24px 44px 0;
  overflow-y: auto;
  height: 100%;
}

.share-pfs-modal .modal-content {
  height: 100%;
  border: 0;
}

.share-pfs-modal .modal-header {
  position: relative;
  padding: 32px 56px 32px;
  border: 1px solid var(--neutral-20);
}

.share-pfs-modal .modal-header .pfs-preview-modal {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pfs-preview-modal.modal-body {
  padding: 0;
}

.pfs-header {
  margin-bottom: 8px;
}

.view-pfs-modal p {
  min-width: 84px;
  margin-right: 8px;
}

.pfs-second-modal.modal-footer{
  flex-wrap: nowrap;
}
.pfs-second-modal.modal-footer .pfs-second-modal-check-box {
  width: 70%;
}

.pfs-second-modal-check-box .cs-form-check-box {
  align-items: baseline;
}

.pfs-second-modal-check-box .cs-form-check-box input#signaturereturnbox {
  position: relative;
  top: 3px;
}
.cs-pfs-right {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-pfs-right .modal-title {
  margin-left: 72px;
}

.view-pfs-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid var(--primary-border);
  background: var(--primary-surface);
  margin-bottom: 24px;
  color: var(--neutral-100);
}

.pfs-common-box .finance-data,
.pfs-common-box .contingent-finance-data {
  border: 1px solid var(--neutral-50);
}

.pfs-common-box .total-networth {
  border: 1px solid var(--primary-border);
  border-bottom: 5px solid var(--primary-main);
}

.pfs-footer {
  position: relative;
  padding: 24px;
  margin-top: 32px;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
}

/* .share-pfs-modal .buttons {
  width: 47%;
}

*/
.share-pfs-modal .cs-pfs-right {
  width: 53%;
}

.share-pfs-modal .cs-pfs-right .modal-title {
  margin-left: 0;
}

.pfs-preview-modal .cs-tabs .cs-stroke-default-tab {
  width: 200px;
}

/* PFS signature start */
.dashboard-main-section .pfs-preview-modal .go-next-page-div {
  display: none;
}

.dashboard-main-section .pfs-preview-modal .total-networth {
  padding-right: 66px;
}

.dashboard-main-section .pfs-preview-modal .contingent-tbody .dollar-value-gap {
  width: 152px;
  /* padding-right: 10px; */
}

.pfs-preview-modal .contingent-tbody tr td:nth-child(2) {
  padding-right: 42px;
}

.dashboard-main-section .pfs-preview-modal .dashboard-tfoot td:nth-last-child(0n + 2) {
  border-radius: 0 4px 4px 0 !important;
}

.pfs-signature-container {
  margin-top: 16px;
}

.pfs-signature-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pfs-signature-box span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 16px;
}

.pfs-signature-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 722px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pfs-signature-box span:last-child {
  width: 100%;
  border-bottom: 1px solid var(--neutral-50);
}

.pfs-signature-box.pfs-name-block span:nth-child(1) {
  width: 84px;
}

.pfs-signature-bottom span:last-child {
  height: 30px;
}

.pfs-signature-box span:first-child {
  padding-right: 0;
  padding-left: 0;
  margin-right: 16px;
}

.pfs-signature-box.pfs-signature-block {
  width: 484px;
  margin-right: 16px;
}

.pfs-signature-box.pfs-name-block {
  width: 722px;
  margin-bottom: 16px;
}

.pfs-signature-box.pfs-signature-block span:first-child {
  width: 90px;
}

.pfs-signature-box.pfs-date-block {
  width: 224px;
}

.pfs-signature-box.pfs-date-block span:first-child {
  width: 58px;
}

.share-pfs-modal .modal-footer {
  border: 0;
  padding: 30px 56px 32px;
}

/* PFS signature end */
.share-pfs-modal a:-webkit-any-link,
.share-pfs-modal .go-next-page-div,
.share-pfs-modal .go-next-page-div a,
.share-pfs-modal .go-next-page-div svg,
.share-pfs-modal .cs-file-icon span,
.dashboard-main-section .pfs-preview-modal .contingent-tbody .dollar-value-gap {
  cursor: default;
}

/* PFS Worksheet tables start */

.pfs-h2-title {
  margin-bottom: 20px;
}

.pfs-table-container {
  margin-bottom: 24px;
}

.pfs-assets-table .pfs-table-container:last-child {
  margin-bottom: 32px;
}

.pfs-common-title {
  margin-bottom: 8px;
}

.pfs-common-title h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pfs-container .pfs-h2-title a {
  margin-left: 8px;
  cursor: pointer;
}

.pfs-common-title a .cs-liability.icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 20px;
}

.institution-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.institution-title span {
  margin-left: 8px;
}

.pfs-table-container .table-icons-container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* .pfs-table-container td,
.pfs-table-container th {
  font-size: 14px;
  line-height: 18px;
} */

/* PFS Worksheet tables end */

/* ======== Attachments tab Start ======== */
.attachment-row {
  position: relative;
  height: 100%;
  padding: 24px 24px 24px 24px;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
}

.attachment-row .row:first-child {
  padding-right: 35px;
}

.cs-choose-file ::-webkit-file-upload-button {
  display: none;
}

.cs-choose-file {
  position: relative;
}

.cs-choose-file .cs-icon {
  position: absolute;
  top: 50%;
  right: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: var(--primary-main);
  border-radius: 2px;
  pointer-events: none;
}

.attachment-row .row {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  --bs-gutter-x: 1.5rem;
}

.attachment-row .cs-form-group {
  margin-bottom: 0;
}

.cs-choose-file .form-control {
  padding-right: 40px;
}

.attachment-row .cs-btn-primary {
  padding: 8px 10px;
  max-width: 110px;
}

.attachment-row.attachment-comments-box {
  padding: 24px;
}

.attachment-comments-box .cs-textarea {
  height: 210px;
}

.attachment-files.cs-files {
  margin-top: 24px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.attachment-files.cs-files .cs-selected-files {
  width: auto;
  height: 30px;
  padding: 6px 8px;
  margin: 0 16px 16px 0;
  background: var(--neutral-20);
}

.attachment-files.cs-files .cs-selected-files .download-filename {
  max-width: 134px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-align: center;
}

.attachment-files.cs-files .cs-selected-files span.cs-icon-badge-danger.cs-danger {
  background: transparent;
  width: 24px;
  height: 24px;
  margin: 0;
  cursor: pointer;
}

.attachment-files.cs-files .cs-selected-files .cs-fileicon,
.attachment-files.cs-files .cs-selected-files .cs-icon {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.attachment-files.cs-files .cs-selected-files .cs-files-names {
  padding: 0 8px 0 4px;
}

.attachment-row.attachment-comments-box .form-error-msg {
  position: relative;
}

/* ======== Attachments tab End ======== */
.signature-return-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.signature-return-box .cs-form-check-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.signature-return-box label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.share-pfs-modal .pfs-preview-modal .pfs-container .table-responsive {
  padding-bottom: 4px;
}

.cr-box>div,
.cr-box .pfs-container {
  height: 100%;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
  .share-pfs-modal .modal-dialog {
    width: 100%;
    margin-inline: 0;
    padding-inline: 30px;
  }

  .share-pfs-modal .pfs-modal-scroll {
    padding-inline: 24px;
  }

  .share-pfs-modal .modal-header,
  .share-pfs-modal .modal-footer {
    padding-inline: 34px;
  }

  .attachment-row .row:first-child {
    padding-right: 0;
  }

  .cs-pfs-right .modal-title {
    margin-left: 50px;
  }
}

@media (max-width: 1199px) {
  .pfs-modal-scroll .pfs-footer {
    padding: 14px;
  }

  .pfs-modal-scroll .view-pfs-modal {
    padding-inline: 14px;
  }

  .pfs-signature-box.pfs-name-block,
  .pfs-signature-bottom,
  .pfs-signature-box.pfs-name-block span:nth-child(1),
  .pfs-signature-box.pfs-signature-block span:first-child,
  .pfs-signature-box.pfs-date-block span:first-child {
    width: auto;
  }

  .pfs-signature-container .col-lg-6 {
    width: 100%;
  }

  .attachment-row,
  .attachment-row.attachment-comments-box {
    padding: 14px;
  }

  .attachment-row .row .col-lg-5:first-child {
    width: 100%;
    margin-bottom: 14px;
  }

  .attachment-row .row .col-lg-5 {
    width: 70%;
  }

  .attachment-row .row .col-lg-2 {
    width: 30%;
  }

  .assets-table-container .dollar-value-gap,
  .category-cs-table-container .dollar-value-gap,
  .pfs-table-container .dollar-value-gap {
    padding-right: 30px;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .share-pfs-modal .modal-header {
    display: block;
    text-align: center;
  }

  .share-pfs-modal .modal-header .modal-title {
    margin: 0;
  }

  .cs-pfs-right {
    width: auto;
  }

  .pfs-modal-scroll .pfs-footer {
    margin-bottom: 24px;
  }

  .pfs-preview-dropdown .dropdown .dropdown-toggle,
  .pfs-preview-dropdown .dropdown .dropdown-menu.show {
    width: 250px;
  }

  .share-pfs-modal .modal-header .modal-title {
    text-align: center;
    white-space: nowrap;
  }
}

@media (max-width: 767px) {
  .share-pfs-modal .modal-header .pfs-preview-modal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .share-pfs-modal .modal-header .modal-title {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  .pfs-preview-modal .buttons {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    /* width: 50%; */
    width: 100%;
  }

  .pfs-preview-dropdown .dropdown .dropdown-toggle,
  .pfs-preview-dropdown .dropdown .dropdown-menu.show {
    width: 100%;
  }

  .share-pfs-modal .modal-header {
    padding: 14px 24px 24px;
  }

  .pfs-container .container-fluid {
    padding-inline: 0;
  }

  .share-pfs-modal .pfs-modal-scroll {
    padding-inline: 15px 10px;
  }

  .share-pfs-modal .modal-footer {
    -webkit-padding-end: 25px;
    padding-inline-end: 25px;
  }

  .pfs-modal-scroll .view-pfs-modal {
    margin-bottom: 14px;
  }

  .other-attachment-container .col-lg-6.col-md-6 {
    margin-bottom: 24px;
  }

  .other-attachment-container .col-lg-6.col-md-6:last-child {
    margin-bottom: 0;
  }

  .attachment-comments-box .cs-textarea {
    height: 130px;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */

@media (max-width: 660px) {
  .cs-pfs-right {
    position: relative;
    top: -10px;
  }

  .cs-view-pfs-filter {
    display: block;
    text-align: left;
  }

  .share-pfs-modal .modal-header {
    padding-inline: 14px;
    padding-bottom: 10px;
  }

  .pfs-preview-modal .buttons {
    width: 100%;
    margin-bottom: 10px;
  }

  .pfs-preview-dropdown .dropdown .dropdown-toggle,
  .cs-view-pfs-filter .cs-filter-dropdown .dropdown-toggle {
    width: 100%;
    padding-inline: 12px;
    font-size: 12px;
    line-height: 16px;
  }

  .pfs-preview-modal .buttons svg,
  .cs-view-pfs-filter .cs-filter-dropdown .dropdown-toggle svg {
    margin-left: 4px;
  }

  .cs-view-pfs-filter .default-regular-body-text-m {
    font-size: 12px;
    line-height: 16px;
    margin-right: 0;
    margin-bottom: 3px;
  }
}

@media (max-width: 575px) {
  .cs-form-group.last-notes-field {
    margin-bottom: 0;
  }

  .pfs-signature-bottom {
    display: block;
  }

  .pfs-signature-box.pfs-signature-block {
    width: 100%;
  }

  .pfs-signature-box.pfs-date-block {
    width: 90%;
  }

  .share-pfs-modal .modal-dialog {
    padding-inline: 15px;
    margin-inline: 0;
  }

  .share-pfs-modal .attachment-row .col-lg-2 button {
    height: 38px;
  }
}

@media (max-width: 425px) {
  .attachment-row .row .col-lg-5 {
    width: 100%;
  }

  .attachment-row .row .col-lg-2 {
    margin-top: 14px;
  }

  .attachment-row .cs-btn-primary {
    width: 110px;
  }
}

@media (max-width: 375px) {
  .view-pfs-modal p {
    min-width: auto;
  }

  .pfs-body .row .col-lg-6 {
    margin-bottom: 24px;
  }

  .pfs-common-box,
  .pfs-modal-scroll.dashboard-main-section .total-networth {
    overflow: auto;
  }

  .pfs-modal-scroll .pfs-footer {
    margin-bottom: 4px;
  }

  .finance-data-container {
    width: 420px;
  }

  .finance-box {
    width: 100%;
    overflow-x: auto;
  }

  .finance-data-container {
    margin-top: 30px;
    width: 100%;
    overflow-x: auto;
  }

  .finance-box .finance-data-container {
    margin-top: 0;
  }

  .finance-data-container .finance-data,
  .finance-data-container .contingent-finance-data {
    width: 420px;
  }

  .share-pfs-modal .attachment-row .row .col-lg-2,
  .share-pfs-modal .attachment-row .cs-btn-primary,
  .share-pfs-modal .cs-modal-btn.wm-req-shareicon-viewpdf button {
    width: 100%;
    max-width: 100%;
  }

  .share-pfs-modal .cs-modal-btn.wm-req-shareicon-viewpdf button:last-child {
    margin: 16px 0 0 0;
  }

  .share-pfs-modal .cs-modal-btn.wm-req-shareicon-viewpdf {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */