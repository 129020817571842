.assets-table-container .dollar-value-gap .cs-icon,
.category-cs-table-container .dollar-value-gap .cs-icon,
.pfs-table-container .dollar-value-gap .cs-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.pfs-table-container .ownership-td {
  padding-right: 75px !important;
}

.share-pfs-modal .pfs-share-worksheet-listing .ownership-td {
  padding-right: 45px !important;
}

/* .assets-table-container .cs-tbody tr:not(.cs-tbody tr:last-child) {
  cursor: pointer;
} */

.action-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* .action-box .cs-common-add-dropdown,
.action-box .common-tooltip-margin {
  margin-left: 32px;
} */

.common-tooltip-margin span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 22px;
  height: 22px;
}

.cs-tbody .common-tooltip-margin span,
.cs-tbody .common-tooltip-margin span svg {
  width: 18px;
  height: 18px;
}

.common-tooltip-margin svg path {
  fill: var(--neutral-90) !important;
}

.action-box .cs-disabled-icon.common-tooltip-margin svg path {
  fill: var(--neutral-60) !important;
}

/* ========== Category Common start ========== */

.category-cs-table-container .row {
  margin: 0;
}

.category-cs-table-container .col {
  padding: 0;
}

.category-cs-table-container {
  width: 100%;
  max-width: 7680px;
  padding-bottom: 4px;
  overflow: hidden;
  overflow-x: auto;
}

/* Assets start */
/* Assets end */

/* Liabilities start */
.category-manage-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  width: 100%;
  margin-bottom: 32px;
}

/* Liabilities start */

.category-manage-row .cs-common-add-dropdown .xl-btn {
  width: 245px;
  background: var(--primary-main);
  border-color: var(--primary-main) !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.category-manage-row .cs-form-check-box {
  width: 100%;
  /* height: 36px; */
  min-height: auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin: 0;
  padding-inline: 16px;
}

.manage-columns-items {
  width: 100%;
}

.manage-columns-items .cs-form-check-box .form-check-input {
  margin: 0;
  border-radius: 4px !important;
}

.custom-dropdown-menu .cs-form-check-box .form-check-inline {
  cursor: pointer;
}

.manage-columns-items .cs-form-check-box .form-check-label {
  display: block;
  width: 100%;
  padding-block: 10px;
  padding-inline: 0 16px;
}

.manage-columns-items .cs-form-check-box:hover {
  background: var(--primary-surface);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.manage-columns-items .cs-form-check-box .form-check-input {
  position: relative;
  /* background-color: var(--neutral-50); */
  background-color: var(--neutral-10);
  background-image: none !important;
}

.manage-columns-items .cs-form-check-box .form-check-input::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  -webkit-mask-image: url(../../../../../assets/icons/svg/outlined/check-outlined.svg);
  mask-image: url(../../../../../assets/icons/svg/outlined/check-outlined.svg);
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-position: bottom;
  mask-position: bottom;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  /* background-color: var(--neutral-10); */
  background-color: transparent;
  /* background-image: url(../../../../../assets/icons/svg/outlined/check-outlined.svg); */
}

.manage-columns-items .cs-form-check-box .form-check-input:checked {
  background-color: var(--primary-main);
}

.manage-columns-items .cs-form-check-box .form-check-input:checked:before {
  background-color: var(--neutral-10);
}

.manage-columns-items .cs-form-check-box .form-check-inline.cs-disabled,
.custom-dropdown-menu li,
.manage-columns-items
  .cs-form-check-box
  .form-check-inline.cs-disabled
  .form-check-label {
  cursor: not-allowed !important;
}

/* ========== Category Common start ========== */
/* .category-cs-table-container .other-action-td svg,
.cs-main-area .cs-file-icon svg,
.payment-method-modal .change-card-delete svg {
  width: 24px;
  height: 24px;
} */

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .cs-thead th {
    white-space: nowrap;
  }

  .category-manage-row .cs-common-add-dropdown .xl-btn {
    width: 40px;
    height: 40px;
    font-size: 0 !important;
  }

  .category-manage-row .cs-common-add-dropdown .xl-btn span.cs-neutral-10 {
    margin: 0;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */

@media (max-width: 660px) {
  .assets-table-container {
    overflow-x: auto;
    padding-bottom: 4px;
  }

  .assets-table-container .cs-table.table {
    width: 530px;
  }

  .assets-table-container .cs-tbody tr td:first-child,
  .assets-table-container .cs-tbody tr td:first-child div {
    white-space: normal;
    width: 220px !important;
  }

  .assets-table-container .cs-tbody tr td:last-child {
    padding-left: 0;
  }
}

@media (max-width: 575px) {
  .category-manage-row {
    margin-bottom: 24px;
  }

  .cs-thead th,
  .cs-tbody td {
    font-size: 14px;
    line-height: 20px;
  }

  .assets-table-container .dollar-value-gap,
  .category-cs-table-container .dollar-value-gap,
  .pfs-table-container .dollar-value-gap {
    width: 130px;
    padding-right: 0;
  }

  .action-box .common-tooltip-margin p {
    font-size: 14px;
    line-height: 20px;
  }

  .category-manage-row .manage-columns-items.dropdown-menu.show {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
