/*=====================================Start Skeleton===========================================*/
.amortization-skeleton-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-chart-legend .skeleton-pie-chart-container {
  margin-top: 24px;
}

.skeleton-chart-legend .skeleton-pie-legends:nth-child(even) {
  margin-bottom: 12px;
}

.amortization-skeleton-breadcrumb .skeleton-breadcrumb-1,
.amortization-skeleton-breadcrumb .skeleton-breadcrumb-2,
.amortization-skeleton-breadcrumb .skeleton-breadcrumb-3,
.amortization-skeleton-breadcrumb .skeleton-breadcrumb-4 {
  margin-right: 4px;
}

.cs-main-layout .cs-main-area .amortizationSection .amort-alignment {
  padding-right: 64px !important;
}

.amortizationSection .amortization-pie-chart .skeleton-chart-legend,
.amortizationSection .amortization-pie-chart .skeleton-chart-legend > div,
.amortizationSection .amortization-pie-chart .skeleton-pie-chart-container {
  padding: 0;
  margin: 0;
}

.amortizationSection
  .amortization-pie-chart
  .skeleton-pie-chart-container
  .skeleton-pie-legends {
  padding: 0;
  margin: 0 0 0 60px;
}

/*=====================================End Skeleton===========================================*/

.amortizationSection
  .amortization_headingTable
  .amortization-pie-chart
  .graph-pie-chart-main-container
  .recharts-responsive-container {
  width: 255px !important;
  height: 255px !important;
}

.amortizationSection
  .amortization_headingTable
  .amortization-pie-chart
  .graph-pie-chart-main-container
  .recharts-wrapper {
  max-width: 250px !important;
  max-height: 250px !important;
}

.cs-amortization-table .cs-tbody tr td:nth-child(2) {
  min-width: 155px;
}

.amortization_headingTable .amortization-header h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.amortization_headingTable .amortization-header .amortization-icon,
.amortization_headingTable .amortization-header .amortization-icon span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.amortizationSection .cs-amortization-table .cs-tbody tr {
  height: 50px;
}

.amortizationSection .cs-amortization-table .cs-tbody tr td,
.amortizationSection .cs-amortization-table .cs-table tr th {
  padding: 15px 24px;
}

.amortizationSection .amortization-main-listing .accordion-header .table td {
  height: 44px;
}

.amortizationSection .amortization-main-listing .accordion-body .table td {
  padding: 8px 16px;
}

.amortizationSection .amortization-main-listing .accordion-body .table td {
  height: 36px;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1799px) {
  .amortization-table-container {
    padding-bottom: 4px;
  }

  .amortization-main-listing {
    width: 100%;
    max-width: 1499px;
    padding-bottom: 4px;
    overflow: hidden;
    overflow-x: auto;
  }

  .amortization-table-container .cs-table {
    width: 1600px;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 1499px) {
  .amortization-chart-data {
    margin-left: 24px;
  }

  .amortizationSection
    .amortization-pie-chart
    .skeleton-pie-chart-container
    .skeleton-pie-legends {
    margin: 0;
  }
}

@media (max-width: 1399px) {
  .amortization_headingTable .col-lg-6 {
    width: 100%;
  }

  .transaction-search-bar,
  .amortization-header {
    margin-bottom: 24px;
  }

  .amortization-chart-data {
    margin-left: 95px;
  }
}

@media (max-width: 991px) {
  .transaction-search-bar,
  .amortization-header {
    margin-bottom: 24px;
  }

  .amortization-pie-chart.pie-chart-container {
    margin-inline: 0;
    margin-bottom: 0;
  }

  .amortization-table-container .cs-table {
    width: 1180px;
  }

  .amortization-header .amortization-icon svg {
    width: 100%;
    height: 100%;
  }

  .assets-listing.amortization-main-listing tr th.th-mob-width span {
    /* display: block;
        width: 110px; */
    white-space: normal;
  }

  .assets-listing.amortization-main-listing
    tr
    th.th-mob-width:first-child
    span {
    width: 104px;
  }

  .assets-listing.amortization-main-listing
    tr
    th.amortization-principal-icon.th-mob-width,
  .assets-listing.amortization-main-listing
    tr
    th.amortization-interest-icon.th-mob-width {
    width: auto !important;
    padding: 0 !important;
  }

  .assets-listing.amortization-main-listing
    tr
    th.amortization-principal-icon.th-mob-width
    span,
  .assets-listing.amortization-main-listing
    tr
    th.amortization-interest-icon.th-mob-width
    span {
    width: 20px !important;
    text-align: center;
  }

  td.amortization-interest .mob-width,
  td.amortization-principal .mob-width {
    width: 130px !important;
  }

  td.amortization-interest .mob-width div,
  td.amortization-principal .mob-width div {
    width: 100%;
    max-width: 100%;
  }

  th.amortization-principal.th-mob-width span {
    width: 136px !important;
  }

  th.amortization-interest.th-mob-width span {
    width: 140px !important;
  }

  th.amortization-interest.th-mob-width {
    padding-right: 13px;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .transaction-search-bar {
    padding: 16px;
    margin-bottom: 16px;
  }

  .amortization-header {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 9px 16px;
    margin-bottom: 16px;
  }

  .amortization-header h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .cs-amortization-table .cs-tbody tr td,
  .cs-amortization-table .cs-table tr th {
    padding: 8px 16px;
  }

  .amortization-pie-chart .graph-pie-chart-main-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .amortization-chart-data {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 16px 0 0 0;
  }

  .amortization-price-box {
    margin-right: 16px;
    margin-bottom: 0;
  }

  .amortization-price-box:last-child {
    margin-right: 0;
  }

  .amortization-content span {
    color: var(--neutral-80);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .amortization-content .value1 {
    color: var(--neutral-100);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .amortization-pie-chart .chart-heading h2 {
    color: var(--neutral-100);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .amortization-header .amortization-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  .amortizationSection .cs-amortization-table {
    max-width: 575px;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 4px;
  }

  .cs-main-layout .cs-main-area .amortizationSection .amort-alignment {
    padding-right: 40px !important;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
