.skeleton-search-download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.skeleton-search-download span:first-child {
  margin-right: 16px;
}

.transaction-main-section .showdate {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.transaction-main-section .cs-report-period .form-error-msg {
  bottom: -16px;
  font-size: 9px !important;
}
.transaction-main-section .transaction-period-select .react-date-picker {
  background-color: var(--primary-surface);
  border: none;
}

.transaction-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.transaction-flex,
.plaid-transactions-table .sorting-icon,
.plaid-transactions-table .sorting-icon span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.transaction-flex img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  border-radius: 2px;
}

.transaction-main-section .cs-transaction-table div#cell-2-undefined,
.transaction-main-section .cs-transaction-table div#cell-3-undefined,
.transaction-main-section
  .cs-transaction-table
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(2),
.transaction-main-section
  .cs-transaction-table
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(3) {
  width: 300px !important;
  min-width: 300px;
}

.transaction-search-bar.for-des {
  display: block;
}

.transaction-search-bar.for-mob {
  display: none;
}

.plaid-transactions-table .sorting-icon span {
  margin-left: 8px;
}

.wm-clientbill-filter.transaction-filter .dropdown .dropdown-item span {
  margin-left: 0 !important;
}

.plaid-transactions-table .cs-tbody .text-end {
  display: block;
  white-space: nowrap;
}

.plaid-transactions-table
  .default-medium-sub-heading-m.text-end
  .sorting-icon.false {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.plaid-transactions-table .cs-thead tr th:last-child,
.plaid-transactions-table .cs-tbody tr td:last-child {
  padding-right: 40px;
}

.plaid-transactions-table .text-end .sorting-icon.cursor-pointer {
  position: relative;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.plaid-transactions-table
  .text-end
  .sorting-icon.cursor-pointer
  span.cs-neutral-100 {
  position: absolute;
  right: -22px;
  margin: 0;
}

.transaction-main-section .transaction-filter .dropdown .dropdown-menu.show {
  width: auto;
}

.transaction-flex div {
  white-space: nowrap;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1366px) {
  .transaction-period-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .plaid-transactions-table .cs-tbody td:nth-child(2) .transaction-flex {
    min-width: 300px;
    width: 100%;
  }

  .plaid-transactions-table {
    position: relative;
    width: 100%;
    max-width: 1199px;
    overflow-x: auto;
    padding-bottom: 4px;
  }
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .transaction-main-section .showdate {
    width: 100%;
  }

  .plaid-transactions-table .cs-tbody td:nth-child(2) .transaction-flex,
  .plaid-transactions-table .cs-thead th:nth-child(2) {
    min-width: 190px;
    width: 100%;
  }

  .plaid-transactions-table .cs-tbody td:nth-child(2) .transaction-flex span {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 160px;
    display: block;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .transaction-main-section .showdate {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .transaction-search-bar.for-des {
    display: none;
  }

  .transaction-search-bar.for-mob {
    display: block;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
