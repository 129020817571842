.method-twenty-five .cs-common-add-dropdown,
.method-twenty-six .cs-common-add-dropdown,
.method-twenty-seven .cs-common-add-dropdown {
    margin-bottom: 24px;
}

.method-twenty-six .cs-form-check-box {
    margin-top: 24px;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.method-twenty-six .net-worth-asset-libility {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.method-twenty-six .net-worth-asset-libility p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.method-twenty-six .net-worth-asset-libility p span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 8px;
}

.method-twenty-six .cs-form-check-box label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.upload-credit-report p:first-child {
    margin-bottom: 16px;
}

.upload-credit-report p:nth-child(2) {
    margin-bottom: 16px;
}

.upload-credit-report ol {
    margin-bottom: 24px;
}

.upload-credit-report li {
    margin-top: 16px;
}