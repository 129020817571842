.credit-change-method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-20);
  cursor: pointer;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  height: 100px;
}

.change-method-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--primary-main);
  background: var(--primary-surface);
  cursor: pointer;
}

.settings-main-section .payment-cards .credit-change-method {
  margin-bottom: 0;
}

.add-payment-method-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.change-card-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* padding-top: 6px; */
}

.cs-card-logo {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 52px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid var(--neutral-30);
  background: var(--neutral-10);
  margin-top: -6px;
  margin-right: 16px;
  padding: 6px;
}

.change-card-image .cs-card-logo img,
.payment-cards .credit-text .cs-card-logo img {
  width: 100%;
  max-width: 46px;
  /* height: 26px; */
  height: auto;
  margin: 0 !important;
}

.change-credit-text p {
  margin-bottom: 8px !important;
}

.change-card-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 6px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background: var(--danger-surface);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.change-card-delete:hover {
  background: var(--danger-hover);
}

.change-card-delete:hover svg path {
  fill: var(--neutral-10);
}

.change-card-image img {
  margin-right: 16px;
  width: 56px;
  height: 32px;
}

.change-bank-image img {
  margin-right: 16px;
}

.add-payment-method-button button.default-regular-h5.cs-btn-icon-tertiary {
  padding: 0 !important;
}

p.change-content {
  margin-top: 24px;
}

.add-payment-method-button .cs-modal-btn {
  margin-top: 16px;
}

.choose-payment-modal .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-top: 16px;
}

.choose-payment-modal .form-check span {
  cursor: pointer;
}

.routing-number-input .form-error-msg {
  position: relative;
}

/*=========================================================================================*/

/* *-*-*-* Storage model start *-*-*-* */
.file-management .subscript-Planarea.subscription-plan-cards {
  display: grid;
  align-items: flex-start;
  grid-gap: 24px;
  grid-gap: 24px;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  margin: 0;
}
.file-management.cs-subscription-section ul li:before {
  display: none;
}
.file-management .card {
  width: 100% !important;
  box-shadow: none !important;
  margin: 8px 0 10px !important;
  cursor: pointer;
}
.file-management .card .card-plan.card-body {
  box-shadow: none;
  background-color: var(--neutral-20);
  border: 1px solid var(--neutral-20);
  padding: 16px;
}
.file-management .selected-plan .card-plan.card-body {
  background-color: var(--primary-surface);
  border-color: var(--primary-border);
}
.file-management.cs-subscription-section .card-plan-amount {
  margin: 14px 0 6px;
}
.file-management .card-plan-summary {
  display: flex;
  align-items: center;
}
.file-management .card-plan-summary .cs-primary {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 16px;
  border: 1px solid var(--primary-border);
  background-color: var(--primary-surface);
}

/* *-*-*-* Storage model start *-*-*-* */

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .file-management .subscript-Planarea.subscription-plan-cards {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .cloud-storage-modal .cs-modal-btn.modal-footer {
    flex-wrap: nowrap;
  }
}

@media (max-width: 767px) {
  .file-management .subscript-Planarea.subscription-plan-cards {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .file-management .card {
    margin: 0 !important;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .change-card-delete {
    padding: 6px;
  }

  .change-card-delete svg {
    width: 20px;
    height: 20px;
  }

  .add-payment-method-button button {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .add-payment-method-button span.cs-primary svg {
    width: 16px;
    height: 16px;
  }

  p.change-content.default-regular-body-text-m.cs-neutral-100 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 320px) {
  .cs-card-logo {
    margin-right: 8px;
  }
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
