.upper-dropdown .dropdown-menu.show {
  -webkit-transform: translate(-215px, -5px) !important;
  -ms-transform: translate(-215px, -5px) !important;
  transform: translate(-215px, -5px) !important;
}

.verified-value-icon,
.ira-portfolio-tooltip span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.verified-value-icon .cs-success {
  margin-left: 8px;
}

.category-cs-table-container .other-action-td .common-tooltip-margin.last-td-content.cs-neutral-60 {
  cursor: not-allowed;
}

.pie-chart-container .cs-common-no-record,
.line-chart-container .cs-common-no-record,
.category-line-chart-container .cs-common-no-record {
  border: none;
  height: 100%;
}

.cs-context .graph-pie-chart-main-container>div {
  height: 100%;
  min-height: 100% !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-main-layout .cs-main-area .pie-chart-container {
  height: 100%;
}

.pie-chart-container .cs-common-no-record {
  height: 400px;
  padding: 0;
  margin: 0;
}

.cs-main-layout .cs-main-area .pie-chart-container.skeleton-pie-graph {
  height: auto;
}

.amortization-pie-chart .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-label {
  font-size: 10px !important;
}

.amortization-pie-chart .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
  font-size: 12px !important;
}

.amortization-pie-chart .apexcharts-text.apexcharts-pie-label {
  fill: #ffffff !important;
}

.amortization-pie-chart .chart-heading {
  padding-bottom: 8px;
}

.amortization-pie-chart .amort-pie-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 16px !important;
  fill: var(--neutral-90) !important;
}

.amortizationSection .amortization-pie-chart .apexcharts-legend-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  fill: var(--neutral-100) !important;
}

.amortization-pie-chart .chart-heading h2 {
  text-align: left;
}

/************* Apex Pie Chart Design End************/

.manage-file-container {
  position: relative;
}

.manage-file-container .form-error-msg {
  z-index: 2;
  display: block;
  /* width: 96%; */
  padding-bottom: 6px;
}

.plaid-transaction-mini-loader,
.cs-breadcrumb-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.plaid-transaction-mini-loader .text-center {
  width: 20px;
  height: 20px;
  margin-left: 6px;
}

.plaid-transaction-mini-loader .text-center img {
  width: 100%;
  height: auto;
}

/*=========================================================================================*/
/* ============================= */
/* START Laptop - Viewport */
/* ============================= */

@media (max-width: 1499px) {

  .cs-main-area .dashboard-main-section .chart-container .pie-chart-container,
  .cs-main-area .dashboard-main-section .chart-container .line-chart-container {
    height: 440px;
    margin-bottom: 0;
  }

  .cs-context .dashboard-main-section .graph-line-chart-main-container {
    height: 356px;
  }

  .graph-line-chart-main-container .legend-row {
    padding-top: 20px;
  }

  .cs-main-area .chart-container .pie-chart-container,
  .cs-main-area .chart-container .line-chart-container {
    height: 100%;
    margin-bottom: 30px;
  }

  .amortizationSection .amortization-pie-chart .skeleton-pie-chart-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 22px;
  }

  .amortizationSection .amortization-pie-chart .skeleton-chart-legend,
  .amortizationSection .amortization-pie-chart .skeleton-chart-legend div {
    bottom: auto;
    left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-pie-chart-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }

  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-pie-chart-container .skeleton-pie-legends:nth-child(2),
  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-pie-chart-container .skeleton-pie-legends:nth-child(3),
  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-pie-chart-container .skeleton-pie-legends:nth-child(4) {
    display: none;
  }

  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-chart-legend {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 24px;
  }

  .chart-tooltip:after {
    min-width: auto;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .category-line-chart-container .legend-row {
    padding-top: 0;
  }

  .cs-context .dashboard-main-section .graph-pie-chart-main-container {
    height: 100%;
  }

  .cs-context .graph-pie-chart-main-container {
    padding: 0;
    margin: 0;
  }

  .skeleton-pie-chart-container .for-mob,
  .chart-heading .for-mob {
    display: none;
  }

  .amortizationSection .amortization-pie-chart .skeleton-pie-chart.for-des {
    display: none;
  }

  .amortizationSection .amortization-pie-chart .skeleton-pie-chart.for-mob {
    display: block;
    width: 100%;
  }

  .amortizationSection .amortization-pie-chart .skeleton-pie-chart-container .skeleton-pie-legends {
    margin-left: auto;
  }

  .cs-context .graph-line-chart-main-container {
    height: 400px;
  }
}

@media (max-width: 1449px) {

  .charts-container .col-lg-4,
  .charts-container .col-lg-8 {
    width: 50%;
  }
}

@media (max-width: 1420px) {

  .chart-container .col-lg-4,
  .chart-container .col-lg-8 {
    width: 50%;
  }

  .category-cs-table-container .verified-value-icon .dollar-value-gap {
    padding: 0;
  }

  .chart-tooltip:after {
    min-width: auto;
    max-width: 100px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    white-space: normal;
  }

  .skeleton-pie-chart-container .for-des,
  .chart-heading .for-des {
    display: none;
  }

  .skeleton-pie-chart-container .for-mob,
  .chart-heading .for-mob {
    display: block;
  }

  .cs-main-layout .cs-main-area .pie-chart-container.skeleton-pie-graph {
    margin: 0;
  }
}

@media (max-width: 1399px) {}

@media (max-width: 1199px) {

  .cs-main-area .dashboard-main-section .chart-container .pie-chart-container,
  .cs-main-area .dashboard-main-section .chart-container .line-chart-container {
    height: 480px !important;
  }

  .legend-row .legend-check {
    margin-right: 6px;
  }

  .amortization_headingTable .col-lg-5,
  .amortization_headingTable .col-lg-7 {
    width: 100%;
  }

  .amortizationSection .amortization_headingTable .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .amortizationSection .amortization-pie-chart .skeleton-chart-legend>div,
  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-pie-chart-container {
    padding-top: 0 !important;
  }

  .amortizationSection .amortization-new-pie-chart {
    margin-top: 24px;
  }

  .amortizationSection .amortization-pie-chart.pie-chart-container {
    height: 400px !important;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {

  .pie-chart-container,
  .chart-container {
    margin-bottom: 56px;
  }

  .graph-line-chart-main-container .legend-row {
    padding-top: 0 !important;
  }

  .cs-context .dashboard-main-section .graph-line-chart-main-container {
    height: 385px;
  }

  .chart-tooltip:after {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    max-width: 100px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}

@media (max-width: 767px) {}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .loan-term-dropdown .dropdown-toggle {
    height: 38px;
  }

  .upper-dropdown .dropdown-menu.show {
    -webkit-transform: translate(-215px, -20px) !important;
    -ms-transform: translate(-215px, -20px) !important;
    transform: translate(-215px, -20px) !important;
  }

  .cs-main-layout .cs-main-area .pie-chart-container,
  .cs-main-layout .cs-main-area .line-chart-container {
    height: 480px;
  }

  .pie-chart-container .cs-common-no-record {
    height: 365px;
  }

  .amortizationSection .amortization-pie-chart .skeleton-pie-chart-container .skeleton-pie-legends {
    margin: 0;
  }

  .chart-tooltip:after {
    min-width: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    white-space: normal;
  }

  .cs-common-carousel .slick-dots {
    z-index: -1;
  }

  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-pie-chart-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-pie-chart-container .skeleton-pie-legends:nth-child(2),
  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-pie-chart-container .skeleton-pie-legends:nth-child(3),
  .amortizationSection .amortization-pie-chart .skeleton-chart-legend .skeleton-pie-chart-container .skeleton-pie-legends:nth-child(4) {
    display: none;
  }

  .amortizationSection .amortization-pie-chart .chart-heading h2 {
    text-align: center;
  }

  .cs-main-layout .cs-main-area .dashboard-main-section .pie-chart-container,
  .cs-main-layout .cs-main-area .pie-chart-container.skeleton-pie-graph {
    height: 400px;
  }

  .cs-main-layout .cs-main-area .dashboard-main-section .line-chart-container {
    height: 480px;
  }

  .os-ios .cs-main-layout .cs-main-area .dashboard-main-section .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center {
    bottom: -0px !important;
  }

  .cs-main-layout .cs-main-area .dashboard-main-section .line-chart-container .recharts-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media (max-width: 425px) {}

@media (min-width: 391) and (max-width: 393px) {}

@media (max-width: 375px) {}

@media (max-width: 360px) {}

@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */

/* ==========New Amortization pie chart css Start=================== */
.amortizationSection .amortization-main-listing .accordion-header .table td,
.cs-main-layout .cs-main-area .amortizationSection td,
.amortizationSection .amortization-main-listing .table th {
  min-width: 200px !important;
  width: 200px !important;
}

.amortizationSection .amortization-main-listing .accordion-header .table td:first-child,
.amortizationSection .amortization-main-listing .table th:first-child,
.amortizationSection .amortization-main-listing .accordion-body .table td:first-child {
  width: 150px !important;
  min-width: 150px !important;
}

.amortizationSection .amortization-main-listing .accordion-header .table td.amortization-principal-icon,
.amortizationSection .amortization-main-listing .accordion-header .table td.amortization-interest-icon,
.amortizationSection .amortization-main-listing .accordion-body .table td.amortization-principal-icon,
.amortizationSection .amortization-main-listing .accordion-body .table td.amortization-interest-icon,
.amortizationSection #table-head-details tr th.amortization-principal-icon,
.amortizationSection #table-head-details tr th.amortization-interest-icon {
  width: 26px !important;
  min-width: 26px !important;
  text-align: center;
}

.amortizationSection .amortization-main-listing .accordion-header .table td:last-child,
.cs-main-layout .cs-main-area .amortizationSection td:last-child,
.amortizationSection .amortization-main-listing .table th:last-child {
  width: 60px !important;
  min-width: 60px !important;
}

.amortizationSection .amortization-new-pie-chart .chart-heading {
  padding: 16px 24px;
}

.amortizationSection .amortization-new-pie-chart {
  background-color: var(--neutral-10);
}

.amortizationSection .amortization-new-pie-chart .apexcharts-canvas,
/* .amortizationSection .amortization-new-pie-chart .apexcharts-canvas svg, */
.amortizationSection .graph-pie-chart-main-container.asset-pie-chart.skeleton-chart-legend {
  height: 270px !important;
  padding-top: 0;
}

.amortizationSection .amortization-new-pie-chart foreignObject {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.amortizationSection .amortization-new-pie-chart .apexcharts-legend-series {
  margin: 0 0 8px 0 !important;
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.amortizationSection .amortization-new-pie-chart .apexcharts-legend-series span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: flex-start !important;
  -ms-flex-align: flex-start !important;
  align-items: flex-start !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.amortizationSection .amortization-new-pie-chart .apexcharts-legend {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  height: 100%;
  bottom: auto !important;
  top: 0 !important;
  padding-inline: 0 !important;
  right: 6vw !important;
}

.amortizationSection .amortization-new-pie-chart .common-pie-charts,
.amortizationSection .amortization-new-pie-chart .common-pie-charts>div {
  padding: 0;
}

.amortizationSection .amortization-new-pie-chart span.apexcharts-legend-text .amort-pie-value {
  font-size: 12px !important;
  color: var(--neutral-100) !important;
  font-weight: 400 !important;
}

/* ==============Amortization Responsive====================== */

@media (max-width: 1899px) {
  .amortizationSection .amortization-new-pie-chart .apexcharts-legend {
    right: 4vw !important;
  }
}

@media (max-width: 1499px) {
  .amortizationSection .amortization-new-pie-chart .apexcharts-legend {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .amortizationSection .amortization-new-pie-chart .apexcharts-legend {
    height: auto;
    width: 100%;
    bottom: 2vh !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
  }

  .amortizationSection .amortization-new-pie-chart .apexcharts-legend-series {
    margin: 0 16px 0 0 !important;
  }

  .amortizationSection .amortization-new-pie-chart .apexcharts-legend-series:last-child {
    margin: 0 !important;
  }
}

@media (max-width: 1199px) {

  .amortizationSection .amortization-new-pie-chart .common-pie-charts,
  .amortizationSection .amortization-new-pie-chart .common-pie-charts>div {
    padding: 10px 0 !important;
  }

  .amortizationSection .amortization-new-pie-chart .apexcharts-canvas,
  .amortizationSection .graph-pie-chart-main-container.asset-pie-chart.skeleton-chart-legend {
    height: auto !important;
    width: auto !important;
  }
}

/* @media (max-width: 575px) {

  .amortizationSection .amortization-new-pie-chart .apexcharts-canvas,

  .amortizationSection .graph-pie-chart-main-container.asset-pie-chart.skeleton-chart-legend {
    height: 300px !important;
  }
} */

/* ==========New Amortization pie chart css end=================== */