.company-popup-remove {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 6px 0;
  cursor: pointer;
}

.company-popup-remove span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.company-popup-remove span:first-child {
  margin-right: 8px;
}

.company-modal-line hr {
  margin: 16px 0;
}

.company-add-popup .modal-body button.cs-btn-icon-tertiary {
  padding-left: 0 !important;
}

.associate-add-more {
  margin-top: 10px;
}

.associate-add-more .cs-common-add-dropdown .dropdown .dropdown-menu.show {
  max-height: 190px;
}

.company-add-popup .associate-add-more .cs-primary svg {
  margin-right: 8px;
}

.company-add-popup .associate-add-more .cs-btn-icon-tertiary span.cs-primary {
  margin-right: 0;
}

.associate-add-more .cs-form-check-box {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 24px;
}

.associate-add-more span.form-error-msg {
  left: auto;
}

.associate-add-more .cs-form-check-box label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: var(--neutral-90);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  word-break: break-all;
}

.associate-add-more.cs-disabled .cs-form-check-box label {
  cursor: not-allowed;
}

.associate-add-more .cs-common-add-dropdown {
  margin-top: 24px;
}

.associate-add-more .cs-common-add-dropdown .dropdown .dropdown-toggle > p,
.associate-add-more
  .cs-common-add-dropdown
  .dropdown
  .dropdown-menu.show
  .dropdown-item.cs-selected-dropdown {
  display: block;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cs-create-company .cs-form-group {
  position: sticky;
  top: -8px;
  left: 0;
  width: 100%;
  padding-block: 10px 6px;
  padding-inline: 16px 12px;
  margin-bottom: 0;
  background: #fff;
}

.company-add-popup .modal-body {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.partners-details-table {
  margin-top: 16px;
}

.partners-details-table p:first-child,
.cs-create-company div:first-child {
  margin-bottom: 4px;
}

.partners-details-table span {
  display: inline-block;
  width: 200px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.company-add-popup .add-company-form span.partner-detail {
  margin-bottom: 16px;
}

.add-more-btn button {
  padding-left: 0;
}

.add-more-btn button span:first-child {
  margin-right: 8px;
}

.cs-create-company .cs-form-group .input-field-icon {
  display: inline-block;
  margin-right: 12px;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
}

@media (max-width: 1199px) {
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .associate-add-more .cs-form-check-box {
    margin-top: 16px;
  }

  .associate-add-more .input-field-icon {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .wm-individual-action span.cs-danger {
    width: 24px;
    height: 24px;
  }

  .wm-individual-action span.cs-danger svg,
  .companies-table .action-box.table-icons-container svg {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 425px) {
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
