.companies-table {
    margin: 0;
}

.companies-table .accordion-header .table td,
.companies-table .accordion-body .table td {
    height: 0;
}

.companies-table .accordion-table {
    border-bottom: 1px solid var(--neutral-50) !important;
    border-radius: 0 !important;
}

.companies-table .accordion-table .cs-thead th {
    background: var(--neutral-20);
    border: none;
}

.companies-table .accordion-table .cs-tbody tr {
    border-bottom: 1px solid var(--neutral-50);
}

.cs-main-layout .cs-main-area .dashboard-main-section .companies-table .accordion-button .collapsed {
    border-bottom: 1px solid var(--neutral-50) !important;
    border-radius: 0 !important;
}

.cs-main-layout .cs-main-area .dashboard-main-section .companies-table .accordion-button .cs-tbody tr td {
    border-bottom: 1px solid var(--neutral-50) !important;
    border-radius: 0 !important;
}

.cs-main-layout .cs-main-area .dashboard-main-section .companies-table .accordion-button[aria-expanded="true"] .cs-tbody tr td {
    border-bottom: 0 !important;
}

.companies-table .cs-tbody tr td:last-child {
    border-bottom: none;
}

.companies-table .cs-tbody tr:last-child {
    border-radius: 0 0 4px 4px !important;
}

.companies-table .accordion-collapse .collapse .show {
    border: 0 !important;
}

.companies-table .accordion-collapse {
    border: 0;
}

.companies-table .accordion-table-collapse.accordion-after .cs-table th:first-child {
    border-radius: 4px 0 0px 4px;
}

.companies-table .accordion-table-collapse.accordion-after .cs-table th:last-child {
    border-radius: 0 4px 4px 0;
}

.cs-main-layout .cs-main-area .companies-table .cs-table.accordion-table .cs-tbody tr td {
    border-radius: 0 !important;
    border-bottom: 1px solid var(--neutral-50) !important;
}

.cs-main-layout .cs-main-area .companies-table .cs-table.accordion-table .cs-tbody tr:last-child td {
    border-radius: 0 !important;
    border-bottom: 0 !important;
}

.wm-individual-action.table-icons-container.cs-file-icon .cs-disabled-icon span,
.wm-individual-action.table-icons-container.cs-file-icon .cs-disabled {
    cursor: not-allowed !important;
}

.companies-table .wm-individual-action.table-icons-container.cs-file-icon .cs-disabled-icon {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

.companies-table .wm-individual-action.table-icons-container.cs-file-icon .cs-neutral-80,
.wm-individual-action.table-icons-container.cs-file-icon .cs-disabled-icon span {
    padding: 0;
}

/* ===== Delete Individual CSS start ===== */
.cs-dflex-btn {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cs-dflex-btn .lg-btn {
    max-width: 100%;
}

.cs-dflex-btn .cs-neutral-80 {
    position: absolute;
    right: -30px;
}

.tooltip-inner {
    width: auto;
}

.delete-individual-modal .cs-modal-btn.cs-center-btn {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-inline: 76px;
}

.delete-individual-modal .cs-modal-btn button:last-child {
    margin: 0;
}

.delete-individual-modal .cs-divider {
    margin: 6px auto;
    width: calc(100% - 50px);
}

.companies-table .company-first-table .action-box {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

/* ===== Delete Individual CSS end ===== */

.wm-skeleton-menu .menu-3 {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.wm-skeleton-menu .menu-3 .item-1 {
    margin-right: 24px;
}

.wm-skeleton-header {
    margin-top: 0px;
}

/* .wm-billing .cs-common-spinner {
    margin-left: 30px;
} */
.cs-main-layout .accordion-body .cs-thead th,
.cs-main-layout .accordion-body .cs-tbody td {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.cs-main-layout .accordion-body .cs-tbody td {
    font-weight: 300;
}

.cs-main-layout .accordion-body .cs-thead th {
    color: var(--neutral-100);
}

.cs-main-layout .accordion-body .cs-tbody td {
    color: var(--neutral-90);
}

.companies-table .company-first-table [aria-expanded=false] .cs-tbody td:first-child {
    white-space: normal;
    word-break: break-all;
}

/*=========================================================================================*/


/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {

    .companies-table .company-first-table {
        width: 1199px;
    }

    .companies-table .company-first-table .cs-thead tr th:nth-child(1),
    .companies-table .company-first-table .cs-tbody tr td:nth-child(1) {
        max-width: 350px;
    }

    .companies-table .company-first-table .cs-thead tr th:nth-child(2),
    .companies-table .company-first-table .cs-tbody tr td:nth-child(2) {
        width: 150px;
    }

    .companies-table .company-first-table .cs-thead tr th:nth-child(3),
    .companies-table .company-first-table .cs-tbody tr td:nth-child(3) {
        width: 200px;
    }
}


@media (max-width: 1199px) {

    .companies-table .accordion-body .cs-table.accordion-table th:last-child,
    .companies-table .accordion-body .cs-table.accordion-table td:last-child {
        min-width: 150px;
    }

    .companies-table .company-first-table {
        max-width: 1199px;
    }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */


/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {

    .companies-table .company-first-table .cs-thead tr th:nth-child(1),
    .companies-table .company-first-table .cs-tbody tr td:nth-child(1) {
        max-width: 450px;
    }
}


@media (max-width: 767px) {}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */


/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {

    .companies-table .company-first-table .cs-thead tr th:nth-child(1),
    .companies-table .company-first-table .cs-tbody tr td:nth-child(1) {
        width: 200px;
    }

    .companies-table .company-first-table .cs-thead tr th:nth-child(2),
    .companies-table .company-first-table .cs-tbody tr td:nth-child(2) {
        width: 100px;
    }

    .companies-table .company-first-table .cs-thead tr th:nth-child(3),
    .companies-table .company-first-table .cs-tbody tr td:nth-child(3) {
        width: 150px;
    }

    .delete-individual-modal .cs-modal-btn.cs-center-btn {
        padding-inline: 45px;
    }
}

@media (max-width: 425px) {
    .delete-individual-modal .cs-modal-btn.cs-center-btn {
        padding-inline: 30px;
    }
}


@media (max-width: 360px) {}


@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */