/* ================== */
/* Start - Auth CSS */
/* ================== */
.auth-section .cs-btn-primary {
  margin: 0 auto;
}

.auth-form-main-part {
  text-align: center;
}

/* .auth-left-area img {
  width: 80%;
} */

.auth-cs-logo {
  margin-bottom: 24px;
}

.auth-section .mobile-tooltip {
  display: none;
}

.signup .auth-section .form-check-label {
  margin-left: 8px;
}

.signup .auth-section .cs-form-check-box.form-check {
  margin-bottom: 32px;
}

.auth-section h1.default-semi-bold-h2 {
  margin-bottom: 16px;
}

.auth-section .formik-form-error {
  /* padding-top: 3px; */
}

.error-msg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
}

.head-underline {
  width: 282px;
  text-align: center;
  margin: 16px auto 16px;
  border: 1px solid var(--primary-main);
  opacity: 1;
  background: var(--darker);
}

.auth-left-part {
  background: var(--neutral-100);
  padding: 70px 50px;
  border-radius: 20px;
  height: 100%;
  text-align: center;
}

.auth-capsync-logo {
  width: 196px;
}

.auth-section {
  /* position: relative;
  padding-left: 20px;
  padding-right: 20px;
  height: 100vh; */
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: var(--neutral-10);
  overflow-x: hidden;
}

.auth-section .container-fluid {
  padding-left: 0;
}

.auth-left-area {
  height: 100%;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: normal;
  -ms-flex-align: normal;
  align-items: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; */
  background: var(--primary-canvas-surface);
  overflow: hidden;
}

.auth-section .align-items-center {
  height: 100vh;
}

.auth-section .align-items-center .col-lg-6 {
  height: 100%;
}

.auth-left-area .content-area {
  /* margin-top: 56px;
  color: var(--neutral-10);
  text-align: center; */
  position: relative;
  top: 7vh;
  left: 8vh;
  width: 400px;
  color: var(--neutral-10);
  z-index: 2;
}

.signup .cs-tooltip .tooltip-inner {
  min-width: 100%;
  width: 170px;
}

.auth-left-area .cs-btn-icon-secondary {
  width: 124px;
  height: 40px;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  margin-top: 24px;
  background-color: transparent;
}

.auth-image-box {
 /* position: relative;
  width: 100%;
  height: calc(100% - 13vw);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; */
      position: relative;
    width: 100%;
    height: calc(100% - 8vw);
      display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
-webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.auth-left-area img {
    position: relative;
  max-width: 100%;
  /* margin-top: -13vh; */
}

.auth-right-section {
  position: relative;
  height: calc(100vh - 30px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  /* padding-left: 163px;
  padding-right: 156px; */
  padding-inline: 9vw 7.7vw;
}

.auth-main-link {
  display: inline-block;
  border: 1px solid var(--neutral-50);
  background-color: var(--neutral-10);
  padding: 11px 36px;
  border-radius: 50px;
  color: var(--neutral-100);
  margin-top: 20px;
}

.auth-forgot-Link-text {
  margin-bottom: 16px;
  text-align: right;
}

.forgot-password .btn.cs-btn-primary {
  margin-top: 30px;
}

.forgot-password .auth-section .cs-form-group {
  margin-bottom: 16px;
}

.auth-form.form-box .row:nth-child(2) .col-lg-12 .cs-form-group {
  margin-bottom: 16px;
}

.signin .row:nth-child(2) .col-lg-12 .cs-form-group {
  margin-bottom: 8px;
}

.signin .auth-signin-btn {
  margin-top: 32px;
}

.auth-sign-up-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  height: 540px;
  margin-right: -6px;
  padding-right: 6px;
}

.auth-section .cs-logo {
  margin-bottom: 32px;
}

.signup-mf-auth-google-setup .qrcode img {
  border: 1px solid var(--neutral-50);
  border-radius: 5px;
  padding: 13px;
}

.back-to-sign-in .btn {
  text-decoration: none;
}

.mf-auth-emailpin .auth-section b {
  color: var(--primary-main);
}

/* start - email-verify-page */
.email-verify-page .auth-content-area {
  padding: 0 5vw;
}

.email-verify-page .auth-content-area .default-regular-sub-heading-s {
  margin-bottom: 32px;
}

.email-verify-page .auth-content-area .cs-btn-primary {
  margin: 8px auto;
}

.back-to-sign-in .cs-btn-tertiary {
  margin: 0 auto;
}

.profile.cs-avatar-lg .cs-avatar-md {
  width: 100%;
  height: 100%;
}

.profile.cs-avatar-lg .cs-avatar-md span {
  width: 40px;
  height: 40px;
}

.profile.cs-avatar-lg .cs-avatar-md span svg {
  width: 22px;
  height: 22px;
}

.cs-avatar-md div svg,
.cs-avatar-md svg {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

/* end - email-verify-success page  */
.email-verify-success-page .auth-right-section {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  height: calc(100vh - 64px);
}

.email-verify-success-page .auth-right-section .cs-btn-primary {
  margin-top: 32px;
}

.profile-update-popup .auth-mfa-options {
  text-align: left;
}

.profile-update-popup .auth-mfa-item {
  margin-block: 24px 0;
}

/* end - email-verify-success page */
/* ---------------------------forgot password--------------------------------------------------- */
.forgot-password p.cs-neutral-80 {
  text-align: center;
  margin-bottom: 24px;
}

.mf-auth-emailpin .otp-layout {
  margin: 24px 0px 16px 0px;
}

.mf-auth-emailpin .default-regular-h5 {
  margin-bottom: 30px;
}

.mf-auth-emailpin .cs-form-check-box.form-check {
  margin-block: 0 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.auth-mfa-item {
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px 24px;
}

.cs-auth-form div .auth-mfa-item:last-child {
  margin-bottom: 5px;
}

.auth-mfa-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
}

.auth-mfa-options {
  margin-right: 16px;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.otp-layout .otp-txt {
  width: 40px !important;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  height: 40px;
}

.otp-layout .otp-txt:focus-visible,
.otp-layout .otp-txt:active {
  border: 1px solid var(--neutral-50);
  outline: var(--neutral-50);
}

.auth-section .otp-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.auth-section .auth-right-section .otp-layout input.otp-txt {
  margin-left: 8px;
}

.auth-section .auth-right-section .otp-layout input.otp-txt:first-child {
  margin-left: 0px;
}

.auth-text-color-resend {
  color: var(--neutral-90);
}

.auth-agreeInvalid-gap {
  margin-top: 24px;
}

.input-field-icon.cursor-pointer.password-info {
  right: 44px;
}

.mfa .form-check .form-check-input,
.mf-auth-googlepin .form-check .form-check-input,
.mfa-pin-google .form-check .form-check-input {
  margin-left: 0;
  float: none;
  margin-right: 8px;
}

.signup hr.head-underline,
.signin hr.head-underline {
  margin-bottom: 24px;
}

.auth-mobile-setup .auth-cs-logo {
  margin-bottom: 24px;
}

.signup-mf-auth-phone-setup .cs-form-group {
  margin-bottom: 32px;
}

.signup-mf-auth-google-setup p.cs-neutral-100 {
  margin-bottom: 0px;
}

.signup-mf-auth-google-setup .qrcode {
  margin-bottom: 32px;
}

.signup-mf-auth-google-setup p.cs-neutral-80 {
  margin-bottom: 24px;
}

.mf-auth-phonepin .otp-layout {
  margin-top: 24px;
  margin-bottom: 8px;
}

.mf-auth-googlepin .cs-form-check-box.form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-block: 26px 16px;
}

.cs-ver-btn {
  margin-block: 0 22px !important;
}

.signin-mf-auth-setup .auth-section h4.cs-neutral-100.default-regular-h4,
.signin-mf-verification .auth-section h4.cs-neutral-100.default-regular-h4 {
  text-align: left;
}

.signin-mf-verification p.cs-neutral-80.default-regular-h5,
.signin-mf-auth-setup p.cs-neutral-80.default-regular-h5 {
  margin-bottom: 24px;
}

.mf-auth-phonepin .cs-form-check-box.form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-block: 0 16px;
  padding: 0;
}

.mf-auth-phonepin span.auth-phone-pin {
  margin-bottom: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.signup label.form-check-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.signup .cs-form-check-box.form-check {
  display: block;
}

.mf-auth-emailpin .otp-layout .auth-code-text-margin {
  margin: 32px 0px 8px 0px;
}

.mf-auth-googlepin p.QRauth-info {
  margin-top: 32px;
}

.mf-auth-googlepin .btn.cs-btn-primary {
  margin-top: 32px;
  margin-bottom: 16px;
}

.align-items-center .cs-auth-form .initial-reset-password {
  margin-top: 16px;
}

.cs-auth-form div .auth-mfa-item:last-child {
  margin-bottom: 5px;
}

.signup-checkbox .form-check-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0px;
  cursor: pointer;
}

.cs-auth-form .dropdown-toggle[disabled] {
  background-color: var(--neutral-40);
}

.otheruser-section .cs-auth-form p {
  padding: 0 54px;
  margin-bottom: 32px !important;
}

/* ---------------error msg ------------------ */
.signin .cs-msg,
.forgot-password .cs-msg {
  padding: 0px;
}

.cs-password-expire-box p {
  margin-bottom: 24px;
}

.auth-section .auth-left-area .default-semi-bold-h2 {
  font-size: 30px;
  line-height: 40px;
  color: var(--primary-main);
  margin-bottom: 0;
}

.content-area .default-regular-sub-heading-m {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--neutral-100);
  width: 380px;
  margin-top: 15px;
}

.cs-auth-form.auth-sign-up-scrollbar .cs-form-check-box .form-check-input {
  margin-top: 4px;
}

/* India and UK hiding for phone input */
.PhoneInput .PhoneInputCountrySelect,
.PhoneInput .PhoneInputCountrySelectArrow {
  display: none;
}

.mfa-pin-google .auth-right-section .auth-form .cs-msg {
  margin-top: 16px;
}

.auth-section .error-msg span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* ================== */
/* END - Auth CSS */
/* ================== */

/* ================== */
/* START Responsive */
/* ================== */
/* START Max-Height */
@media (max-height: 980px) {
  .auth-left-area img {
max-width: 80%;
  }
}

/* @media (max-height: 834px) {
} */
@media (max-height: 850px) {
  .auth-left-area {
    width: 100%;
  }
 
  .auth-sign-up-scrollbar {
    height: 380px;
  }
}

@media (max-height: 700px) {
  .auth-sign-up-scrollbar {
    height: 340px;
    margin-bottom: 80px;
  }
}

/* END Max-Height */

@media (max-width: 1799px) {
  .auth-section .dashboard-tooltip {
    display: none;
  }

  .auth-section .mobile-tooltip {
    display: block;
  }
}

@media (max-width: 1600px) {
  .auth-right-section {
    padding-inline: 80px;
  }
}

@media (max-width: 1499px) {
  .auth-main-link {
    padding: 15px;
  }
  .auth-image-box {
    width: 70%;
    height: calc(100% - 11vw);
    margin: 0 auto;
}
}

@media (max-width: 1199px) {
  .auth-right-section {
    padding-inline: 20px;
  }
}

@media (max-width: 991px) {
  .auth-section {
    height: 100vh;
    padding: 0;
  }

  .auth-section .container-fluid {
    padding-inline: calc(var(--bs-gutter-x) * 0.5);
  }

  .auth-right-section,
  .email-verify-success-page .auth-right-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    padding: 30px 65px 24px 65px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .auth-left-area {
    margin: 30px 0;
    height: auto;
  }

  .align-items-center .col-lg-6:first-child {
    display: none;
  }

  .auth-section .cs-btn-primary {
    line-height: 20px;
  }

  .auth-section .auth-main-link {
    padding: 16px;
    bottom: 24px;
  }

  .auth-section .cs-auth-form {
    width: 100%;
    height: auto;
  }

  .cs-auth-form.auth-sign-up-scrollbar .row .col-lg-6 {
    display: block;
  }

  .signin-mf-verification p.cs-neutral-80.default-regular-h5,
  .signin-mf-auth-setup p.cs-neutral-80.default-regular-h5 {
    margin-bottom: 24px;
  }

  .auth-mfa-item {
    margin-bottom: 16px;
  }

  .signin-mf-auth-setup .auth-section h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .signin-mf-auth-setup .auth-section .default-regular-h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .auth-right-section .auth-mfa-item svg {
    width: 20px;
    height: 20px;
  }

  .signup-mf-auth-phone-setup .cs-form-group {
    margin-bottom: 32px;
  }

  .mf-auth-phonepin .otp-layout {
    margin: 24px 0 16px 0;
  }

  .auth-section .auth-right-section span.default-light-body-text-s {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  .signup-mf-auth-google-setup p.cs-neutral-80 {
    margin-bottom: 24px;
  }

  .auth-right-section .auth-form p.default-light-body-text-m.cs-neutral-80 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .auth-section
    .auth-right-section
    span.form-error-msg.default-light-body-text-s.cs-danger {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
  }

  .forgot-password .btn.cs-btn-primary {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .auth-section .align-items-center {
    height: auto;
  }
}

@media (max-width: 767px) {
  .email-verify-page .auth-content-area {
    padding: 0 20px;
  }
}

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */

@media (max-width: 575px) {
  .auth-section .align-items-center {
    height: auto;
  }

  .auth-right-section,
  .email-verify-success-page .auth-right-section {
    /* padding: 60px 0 24px; */
    padding: 0;
    height: 100vh;
  }

  .cs-form-dropdown {
    margin-bottom: 16px;
  }

  .auth-section .auth-main-link {
    position: relative;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    bottom: 0;
    left: 0;
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
    padding: 8px 24px;
    /* margin-top: 24px; */
    margin-top: 16px;
  }

  .auth-right-section .cs-auth-form .input-field-icon svg {
    width: 16px;
    height: 16px;
  }

  .auth-right-section .default-regular-sub-heading-s {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .auth-mfa-item {
    padding: 12px 16px;
  }

  .auth-mfa-item .radio-button {
    width: 16px;
    height: 16px;
  }

  .email-verify-page .auth-content-area .default-regular-sub-heading-s {
    margin-bottom: 24px;
  }

  .auth-cs-logo {
    margin-bottom: 24px;
  }

  .auth-section .auth-phone-pin {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .auth-section .auth-right-section span.cs-neutral-80 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .auth-section .auth-right-section span.default-light-body-text-s {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  .auth-section .cs-logo {
    margin-bottom: 24px;
  }

  .auth-section .auth-cs-logo span.default-regular-sub-heading-s {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .auth-section .auth-right-section div.default-regular-sub-heading-s {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .auth-section
    .auth-right-section
    span.default-light-body-text-m.cs-neutral-9s0 {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  .auth-right-section .auth-form p.default-light-body-text-m.cs-neutral-80 {
    font-size: 12px;
    line-height: 16px;
  }

  .forgot-password .btn.cs-btn-primary {
    margin-top: 24px;
  }

  .auth-section .auth-right-section .cs-auth-form {
    padding: 0;
    margin: 0;
    height: auto;
  }

  .auth-section .auth-right-section .cs-auth-form .sign-up-form {
    max-height: 430px;
    overflow: hidden;
    overflow-y: auto;
  }
}

@media (max-width: 425px) {
  .email-verify-page .auth-content-area {
    padding: 0;
  }

  .input-field-icon.cursor-pointer.password-info {
    right: 32px;
  }
}

@media (max-width: 360px) {
}

@media (max-width: 320px) {
}

@media (max-width: 1199px) and (max-height: 615px) {
  /* .auth-right-section,
  .email-verify-success-page .auth-right-section {
    display: block;
    height: 100%;
    padding: 30px 65px 24px 65px;
  } */
  .auth-section .cs-auth-form {
    margin-bottom: 0;
  }
}
/* ============================= */
/* END Mobile - Viewport */
/* ============================= */

/* ================== */
/* END Responsive */
/* ================== */
