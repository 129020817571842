.settings-main-section .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.settings-main-section .card {
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
}

.settings-main-section .settings-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.settings-body .settings-mobile-wrapper .settings-dropdown {
  display: none;
}

.settings-main-section .settings-body .settings-tab-btn {
  padding: 16px 32px 0px 32px;
}

.settings-main-section .settings-body .account-details-page {
  padding: 32px;
}

.account-details-page .account-detail-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-right: 100px;
}

.settings-main-section .settings-body .account-details-page .profile-personal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.settings-main-section .profile .cs-avatar-md {
  position: relative;
}

.account-details-page .profile span.cs-icon-badge-primary {
  position: absolute;
  width: 28px;
  height: 28px;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.settings-main-section .profile .display-names {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-left: 24px;
}

.settings-main-section .profile .display-names p {
  margin: 0;
}

.settings-main-section
  .settings-body
  .account-details-page
  .cs-form-dropdown
  button {
  border: none;
  padding: 0;
}

.settings-main-section .account-details-page .cs-form-dropdown .google-map {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.settings-main-section .account-details-page .profile img {
  border-radius: 100%;
}

.settings-main-section
  .account-details-page
  .personal-inform-form
  .account-change-password
  span {
  margin-top: 10px;
  cursor: pointer;
}

.settings-main-section .account-details-page .personal-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.modal-body .personal-inform-form .cs-form-dropdown .form-control {
  padding: 0;
  border: 0;
  background: transparent !important;
}

.success-change-password,
.success-change-password p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.success-change-password img {
  margin-bottom: 24px;
}

.account-change-password button {
  display: none;
}

.modal-body
  .personal-inform-form
  .cs-form-group.account-form-field:nth-child(1) {
  margin-bottom: 24px !important;
}

.address-last-field {
  margin-bottom: 0;
}

.skeleton-account-box {
  width: 67%;
  padding: 32px;
}

.skeleton-profile {
  margin-right: 24px;
}

.skeleton-personal-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.skeleton-change-pass {
  margin-top: 10px;
}

.skeleton-account-tabs {
  padding: 16px 32px 0px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.skeleton-account-tabs .skeleton-account-tab {
  padding: 10px 0px;
  width: 15%;
  text-align: center;
}

.personal-inform-form .account-change-password span {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.personal-inform-form .mt-2 .cs-msg {
  margin-bottom: 0;
}

.skeleton-account-box .personal-inform-form .skeleton-forms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 24px;
}

.skeleton-account-box .personal-inform-form .row:last-child .skeleton-forms {
  margin-bottom: 0;
}

.skeleton-account-box .personal-inform-form .skeleton-forms .skeleton-label {
  width: 6vw;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1499px) {
  .account-details-page .account-detail-box {
    padding-right: 0;
  }
}

@media (max-width: 1440px) {
  .billing-subscription-page .col-lg-4 {
    width: 50%;
  }
}

@media (max-width: 1199px) {
  .settings-tab-btn.cs-tabs .cs-stroke-bottom-tab {
    width: auto;
  }

  .account-details-page .col-lg-9,
  .mfa-setup-page .col-lg-8 {
    width: 100%;
  }

  .billing-subscription-page .col-lg-4 {
    width: 80%;
  }

  .audit-logs-page .cs-tabs-flex .cs-tabs {
    margin-right: 16px;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .settings-main-section .settings-body .account-details-page {
    padding: 24px 48px;
  }

  .personal-inform-form .cs-form-group.last-form-field {
    margin-bottom: 24px;
  }

  .personal-inform-form .col-lg-4:last-child .cs-form-group.last-form-field {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .billing-subscription-page .col-lg-4 {
    width: 100%;
  }

  .settings-main-section .settings-body .account-details-page {
    padding: 24px 16px;
  }

  .settings-main-section .profile .display-names {
    display: none;
  }

  .settings-main-section
    .settings-body
    .account-details-page
    .profile-personal {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .settings-main-section
    .account-details-page
    .personal-information
    button.cs-btn-primary {
    display: none;
  }

  .settings-main-section .account-details-page .profile,
  .settings-main-section .account-details-page .personal-information {
    margin-bottom: 24px;
  }

  .settings-main-section .account-details-page .account-change-password button {
    display: block;
    padding: 8px;
    max-width: 100%;
  }

  .settings-main-section .account-details-page .account-change-password {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 8px;
    width: 100%;
  }

  .settings-main-section
    .account-details-page
    .personal-inform-form
    .account-change-password
    span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 8px !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    border: 2px solid var(--primary-main);
    -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    margin-top: 0;
    width: 100%;
    max-width: 151px;
    line-height: 20px;
    white-space: nowrap;
    max-width: 100%;
    margin: 0 24px 0 0;
  }

  .settings-main-section
    .account-details-page
    .personal-inform-form
    .account-change-password
    button {
    line-height: 20px;
    height: 36px;
  }

  .modal-body
    .personal-inform-form
    .cs-form-group.account-form-field:nth-child(1) {
    margin-bottom: 16px !important;
  }

  .cs-btn-primary:disabled,
  .cs-btn-icon-primary:disabled,
  .cs-btn-danger:disabled {
    border-width: 2px;
  }

  .personal-inform-form .cs-form-group.last-form-field {
    margin-bottom: 16px;
  }

  .personal-inform-form .col-lg-4:last-child .cs-form-group.last-form-field {
    margin-bottom: 0;
  }

  .skeleton-personal-information {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .skeleton-account-box {
    width: 100%;
  }

  .skeleton-profile {
    margin: 0;
  }

  .skeleton-account-box .profile-personal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .skeleton-account-box .personal-inform-form .skeleton-forms .skeleton-label {
    width: 14vw;
  }
}

@media (max-width: 425px) {
  .cs-radio-btn.cursor-pointer .cs-flex {
    margin-bottom: 6px;
  }
}

@media (max-width: 320px) {
  .settings-main-section .account-details-page .account-change-password {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }

  .settings-main-section
    .account-details-page
    .personal-inform-form
    .account-change-password
    button {
    margin: 16px 0 0 0;
    max-width: 100%;
  }

  .settings-main-section
    .account-details-page
    .personal-inform-form
    .account-change-password
    span {
    max-width: 100%;
    margin: 0;
  }
}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */
